/* eslint-disable no-nested-ternary */
/* global G */

/**
 * Removes an item from the current cart. Dispatches {@code G.CART, G.SET} event afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail = {} }) => {
  const { key, name, all = false } = detail[G.DATA]

  const adapter = app[G.ADAPTER][G.CART]
  const activeCart = adapter[G.DATA]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const newCart = {
    ...activeCart,
    value: {
      ...activeCart.value,
      positions: all ? [] : activeCart.value.positions.filter(position => (
        key ? position.key !== key : position.name !== name)),
    },
    refs: {
      ...activeCart.refs,
      article: all
        ? []
        : key
          ? activeCart.refs.article.filter(ref => ref !== key)
          : activeCart.refs.article,
    },
  }

  eventBus.dispatch(eventBus.type(G.CART, G.SET), { [G.DATA]: newCart })
}

export default fn
