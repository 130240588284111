/* global G */
import { pipe } from '@gaia/util'
import { moduleComposition } from '@gaia/trait/composition'
import { withGetterSetter, withObjectFreeze } from '@gaia/trait/with'
import { usesGetterSetter } from '@gaia/trait/uses'
import usesWindowHash from '@platform/trait/uses/WindowHash'
import EventAdapter from '@platform/adapter/event'

/**
 * Platform Web Application.
 *
 * Native consumer of Gaia Library
 *
 * @namespace Gaia.Web
 * @memberOf Gaia
 * @property {Application} app - Web Application
 */

const descriptor = 'platform.web'

/**
 * Web Application Composition
 *
 *
 * All deps are provided after instantiation has taken place
 * See src/bootstrap.js for more details.
 *
 * @namespace Web.Application
 * @memberOf Gaia.Web
 * @typedef {Object} Web.Application
 */
const webApplication = pipe(
  moduleComposition(descriptor),
  withGetterSetter(G.REF),
  withGetterSetter(G.SESSION),
  withGetterSetter(G.PROVIDER),
  withGetterSetter(G.ADAPTER),
  withGetterSetter(G.EVENTS),
  usesGetterSetter(G.EVENTS, EventAdapter()),
  usesWindowHash,
  withObjectFreeze,
)

export default webApplication
