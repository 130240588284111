/* global G */

import { getFirstItem } from 'lib/util'

/**
 * Updates the incoming cart (contained in {@code detail[G.DATA]}) on the server without touching
 * the users current cart. Will dispatch {@code G.CART, G.DONE, key} afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail = {} }) => {
  const data = detail[G.DATA]

  const adapter = app[G.ADAPTER][G.CART]
  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const { [G.API]: { type, version } = {} } = adapter[G.STATE] || {}

  try {
    const url = `/api/v${version}/${type}/${data.key}/default`

    const result = await httpAdapter[G.API].put({ url,
      params: {
        ...data,
        key: undefined,
      } })
    const response = getFirstItem(result)

    if (response._rev) {
      eventBus.dispatch(eventBus.type(G.CART, G.DONE, response.key), { [G.DATA]: response })
    }
  } catch (e) {
    console.error(e)
  }
}

export default fn
