/* eslint-disable implicit-arrow-linebreak,no-param-reassign */
/* global G */
import { pipe } from 'lib/util'

// const _getEmptyChildren = (obj, data) => Object
//   .keys(obj[G.CHILDREN])
//   .filter(key => !data.refs[key] && !data.value[key])

/**
 * Transforms Attributes based on Model Transformers
 *
 * Fills in empty refs...
 * and what else?
 *
 * @param {Gaia.Model.Spec} obj - data model composition
 * @return {function(*=): *}
 */
const modelTransformation = obj => (data) => {
  // !!obj[G.TRANSFORMER] && console.groupCollapsed(
  //   'sequenceModelTransformation',
  //   !!obj[G.TRANSFORMER],
  //   obj._name,
  //   obj[G.PROPS].key,
  // ) // , obj._name , _getEmptyChildren(obj, data), obj[G.TRANSFORMER])

  // if (obj[G.TRANSFORMER]) {
  //   const a = { ...obj[G.CACHE] } || {}
  //   const b = { ...obj[G.DATA] } || {}
  //   console.log('obj cache', a)
  //   console.log('obj data', b)
  //   console.log('data', data)
  // }

  // _getEmptyChildren(obj, data).reduce(
  !!obj[G.TRANSFORMER] && Object.keys(obj[G.TRANSFORMER]).reduce(
    (acc, key) => obj[G.TRANSFORMER][key](obj, data) && acc,
    data,
  )
  // !!obj[G.TRANSFORMER] && console.groupEnd()
  return data
}

/**
 * Recursively calls itself by passing each G.CHILDREN item of {@param obj} and its data, then calls
 * {@link modelTransformation} with {@param obj} and data.
 *
 * @param {Gaia.Model.Spec} obj - data model composition
 * @return {function(*): *}
 */
const modelTreeTransformation = obj => (data) => {
  Object.keys(obj[G.CHILDREN])
    .filter(key => obj[G.CHILDREN][key][G.TRANSFORMER])
    .reduce((acc, key) => {
      try {
        const attribute = obj[G.CHILDREN][key]
        const { type } = attribute[G.PROPS]
        acc[type][key] = modelTreeTransformation(attribute)(attribute[G.STATE][G.DATA])
      } catch (e) {
        console.warn(e)
      }
      return acc
    }, obj[G.STATE][G.DATA])

  return modelTransformation(obj)(data)
}

/**
 * Transforms {@param obj}'s G.STATE G.DATA by calling all G.TRANSFORMER functions of {@param obj}
 * and its G.CHILDREN items, recursively, from bottom to top.
 *
 * @param {Gaia.Model.Spec} obj - data model composition
 * @return {Gaia.Model.Spec} - data model composition
 */
const sequenceModelDataTransformation = obj => pipe(
  modelTreeTransformation(obj),
)(obj[G.STATE][G.DATA]) && obj

export default pipe(
  sequenceModelDataTransformation,
)
