/* global G */

/**
 * Fetches the count of unread messages for the logged-in user
 * and returns it.
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function({detail: *}): Promise<{newestMessage: *|null, count: *}|null>)|*}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.STATE]: groupState, [G.DATA]: groupData } = group
  const { [G.API]: { ref, type, version, listing } = {} } = groupState

  const countUrl = `/api/v${version}/${listing}/${ref}/${type}`

  const eventBus = app[G.ADAPTER][G.EVENTS]
  const httpAdapter = app[G.ADAPTER][G.HTTP]

  try {
    const { count } = await httpAdapter[G.API].get({ url: countUrl })

    groupData.count = count

    eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.DONE, groupName), { [G.DATA]: groupData })
  } catch (e) {
    console.error(e)
  }

  return null
}

export default fn
