/* eslint-disable max-len */
/* global G */
import { pipe } from 'lib/util'
import { withDescriptor, withObjectFreeze } from 'trait/with'
import { hasNamespace } from 'trait/has'
import { usesScopedNamespace, usesNamespace } from 'trait/uses'

const descriptor = 'adapter:Event'

/**
 * @namespace Gaia.Adapter.EventBus.EventTarget
 * @memberOf EventBus
 * @typedef EventBus.EventTarget
 *
 * @property {EventTarget} current
 */
const _eventTarget = { current: null }

const _get = () => _eventTarget.current

/**
 * Singleton _eventTarget initializer
 *
 * @return {EventTarget}
 * @private
 */
const _init = () => {
  _eventTarget.current = new EventTarget()
  return _get()
}

/**
 * Custom Event Initializer
 *
 * @param {string} type
 * @param {object} detail
 * @return {CustomEvent<unknown>}
 * @private
 */
const _event = (type, detail) => new CustomEvent(type, { detail })

/**
 * Type provider
 *
 * extracts event type string presentation
 * from registered types
 *
 * @param {Iterable} types - registered types
 * @param {Iterable} args - keys collection, ie domain, action
 * @return {*} type - string presentation
 * @private
 */
const _type = (types, ...args) => args.reduce((acc, item) => acc[item], types)

/**
 * Event Bus Adapter API
 *
 * @param {EventTarget} obj
 * @return {Readonly<{}>}
 */
const api = obj => adapter => Object.freeze(Object.create({}, {
  dispatchEvent: {
    /**
     * use simplified dispatch() instead
     * proxy to native event target dispatch event
     *
     * @deprecated
     */
    value: (...args) => obj.dispatchEvent(...args),
    iterable: true,
    enumerable: true,
  },
  addEventListener: {
    /**
     * use add() instead
     * @deprecated
     */
    value: (type, listener, ...options) => obj.addEventListener(type, listener, ...options),
    iterable: true,
    enumerable: true,
  },
  removeEventListener: {
    /**
     * use remove() instead
     * @deprecated
     */
    value: (type, listener, ...options) => {
      obj.removeEventListener(type, listener, ...options)
    },
    iterable: true,
    enumerable: true,
  },
  dispatch: {
    /**
     * @memberOf EventBus#
     * @typedef {function} EventBus.dispatch
     *
     * proxy for EventTarget.dispatchEvent {@link EventTarget.dispatchEvent}
     *
     * @param {string} type - event name type
     * @param {object} detail - event payload object
     */
    value: (type, detail) => {
      obj.dispatchEvent(_event(type, detail))
    },
    iterable: true,
    enumerable: true,
  },
  add: {
    value: (type, listener, ...options) => {
      obj.addEventListener(type, listener, ...options)
    },
    iterable: true,
    enumerable: true,
  },
  remove: {
    value: (type, listener, ...options) => {
      obj.removeEventListener(type, listener, ...options)
    },
    iterable: true,
    enumerable: true,
  },
  /**
   * @memberOf EventBus#
   * @typedef {function} EventBus.type
   *
   * Registered Event Types Resolver with Arguments
   *
   * uses args to return registered event type appended with arguments
   *
   * @param domain - type domain, ie G.ATTACHMENT
   * @param action - type action, ie G.UPDATE
   * @param args - collection of keys to append, ie uuid
   * @return {string} type - appended event type
   */
  type: {
    value: (domain, action, ...args) => args.reduce(
      (acc, item) => `${acc}:${item}`,
      _type(adapter[G.PROPS], domain, action),
    ),
    iterable: true,
    enumerable: true,
  },
}))

/**
 * Native Event Bus Adapter
 *
 * used in pub/sub scenarios
 * event handling on global and application scopes
 * used for attaching and dispatching custom events with dynamic payloads
 *
 * @memberOf Gaia.Adapter#
 * @typedef {EventTarget | object} Gaia.Adapter.EventBus
 * @property {function} add - proxy for EventTarget.addEventListener
 *   {@link EventTarget.addEventListener}
 * @property {function} remove - proxy for EventTarget.removeEventListener
 *   {@link EventTarget.removeEventListener}
 * @property {function} dispatch - proxy for EventTarget.dispatchEvent {@link EventBus.dispatch}
 * @property {function} type - resolver for registered event types {@link EventBus.type}
 *
 * @param {Gaia.Web.Application } obj - native application composition
 */
const adapterFn = () => {
  const adapter = pipe(
    withDescriptor(descriptor),
    hasNamespace(G.PROPS),
    usesNamespace(G.PROPS, withObjectFreeze({
      [G.ATTACHMENT]: withObjectFreeze({
        [G.CREATE]: 'gaia:attachment:create',
        [G.READ]: 'gaia:attachment:read',
        [G.UPDATE]: 'gaia:attachment:update',
        [G.DELETE]: 'gaia:attachment:purge',
        [G.DONE]: 'gaia:attachment:done',
        [G.DATA]: 'gaia:attachment:add',
        [G.CACHE]: 'gaia:attachment:set',
        [G.REMOVE]: 'gaia:attachment:remove',
        [G.APPLY]: 'gaia:attachment:apply',
        [G.UNDO]: 'gaia:attachment:undo',
        [G.INIT]: 'gaia:attachment:init',
        [G.DESTROY]: 'gaia:attachment:destroy',
        [G.HTTP]: 'gaia:attachment:http',
      }),
      [G.ACL]: withObjectFreeze({
        [G.CACHE]: 'gaia:acl:set',
        [G.DELETE]: 'gaia:acl:delete',
      }),
      [G.MESSAGE]: withObjectFreeze({
        [G.DATA]: 'gaia:message:set',
        [G.CREATE]: 'gaia:message:create',
        [G.UPDATE]: 'gaia:message:update',
        [G.DELETE]: 'gaia:message:delete',
        [G.ADD]: 'gaia:message:add',
        [G.DONE]: 'gaia:message:done',
      }),
      [G.CART]: withObjectFreeze({
        [G.CREATE]: 'gaia:cart:create',
        [G.INIT]: 'gaia:cart:init',
        [G.READ]: 'gaia:cart:read',
        [G.REMOVE]: 'gaia:cart:remove',
        [G.UPDATE]: 'gaia:cart:update',
        [G.SET]: 'gaia:cart:set',
        [G.CHANGE]: 'gaia:cart:change',
        [G.CACHE]: 'gaia:cart:cache',
        [G.DELETE]: 'gaia:cart:delete',
        [G.ACTIVATE]: 'gaia:cart:activate',
        [G.APPLY]: 'gaia:cart:apply',
        [G.ADD]: 'gaia:cart:add',
        [G.DONE]: 'gaia:cart:done',
      }),
      [G.SETTINGS]: withObjectFreeze({
        [G.INIT]: 'gaia:settings:init',
        [G.DONE]: 'gaia:settings:done',
      }),
      [G.NOTE]: withObjectFreeze({
        [G.DATA]: 'gaia:note:set',
        [G.CREATE]: 'gaia:note:create',
        [G.UPDATE]: 'gaia:note:update',
        [G.DELETE]: 'gaia:note:delete',
        [G.DONE]: 'gaia:note:done',
      }),
      [G.DATA]: withObjectFreeze({
        [G.UPDATE]: 'gaia:data:update',
        [G.UNDO]: 'gaia:data:undo',
        [G.INIT]: 'gaia:data:init',
        [G.DESTROY]: 'gaia:data:destroy',
        [G.DONE]: 'gaia:data:done',
      }),
      [G.NOTIFICATION]: withObjectFreeze({
        [G.INIT]: 'gaia:notification:init',
        [G.DESTROY]: 'gaia:notification:destroy',
        [G.CREATE]: 'gaia:notification:create',
        [G.DONE]: 'gaia:notification:done',
      }),
      [G.LOAD]: withObjectFreeze({
        [G.INIT]: 'gaia:load:init',
        [G.DONE]: 'gaia:load:done',
      }),
      [G.FRAME]: withObjectFreeze({
        [G.INIT]: 'gaia:frame:init',
        [G.DONE]: 'gaia:frame:done',
      }),
      [G.HTTP]: withObjectFreeze({
        [G.ONLINE]: 'gaia:http:online',
        [G.UPDATE]: 'gaia:http:update',
      }),
    })),
    hasNamespace(G.API),
    usesScopedNamespace(G.API, api(_init())),
    withObjectFreeze,
  )({})
  return adapter[G.API]
}

export default adapterFn
