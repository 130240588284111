/* eslint-disable no-use-before-define */
/* global G */
import { isStr, pipe } from 'lib/util'
import cancelOnError from 'lib/sequence/model/cancelOnError'
import sequenceGetValueFromModel from 'lib/sequence/model/attribute/getValue'
import { empty } from 'lib/util/object'

const descriptor = 'sequence::model::create'

/**
 * Creates the {@param item}'s payload and either returns it inside an array or returns null.
 *
 * @param {Gaia.Model.Spec} item a model to create
 * @returns {*[]|null}
 */
const createChild = (item) => {
  const data = create(item)[G.STATE][G.DATA]
  return data ? [data] : null
}

/**
 * Prepares the G.STATE G.DATA structure of a model object according to the following:
 *
 * The values inside G.DATA should be always prioritized. If it contains something, we shouldn't
 * look inside G.CACHE.
 *
 * The key and _rev properties of a model object can be changed by the server and set to G.CACHE or
 * changed by the user and set to G.DATA.
 *
 * @param {Gaia.Model.Spec} obj
 * @return {object}
 * @private
 */
const _dataStructure = (obj) => {
  const cache = obj[G.CACHE]?.[0] || obj[G.CACHE]
  const ref = cache && isStr(cache) ? cache : cache?.key
  const data = obj[G.DATA].value?.[0] || obj[G.DATA].value || obj[G.DATA]
  const { _rev } = cache || data || {}
  const { key = ref, _id } = data || {}
  const { type } = obj[G.STATE][G.DATA] || {}

  return {
    ...key && { key },
    ..._rev && { _rev },
    ..._id && { _id },
    ...type && { type },
  }
}

/**
 * Sync Model Create Sequence
 *
 * we expect G.DATA to exist in model composition
 *
 * iterate over model's attributes (G.CHILDREN)
 * map value, found via key, to payload object
 *
 *
 * @param {Gaia.Model.Spec} obj
 * @return {Gaia.Model.Spec}
 */
const sequenceModelCreateFn = (obj) => {
  const objState = obj[G.STATE]
  objState[G.DATA] = _dataStructure(obj)
  // creating payload from children objects
  Object.keys(obj[G.CHILDREN]).reduce((acc, key) => {
    const item = obj[G.CHILDREN][key]
    const { type } = item[G.PROPS]
    try {
      const child = item[G.CHILDREN]
        ? createChild(item)
        : sequenceGetValueFromModel(obj, key);

      (child || child === '') && (acc[type] = { ...acc[type], [key]: child })
    } catch (e) {
      throw Error(e)
    }
    return acc
  }, objState[G.DATA])
  // deleting object if no data could be deduced
  empty(objState[G.DATA]) && delete objState[G.DATA]
  return obj
}

const create = obj => pipe(
  cancelOnError(descriptor),
  sequenceModelCreateFn,
)(obj)

export default create
