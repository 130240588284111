/* eslint-disable no-unused-vars */
/* global G */

/**
 * Executes {@code G.CART, G.SET} with the users current cart
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail = {} }) => {
  const adapter = app[G.ADAPTER][G.CART]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const currentCart = adapter[G.DATA]

  eventBus.dispatch(eventBus.type(G.CART, G.SET), { [G.DATA]: currentCart })
}

export default fn
