import { curry } from 'lib/util'

import validatorComposition from 'trait/composition/validator'

/**
 * @memberOf Gaia
 * @typedef {{
 *    [G.DESCRIPTOR: string,
 *    [G.FN]: Function,
 *    [G.STATE]: Object
 * }} Gaia.Validator
 *
 */

/**
 * Validator Factory.
 *
 * Converts provided validator types into validator object composition.
 *
 * @param {Gaia.requireLoaders} loaders - collection of loaders
 * @param {Array} validators - validators map
 * @return {Gaia.Validator[]} validators - validator modules array {@link Gaia.Validator}
 */
export const validatorFactoryFn = (loaders, validators) => validators.map(
  validator => validatorComposition({
    ...validator,
    fn: loaders.validator(validator.type),
  }),
)

export default curry(validatorFactoryFn)
