/* global G */
import { getFirstItem } from 'lib/util'

/**
 * Updates the user document
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {Object} options            options for the post
 * @param {Object} options.user       user document
 * @param {Object} options.settings   userSettings document
 * @param {Object} options.api        api options
 * @returns {Promise<*>}
 * @private
 */
const _update = async (app, options) => {
  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const { settings, user, api: { version } } = options

  const params = {
    ...user,
    key: undefined,
    refs: {
      ...user.refs,
      settings: [settings.id],
    },
  }

  const url = `/api/v${version}/user/${user.key}`

  return getFirstItem(await httpAdapter[G.API].put({ url, params }))
}

export {
  _update as default,
}
