import sequenceComponentState from 'lib/sequence/component/state/index'

const {
  get: disabled,
  set: disable,
  unset: enable,
  toggle,
} = sequenceComponentState('disabled')

const sequenceComponentStateDisabled = {
  disabled,
  disable,
  enable,
  toggle,
}

export {
  sequenceComponentStateDisabled as default,
  disabled,
  disable,
  enable,
  toggle,
}
