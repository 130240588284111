/**
 * Validator Async Loader.
 *
 * Async Await Implementation of a dynamic import functionality.
 *
 * Uses webpack v4 chunk mechanism
 * Requires babel dynamic import plugin
 *
 * @memberOf Gaia.asyncLoaders
 * @typedef {Function} asyncValidatorLoader
 * @param {string} type - validator type
 * @return {Promise<*>} validator module
 */
export default async type => await import(/* webpackChunkName: "validator." */`lib/validator/${type}`)
  .then(module => module.default);
