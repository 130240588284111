/* eslint-disable no-param-reassign */
/* global G */
import modelFlatten from 'lib/sequence/model/api/flatten'
import modelCreate from 'lib/sequence/model/api/create'
import modelTransform from 'lib/sequence/model/transformer'
import { asyncpipe, isArr, pipe } from 'lib/util'

/**
 * Retrieves id and rev from the request's {@param result} and updates the {@param model}'s
 * cache and state with their values.
 *
 * @param {Gaia.Model.Spec} model the request's model object
 * @param {object} result         the request's response
 * @private
 */
const _updateModel = (model, result) => {
  const state = model[G.STATE] || {}
  const cache = model[G.CACHE] || {}
  // TODO: update id to key when/if the server does it
  const { ok, id, rev } = result
  if (ok) {
    state[G.REF] = id
    cache.key = id
    cache._rev = rev
  }
}

/**
 * Attempts to create or update an array of models depending on whether they have cache key and
 * according to their state data.
 *
 * @param {Gaia.Component.Spec} endpoint - endpoint url
 * @returns {function(*): Promise<*>}
 */
const bulkData = endpoint => async (models) => {
  const [model] = models
  const { version } = model[G.PROPS]
  const params = models.map(item => item[G.STATE]?.[G.DATA] || item)
  const url = `/api/v${version}/${endpoint || 'bulk'}`
  const result = await model[G.ADAPTER][G.HTTP].post({ url, params })
  result && isArr(result) && result.forEach((item, index) => _updateModel(models[index], item))
  return models
}

/**
 * Adds a type property indicating the model name to the model's state data.
 *
 * @param {Gaia.Model.Spec} model
 * @returns {*}
 */
const modelAddType = (model) => {
  const modelState = model[G.STATE]
  const { api } = model[G.PROPS]
  modelState[G.DATA].type = api
  return model
}

/**
 * Attempts to concatenate all data from all attachments properties of type 'refs' among the models
 * to the payload.
 *
 * @param {Gaia.Model.Spec[]} items
 * @returns {*}
 */
const flattenAttachments = (items) => {
  const attachments = items.reduce((acc, item) => {
    const { attachments: itemAttachments } = item[G.CHILDREN]
    return itemAttachments?.[G.PROPS]?.type === 'refs' && item[G.DATA].attachments
      ? acc.concat(item[G.DATA].attachments)
      : acc
  }, [])
  return items.concat(attachments)
}

/**
 * Model Bulk Sequence.
 *
 * Executes model data remote submission, based on model cache and reference and children models.
 *
 * @param {Gaia.Model.Spec} obj - app module composition
 * @param {object} [options]
 * @return {function(...[*]): Promise<*[]>}
 */
export default async (obj, options = {}) => await asyncpipe(
  modelFlatten,
  items => items.map(pipe(
    modelCreate,
    modelTransform,
    modelAddType,
  )),
  flattenAttachments,
  bulkData(options?.endpoint || null),
)(obj)
