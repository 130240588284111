/* global G */
import { sequenceModalModeOff } from 'lib/sequence/app/session/state/modal'

/**
 * Effect Restore Cached Application
 *
 * restores application state to previous module and action
 * resets cache as we just restored it
 *
 * it is used in case of routing from modal context
 * it does an internal ui update call
 *
 * @param obj
 * @return {function(...[*]): *[]}
 */
const restoreCachedApp = obj => (...args) => {
  const sessionState = obj[G.SESSION][G.STATE]
  // restore application to previous module and action
  sessionState[G.CONTEXT] = sessionState[G.CACHE][G.CONTEXT]
  sessionState[G.MODULE] = sessionState[G.CACHE][G.MODULE]
  sessionState[G.ACTION] = sessionState[G.CACHE][G.ACTION]

  // reset cache as we just restored it
  sessionState[G.CACHE] = null

  // update ui, to get rid of modal, if open
  sequenceModalModeOff(sessionState[G.MODULE])()
  obj[G.ADAPTER][G.UI][G.API].update(sessionState[G.MODULE])
  return args
}

export default restoreCachedApp
