import init from 'platform/adapter/settings/api/init'
import change from 'platform/adapter/settings/api/change'
import create from 'platform/adapter/settings/api/create'
import apply from 'platform/adapter/settings/api/apply'
import set from 'platform/adapter/settings/api/data/set'
import get from 'platform/adapter/settings/api/data/get'
import unset from 'platform/adapter/settings/api/data/unset'

const api = app => Object.create({}, {
  // methods that interact with server
  init: {
    value: init(app),
    iterable: true,
    enumerable: true,
  },
  change: {
    value: change(app),
    iterable: true,
    enumerable: true,
  },
  create: {
    value: create(app),
    iterable: true,
    enumerable: true,
  },
  apply: {
    value: apply(app),
    iterable: true,
    enumerable: true,
  },
  // methods to interact with the data
  set: {
    value: set(app),
    iterable: true,
    enumerable: true,
  },
  setFavorite: {
    value: args => set(app)({ ...args, ns: 'favorites' }),
    iterable: true,
    enumerable: true,
  },
  unset: {
    value: unset(app),
    iterable: true,
    enumerable: true,
  },
  unsetFavorite: {
    value: args => unset(app)({ ...args, ns: 'favorites' }),
    iterable: true,
    enumerable: true,
  },
  get: {
    value: get(app),
    iterable: true,
    enumerable: true,
  },
  getFavorite: {
    value: args => get(app)({ ...args, ns: 'favorites' }),
    iterable: true,
    enumerable: true,
  },
})

export default api
