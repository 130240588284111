import { curry } from 'lib/util'
import { withDependencyCheck } from 'trait/with'

const trait = 'usesScopedNamespace'

/**
 * Uses Namespace
 *
 * Sets value to namespace symbol, used in obj.
 * Since the spread operator is used on the value object,
 * Properties should be enumerable.
 *
 * @example
 * import { pipe } from '@gaia/util'
 * import { hasNamespace } from '@gaia/trait/has'
 * import { usesNamespace } from '@gaia/trait/uses'
 *
 * const someValue = { foo: 'bar' }
 * const someSymbol = 'baz'
 *
 * const composition = pipe(
 *    hasNamespace(someSymbol),
 *    usesScopedNamespace(someValue)(someSymbol),
 * )
 *
 *
 * @param { function } value - value(s) object to be used once scope has been provided
 * @param { Symbol } symbol - symbol identifier
 * @param { Module.Spec | AppModule.Spec } obj - object composition
 * @return { Module.Spec | AppModule.Spec } obj - object composition
 */
const usesScopedNamespace = (symbol, value, obj) => {
  withDependencyCheck(trait, [symbol], obj)
  Object.assign(obj[symbol], { ...value(obj) })
  return obj
}

export default curry(usesScopedNamespace)
