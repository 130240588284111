/* global G */
import { getUrl } from '@platform/adapter/message/helper'

/**
 * Attempts to delete a message from the server. On success, it also removes it from the adapter's
 * data and then dispatches a G.DONE event.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => async ({ detail }) => {
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapter = app[G.ADAPTER][G.MESSAGE]
  const adapterData = adapter[G.DATA]
  const { key } = detail[G.DATA]

  const url = getUrl(app, { key })
  const result = await app[G.ADAPTER][G.HTTP][G.API].delete({ url })

  if (result.ok) {
    const itemIndex = adapterData.findIndex(dataItem => dataItem.key === key)
    adapterData.splice(itemIndex, 1)

    eventBus.dispatch(eventBus.type(G.MESSAGE, G.DONE), { [G.DATA]: adapterData })
  }
}

export default fn
