/* global G */
import validateSequence from 'lib/sequence/model/validate'
import sequenceComponentFindProxy from 'lib/sequence/component/children/find'

const descriptor = Symbol('hook::model::validateAttribute').toString()

/**
 * Model Validate Attribute Hook
 *
 * Validates a sub-model using {@link validateSequence}. Will return the validated sub-model
 * afterward alongside the {@code args}.
 *
 * @param {string} attribute          the model to validate
 * @param {string|null} [component]   the component to get the data from. If not present, the action
 *                                    component will be used
 * @return {function(...[*]): *[]}
 */
const modelValidateAttribute = (attribute, component = null) => obj => async (...args) => {
  const actionComponent = obj[G.STATE][G.ACTION][G.COMPONENT]
  try {
    const { [component]: validationComponent } = sequenceComponentFindProxy(actionComponent)
    await validateSequence(obj[G.MODEL][G.CHILDREN][attribute])(validationComponent || actionComponent)
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }
  // Returning the validated model so that the action logic may use it to do stuff
  return [obj[G.MODEL][G.CHILDREN][attribute], ...args]
}

export default modelValidateAttribute
