/* eslint-disable no-undefined,function-paren-newline,implicit-arrow-linebreak */
/* global G */

/**
 * Adds a message to the adapter's G.DATA list of messages and dispatches a G.DONE event.
 * It does not attempt to create the message on the server. This is only used for
 * adding an already existing message to the adapter's G.DATA list of messages.
 * If the message already exists in the set, it doesn't do anything.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => async ({ detail }) => {
  const adapter = app[G.ADAPTER][G.MESSAGE]
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapterData = adapter[G.DATA]
  const item = detail[G.DATA]

  /**
   * When we create a new message (by submitting the form), the {@code G.MESSAGE, G.CREATE}
   * event handler in {@code client/platform/web/src/lib/adapter/message/api/create.js}
   * just gets the local message (without message ID), it then creates the message on
   * the server, fetches its ID, extends the local message with the remote data and
   * adds it to the state. All good!
   *
   * However, in some rare occasions, socketio is so fast (after {@code POST}ing the message)
   * that this event handler gets triggered with the new message ID BEFORE the {@code create}
   * message event handler fetched the ID itself (and adds the enriched message to the state).
   *
   * This means this piece of code:
   * {@code adapterData.some(message => message.key === item.key)} would return {@code false}
   * (meaning we haven't added the message yet) even though we have because we compare the
   * local message ({@code message.key === undefined}) to the fetched message from socketio.
   *
   * We need to prevent this, there are two scenarios:
   * - If {@code message.key !== undefined} simply compare {@code message.key} to {@code item.key}
   * - If it is however, is it the last item in the array? If so, return {@code true}
   *
   * With this, we prevent this from happening.
   */
  if (
    !adapterData.some((message, i, arr) => (
      // MAYBE: Replace {@code i === arr.length - 1} with {@code true} to be more generic?
      message.key !== undefined ? message.key === item.key : i === arr.length - 1),
    )
  ) {
    adapterData.push(item)
    eventBus.dispatch(eventBus.type(G.MESSAGE, G.DONE), {
      [G.DATA]: adapterData,
    })
  }
}

export default fn
