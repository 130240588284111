/* global G */
import { curry } from 'lib/util'

/**
 * Module's Model Reference Setter
 *
 * requirement for model sequences, ie read()
 *
 * @type {function(...[*]=)}
 */
const modelInitFn = (obj, ref, args) => {
  const { [G.MODEL]: model } = obj[G.SESSION][G.STATE][G.MODULE]
  ref && model && (model[G.STATE][G.REF] = ref)
  return args
}

const modelInit = curry(modelInitFn)

export default modelInit
