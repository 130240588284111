import sequenceComponentState from 'lib/sequence/component/state/index'

const {
  get: checked,
  set: check,
  unset: uncheck,
  toggle,
} = sequenceComponentState('checked')

const sequenceComponentStateChecked = {
  checked,
  check,
  uncheck,
  toggle,
}

export {
  sequenceComponentStateChecked as default,
  checked,
  check,
  uncheck,
  toggle,
}
