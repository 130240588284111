/* eslint-disable implicit-arrow-linebreak,no-param-reassign */
/* global G */
import { def, isObj } from 'lib/util'

/**
 * Sets {@param model}'s own and children data from every property inside {@param data} the model
 * also has.
 *
 * @param {Gaia.Model.Spec} model the model whose data to set
 * @param {object} data           an object defining new values for the model properties' data
 * @returns {*}
 */
const setData = (model, data) => {
  const keys = Object.keys(data).filter(key =>
    def(model[G.CHILDREN][key]) || console.warn(`No property ${key} found in model ${model._name}`))

  keys.forEach((key) => {
    const attribute = model[G.CHILDREN][key]
    const value = data[key]
    if (value !== null && def(value) && isObj(value) && attribute[G.CHILDREN]) {
      setData(attribute, value)
    }
    model[G.DATA][key] = value
  })

  return model
}

const api = {
  setData,
}

export {
  api as default,
  setData,
}
