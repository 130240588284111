/* global G */
import { setKey } from 'lib/util'

const attr = G.PROPS
const prop = G.ROUTE

const setPath = (component) => {
  const path = component[attr][prop] || [component[G.PROPS].key]

  setKey(path, prop, component[attr])

  component[G.CHILDREN].map((item) => {
    setKey([...path, item[G.PROPS].key], prop, item[attr])
    item[G.CHILDREN] && item[G.CHILDREN].length > 0 && setPath(item)
    return item
  })
}

/*
 model.api - person
 model.attachment.api - attachment
 component.acl - person
 component.attachment.acl - attachment
 component.attachment.list.acl - attachment
 component.button.acl - person
 component.address.acl - person
 component.list.acl - person
 component.organisation.acl - organisation
 component.organisation.address.acl - organisation
*/
const providePath = (obj) => {
  // // iterate over actions, if component go deep
  Object.keys(obj[G.ACTIONS]).reduce((acc, action) => {
    acc[action][G.COMPONENT] && setPath(acc[action][G.COMPONENT])
    return acc
  }, obj[G.ACTIONS])
  return obj
}

export default providePath
