/* eslint-disable no-param-reassign */
/* global G */

import { isArr } from 'lib/util'

/**
 * Removes an item from the adapters {@code G.DATA}
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ key, ns = null }) => {
  const settings = app[G.ADAPTER][G.SETTINGS][G.DATA]

  const target = ns ? settings[ns] : settings

  if (isArr(target)) {
    const alreadyAdded = target.findIndex(x => x?.key === key)
    alreadyAdded !== -1 && target.splice(alreadyAdded, 1)
  } else {
    target[key] = undefined
  }

  return await app[G.ADAPTER][G.SETTINGS][G.API].apply()
}

export default fn
