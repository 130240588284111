import { makeStyles, createStyles } from '@mui/styles'

/**
 * Material Ui Hook useStyles.
 *
 * Removes the need to imperatively use HoC withStyles()
 *
 * @param {function} styles styles function
 * @param {Object} props    additional props
 * @return {() => Object}
 */
const useStyles = (styles, props = {}) => makeStyles(theme => createStyles(styles(theme, props)))

export default useStyles
