import { curry } from 'lib/util'
import showConflictErrorDialog from './error/conflict'
import showForbiddenErrorDialog from './error/forbidden'
import showUnauthorizedErrorDialog from './error/unauthorized'
import showErrorDialog from './error/generic'
import showTooManyRequestsErrorDialog from './error/tooManyRequests'

const dialogs = {
  400: showErrorDialog,
  401: showUnauthorizedErrorDialog,
  403: showForbiddenErrorDialog,
  409: showConflictErrorDialog,
  429: showTooManyRequestsErrorDialog,
  500: showErrorDialog,
}

const showDialog = (obj, code) => dialogs[code]?.(obj)

/**
 * Error middleware handler.
 *
 * Shows an error dialog depending on the HTTP status code received in a server's response.
 *
 * @type {Gaia.Adapter.Http.Middleware}
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {function} next             the next middleware function
 * @param {object} args               the request's arguments
 * @return {object}                   the response object
 */
export default curry(async (obj, next, args) => {
  let result
  try {
    result = await next(args)
  } catch (e) {
    showDialog(obj, e.code)
    throw e
  }
  return result
})
