/* global G */
import { useContext, useRef, useState } from 'react'
import ApplicationContext from '@platform/react/context/application'
import useEventHandler from '@platform/react/hook/useEventHandler'
import useEventCallback from '@platform/react/hook/useEventCallback'

/**
 * Listens to G.DONE events and keeps its state up to date by filling it with the new notes.
 *
 * @returns {object} state  the hook state
 */
const useNoteAPI = () => {
  const { eventBus } = useContext(ApplicationContext)

  const [state, setState] = useState({
    notes: [],
  })

  const _msgEventName = useRef(eventBus.type(G.NOTE, G.DONE))
  const _msgEventHandler = useEventCallback(({ detail }) => {
    const { [G.DATA]: notes } = detail
    setState({ notes })
  })
  useEventHandler(eventBus, _msgEventName.current, _msgEventHandler)

  return { state }
}

export default useNoteAPI
