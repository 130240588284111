/* global G */
import { curry, getFirstItem, setKey } from 'lib/util'
import getUserRole from 'app/_shared/events/user/role'

/**
 * Sets META flag in session state
 *
 * @param {Gaia.Web.Spec} obj - native application composition
 * @param {*} args - arguments
 * @return {function(*=): *}
 */
const sequenceAppSessionStateMetaSetFn = (obj, args) => setKey(
  args,
  G.META,
  obj[G.SESSION][G.STATE],
) && args

const sequenceAppSessionStateRoleSetFn = (obj, args) => {
  const user = getFirstItem(args?.refs?.user)
  const userRole = getUserRole(user.value.roles, obj, null, null)

  return { role: userRole, ...args }
}

const sequenceAppSessionStateRoleSet = curry(sequenceAppSessionStateRoleSetFn)
const sequenceAppSessionStateMetaSet = curry(sequenceAppSessionStateMetaSetFn)

export {
  sequenceAppSessionStateMetaSet as default,
  sequenceAppSessionStateRoleSet,
}
