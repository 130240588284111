/* eslint-disable no-unused-vars */
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import options from '@tenant/theme'
import IconPreface from 'ui/Element/Button/IconPreface'

const styles = {
  palette: {
    white: {
      main: '#FFFFFF',
      dark: '#E5E5E5',
      contrastText: '#151515',
    },
    black: {
      main: '#000000',
      contrastText: '#FFFFFF',
    },
    transparent: {
      main: 'rgba(0, 0, 0, 0)',
      dark: '#E5E5E5',
      light: 'rgba(255, 255, 255, 0.9)',
      contrastText: '#151515',
    },
    gray: {
      main: '#F2F2F2',
      dark: '#E5E5E5',
      light: '#FAFAFA',
      contrastText: '#151515',
      400: '#666666',
      500: '#808080',
      600: '#999999',
      700: '#B3B3B3',
      800: '#CCCCCC',
      900: '#E5E5E5',
      930: '#EDEDED',
      950: '#F2F2F2',
      960: '#F5F5F5',
      ...options.palette.gray,
    },
    pending: {
      50: '#EFF8FF',
      300: '#84CAFF',
      500: '#2E90FA',
      700: '#175CD3',
    },
    warning: {
      50: '#FFFAEB',
      300: '#FEC84B',
      500: '#F79009',
      700: '#B54708',
    },
    error: {
      50: '#FEF3F2',
      300: '#FDA29B',
      500: '#F04438',
      700: '#B42318',
    },
    success: {
      50: '#ECFDF3',
      300: '#6CE9A6',
      500: '#12B76A',
      700: '#027A48',
    },
  },
  typography: {
    '32/regular': {
      fontSize: '2rem',
      fontWeight: 400,
      lineHeight: '2.5rem',
    },
    '32/medium': {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: '2.5rem',
    },
    '32/bold': {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '2.5rem',
    },
    '24/regular': {
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '2rem',
    },
    '24/medium': {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '2rem',
    },
    '24/bold': {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '2rem',
    },
    '20/regular': {
      fontSize: '1.25rem',
      fontWeight: 400,
      lineHeight: '1.75rem',
    },
    '20/medium': {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: '1.75rem',
    },
    '20/bold': {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.75rem',
    },
    '18/regular': {
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: '1.625rem',
    },
    '18/medium': {
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '1.625rem',
    },
    '18/bold': {
      fontSize: '1.125rem',
      fontWeight: 700,
      lineHeight: '1.625rem',
    },
    '16/regular': {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },
    '16/medium': {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    '16/semibold': {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1.5rem',
    },
    '16/bold': {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
    },
    '14/regular': {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.375rem',
    },
    '14/medium': {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '1.375rem',
    },
    '14/semibold': {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1.375rem',
    },
    '14/bold': {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.375rem',
    },
    '12/regular': {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1rem',
    },
    '12/medium': {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: '1rem',
    },
    '12/bold': {
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: '1rem',
    },
    '10/regular': {
      fontSize: '0.625rem',
      fontWeight: 400,
      lineHeight: '0.75rem',
    },
    '10/medium': {
      fontSize: '0.625rem',
      fontWeight: 500,
      lineHeight: '0.75rem',
    },
    '10/bold': {
      fontSize: '0.625rem',
      fontWeight: 700,
      lineHeight: '0.75rem',
    },
  },
}

/**
 * Adds overrides and base configuration to custom theme.
 */
export default responsiveFontSizes(createTheme({
  ...options,
  spacing: factor => `${factor / 2}rem`,
  typography: {
    allVariants: {
      fontFamily: ['Inter', 'sans-serif'].join(', '),
    },
    fontSize: 14,
    useNextVariants: true,
    button: {
      textTransform: 'none',
    },
    ...styles.typography, // so that we can use.g. "theme.typography['14/medium']"
  },
  components: {
    MuiButtonBase: {
      defaultProps: { // src: https://material-ui.com/getting-started/faq/#how-can-i-disable-the-ripple-effect-globally
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          '32/regular': 'p',
          '32/medium': 'p',
          '32/bold': 'p',
          '24/regular': 'p',
          '24/medium': 'p',
          '24/bold': 'p',
          '20/regular': 'p',
          '20/medium': 'p',
          '20/bold': 'p',
          '18/regular': 'p',
          '18/medium': 'p',
          '18/bold': 'p',
          '16/regular': 'p',
          '16/medium': 'p',
          '16/semibold': 'p',
          '16/bold': 'p',
          '14/regular': 'p',
          '14/medium': 'p',
          '14/semibold': 'p',
          '14/bold': 'p',
          '12/regular': 'p',
          '12/medium': 'p',
          '12/bold': 'p',
          '10/regular': 'p',
          '10/medium': 'p',
          '10/bold': 'p',
        },
      },
      variants: [
        // Text variants
        {
          props: { variant: '32/regular' },
          style: styles.typography['32/regular'],
        },
        {
          props: { variant: '32/medium' },
          style: styles.typography['32/medium'],
        },
        {
          props: { variant: '32/bold' },
          style: styles.typography['32/bold'],
        },
        {
          props: { variant: '24/regular' },
          style: styles.typography['24/regular'],
        },
        {
          props: { variant: '24/medium' },
          style: styles.typography['24/medium'],
        },
        {
          props: { variant: '24/bold' },
          style: styles.typography['24/bold'],
        },
        {
          props: { variant: '20/regular' },
          style: styles.typography['20/regular'],
        },
        {
          props: { variant: '20/medium' },
          style: styles.typography['20/medium'],
        },
        {
          props: { variant: '20/bold' },
          style: styles.typography['20/bold'],
        },
        {
          props: { variant: '18/regular' },
          style: styles.typography['18/regular'],
        },
        {
          props: { variant: '18/medium' },
          style: styles.typography['18/medium'],
        },
        {
          props: { variant: '18/bold' },
          style: styles.typography['18/bold'],
        },
        {
          props: { variant: '16/regular' },
          style: styles.typography['16/regular'],
        },
        {
          props: { variant: '16/medium' },
          style: styles.typography['16/medium'],
        },
        {
          props: { variant: '16/semibold' },
          style: styles.typography['16/semibold'],
        },
        {
          props: { variant: '16/bold' },
          style: styles.typography['16/bold'],
        },
        {
          props: { variant: '14/regular' },
          style: styles.typography['14/regular'],
        },
        {
          props: { variant: '14/medium' },
          style: styles.typography['14/medium'],
        },
        {
          props: { variant: '14/semibold' },
          style: styles.typography['14/semibold'],
        },
        {
          props: { variant: '14/bold' },
          style: styles.typography['14/bold'],
        },
        {
          props: { variant: '12/regular' },
          style: styles.typography['12/regular'],
        },
        {
          props: { variant: '12/medium' },
          style: styles.typography['12/medium'],
        },
        {
          props: { variant: '12/bold' },
          style: styles.typography['12/bold'],
        },
        {
          props: { variant: '10/regular' },
          style: styles.typography['10/regular'],
        },
        {
          props: { variant: '10/medium' },
          style: styles.typography['10/medium'],
        },
        {
          props: { variant: '10/bold' },
          style: styles.typography['10/bold'],
        },
        {
          props: { color: 'textDescription' },
          style: {
            color: options.palette.text.description,
          },
        },
        {
          props: { color: 'textSecondary' },
          style: {
            color: options.palette.text.secondary,
          },
        },
        {
          props: { color: 'textBlack' },
          style: {
            color: styles.palette.black.main,
          },
        },
        {
          props: { color: 'textDark' },
          style: {
            color: options.palette.text.dark,
          },
        },
        {
          props: { color: 'textWarning' },
          style: {
            color: options.palette.warning.main,
          },
        },
        {
          props: { color: 'black' },
          style: {
            color: styles.palette.black.main,
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        size: 'none',
      },
      styleOverrides: {
        root: {
          /**
           * Some buttons should be taller, like {@link IconPreface}.
           * So set {@code minHeight} instead of {@code height}.
           */
          minHeight: '2.5rem',
          boxShadow: 'none',
          borderRadius: '0.5rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          fontSize: '0.875rem',
          fontWeight: 700,
        },
        textPrimary: {
          color: 'inherit',
        },
      },
      variants: [
        {
          props: { variant: 'textBlack' },
          style: {
            color: styles.palette.black.main,
            '&.MuiButton-root': {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
        {
          props: { variant: 'textSecondary' },
          style: {
            color: options.palette.text.secondary,
            '&.MuiButton-root': {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
        {
          props: { size: 'tiny' },
          style: {
            minHeight: '1.5rem',
            fontSize: '0.65rem',
            paddingTop: '0.25rem', // 4px
            paddingBottom: '0.25rem', // 4px
            fontWeight: 600,
            lineHeight: '1.25rem',
          },
        },
        {
          props: { size: 'small' },
          style: {
            minHeight: '2rem',
            fontSize: '0.875rem',
            paddingTop: '0.313rem', // 5px
            paddingBottom: '0.313rem', // 5px
            fontWeight: 600,
            lineHeight: '1.375rem',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            minHeight: '2.5rem',
            fontSize: '0.875rem',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            fontWeight: 600,
            lineHeight: '1.375rem',
          },
        },
        {
          props: { size: 'large' },
          style: {
            minHeight: '3rem',
            fontSize: '1rem',
            paddingTop: '0.75rem', // 12px
            paddingBottom: '0.75rem', // 12px
            fontWeight: 600,
            lineHeight: '1.5rem',
          },
        },
        {
          props: { size: 'huge' },
          style: {
            minHeight: '3.5rem',
            fontSize: '1rem',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            fontWeight: 600,
            lineHeight: '1.5rem',
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: options.palette.background.main,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '1.5rem',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: styles.palette.black.main,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: options.palette.text.secondary,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '1.5rem',
          color: options.palette.primary.main,
          '& > *': {
            maxWidth: 'none!important',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: options.palette.background.main,
        },
      },
    },
    // interesting behaviour, this has to be checked on component basis
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: options.palette.background.content,
          borderRadius: '0.75rem',
        },
      },
      variants: [
        {
          props: { background: 'transparent' },
          style: {
            backgroundColor: 'transparent',
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        label: {
          width: 'auto',
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        rippleVisible: {
          opacity: 0.1,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        // Checkbox label container
        root: {
          alignItems: 'center',
        },
        // Checkbox label
        label: {
          fontSize: '0.875rem',
        },
      },
      variants: [
        {
          props: { color: 'textBlack' },
          style: {
            color: styles.palette.black.main,
          },
        },
        {
          props: { color: 'textSecondary' },
          style: {
            color: options.palette.text.secondary,
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0.25rem',
          paddingTop: 0,
          '& .MuiSvgIcon-root': {
            // Checkbox icon size
            fontSize: '1.875rem',
          },
        },
      },
      variants: [
        {
          props: { variant: 'normal' },
          style: {
            padding: '0.25rem',
            paddingTop: 0,
            '& .MuiSvgIcon-root': {
              // Checkbox icon size
              fontSize: '1.875rem',
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            '& .MuiSvgIcon-root': {
              // Checkbox icon size
              fontSize: '1.5rem',
            },
          },
        },
      ],
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-disabled.Mui-checked': {
            color: options.palette.primary.main,
          },
          '&.Mui-disabled.Mui-checked + span': {
            color: options.palette.text.primary,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // label
          color: options.palette.text.secondary,
          // label on focus (when it's shrunk)
          '&.Mui-focused': {
            transform: 'translate(0, -0.094px) scale(0.75)',
            // need important here, otherwise will get overwritten by Mui-error
            color: `${styles.palette.black.main}!important`,
            fontWeight: 500,
          },
          '&.Mui-error': {
            color: options.palette.text.regular,
          },
        },
        // Outlined variant has different translate params.
        outlined: {
          '&.Mui-focused': {
            // label on focus (when it's shrunk)
            transform: 'translate(0.875rem, -0.563rem) scale(0.75)',
            color: options.palette.text.regular,
            fontWeight: 500,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: options.palette.text.primary,
          '&.MuiFormLabel-filled': {
            // need important here, otherwise will get overwritten by Mui-error
            color: `${styles.palette.black.main}!important`,
            fontWeight: 500,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          marginTop: '0.75rem!important',
          height: '2.5rem',
          color: styles.palette.black.main,
          '&.Mui-disabled': {
            '&:before': {
              borderBottom: `1px solid ${options.palette.text.secondary}`,
            },
          },
        },
        underline: {
          // thin border
          '&:before': {
            borderBottom: `1px solid ${styles.palette.black.main}`,
          },
          // thick border on hover & focus
          '&:after': {
            borderBottom: `2px solid ${styles.palette.black.main}`,
          },
        },
        multiline: {
          height: 'auto',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& > .MuiButtonBase-root': {
            color: options.palette.text.secondary,
          },
          '&.Mui-disabled': {
            // thin border
            '&:before': {
              borderBottom: `1px solid ${options.palette.text.disabled}`,
            },
            // thick border on hover & focus
            '&:after': {
              borderBottom: `2px solid ${options.palette.text.disabled}`,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E5E5E5',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          // '&:not(body, main):hover > ::-webkit-scrollbar': {
          //   display: 'block',
          // },
          // '&:not(body, main)::-webkit-scrollbar': {
          //   display: 'none',
          // },
          // '&::-webkit-scrollbar': {
          //   width: 8,
          // },
          // '&::-webkit-scrollbar-track': {
          //   background: 'transparent',
          // },
          // '&::-webkit-scrollbar-track-piece': {
          //
          // },
          // '&::-webkit-scrollbar-thumb': {
          //   background: '#CCCCCC',
          // },
          // '&::-webkit-scrollbar-thumb:hover': {
          //   background: '#888888',
          // },
          // '&::-webkit-scrollbar-thumb:active': {
          //   background: '#AAAAAA',
          // },
          // '&::-webkit-scrollbar-button': {
          //   display: 'none',
          // },
          // '&::-webkit-scrollbar-corner': {
          //   background: '#EEEEEE',
          // },
          // '&::-webkit-resizer': {
          //   background: '#5b5b5b',
          // },
        },
      },
    },
  },
  custom: {
    ...options.custom,
  },
  palette: {
    ...options.palette,
    ...styles.palette,
    appBar: {
      ...options.palette.appBar,
    },
    sideBar: {
      ...options.palette.sideBar,
    },
  },
  layout: {
    navigationDrawerWidth: '15rem',
    drawerWidth: '46rem',
    drawerPadding: '2.5rem',
    actionBarHeight: '5rem',
    toolBarHeight: '2.5rem',
    appBarHeight: '7.5rem',
    contentSpace: '2rem',
    contentGap: '4rem',
  },
}))
