/* eslint-disable no-undefined */
import sequenceComponentState from 'lib/sequence/component/state/index'

const {
  get,
  set,
  undefine: reset,
} = sequenceComponentState('value')

const sequenceComponentStateValue = {
  get,
  set,
  reset,
}

export {
  sequenceComponentStateValue as default,
  get,
  set,
  reset,
}
