/* eslint-disable no-unused-expressions */
/* global G */
import { setKey, deleteKey } from 'lib/util'

const _event = 'popstate'

const remove = listener => window.removeEventListener(_event, listener)

const restore = listener => window.addEventListener(_event, listener)

const add = listener => window.addEventListener(_event, listener, { once: true })

let currentUri
let listener

/**
 * Dispatch a custom 'back' event containing the {@param confirmed}'s value with window as the
 * target, so that we can detect whether the user confirms or cancels the dialog.
 *
 * @param {boolean} confirmed whether the user has confirmed the confirmation dialog
 * @returns {boolean}         always true
 */
const dispatchBackEvent = (confirmed) => {
  const event = new CustomEvent('back', { detail: { confirmed } })
  window.dispatchEvent(event)
  return true
}

/**
 * Restores the application's route handling and redirects the user to the corresponding route.
 *
 * @param {Gaia.Web.Application} app  application
 */
const proceed = async (app) => {
  dispatchBackEvent(true)
  window.history.pushState(null, null, currentUri)
  remove(listener)
  restore(app[G.STATE][G.EVENTS].popStateHandler)
  await window.history.back()
}

/**
 * Fires a G.DATA, G.UNDO event in order to show the confirmation dialog.
 *
 * @param {Gaia.Web.Application} app  application
 */
const ask = (app) => {
  const eventBus = app[G.EVENTS]
  const module = app[G.SESSION][G.STATE][G.MODULE]
  const session = module[G.ADAPTER][G.SESSION]
  const action = module[G.STATE][G.ACTION]
  const isModalOrDrawer = action[G.UI].modal || action[G.UI].drawer

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: module[G.ADAPTER][G.INTL]._t(
      'dialog.discard.title',
      {
        ns: 'common',
        _key: 'dialog.discard.title',
        defaultValue: 'Warning',
      },
    ),
    text: module[G.ADAPTER][G.INTL]._t(
      'dialog.discard.text',
      {
        ns: 'common',
        _key: 'dialog.discard.text',
        defaultValue: 'All unsaved changes will be lost',
      },
    ),
    children: {
      cancel: {
        key: 'cancel',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.cancel',
          {
            ns: 'common',
            _key: 'button.cancel',
            defaultValue: 'Cancel',
          },
        ),
      },
      ok: {
        key: 'ok',
        value: module[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
    okHandler: async () => setKey(null, G.ERROR, module[G.STATE])
                   && setKey(null, G.UNDO, action[G.STATE])
                   && (!isModalOrDrawer ? deleteKey(G.ORIGIN, session[G.STATE]) : true)
                   && await proceed(app),
    cancelHandler: async () => {
      dispatchBackEvent(false)
      // the next history call triggers popstate, so we actually add the listener after that
      add(() => add(listener))
      await window.history.forward()
      await window.history.back()
    },
  })
}

/**
 * If the state of the current module action is dirty (G.UNDO is truthy), it calls {@link ask} to
 * display the confirmation dialog, otherwise it calls {@link proceed} to restore the route and take
 * the user to the corresponding one.
 *
 * @param {Gaia.Web.Application} app  application
 * @returns {function(): void}
 */
const handler = app => async () => (
  app[G.SESSION][G.STATE][G.MODULE][G.STATE][G.ACTION][G.STATE][G.UNDO]
    ? ask(app)
    : await proceed(app)
)

/**
 * Enables the discard dialog functionality for the current module's action.
 *
 * Replaces the current window {@link #_event} event handler in order to show the confirmation
 * dialog on the next route change.
 *
 * Setting this as a hook for an action and that action's state G.UNDO key as truthy will make the
 * dialog trigger the next time the user attempts to navigate.
 *
 * @param {Gaia.Web.Application} app  application
 * @returns {function(...[*]): *[]}
 */
const toForm = app => (...args) => {
  currentUri = currentUri || window.location.href
  // if we reuse the same listener, it won't be added again if we rerender
  listener = listener || handler(app)
  remove(app[G.STATE][G.EVENTS].popStateHandler)
  add(listener)
  return args
}

export default toForm
