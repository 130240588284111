/* global G */
import { curry, setKey } from 'lib/util'

/**
 * Sets META flag in adapter session state
 *
 * @param {Gaia.AppModule.Spec} obj - module composition
 * @param {*} args - arguments
 * @return {function(*=): *}
 */
const sequenceModuleAdapterSessionStateMetaSetFn = (obj, args) => setKey(
  args,
  G.META,
  obj[G.ADAPTER][G.SESSION][G.STATE], // propagated via factory.module
) && args

const sequenceModuleAdapterSessionStateMetaSet = curry(sequenceModuleAdapterSessionStateMetaSetFn)

export default sequenceModuleAdapterSessionStateMetaSet
