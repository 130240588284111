/**
 * Local Attachment Create Worker Body
 *
 * is a closure with context of DedicatedWorkerGlobalScope
 * babel transformation error when inline
 * excluded via webpack config
 * current usage of arrow functions needs to be evaluated on multiple OS and browsers,
 * as ES6 native support might still be janky on some constellations
 * alternatives would be to evaluate webpack 5, otherwise use worker-loader
 */
export default () => {
  // eslint-disable-next-line no-restricted-globals
  const _worker = self
  _worker.addEventListener('message', (evt) => {
    // item is File
    const { url, key, name = null, uuid, item } = evt.data
    const xhr = new XMLHttpRequest()
    xhr.open(name ? 'put' : 'post', name ? `${url}/${name}` : url, true)
    // GAIA-689 - provide name to attachment artefact
    xhr.setRequestHeader('Content-Disposition', `attachment; filename=${item.name}`)

    if (xhr.upload) {
      // xhr.upload.onloadend = (event) => {
      //   console.log('xhr is done', event, xhr.responseText)
      // }
      xhr.upload.onprogress = (event) => {
        const done = event.position || event.loaded
        const total = event.totalSize || event.total
        // eslint-disable-next-line no-mixed-operators
        const progress = Math.floor(done / total * 1000) / 10
        _worker.postMessage({
          key, uuid, done, total, progress, status: xhr.status,
        })
      }
      xhr.upload.onerror = (event) => {
        const total = event.totalSize || event.total
        _worker.postMessage({
          key, uuid, done: 0, total, progress: 0, status: xhr.status,
        })
      }
    }

    xhr.onload = () => {
      _worker.postMessage(xhr.status !== 201
        ? {
          key, done: 0, total: 100, progress: 0, status: xhr.status,
        }
        : {
          key,
          done: 100,
          total: 100,
          progress: 100,
          status: xhr.status,
          ref: JSON.parse(xhr.response).id,
          response: JSON.parse(xhr.response),
        })
    }
    xhr.send(item)
  })
}
