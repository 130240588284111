/* global G */
import { curry } from 'lib/util'

/**
 * Gets the user's language and sets it application wide.
 *
 * @param {Gaia.AppModule.Spec} obj - module composition
 * @return {function(*=): *}
 */
const sequenceModuleAdapterIntlLangSetFn = obj => async (...args) => {
  const userSession = obj[G.ADAPTER][G.SESSION][G.STATE][G.META]
  const { lang } = userSession?.refs?.person?.[0]?.value || {}
  lang && await obj[G.ADAPTER][G.INTL].set(lang)

  return args
}

const sequenceModuleAdapterIntlLangSet = curry(sequenceModuleAdapterIntlLangSetFn)

export default sequenceModuleAdapterIntlLangSet
