/* global G */
import popStateHandler from '@platform/trait/uses/WindowHash/popState'
import hashHandler from '@platform/trait/uses/WindowHash/hashChange'
import initHandler from '@platform/trait/uses/WindowHash/init'

const usesWindowHash = (obj) => {
  const eventBus = obj[G.EVENTS]

  const objState = obj[G.STATE]

  objState[G.EVENTS] = {
    popStateHandler: popStateHandler(obj),
    hashHandler: hashHandler(obj),
    initHandler: initHandler(obj),
  }

  window.addEventListener('popstate', objState[G.EVENTS].popStateHandler)
  eventBus.add('gaia:hashchange', objState[G.EVENTS].hashHandler, { useCapture: true })
  eventBus.add('gaia:init', objState[G.EVENTS].initHandler, { useCapture: true, once: true })

  // this blocks webpack hot-reloading, BUT should be used in production,
  // in order to notify user, that they are about to leave our app.
  // window.addEventListener('beforeunload', (e) => {
  //   e.preventDefault()
  //   e.stopImmediatePropagation()
  //   e.stopPropagation()
  //   // console.info('NAVIGATING AWAY', e)
  //   // window.location.href = 'https://www.yahoo.com'
  //   e.returnValue = 'ewrwer'
  //   return e
  // }, { useCapture: true })

  return obj
}

// export default usesWindowHash
export {
  usesWindowHash as default,
  popStateHandler,
  hashHandler,
  initHandler,
}
