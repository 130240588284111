/* eslint-disable no-undefined,no-param-reassign */
/* global G */
import { def } from 'lib/util'

/**
 * Sequence Component State Factory.
 *
 * Returns an object with methods get, set, unset, undefine, nullify and toggle that allows to
 * easily modify the {@param key} state property of an object passed to those functions.
 *
 * @param {string} key    the name of the state property of obj to be modified
 * @returns {object}
 */
const sequenceComponentState = (key) => {
  /**
   * Sets {@param value} as the value of the {@link key} state of obj.
   * @param {object} obj    ui component
   * @param {any} value     the value to set
   * @returns {object}      the passed obj
   * @private
   */
  const _set = (obj, value) => {
    const objState = obj[G.STATE]
    objState[key] = value
    return obj
  }

  /**
   * Returns the value of the {@link key} state of {@param obj} or the value of its prop, if state
   * is not defined (i.e. the initial state).
   * @param {object} obj  ui component
   * @param {boolean} stateOnly whether to look only for the state value
   * @returns {any}   the value of the checked state of obj.
   */
  const get = (obj, stateOnly = false) => {
    const state = obj[G.STATE]?.[key]
    const prop = obj[G.PROPS]?.[key]
    return def(state) || stateOnly ? state : prop
  }

  /**
   * Sets the {@link key} state of obj to {@param value} or {@code true}.
   * @param {object} obj ui component
   * @param {any} [value=true] the value to set
   * @returns {object}   the passed obj
   */
  const set = (obj, value = true) => _set(obj, value)

  /**
   * Sets the {@link key} state of obj to {@code false}.
   * @param {object} obj  ui component
   * @returns {object}    the passed obj
   */
  const unset = obj => _set(obj, false)

  /**
   * Sets the {@link key} state of obj to {@code undefined}.
   * @param {object} obj  ui component
   * @returns {object}    the passed obj
   */
  const undefine = obj => _set(obj, undefined)

  /**
   * Sets the {@link key} state of obj to {@code null}.
   * @param {object} obj  ui component
   * @returns {object}    the passed obj
   */
  const nullify = obj => _set(obj, null)

  /**
   * Deletes the {@link key} state of obj.
   * @param {object} obj ui component
   */
  const clear = (obj) => {
    delete obj[G.STATE][key]
    return obj
  }

  /**
   * Toggles the value of the {@link key} state of obj between {@code true} or {@code false}.
   * @param {object} obj  ui component
   * @returns {object}    the passed obj
   */
  const toggle = obj => _set(obj, !obj[G.STATE][key])

  return {
    get,
    set,
    unset,
    undefine,
    nullify,
    clear,
    toggle,
  }
}

export default sequenceComponentState
