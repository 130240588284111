/* global G */

/**
 * Default translations values
 * @type {{deleteAll: {text: string}, updateAll: {text: string}}}
 */
const translations = {
  updateAll: {
    text: 'Do you really want to mark all notifications as read?',
  },
  deleteAll: {
    text: 'Do you really want to delete all notifications?',
  },
}

/**
 * Shows a configurable confirm dialog using {@param type}.
 *
 * @param {Gaia.Web.Application} app    the Platform Web Application
 * @param {string} type                 type of confirmation
 * @returns {Promise<unknown>}
 */
const confirmationDialog = async (app, type) => {
  const eventBus = app[G.ADAPTER][G.EVENTS]
  return await new Promise((resolve) => {
    eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
      title: app[G.ADAPTER][G.INTL]._t(
        `dialog.notification.alerts.${type}.title`,
        {
          ns: 'common',
          _key: `dialog.notification.alerts.${type}.title`,
          defaultValue: 'Confirm',
        },
      ),
      text: app[G.ADAPTER][G.INTL]._t(
        `dialog.notification.alerts.${type}.text`,
        {
          ns: 'common',
          _key: `dialog.notification.alerts.${type}.text`,
          defaultValue: translations[type].text,
        },
      ),
      children: {
        ok: {
          key: 'ok',
          value: app[G.ADAPTER][G.INTL]._t(
            'button.ok',
            {
              ns: 'common',
              _key: 'button.ok',
              defaultValue: 'Ok',
            },
          ),
        },
        cancel: {
          key: 'cancel',
          value: app[G.ADAPTER][G.INTL]._t(
            'button.cancel',
            {
              ns: 'common',
              _key: 'button.cancel',
              defaultValue: 'Cancel',
            },
          ),
        },
      },
      okHandler: () => resolve(true),
      cancelHandler: () => resolve(false),
    })
  })
}
/**
 * Shows a confirm deletion dialog.
 *
 * @param {Gaia.Web.Application} app    the Platform Web Application
 * @returns {Promise<Boolean>}
 */
export const confirmDeleteDialog = app => confirmationDialog(app, 'deleteAll')

/**
 * Shows a confirm update dialog.
 *
 * @param {Gaia.Web.Application} app    the Platform Web Application
 * @returns {Promise<Boolean>}
 */
export const confirmUpdateDialog = app => confirmationDialog(app, 'updateAll')

export default app => confirmationDialog(app, 'updateAll')
