/* global G */
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import { withGetterSetter } from 'trait/with'

import listComposition from 'trait/composition/list'

const canList = pipe(
  withGetterSetter(G.LIST),
  usesGetterSetter(G.LIST, listComposition()),
)

export default canList
