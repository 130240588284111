/* global G */
import { pipe } from '@gaia/util'
import { hasNamespace } from '@gaia/trait/has'
import { withGetterSetterFn } from '@gaia/trait/with/GetterSetter'
import { usesNamespace, usesGetterSetter } from '@gaia/trait/uses'
import { withObjectFreeze, withDescriptor } from 'trait/with'
import api from './api'

const descriptor = 'adapter:Storage'

/**
 * Storage Adapter.
 *
 * Adapter used to manage the browser's localStorage.
 *
 * @memberOf Gaia.Adapter
 * @namespace Storage
 * @type {function}
 *
 * @param {Gaia.Web.Application} obj - Web Application
 * @return {function(*=): *}
 */
const storageAdapter = obj => pipe(
  withDescriptor(descriptor),
  hasNamespace(G.API),
  usesNamespace(G.API, api()),
  withGetterSetterFn(G.APP),
  usesGetterSetter(G.APP, () => obj),
  withGetterSetterFn(G.REF),
  withObjectFreeze,
)({})

export default storageAdapter
