import { curry } from 'lib/util'
import { withDependencyCheck } from 'trait/with'

const trait = 'usesGetterSetter'

/**
 * Uses Getter Setter
 *
 * Sets value to getter setter symbol, used in obj.
 *
 * @example
 * import { pipe } from '@gaia/util'
 * import { withGetterSetter } from '@gaia/trait/with'
 * import { usesGetterSetter } from '@gaia/trait/uses'
 *
 * const someValue = 'foo' // { foo: 'bar' } | [0,1,2] | *
 * const someSymbol = 'bar'
 *
 * const composition = pipe(
 *    withGetterSetter(someSymbol),
 *    usesGetterSetter(someValue)(someSymbol),
 * )
 *
 * @param { Symbol } symbol - symbol identifier
 * @param { * } value - value to be used
 * @param { Module.Spec | AppModule.Spec } obj - object composition
 * @return { Module.Spec | AppModule.Spec } obj - object composition
 */
const usesGetterSetter = (symbol, value, obj) => {
  withDependencyCheck(trait, [symbol], obj)

  // @all, please refrain from doing so, anywhere outside of library
  // allow eslint override, since this is a base trait.

  // eslint-disable-next-line no-param-reassign
  obj[symbol] = value

  return obj
}

export default curry(usesGetterSetter)
