/* global G */

import { isNum } from 'lib/util'

/**
 * Fetches the count of unread notifications
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function({detail: *}): Promise<{count}|null>)|*}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.STATE]: groupState, [G.DATA]: groupData } = group
  const { [G.API]: { api, types, version } = {} } = groupState

  const { count = null } = detail

  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const countUrl = `/api/v${version}/${api}/${types.unread}`

  try {
    const alertCount = !isNum(count)
      ? await httpAdapter[G.API].get({ url: countUrl })
      : null

    groupData.count = alertCount
      ? alertCount?.unread || 0
      : count || 0
    groupData.update = {}

    eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.DONE, groupName), { [G.DATA]: groupData })
  } catch (e) {
    console.error(e)
  }

  return null
}

export default fn
