/* global G */

/**
 * Event Emitter on successful Transaction
 *
 * should be used in conjunction with transactionBegin and transactionEnd hooks
 *
 * @param {string} type               type of the notification
 * @param {string} [severity='info']  severity of the notification
 * @return {function(...[*]): *[]}
 */
const transactionConfirmation = (type, severity = 'info') => obj => (...args) => {
  // Disabled if type not set, see GAIA-989
  if (type) {
    obj[G.ADAPTER][G.EVENTS].dispatch(
      obj[G.ADAPTER][G.EVENTS].type(G.DATA, G.UPDATE),
      {
        message: obj[G.ADAPTER][G.INTL]._t(
          `data.${type}`,
          { ns: 'common', _key: `data.${type}`, defaultValue: 'Document was updated' },
        ),
        severity,
        close: 5000,
      },
    )
  }
  return args
}

export default transactionConfirmation
