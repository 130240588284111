import notEmpty from 'lib/validator/notEmpty'
import notEmptyAttribute from 'lib/validator/notEmptyAttribute'
import minLength from 'lib/validator/minLength'
import isEmail from 'lib/validator/isEmail'
import isPhoneNumber from 'lib/validator/isPhoneNumber'
import isTimeEntry from 'lib/validator/isTimeEntry'

const validator = {
  notEmpty,
  notEmptyAttribute,
  minLength,
  isEmail,
  isPhoneNumber,
  isTimeEntry,
}

export {
  notEmpty,
  notEmptyAttribute,
  minLength,
  isEmail,
  isPhoneNumber,
  isTimeEntry,
  validator as default,
}
