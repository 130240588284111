/* global React */
import {
  useContext,
  useRef,
} from 'react'
import ApplicationContext from '@platform/react/context/application'
import PlatformEvent from 'lib/util/event'

const _getCountData = ({ count, data = {} } = {}) => (count ? { count, data } : { count })

/**
 * High Order Component for displaying count or single entry data
 *
 * @example client/platform/web/src/ui/Component/Subbed/Item/List/Cell/Count.jsx
 *
 * @param Component
 * @return {function(*)}
 * @constructor
 */
const CountHoc = Component => (props) => {
  const { socket: { ref: socket } } = useContext(ApplicationContext)
  const {
    count: {
      value, options, ref, handler,
    },
  } = props
  const docType = Object.keys(options).pop()
  const refType = Object.keys(options[docType]).pop()
  const socketKey = useRef(`${ref}_${docType}`).current
  const initialValue = _getCountData(value?.[docType]?.[refType])
  const [countValue, setValue] = React.useState(initialValue)
  React.useLayoutEffect(() => {
    const listener = async () => {
      const customEvent = new CustomEvent('count', { detail: { key: ref, type: docType } })
      const event = new PlatformEvent(customEvent)
      const result = await handler(event)
      // total is used if result.refType is undefined, and undefined objects cannot be deconstructed
      setValue(_getCountData(result[refType] || result.total))
    }
    socket.sub(socketKey, () => {
      socket.on(socketKey, listener)
    })
    return () => socket.unsub(socketKey, () => {
      socket.off(socketKey, listener)
    })
  }, [socketKey])

  return (
    <Component
      {...props}
      count={{ [docType]: { [refType]: countValue } }}
    />
  )
}

export default CountHoc
