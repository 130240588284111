/* global G */
import deleteRemote from '@platform/adapter/attachment/api/group/delete'
import { setKey } from 'lib/util'

/**
 * Passes all files from the {@param groupName}'s group's (G.DELETE) list of remote files to be
 * deleted to the {@link deleteRemote} method, so that they are removed from the remote source.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => () => {
  const adapter = app[G.ADAPTER][G.ATTACHMENT]
  const group = adapter[G.GROUP][groupName]
  const files = group[G.DELETE]
  // TODO: array methods don't support async. Check whether we should add 'await Promise.all(array)'
  const remaining = files.filter(async (item) => {
    try {
      await deleteRemote(app, groupName)({ detail: { [G.DATA]: item } })
      return false
    } catch (e) {
      return true
    }
  })
  setKey(remaining, G.DELETE, group)
}

export default fn
