/* eslint-disable no-unused-expressions,no-use-before-define */
/* global G */
import _route from 'trait/composition/route'
import { def } from 'lib/util'

/**
 * Returns whether {@param route} has a value for the CONTEXT namespace.
 *
 * @param {Gaia.Route} route  a Gaia route composition
 * @return {boolean}
 * @private
 */
const _hasContext = route => def(route[G.CONTEXT]) && route[G.CONTEXT] !== null

/**
 *
 * null is default of useGetterSetter() trait
 *
 * @param {Gaia.Route} route
 * @return {boolean}
 * @private
 */
const _hasRef = route => def(route[G.REF]) && route[G.REF] !== null

/**
 * Compare between routes.
 *
 *
 * @param {Object} a - old route
 * @param {Object} b - new route
 * @return {boolean} result - is new route
 * @private
 */
const _isNewRoute = (a, b) => (a && b)
  && (a[G.CONTEXT] !== b[G.CONTEXT] || a[G.MODULE] !== b[G.MODULE] || a[G.ACTION] !== b[G.ACTION]
    || (a[G.REF] && b[G.REF] && a[G.REF] !== b[G.REF]))

/**
 * Returns {@code true} only if the current module's action is displayed as a modal or as a drawer.
 * @param {Gaia.Web.Application} obj      application object
 * @returns {Function|boolean|*|boolean}  whether the current module's action is displayed as a
 *                                        modal or as a drawer
 * @private
 */
const _isModalRoute = (obj) => {
  const action = obj[G.SESSION][G.STATE][G.MODULE][G.STATE][G.ACTION]
  const { modal, drawer, fullScreen } = action[G.UI]
  return modal || drawer || fullScreen
}

/**
 * Tackling different cases
 *
 * * on BACK
 * * on User Input
 * * t.b.c.
 *
 * @private
 */
const _else = async (obj) => {
  const sessionState = obj[G.SESSION][G.STATE]
  const { hash } = window.location

  // default case, suspecting press BACK
  if (hash === '') {
    if (obj[G.STATE][G.NEXT]) {
      // todo: bdd this, pretty sure its dead code
      window.history.forward()
    } else {
      window.history.back()
    }
    return
  }

  if (!sessionState[G.CURRENT]) {
    return
  }

  // on user input, we would have an additional entry on history stack, on top of ours.
  // we are going to go back by 2, to buffer entry, because the last two entries in history stack
  // are under our control.
  // buffer entry was added during gaia:init, and we are constantly manipulating
  // the current browser tab.
  window.history.go(-2)
}

const _userInput = async (obj) => {
  const appState = obj[G.STATE]
  const entry = appState[G.NEXT]
  const currentModule = obj[G.SESSION][G.STATE][G.MODULE]?.[G.CONFIGURATION]?.module

  // so we are going to push a new state, thus nullifying any inconsistencies in history stack.
  // meaning, if there was an option to navigate forward, by pushing state, we will be removing
  // this option.
  try {
    const parts = [entry[G.MODULE], entry[G.ACTION]]
    const key = entry[G.REF]
    _hasContext(entry) && parts.unshift(entry[G.CONTEXT])
    _hasRef(entry) && parts.push(key)
    const url = `#/${parts.join('/')}`
    window.history.pushState({ route: entry }, '', url)

    const { moduleAction, action } = obj[G.ADAPTER][G.ROUTER][G.API]
    const args = key ? { key } : {}
    // the following condition is what makes our modules reset (with the consequent
    // cleaning of all states) whenever the user navigates back from a non-modal action
    entry[G.MODULE] === currentModule && _isModalRoute(obj)
      ? await action(entry[G.ACTION], args)
      : await moduleAction(entry, args)
  } catch (e) {
    if (e.name !== 'ACLError') {
      console.error('ERROR: REDIRECT TO NOT_FOUND', e)
      const errorRoute = _route('error', 'index')
      await obj[G.ADAPTER][G.ROUTER][G.API].moduleAction(errorRoute)
      obj[G.ADAPTER][G.EVENTS].dispatchEvent(new CustomEvent('gaia:hashchange', {
        bubbles: true,
        cancelable: true,
        detail: {},
      }))
    }
  }

  // we are resetting G.NEXT, as it has already been used.
  appState[G.NEXT] = false
}

const _disableCanStack = (obj) => {
  const sessionState = obj[G.SESSION][G.STATE]
  const action = sessionState[G.MODULE][G.STATE][G.ACTION]
  const ui = action && action[G.UI]
  ui.noStack = true
  return obj
}

const _setEntryFromStack = async (obj) => {
  const appState = obj[G.STATE]
  const sessionState = obj[G.SESSION][G.STATE]
  appState[G.NEXT] = sessionState[G.PREV].pop()
  appState[G.ROUTE] = appState[G.NEXT]
  // !appState[G.NEXT] && await _setHomeEntry(obj)
  _disableCanStack(obj)
  await _userInput(obj)
  return obj
}

const _stackHasEntries = async (obj) => {
  const sessionState = obj[G.SESSION][G.STATE]
  const nextEntry = obj[G.STATE][G.NEXT]

  if (sessionState[G.CURRENT]) {
    if (nextEntry) {
      await _else(obj)
    } else {
      await _setEntryFromStack(obj)
    }
  } else if (nextEntry) {
    await _userInput(obj)
  } else {
    await _setEntryFromStack(obj)
  }
}

const popStateHandler = obj => async () => {
  const sessionState = obj[G.SESSION][G.STATE]
  const stack = sessionState[G.PREV]
  const { location: { hash, href } } = window
  const length = hash.indexOf('?') !== -1 ? hash.indexOf('?') : hash.length
  const parts = hash.substring(2, length).split('/') // dropping #/ from hash value
  const [context, module, action, ref] = parts

  // if not same domain, end user navigates away from the app
  if (href.indexOf(sessionState[G.HTTP]) === -1) {
    return
  }

  // restore order to sequences when navigator back button has been used
  if (href === `${sessionState[G.HTTP]}/` && !sessionState[G.CURRENT]) {
    sessionState[G.CURRENT] = true
  }

  sessionState[G.CURRENT] = !sessionState[G.CURRENT]

  const route = _route(module, action, ref, context)

  if (hash !== '' && _isNewRoute(obj[G.STATE][G.ROUTE], route)) {
    const appState = obj[G.STATE]
    appState[G.NEXT] = route
  }

  if (stack.length > 0) {
    await _stackHasEntries(obj)
  } else if (obj[G.STATE][G.NEXT] && !sessionState[G.CURRENT]) {
    await _userInput(obj)
  } else {
    await _else(obj)
  }
}

export default popStateHandler
