/* eslint-disable no-param-reassign */

/**
 * Convert a timestamp to a human-readable date.
 *
 * @param {String} timestamp    the timestamp as a string
 * @param {Boolean} [local]     whether to convert to local time or not
 * @param {Boolean} [dateOnly]  whether to only return the date
 *
 * @returns {String}
 */
const getReadableDate = (timestamp, local = false, dateOnly = false) => {
  const date = local
    ? new Date(timestamp.slice(0, -1))
    : new Date(timestamp)

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  const min = date.getMinutes()
  const time = !dateOnly ? ` ${hours}:${`0${min}`.slice(-2)}` : ''

  return `${day}.${month}.${year}${time}`
}
/**
 * Get a duration (end date - start date)
 * in human-readable form.
 *
 * @param {string|Date} t1  Start date
 * @param {string|Date} t2  End date
 * @returns {string|false}  Delta of End - Start in human-readable form
 */
const getRelativeTime = (t1, t2 = new Date()) => {
  t1 = t1 instanceof Date ? t1 : new Date(t1)
  t2 = t2 instanceof Date ? t2 : new Date(t2)

  const secondsPast = (t2.getTime() - t1.getTime()) / 1000
  const parsedSeconds = u => parseInt(secondsPast / u, 10)

  return ((secondsPast < 60) && { seconds: secondsPast })
      || ((secondsPast < 3600) && { minutes: parsedSeconds(60) })
      || ((secondsPast <= 86400) && { hours: parsedSeconds(3600) })
      || ((secondsPast <= 2628000) && { days: parsedSeconds(86400) })
      || ((secondsPast <= 31536000) && { months: parsedSeconds(2628000) })
      || ((secondsPast > 31536000) && { years: parsedSeconds(31536000) })
}

export {
  getRelativeTime as default,
  getReadableDate,
}
