import { curry } from 'lib/util'

/**
 * @callback syncUiFactoryCallback
 * @param {string} path - native platform ui component location
 * @return {function} ui - lazy function
 */

/**
 * Ui Component Factory
 *
 * provides a loaded native platform ui module (lazy)
 *
 * IMPORTANT, it does not initialize the module,
 * as it is platform's responsibility when to render ui
 *
 * @param {Gaia.requireLoaders} loaders - {@link Gaia.requireLoaders}
 * @param {string} path - native platform ui component location
 * @return {syncUiFactoryCallback}
 */
export const uiFactoryFn = (loaders, path) => {
  try {
    return loaders.ui(path)
  } catch (error) {
    throw ReferenceError(`ui factory, ${path}: ${error.message}`)
  }
}

export default curry(uiFactoryFn)
