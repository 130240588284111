/**
 * Regular expression matching a phone number.
 *
 * @type {RegExp}
 */
const re = /^\+?[0-9 ]+$/

/**
 * Phone Number Attribute Validator
 *
 * Validates the given field against {@code re}.
 */
export default (value, options) => (re.test(value) || !value.length ? true : throw TypeError(options.error))
