/* eslint-disable no-unused-vars */
/* global G */

import { getFirstItem, setKey } from 'lib/util'
import ApplicationContext from 'platform/react/context/application'
import { useContext } from 'react'

/**
 * Retrieves the users active cart from the server or creates one if the user doesn't have one.
 * Executes {@code G.CART, G.DONE} event afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail = {} }) => {
  const { data = [] } = detail?.[G.DATA] || {}
  const adapter = app[G.ADAPTER][G.CART]
  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const { key: userKey } = getFirstItem(app[G.SESSION][G.STATE][G.META].refs.user)

  const { [G.API]: { type, version } = {} } = adapter[G.STATE] || {}

  const params = {
    filter: {
      submitter: userKey, // only mine
      status: 50, // only active ones
    },
    query: { type },
  }

  try {
    const result = await httpAdapter[G.API].post({ url: `/api/v${version}/search`, params })
    const activeCart = getFirstItem(result?.value)

    if (activeCart) {
      setKey(activeCart, G.DATA, adapter)

      eventBus.dispatch(eventBus.type(G.CART, G.DONE), { [G.DATA]: activeCart })
    } else {
      // user has no active cart, we need to create one
      const session = app[G.SESSION]
      const { [G.STATE]: { [G.META]: { refs: { user = null } = {} } = {} } = {} } = session || {}
      const currentUser = user ? getFirstItem(user)?.key : null

      if (!currentUser) throw new Error('Could not obtain user')

      const cartData = {
        value: {
          status: 50,
          recipientData: {},
          purchaserData: {},
          positions: [].concat(data),
          submitTimestamp: new Date().toISOString(),
        },
        refs: {
          submitter: [currentUser],
          article: [],
        },
      }

      const created = await httpAdapter[G.API].post({ url: `/api/v${version}/${type}`, params: cartData })
      const createdCart = getFirstItem(created)

      const newCart = {
        key: createdCart.id,
        _rev: createdCart.rev,
        ...cartData,
      }

      if (createdCart.ok) {
        setKey(newCart, G.DATA, adapter)

        eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.CREATE, 'cart'))
        eventBus.dispatch(eventBus.type(G.CART, G.DONE), { [G.DATA]: newCart })
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export default fn
