/* global PLATFORM */
import { pipe, mergeDeep } from 'lib/util'

const OPTIONS = 'Options'
const UI = 'Ui'
const PLATFORM_UI = PLATFORM + UI
const PLATFORM_OPTIONS = PLATFORM + OPTIONS

const _iterateObject = obj => (mapper) => {
  const collection = {}
  Object.keys(obj).forEach((key) => {
    const entry = obj[key]
    collection[key] = mapper(entry)
  })
  return collection
}

/**
 * Merges the options configuration object from a component's {@param config} with the
 * platform-specific options configuration (e.g. webOptions), if specified. The latter have
 * priority.
 *
 * @see PLATFORM_OPTIONS
 * @param {Object} config                   component configuration
 * @param {Object} config.options           default component's options configuration
 * @param {Object} config[PLATFORM_OPTIONS] platform-specific component's options configuration
 * @return {Object}                         an object resulting from the shallow merging of the
 *                                          default and the platform-specific component's options
 *                                          objects
 */
const mergePlatformOptions = (config) => {
  const { options = {}, [PLATFORM_OPTIONS]: platformOptions = {} } = config
  return { ...options, ...platformOptions }
}

/**
 * Merges the ui configuration object from an action's {@param config} with the platform-specific ui
 * configuration (e.g. webUi), if specified. The latter have priority.
 *
 * @see PLATFORM_UI
 * @param {Object} config               action configuration
 * @param {Object} config.ui            default action's ui configuration
 * @param {Object} config[PLATFORM_UI]  platform-specific action's ui configuration
 * @return {Object}                     an object resulting from the merging of the default and the
 *                                      platform-specific action's ui objects
 */
const mergePlatformUI = (config) => {
  const { ui = {}, [PLATFORM_UI]: platformUI = {} } = config
  return { ...ui, ...platformUI }
}

/**
 * Attempts to provide additional configuration to an item by loading and merging the configuration
 * found at {@param props.options.config} and merging it with {@param props}.
 *
 * @param {Gaia.SyncFactories} factory
 * @returns {(function(*): void)|*}
 */
const provideItemConfig = factory => (props) => {
  const itemOptions = mergePlatformOptions(props)
  const itemConfig = itemOptions.config ? factory.config(itemOptions.config) : {}
  // eslint-disable-next-line no-param-reassign
  props.options = { ...itemOptions, ...itemConfig }
}

const provideModuleConfig = factory => (entry) => {
  const moduleConfig = factory.config(entry.config)
  const entryConfigOptions = mergePlatformOptions(entry)
  const moduleConfigOptions = mergePlatformOptions(moduleConfig)
  const moduleOptions = mergeDeep(moduleConfigOptions, entryConfigOptions)
  const t = mergeDeep(moduleConfig.t || {}, entry.t || {})

  // Adding the possibility to have isolated component's options
  const optionsConfig = moduleOptions.config ? factory.config(moduleOptions.config) : {}
  const options = { ...moduleOptions, ...optionsConfig }
  // providing config to structure items
  options.structure?.forEach(provideItemConfig(factory))
  // providing config to action items
  // entry.actions?.forEach?.(provideItemConfig(factory))
  // GAIA-383 - allows merging of model ui presentation and action specific children
  const children = [
    ...moduleConfig.children || [],
    ...entry.children || [],
  ]

  // If we have a child with the same key in both {@link moduleConfig.children} and
  // {@link entry.children}, we need to filter the resulting array to not contain two items with the
  // same key. The child coming from entry will take precedence.
  const filteredChildren = children.filter((child, i) => (child?.options?.key
    ? children.findLastIndex(x => x?.options?.key === child?.options?.key) === i
    : true))

  if (children.length !== filteredChildren.length) {
    console.groupCollapsed('ATTENTION!! Identical children found and filtered')
    console.warn('original children', children)
    console.warn('filtered children', filteredChildren)
    console.groupEnd()
  }

  return {
    ...moduleConfig,
    ...entry,
    t,
    options,
    children: filteredChildren,
  }
}

const provideActions = moduleProvider => (entry) => {
  if (!entry.actions) {
    return entry
  }
  const actions = entry.actions.map(moduleProvider)
  return {
    ...entry,
    actions,
  }
}

const provideChildren = moduleProvider => (entry) => {
  if (!entry.children) {
    return entry
  }
  const children = entry.children.map(moduleProvider)
  return {
    ...entry,
    children,
  }
}

const provideTemplate = moduleProvider => (configuration) => {
  if (!configuration.template) {
    return configuration
  }
  const template = _iterateObject(configuration.template)(moduleProvider)
  return {
    ...configuration,
    template,
  }
}

const provideModule = factory => (entry) => {
  if (!entry.config) {
    return entry
  }
  const moduleProvider = provideModule(factory)
  const provide = pipe(
    provideModuleConfig(factory),
    provideChildren(moduleProvider),
    provideActions(moduleProvider),
    provideTemplate(moduleProvider),
  )
  return provide(entry)
}

/**
 * NEW MODEL CONFIGURATION PROVISIONING
 *
 * we are dropping pshMeta & pshData, and using normalized structure:
 * options -> pshMeta
 * children -> pshData
 *
 * this implementation removes the need to differentiate how we iterate
 * over configuration artifacts, and allows us to reuse existing provisioning mechanism
 * with minimal adjustments to the provided configuration
 *
 * todo: @alv - update BDDs
 *
 * @param factory
 * @return {function(...[*]=)}
 */
const provideModelNew = factory => (entry) => {
  // entry is once configuration.model, afterwards it's all entry.config
  if (!entry.model) {
    if (entry.children) {
      const modelProvider = provideModelNew(factory)
      return provideChildren(modelProvider)(entry)
    }
    return entry
  }
  const modelProvider = provideModelNew(factory)
  const provide = pipe(
    provideModuleConfig(factory),
    provideChildren(modelProvider),
  )
  // todo: a potential issue can arise here, during initial iteration,
  //  as entry contains more than model configuration | name
  return provide({ config: `model/${entry.model}`, ...entry })
}

/**
 * Provides Model Configuration
 *
 * Recursive inclusion of sub-models, when an attribute has config entry.
 *
 * @example
 * {
 *   pshData: {
 *     ...,
 *     value: {
 *      //  inclusion of different model as subset, allowing subsequent data objects
 *      //  to be mapped to another model's pshData
 *      type: 'list',
 *      config: 'model/test/subset', // reference to sub-model's configuration location
 *     },
 *     ...,
 *   },
 * }
 *
 *
 * @param factory
 * @return {Function}
 */
const provideModel = factory => (configuration) => {
  if (!configuration.model) {
    return configuration
  }
  // eslint-disable-next-line no-param-reassign
  configuration.model = provideModelNew(factory)(configuration)
  return configuration
}

const provideController = moduleProvider => (configuration) => {
  if (!configuration.actions) {
    return configuration
  }
  const actions = {}
  Object.keys(configuration.actions).forEach((i) => {
    const screenConfig = configuration.actions[i]
    const parsedModule = moduleProvider(screenConfig)
    actions[i] = {
      ...screenConfig, // defaults
      ...parsedModule, // extended
      ui: mergePlatformUI(screenConfig),
    }
  })
  return {
    ...configuration,
    actions,
  }
}

/**
 * Library Configuration Provider
 *
 * Provides iteration and recursion over configuration object.
 *
 * @param {Gaia.SyncFactories} factory
 * @return {function(path: string): (Gaia.AppModule | Gaia.Module)}
 */
export const configProvider = factory => (path) => {
  const config = factory.config(path)
  const moduleProvider = provideModule(factory)
  const provide = pipe(
    provideController(moduleProvider),
    provideModel(factory),
    moduleProvider,
    provideChildren(moduleProvider),
    provideTemplate(moduleProvider),
  )
  return provide(config)
}

export default configProvider
