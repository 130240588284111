/* global G */
import { deleteKey } from '@gaia/util'

/**
 * Transaction Begin Flag Remover
 *
 * should be used in conjunction with transactionBegin hook
 *
 * @param obj
 * @return {function(...[*]): *[]}
 */
const transactionEnd = obj => (...args) => {
  deleteKey(G.UPDATE, obj[G.STATE])
  return args
}

export default transactionEnd
