import create from '@platform/adapter/notification/api/group/message/create'
import read from '@platform/adapter/notification/api/group/message/read'
import show from 'platform/adapter/notification/api/group/message/show'

const api = Object.create({}, {
  create: {
    value: create,
    iterable: true,
    enumerable: true,
  },
  read: {
    value: read,
    iterable: true,
    enumerable: true,
  },
  show: {
    value: show,
    iterable: true,
    enumerable: true,
  },
})

export {
  api as default,
}
