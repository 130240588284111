/* eslint-disable no-unused-expressions */
/* global G */
import { setKey } from 'lib/util'
import { getDeleteUrl } from '@platform/adapter/attachment/helper'

/**
 * Listener function executed on successful delete call.
 *
 * @param {Gaia.Web.Application} app  Platform Web Application
 * @param {string} groupName          the name of the group the file was deleted from
 * @param {File} item                 a File object
 */
const onComplete = (app, groupName, item) => {
  // eslint-disable-next-line no-unused-expressions
  const { [G.EVENTS]: eventBus, [G.ATTACHMENT]: adapter } = app[G.ADAPTER]
  const group = adapter[G.GROUP][groupName]
  const files = group[G.CACHE]
  const itemPos = files.indexOf(item)
  files.splice(itemPos, 1)
  setKey(files, G.CACHE, group)
  eventBus.dispatch(
    eventBus.type(G.ATTACHMENT, G.DONE, groupName),
    { [G.DATA]: group[G.DATA].concat(group[G.CACHE]), [G.STATE]: group[G.STATE] },
  )
}

/**
 * Attempts to remove the remote file passed inside the event's detail object from the remote
 * source. Dispatches a DONE event with the current local and remote ones afterwards.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => async ({ detail }) => {
  const item = detail[G.DATA]
  const url = `${getDeleteUrl(app)}/${item.key}`
  await app[G.ADAPTER][G.HTTP][G.API].delete({ url })
  onComplete(app, groupName, item)
}

export default fn
