import config from 'lib/loader/async/config'
import component from 'lib/loader/async/component'
import ui from 'lib/loader/async/ui'
import module from 'lib/loader/async/module'
import model from 'lib/loader/async/model'
import validator from 'lib/loader/async/validator'

/**
 * Library Async Loaders.
 *
 * Important:
 *    dynamic import is based on regenerator-runtime,
 *    provided by babel transpiler.
 *    This is a costly procedure.
 *
 * To use for bootstrapping either via
 * - direct imports,
 * - provided dependency,
 * - or exportable as a stand alone module
 *
 * Since those are bootstrappable,
 * a platform can and maybe should extend on the functionality, by...
 * a functional composition >:))
 *
 * ie, const someNativeAsyncLoader = pipe(
 *  importedLibAsyncLoader,
 *  nativePlatformLoader
 * )
 *
 * @memberOf Gaia#
 * @typedef {Object} asyncLoaders
 * @property {Function} config - {@link Gaia.asyncLoaders.config}
 * @property {Function} module - {@link Gaia.asyncLoaders.module}
 * @property {Function} ui - {@link Gaia.asyncLoaders.ui}
 * @property {Function} component - {@link Gaia.asyncLoaders.component}
 * @property {Function} model - {@link Gaia.asyncLoaders.model}
 * @property {Function} validator - {@link Gaia.asyncLoaders.validator}
 */
const asyncLoaders = {
  config,
  component,
  ui,
  module,
  model,
  validator,
}


export {
  config,
  component,
  ui,
  module,
  model,
  validator,
  asyncLoaders as default,
}
