/* global G */
import { withDependencyCheck } from 'lib/trait/with'

const descriptor = 'sequence::module::adapter::router::redirect'

// todo: check
// const abortMessage = 'redirect aborted. possible reasons for it:'
// + '\n - missing route configuration in clicked element'

const abortOnErrorMessage = 'redirect cancelled due to module containing an error'

const checkDeps = (obj, ...args) => {
  withDependencyCheck(descriptor, [G.ROUTER], obj[G.ADAPTER])
  withDependencyCheck(descriptor, [G.ROUTE], obj[G.STATE])
  return args
}

const onError = (obj, ...args) => {
  const state = obj[G.STATE]
  state[G.ROUTE] = state[G.ERROR] ? null : state[G.ROUTE]
  // throws "unreadable code detected" warning
  return !state[G.ERROR] ? args : throw Error(abortOnErrorMessage)
}

const redirect = obj => async (...args) => {
  checkDeps(obj)
  try {
    onError(obj)
    /** const { moduleAction, action } = [platform]/src/lib/adapter/router/api/index.js */
    const { moduleAction, action } = obj[G.ADAPTER][G.ROUTER]
    const route = obj[G.STATE][G.ROUTE]
    return route[G.MODULE]
      ? await moduleAction(route, ...args)
      : await action(route[G.ACTION], ...args)
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
    // wip - selective error handling
    // on successful redirect, we get a message moduleAction() not found,
    // because the runtime of called action isn't finished yet, so the reference is lost.
    // this does not hinder the sequence to run successfully!
    // e.message.indexOf(abortOnErrorMessage) !== -1 && console.warn(e.message)
    //
    // if event handler executes a sequence instead of bound api,
    // we do could skip selective error handling
    // return e.message.indexOf('moduleAction') === -1
    //   && e.message.indexOf(abortOnErrorMessage) === -1
    //   ? throw Error(`${obj._name} ${descriptor} - ${e.message}`)
    //   : args
  }
}

/**
 * Module Redirect Sequence
 *
 * It uses provided adapter, G.ROUTER, in order to display different ui content,
 * be it an action of the same module, or different module/action combination.
 *
 */
export default redirect
