/* global G */
import { useCallback, useContext, useEffect, useState } from 'react'
import ApplicationContext from '@platform/react/context/application'

/**
 * useNetInfo hook
 *
 * Provides information about the current state of the network.
 *
 * @returns {[boolean, function]} state whether the application has internet connectivity and a
 *                                      function to force check it
 */
const useNetInfo = () => {
  const { eventBus, isOnline } = useContext(ApplicationContext)
  const [online, setOnline] = useState(isOnline)

  const forceCheck = useCallback(() => {
    eventBus.dispatch(eventBus.type(G.HTTP, G.UPDATE), {})
  }, [])

  const handleConnectivityChange = useCallback((event) => {
    setOnline(event.detail.online)
  }, [])

  useEffect(() => {
    const eventType = eventBus.type(G.HTTP, G.ONLINE)
    eventBus.add(eventType, handleConnectivityChange)
    return () => eventBus.remove(eventType, handleConnectivityChange)
  }, [])

  return [online, forceCheck]
}

export default useNetInfo
