/* eslint-disable object-curly-newline */
/* global G */
import { active, get, resolve } from 'lib/manager/acl/helper'

/**
 * ACL Cell Resolver
 *
 * Only meant to be used for cell components in {@link StructuredList}.
 * Make sure you are using the Acl variant of the list and that it has
 * access to the {@code cell} acl event handler.
 *
 * @example
 * {
 *   // ...
 *   view: 'Component/Acl/List/Structured',
 *   options: {
 *     events: {
 *       acl: 'cell',
 *       // ...
 *     },
 *   // ...
 * }
 *
 * Add acl checks to cell components like in any other configuration:
 *
 * @example
 *
 * {
 *   view: 'Element/List/Item/Cell/ServiceItem/WithSerial',
 *   placeholder: 'Element/Placeholder/List/Item/Cell/Text',
 *   aclContextRole: ['MasterDataAdministrator'],
 *   options: {
 *     keys: {
 *       // ...
 *     },
 *     placeholder: {
 *       textLineWidth: [100, 20],
 *     }
 *   },
 * },
 *
 * @param {Gaia.Web.Application} obj - native application
 * @returns {(function(*, *): ({}))|*}
 */
const fn = obj => (component, cell) => {
  // is acl active?
  if (!active(obj)) {
    return {}
  }

  const acl = !cell[G.ACL] ? null : resolve(cell)

  // permission check
  try {
    acl && get(obj)[G.API].check(get(obj)[G.API].get(acl[G.META]), acl[G.ACL])
  } catch (e) {
    return acl[G.PROPS]
  }
  // context role check
  if (cell[G.CONFIGURATION].aclContextRole) return get(obj)[G.API].contextRole(cell[G.CONFIGURATION])

  // context role check
  if (cell[G.CONFIGURATION].aclUserRole) return get(obj)[G.API].userRole(cell[G.CONFIGURATION])

  // context check
  if (cell[G.CONFIGURATION]?.aclContext) return get(obj)[G.API].context(cell[G.CONFIGURATION])

  return {}
}

export default fn
