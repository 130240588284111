/**
 * App Module (Controller) Async Loader.
 *
 * Async Await Implementation of a dynamic import functionality.
 *
 * Uses webpack v4 chunk mechanism
 * Requires babel dynamic import plugin
 *
 * @note using the word controller out of convention, even though those are function compositions
 *
 * @memberOf Gaia.asyncLoaders
 * @typedef {Function} asyncModuleLoader
 * @param {string} path - controller path
 * @return {Promise<*>} controller module
 */
export default async path => await import(/* webpackChunkName: "controller." */`app/${path}`)
  .then(module => module.default);
