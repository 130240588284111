/* global G */

/**
 * Effect Application Cache
 *
 * sets session cache module and action
 * based on current session state
 *
 * it is used in case of routing to a different module in modal context
 *
 * @param obj
 * @return {function(...[*]): *[]}
 */
const setAppCache = obj => (...args) => {
  const sessionState = obj[G.SESSION][G.STATE]
  const currentContext = sessionState[G.CONTEXT]
  const currentModule = sessionState[G.MODULE]
  const currentAction = sessionState[G.ACTION]
  sessionState[G.CACHE] = {
    [G.CONTEXT]: currentContext,
    [G.MODULE]: currentModule,
    [G.ACTION]: currentAction,
  }
  return args
}

export default setAppCache
