import { isArr } from 'lib/util'

/**
 * Validator - Not Empty Attribute
 *
 * @param {string} value - value to validate
 * @param {object} options - validator options
 * @return {string} value - validated value
 */
export default (value, options) => {
  const { error } = options
  return (isArr(value) && value.length) || value !== undefined
    ? value
    : throw TypeError(error)
}
