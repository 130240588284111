import init, { reset } from './init'
import action from './action'
import check from './check'
import component from './component'
import data from './data'
import link from './link'
import get from './get'
import model from './model'
import create from './create'
import contextRole from './role/context'
import userRole from './role/user'
import context from './context'
import cell from './cell'

const api = obj => Object.freeze(Object.create({}, {
  contextRole: {
    value: contextRole(obj),
    iterable: true,
    enumerable: true,
  },
  userRole: {
    value: userRole(obj),
    iterable: true,
    enumerable: true,
  },
  context: {
    value: context(obj),
    iterable: true,
    enumerable: true,
  },
  create: {
    value: create(obj),
    iterable: true,
    enumerable: true,
  },
  get: {
    value: get(obj),
    iterable: true,
    enumerable: true,
  },
  link: {
    value: link(obj),
    iterable: true,
    enumerable: true,
  },
  data: {
    value: data(obj),
    iterable: true,
    enumerable: true,
  },
  check: {
    value: check(obj),
    iterable: true,
    enumerable: true,
  },
  component: {
    value: component(obj),
    iterable: true,
    enumerable: true,
  },
  cell: {
    value: cell(obj),
    iterable: true,
    enumerable: true,
  },
  model: {
    value: model(obj),
    iterable: true,
    enumerable: true,
  },
  action: {
    value: action(obj),
    iterable: true,
    enumerable: true,
  },
  init: {
    value: init(obj),
    iterable: false,
    enumerable: false,
  },
  reset: {
    value: reset(obj),
    iterable: false,
    enumerable: false,
  },
}))

export default api
