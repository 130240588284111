/* global G */
import { pipe } from 'lib/util'


/**
 * Decorates Model Attribute Value based on Model Decorator
 *
 * Fills in empty refs...
 * and what else?
 *
 * @param obj
 * @return {function(*=): *}
 */
const modelDecoration = obj => (data) => {
  !!obj[G.DECORATOR] && Object.keys(obj[G.DECORATOR]).reduce(
    (acc, key) => obj[G.DECORATOR][key](obj, data) && acc,
    data,
  )
  return data
}

const sequenceModelValueDecoration = obj => pipe(
  modelDecoration(obj),
)(
  obj[G.STATE][G.DATA],
) && obj

export default pipe(
  sequenceModelValueDecoration,
)
