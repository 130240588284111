/* eslint-disable no-param-reassign,no-use-before-define,no-return-assign,no-unused-expressions,
 no-restricted-syntax,no-await-in-loop */
/* global G */
import { asyncpipe, curry } from 'lib/util'
import { withDependencyCheck } from 'lib/trait/with'
import { disabled } from 'lib/sequence/component/state/disabled'

const descriptor = 'sequence::model::validate'

/**
 * Preliminary Check for sequence required attributes.
 *
 * @param obj - model object composition
 * @param component - component object composition
 * @return {*}
 */
const checkDeps = (obj, component) => {
  withDependencyCheck(`${descriptor} model`, [G.CHILDREN], obj)
  withDependencyCheck(`${descriptor} component`, [G.CHILDREN], component)
  return component
}

/**
 * Model Error Purger
 *
 * Resets ERROR flag in Model's State
 *
 * @param {Gaia.Model.Spec} obj - model composition
 * @param {Gaia.Component} component - component composition
 * @return {*}
 */
const purgeError = (obj, component) => {
  const objState = obj[G.STATE]
  objState[G.ERROR] = null
  return component
}

/**
 * Data to validate Accumulator
 *
 * accumulates data sets, based on current ui availability
 *
 * excludes hidden and disabled ui elements
 *
 * @param obj - model composition instance
 * @param component - ui component composition instance
 * @return {{ui: component, item: Gaia.ModelAttribute}[]} set - data set
 */
const dataToValidate = (obj, component) => component[G.CHILDREN]
  .reduce((acc, item) => {
    const { key } = item[G.PROPS]
    const { hidden, disabled } = { ...item[G.PROPS], ...item[G.STATE] }
    return Object.keys(obj[G.CHILDREN]).includes(key) && !hidden && !disabled
      ? acc.push([item, obj[G.CHILDREN][key]]) && acc
      : acc
  },
  [])

/**
 * Propagates data from parent to children, recursively.
 *
 * @param {Gaia.Model.Spec} parent - model composition
 * @private
 */
const _propagateData = (parent) => {
  const data = parent[G.DATA].value
  const object = (data && data[0]) || data
  const { value, refs, ...rest } = object || {}
  const attributes = { ...value, ...refs, ...rest }

  Object.keys(parent[G.CHILDREN]).forEach((key) => {
    const child = parent[G.CHILDREN][key]
    attributes[key] ? child[G.DATA].value = attributes[key] : delete child[G.DATA].value
    child[G.CHILDREN] && _propagateData(child)
  })
}

/**
 * Data Setter.
 *
 * Sets G.DATA on Model and Attribute.
 *
 * @param obj - model composition
 * @param data
 * @return {*} data
 */
const setData = (obj, data) => {
  !obj[G.STATE][G.ERROR] && data.reduce((acc, arr) => {
    const [ui, item] = arr
    // TODO: should also take G.PROPS into account, but current checkbox true values would prevent
    //       it from working as it should:
    const uiState = ui[G.STATE]

    if (item[G.CHILDREN]) {
      // in case item has children, we have to consider two possible cases:
      if (ui[G.CHILDREN] && ui[G.CHILDREN].length) { // matching UI also has children
        // In case the matching UI also has children (which should mean that is a container for
        // other components) it will not contain a representation of the current state of its
        // children (which usually are form fields), so we gather the children's values and use
        // them for the parent's data
        acc[item._name] = Object.values(ui[G.CHILDREN]).reduce((agg, uiChild) => {
          agg[uiChild[G.PROPS].key] = uiChild[G.STATE].value
          return agg
        }, {})
      } else { // matching UI has no children
        // In case the matching UI has no children (which should mean it is a field), we
        // propagate data from parent to children
        item[G.DATA].value = uiState.value
        acc[item._name] = uiState.value
        _propagateData(item)
      }
    } else {
      // In case item has no children we simply store the ui state value in the model data
      item[G.DATA].value = uiState.value
      acc[item._name] = uiState.value
    }
    return acc
  }, obj[G.DATA])

  return data
}

/**
 * Attribute Validators Async Iterator
 *
 * @param {*} value - value to validate
 * @param {array} validators - validators collection
 * @param {object} [ui] - component composition
 * @param {object} [obj] - model composition
 * @return {Promise<void>}
 * @private
 */
const _runAttributeValidators = async (value, validators, ui, obj) => {
  // for await (const validator of validators) {
  for (let x = 0; x < validators.length; x++) {
    const validator = await validators[x]
    try {
      !validator[G.STATE].disabled
        ? await validator[G.FN](value, validator[G.PROPS])
        : console.log(`Validator is disabled: ${ui[G.PROPS].key}`)
    } catch (e) {
      throw Error(await obj[G.ADAPTER][G.INTL].validator(
        ui[G.PROPS][G.ROUTE], validator._name, e.message, validator[G.CONFIGURATION].t || null,
      ))
    }
  }
}

/**
 * Model Validator Error Message Getter
 *
 * model validator errors are arrays, having keys as error codes
 * we use those errors here, in order to provide a readable error message
 *
 * @example
 * {
 *   validator: {
 *     username: {
 *       options: {
 *         error: {
 *           '403': 'inactive user',
 *           '401': 'unregistered user',
 *         }
 *       },
 *     },
 *   },
 * },
 *
 * @param {object} obj - model composition
 * @param {string} key - validator key identifier
 * @param {string} code - message code
 * @param {string} [message] - message
 * @param {object} [ui] - component composition
 * @return {getMessageFromModelValidator.props|*}
 * @private
 */
export const getMessageFromModelValidator = async (obj, key, code, message, ui) => {
  const { validator: configuration } = obj[G.CONFIGURATION]
  const error = configuration?.[key]?.options.error
  const t = (code && configuration?.[key]?.t?.[code])
            || (configuration?.[key]?.t?.options && configuration?.[key]?.t)
            || { options: { ns: 'common', _key: `validator.http.${code}` } }

  // fallback for non-configured validator ns inside the model
  const string = (error && error[code]) || (error && error[message] ? error[message] : message)
  const route = ui ? ui[G.PROPS][G.ROUTE] : []
  return await obj[G.ADAPTER][G.INTL].validator(route, `${key}.${code}`, string, t)
}

/**
 * Model Attribute Level Validation
 *
 * skips if model state already contains an error
 * iterates over attribute and it's associated ui component set
 * executes validators, if set
 * sets error flag and message on ui component
 * if validation fails
 *
 * @param obj - model object composition
 * @param data
 * @return {Promise<*>}
 */
const asyncAttributeValidation = async (obj, data) => {
  if (obj[G.STATE][G.ERROR]) { // || obj[G.STATE][G.SUCCESS] -> asyncComponentValidation
    return data
  }
  const debugKey = 'refxx'
  const objState = obj[G.STATE]
  console.groupCollapsed('Attribute validation', obj._name)
  // eslint-disable-next-line no-restricted-syntax
  // for await (const arr of data) {
  for (let x = 0; x < data.length; x++) {
    const arr = await data[x]
    const [ui, item] = arr
    const uiState = ui[G.STATE]
    item[G.PROPS].key === debugKey && console.groupCollapsed('asyncAttributeValidation', obj._name) // , obj[G.STATE], data)
    try {
      item[G.PROPS].key === debugKey && console.log('tryin', uiState.value, item[G.VALIDATOR])

      uiState.error = false
      uiState.helperText = '' // todo: read out original helperText from (G.CONFIGURATION | G.PROPS t.b.d.) as G.STATE is overwritten
      // eslint-disable-next-line no-use-before-define
      // recursion using a sub model, we need to propagate their children to validation process
      // disable running validators on sub-model
      !item[G.CHILDREN]
      && item[G.VALIDATOR]
      && await _runAttributeValidators(uiState.value, item[G.VALIDATOR], ui, obj)

      item[G.CHILDREN] && await validate(item, ui)

      console.log(`Validated attribute: ${item[G.PROPS].key}`, uiState.value)
      // bubble sub model errors
      // eslint-disable-next-line no-unused-expressions
      item[G.STATE] && item[G.STATE][G.ERROR] && throw Error(item[G.STATE][G.ERROR])
    } catch (e) {
      console.error(`Validation error on attribute: ${item[G.PROPS].key}`, uiState.value)
      // console.log('hmmm', obj._name, objState)
      // throwing here will hinder continuous validation of follow up components
      objState[G.ERROR] = { ...objState[G.ERROR], [item._name]: e.message }
      uiState.error = true
      // todo: temporary, adheres to mui inputField structure, not generic enough
      uiState.helperText = e.message
      // console.warn(e)
    }
    item[G.PROPS].key === debugKey && console.groupEnd()
  }
  console.groupEnd()
  return data
}

/**
 * UI Component Level Validation
 *
 * skips if model state already contains an error
 * iterates over ui components
 * executes validators, if set
 * sets error flag and message on ui component
 * if validation fails
 *
 * todo: eventually, we might need to add a G.SUCCESS flag,
 *  so the modelValidation wont run for associated sub models.
 *
 * @param obj - model object composition
 * @param data
 * @return {Promise<*>}
 */
const asyncComponentValidation = async (obj, data) => {
  if (obj[G.STATE][G.ERROR]) { // || obj[G.STATE][G.SUCCESS] -> asyncComponentValidation
    return data
  }
  const objState = obj[G.STATE]

  console.groupCollapsed('Component validation', obj._name)
  // for await (const arr of data) {
  for (let x = 0; x < data.length; x++) {
    const arr = await data[x]
    const [ui, item] = arr
    const uiState = ui[G.STATE]
    try {
      uiState.error = false
      uiState.helperText = '' // todo: read out original helperText from (G.CONFIGURATION | G.PROPS t.b.d.) as G.STATE is overwritten
      // recursion using a sub model, we need to propagate their children to validation process
      ui[G.VALIDATOR] && await _runAttributeValidators(uiState.value, ui[G.VALIDATOR])
      // bubble component errors
      // eslint-disable-next-line no-unused-expressions
      item[G.STATE] && item[G.STATE][G.ERROR] && throw Error(item[G.STATE][G.ERROR])
      console.log(`Validated attribute: ${item[G.PROPS].key}`, uiState.value)
    } catch (e) {
      console.error(`Validation error on attribute: ${item[G.PROPS].key}`, uiState.value)
      // throwing here will hinder continuous validation of follow up components
      objState[G.ERROR] = { ...objState[G.ERROR], [item._name]: e.message }
      uiState.error = true
      // todo: temporary, adheres to mui inputField structure, not generic enough
      uiState.helperText = e.message
      // console.warn(e)
    }
  }
  console.groupEnd()
  return data
}

/**
 * Model Level Validation.
 *
 * calls validators assigned to model object composition,
 * filtered by the passed data set.
 *
 * @param obj - model object composition
 * @param data
 * @return {Promise<*>}
 */
const asyncModelValidation = async (obj, data) => {
  // || obj[G.STATE][G.SUCCESS] -> asyncComponentValidation
  if (!obj[G.VALIDATOR] || obj[G.STATE][G.ERROR]) {
    return data
  }
  // console.log('asyncModelValidation', data)
  const objState = obj[G.STATE]
  console.groupCollapsed('Model validation', obj._name)

  // for await (const arr of data) {
  for (let x = 0; x < data.length; x++) {
    const arr = await data[x]
    const [ui] = arr
    const uiState = ui[G.STATE]
    const { key } = ui[G.PROPS]
    const validator = obj[G.VALIDATOR][key]
    const isDisabled = !validator || disabled(validator)
    try {
      validator
        && !isDisabled
        && await obj[G.VALIDATOR][key][G.FN](obj)(uiState, key)
      !isDisabled
        ? console.log(`Validated attribute: ${key}`, uiState.value)
        : console.log(`Validator is disabled: ${key}`)
    } catch (e) {
      console.error(`Validation error on attribute: ${key}`, uiState.value)
      const { message, code } = e
      objState[G.ERROR] = { ...objState[G.ERROR], [key]: message }
      objState[G.ERROR][G.REF] = { ...objState[G.ERROR][G.REF], [key]: code }
      uiState.error = true
      // temp, adheres to mui inputField structure, not generic enough
      uiState.helperText = await getMessageFromModelValidator(
        obj,
        key,
        code,
        message,
        ui,
      )
    }
  }
  console.groupEnd()
  return data
}

/**
 * Model Data Purger.
 *
 * Unset G.DATA on Model and Attribute,
 * if model contains error(s).
 *
 * The Data to be unset correlates to current validators,
 * meaning, if other data already exists, and has been successfully validated,
 * it will not be purged.
 *
 * @param obj - model composition
 * @param data
 * @return {*} data
 */
const purgeDataOnError = (obj, data) => {
  obj[G.STATE][G.ERROR]
  && delete obj[G.DATA].value
  && data.reduce((acc, arr) => {
    const [, item] = arr
    delete item[G.DATA].value
    delete acc[item._name]
    return acc
  }, obj[G.DATA])
  return data
}

/**
 * Validation
 *
 * Executes validators for visible component children,
 * which are mapped to model children.
 *
 * The method uses ES7 'for await...of' constructor / async iterator
 * For more information, see:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/for-await...of
 * Additional sources:
 * - https://medium.com/dailyjs/async-generators-as-an-alternative-to-state-management-f9871390ffca
 *
 * @param obj - model object composition
 * @param component - component object composition, ie module[G.ACTION]
 * @return {Promise<*>}
 */
const validate = async (obj, component) => await asyncpipe(
  curry(checkDeps)(obj),
  curry(purgeError)(obj),
  curry(dataToValidate)(obj),
  curry(setData)(obj),
  curry(asyncAttributeValidation)(obj),
  curry(asyncComponentValidation)(obj),
  curry(asyncModelValidation)(obj),
  curry(purgeDataOnError)(obj),
)(component)

/**
 * Model Validation Sequence.
 *
 * Validates currently visible data by executing model attribute(child) validators
 *
 * @param {Gaia.Model.Spec} obj - model object composition
 * @return {function(*=): *}
 */
export default curry(validate)
