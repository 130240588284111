/* global G */

/**
 * Generic error dialog
 *
 * Displays an error informing the user that an operation was unsuccessful.
 *
 * To be displayed when a response with code 400 or 500 is received from the server.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 */
export default (obj) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.generic.title',
      {
        ns: 'common',
        _key: 'dialog.error.generic.title',
        defaultValue: 'Error',
      },
    ),
    text: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.generic.text',
      {
        ns: 'common',
        _key: 'dialog.error.generic.text',
        defaultValue: 'An error has occurred, please try again later.',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: obj[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
  })
}
