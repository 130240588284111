/* global G */
import { pipe } from 'lib/util'
import { withDescriptor, withObjectFreeze } from 'trait/with'
import { withGetterSetterFn } from '@gaia/trait/with/GetterSetter'
import { hasNamespace } from 'trait/has'
import { usesGetterSetter, usesNamespace } from 'trait/uses'
import api from './api'

const descriptor = 'adapter:Notification'

/**
 * Native Web Platform Notification Adapter
 *
 * @memberOf Gaia.Adapter#
 * @typedef Adapter.Notification
 *
 * @property {String} _name             module descriptor. see {@link withDescriptor}
 * @property {object} G.API             adapter api
 * @property {Adapter.Message.API} API  adapter api revised
 *
 * @param {Gaia.Web.Application} obj    web application
 * @return {Gaia.Adapter.Notification}  composition - Native Platform Web Adapter
 */
const adapterFn = (obj) => {
  const adapter = pipe(
    withDescriptor(descriptor),
    withGetterSetterFn(G.GROUP),
    usesGetterSetter(G.GROUP, {}),
    hasNamespace(G.API),
    usesNamespace(G.API, api(obj)),
    withObjectFreeze,
  )({})

  /**
   * @type {Gaia.Adapter.EventBus}
   */
  const eventBus = obj[G.EVENTS]

  const defaults = {
    init: G.INIT,
  }

  Object.keys(defaults).reduce(
    (acc, key) => eventBus.add(
      eventBus.type(G.NOTIFICATION, acc[key]),
      adapter[G.API][key],
    ) || acc,
    defaults,
  )

  return adapter
}

export default adapterFn
