import appModuleComposition from './appmodule'
import moduleComposition from './module'
import modelComposition from './model'
import componentComposition from './component'
import modelAttributeComposition from './modelAttribute'

const traitCollection = {
  appModuleComposition,
  moduleComposition,
  modelComposition,
  componentComposition,
  modelAttributeComposition,
}

export {
  appModuleComposition,
  moduleComposition,
  modelComposition,
  componentComposition,
  modelAttributeComposition,
  traitCollection as default,
}
