/* global G, ENV */
import '@formatjs/intl'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import settings from '@tenant/settings'
import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import { API_URL } from 'lib/util/environment'
import api from './api'
import disabled from './api/disabled'

const baseConfig = {
  backend: {
    loadPath: `${API_URL}/intl/{{lng}}/{{ns}}.json`,
  },
  lng: 'en',
  // TODO: deduce them from languages' collection during app initialization
  supportedLngs: settings.languages
    || ['en', 'de', 'ca', 'fr', 'es', 'pt', 'tr', 'it', 'pl', 'ru', 'zh', 'ja', 'cs', 'sv'],
  ns: ['common', 'custom', 'generic', 'sidebar'],
  fallbackNS: 'common',
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
}

const intlConfig = ENV === 'development' ? obj => (
  {
    ...baseConfig,
    backend: {
      ...baseConfig.backend,
      addPath: `${API_URL}/intl/add/{{lng}}/{{ns}}`,
    },
    // debug: true,
    updateMissing: false, // required for missingKeyHandler
    saveMissing: true, // required for missingKeyHandler
    missingKeyHandler: (...args) => obj[G.ADAPTER][G.INTL].missing(...args),
  }
) : () => ({
  ...baseConfig,
  fallbackLng: 'en',
})

/**
 *
 * @param {Gaia.Web.Application} obj
 * @returns {any}
 */
const translationManager = obj => (!obj[G.PROPS].i18n ? disabled : async () => {
  await i18n
    .use(HttpApi)
    .init(intlConfig(obj))

  return api(i18n)(obj)
})

export default translationManager
