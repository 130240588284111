/* eslint-disable max-len */
import init from '@platform/adapter/attachment/api/init'
import set from '@platform/adapter/attachment/api/set'
import read from '@platform/adapter/attachment/api/read'
import url from '@platform/adapter/attachment/api/url'

/**
 * Attachment data model.
 *
 * Used to obtain and handle attachment objects with the {@link Gaia.Adapter.Attachment.API}.
 *
 * @typedef {Object} Attachment
 * @property {string} key     the object identifier; if it's a named attachment, it may simply
 *                            contain the value of {@param name}
 * @property {string} uuid    a unique identifier for the attachment
 * @property {string} [name]  a string identifying the attachment, if it's a named one
 * @property {string} [url]   a local url pointing to the attachment's binary
 * @property {number} size    the binary's size, in bytes
 * @property {string} type    the binary's meta-type
 * @property {Object} api     api specifications to be considered when handling the attachment
 * @property {string} group   name of the attachment group containing the attachment
 * @property {File} value     binary contents
 */

/**
 * @memberOf Gaia.Adapter.Attachment.API
 * @typedef {Object} AttachmentEventDetailData
 * @property {File[]} [G.DATA]  the file/s to handle
 * @property {Object} [G.PROPS] attachment filters
 * @property {string} [G.NAME]  attachment name, if it's named
 * @property {string} [G.API]   api specifications to be considered when handling files
 */

/**
 * @memberOf Gaia.Adapter.Attachment.API
 * @typedef {Object} AttachmentEventDetail
 * @property {AttachmentEventDetailData} [G.DATA] event detail data
 */

/**
 * AttachmentEvent
 *
 * Intended to transfer information of events triggered wih the {@link Gaia.Adapter.Attachment.API}.
 *
 * @memberOf Gaia.Adapter.Attachment.API
 * @typedef {Object} AttachmentEvent
 * @property {AttachmentEventDetail} detail
 */

/**
 * AttachmentEventListener
 *
 * Intended to handle events triggered with the {@link Gaia.Adapter.Attachment.API}.
 *
 * @memberOf Gaia.Adapter.Attachment.API
 * @callback AttachmentEventListener
 * @param {AttachmentEvent} event
 * @returns {void}
 */

/**
 * @memberOf Gaia.Adapter.Attachment
 * @namespace API
 * @typedef Adapter.Attachment.API
 *
 * @property {function} set - persist remote payload in G.CACHE
 * @param {Gaia.Web.Application} app  Platform Web Application
 * @return {{}}
 */
const api = app => Object.create({}, {
  init: {
    value: init(app),
    iterable: true,
    enumerable: true,
  },
  set: {
    value: set(app),
    iterable: true,
    enumerable: true,
  },
  read: {
    value: read(app),
    iterable: true,
    enumerable: true,
  },
  url: {
    value: url(app),
    iterable: true,
    enumerable: true,
  },
})

export default api
