/* global G */
import { getFirstItem } from 'lib/util'

/**
 * Sets a single notification as read.
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function({detail: *}): Promise<*|null>)|*}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.STATE]: groupState, [G.DATA]: groupData } = group
  const { [G.API]: { api, types, version } = {} } = groupState
  const { id } = detail

  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const currentNotification = groupData.data.find(x => x.id === id)
  const isRead = !!currentNotification.value.read.length

  const url = `/api/v${version}/${api}/${!isRead ? types.setRead : types.setUnread}`
  const params = { id }

  try {
    const result = await httpAdapter[G.API].put({ url, params })

    const data = getFirstItem(result)
    if (data.ok) {
      const updatedItem = groupData.data.find(notification => notification.id === data.id)

      // Server doesn't return timestamp after we set as read, let's create a temporary one.
      // On next fetch we will have the correct one.
      if (updatedItem) {
        updatedItem.value.read = !isRead ? new Date().toISOString() : ''
        !isRead
          ? groupData?.count > 1 && (groupData.count -= 1)
          : groupData.count += 1
      }
    }

    eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.CREATE, groupName), { [G.DATA]: { count: groupData.length } })
  } catch (e) {
    console.error(e)
  }
}

export default fn
