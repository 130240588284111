import { Icon } from '@mui/material'
import { useTemplateStyles } from '@platform/react/hoc/list/cell'
import { isStr } from 'lib/util'

/**
 * Default styles to apply to all option components.
 */
export const optionStyles = theme => ({
  option: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  label: {
    fontSize: 12,
    fontStyle: 'italic',
    color: theme.palette.text.secondary,
  },
})

/**
 * Returns {@param score} number of {@code Icon} nodes.
 *
 * @param {number} score    the score of the option
 * @returns {JSX.Element[]}
 * @private
 */

export const _getStars = score => [...Array(score)].map((e, i) => <Icon sx={{ color: 'pending.main' }} key={`star-${i}`}>star</Icon>)

/**
 * Returns the name and last name of the person set as ref of {@param obj}.
 *
 * @param {object} obj  a raw model object that has a person ref.
 * @returns {*|string}  the person's name
 * @private
 */
const _getPersonName = (obj) => {
  const { name, firstName, lastName } = obj.refs?.person?.[0]?.value || {}
  return name || (firstName && `${firstName} ${lastName}`)
}

/**
 * Displays an {@param option} as a label according to our needs.
 *
 * @param {object} option the option to display
 * @returns {string}      the resulting label
 */
export const getOptionLabel = option => (isStr(option) && option)
    || (isStr(option.value) && option.value)
    || option.value?.name
    || option.value?.label
    || _getPersonName(option)
    || ''

/**
 * Option Higher Order Component
 *
 * HOC that will facilitate the common structure and styling of an option in a {@link Autocomplete}.
 * {@param renderFn} is supposed to be a function responsible for rendering the actual
 * content (formerly called {@code propsToContent}).
 *
 * @param {Function} renderFn         function to render the content
 * @param {Function} additionalStyles additional styles for the cell component
 *
 * @return {*}
 * @constructor
 */
const OptionHOC = (renderFn, additionalStyles = () => ({})) => (props) => {
  const classes = useTemplateStyles(additionalStyles, props, optionStyles)
  const content = renderFn(classes, props)

  return content
}

export default OptionHOC
