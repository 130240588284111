/* global G */
import { get, active } from 'lib/manager/acl/helper'
import ACLError from 'lib/manager/acl/error'

/**
 * used in acl hook - create
 *
 * @todo Change name to something meaningful (maybe 'permission' ?)
 * @todo it's no longer just checking for create permissions, see GAIA-1090
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(): *}
 */
const fn = obj => (permissions = [10]) => {
  if (!active(obj)) {
    return true
  }
  try {
    obj[G.SESSION][G.STATE][G.MODULE][G.MODEL]
    && get(obj)[G.API].check(
      obj[G.SESSION][G.STATE][G.MODULE][G.MODEL][G.ACL],
      permissions,
    )
  } catch (e) {
    throw new ACLError('creation insufficient rights')
  }
  return true
}

export default fn
