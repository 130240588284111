/* global G */
import { setKey } from '@gaia/util'

/**
 * Sets remote assets to adapter's cache and empties the adapter's local and delete data.
 *
 * @param {Gaia.Web.Application} app  the Web platform Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => ({ detail = {} }) => {
  const { [G.DATA]: files } = detail[G.DATA] || {}
  const { [G.EVENTS]: eventBus, [G.ATTACHMENT]: adapter } = app[G.ADAPTER]

  const group = adapter[G.GROUP][groupName]

  if (files) {
    setKey(files, G.CACHE, group)
    setKey([], G.DATA, group)
    setKey([], G.DELETE, group)
  }

  eventBus.dispatch(
    eventBus.type(G.ATTACHMENT, G.DONE, groupName),
    { [G.DATA]: group[G.DATA].concat(group[G.CACHE]), [G.STATE]: group[G.STATE] },
  )
}

export default fn
