/* global G */
import toAction from './toAction'
import toModule from './toModule'


/**
 * Route to a different Module and Action.
 *
 * Pipes toModule(), toAction() api methods.
 *
 *
 * @param {Gaia.Web.Application} app - web application reference
 * @param {*} args - arguments
 * @return {function(...[*])}
 */
const toModuleAction = app => async (route, ...args) => {
  await toModule(app)(route, ...args)
  await toAction(app)(route[G.ACTION], ...args)
  return args
}

export default toModuleAction
