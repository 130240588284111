/**
 * Local Attachment Preview Worker Body
 *
 * is a closure with context of DedicatedWorkerGlobalScope
 * babel transformation error when inline
 * excluded via webpack config
 * current usage of arrow functions needs to be evaluated on multiple OS and browsers,
 * as ES6 native support might still be janky on some constellations
 * alternatives would be to evaluate webpack 5, otherwise use worker-loader
 */
export default () => {
  // eslint-disable-next-line no-restricted-globals
  const _worker = self
  _worker.addEventListener('message', (evt) => {
    const { item, key, uuid } = evt.data
    _worker.postMessage({ blob: item, key, uuid })
  })
}
