/* global G */
import seqRead from '@gaia/sequence/model/api/read'

const descriptor = Symbol('hook::model::read').toString()

/**
 * Model Read Hook.
 *
 * Executes sequence read, which in turn maps payload to provided by the passed component.
 *
 *
 * @param {Gaia.AppModule.Spec} obj - app module composition
 * @return {function(...[*]): *[]}
 */
const modelRead = obj => async (...args) => {
  try {
    await seqRead(obj[G.MODEL])(obj[G.STATE][G.ACTION][G.COMPONENT])
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }
  return args
}

export default modelRead
