/* global G */
import { get, strategy } from 'lib/manager/acl/helper'

/**
 * gets domain acl from manager cache
 *
 * @param obj
 * @return {function(*): *}
 */
const fn = obj => (domain) => {
  const _acl = get(obj)[G.CACHE][domain] || null
  strategy(!!_acl)
  return _acl
}

export default fn
