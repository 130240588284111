/* global G */
import { curry } from '@gaia/util'

/**
 * Provides Guest Context to Layout Component
 *
 * @param rootGetter
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @return {Promise<void>} fire and forget
 */
// eslint-disable-next-line no-unused-vars
const provideFn = async (rootGetter, app, obj) => {
  const layout = app[G.REF]
  // console.log('guest?', app[G.SESSION][G.STATE][G.META])
  layout.setState({
    guest: !!app[G.SESSION][G.STATE][G.META],
    lang: app[G.SESSION][G.STATE][G.LANGUAGE],
  })
}

export default curry(provideFn)
