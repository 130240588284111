/* global G */
/* eslint-disable max-len */

import toggle from 'platform/adapter/notification/api/group/toggle'

/**
 * Creates group specific API by dynamically importing each method defined by the group and
 * creating an object out of the methods.
 *
 * @memberOf Gaia.Adapter.Notification.API
 * @namespace Group
 * @typedef Adapter.Notification.API.Group
 *
 * @param {Gaia.Web.Application} app                Platform Web Application
 * @param {string} groupName                        the name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @return {{}}
 */
const api = async (app, groupName, group) => {
  const { default: implementation } = await import(`./${groupName}`) || {}
  const { [G.MENU]: menu = false } = group[G.STATE]

  const defaultApi = {
    ...menu && {
      toggle: {
        value: toggle(app, groupName, group),
        iterable: true,
        enumerable: true,
      },
    },
  }

  const groupApi = Object.keys(implementation).reduce((acc, key) => {
    acc[key] = {
      value: implementation[key](app, groupName, group),
      iterable: true,
      enumerable: true,
    }
    return acc
  }, defaultApi)

  return Object.create({}, groupApi)
}

export default api
