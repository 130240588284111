/* global G */


/**
 * Library Trait With Configuration.
 *
 * Sets configuration symbol getter in an object,
 * which will be used as prototype for object composition
 *
 * @example
 * ...
 * export default pipe(withConfiguration, withGetterSetter(G.API), hasNamespace(G.STATE))
 * ...
 *
 * @param {Gaia.AppModule.Spec | Gaia.Component.Spec} configuration - {@link Gaia.Component.Spec}
 * @return {object} module object - base for object composition
 */
const withConfiguration = configuration => ({
  get [G.CONFIGURATION]() {
    return { ...configuration }
  },
})

export default withConfiguration
