/* global G */
import routeComposition from '@gaia/trait/composition/route'

/**
 * Can Use Current Action in Browser History Stack Tester.
 *
 * Takes current action's [G.UI] settings, and checks for specific flags.
 *
 * Flag - Modal - this action is not used in Stack
 * Flag - Bookmark - has to be set to true, in order to change current browser history state.
 *
 * @param ui - action's ui configuration {@link Gaia.AppModule.Action.Ui}
 * @return {boolean} result - can action be used on nav stack
 * @private
 */
const _canUse = ui => ui.bookmark && !ui.modal && !ui.drawer

const _hasRef = app => app[G.SESSION][G.STATE][G.MODULE][G.MODEL]
  && app[G.SESSION][G.STATE][G.MODULE][G.MODEL][G.STATE][G.REF]

const _getRef = app => app[G.SESSION][G.STATE][G.MODULE][G.MODEL][G.STATE][G.REF]

/**
 * Sets the session's route as the current module's route. If the current action {@link _canUse},
 * it also triggers a gaia:hashchange event to change the url hash.
 *
 * @param {Gaia.Web.Application} app
 * @returns {function(...[*]): Promise<*[]>}
 */
const setAppRoute = app => async (...args) => {
  const sessionState = app[G.SESSION][G.STATE]
  const appState = app[G.STATE]
  const action = sessionState[G.MODULE][G.STATE][G.ACTION]
  const ui = action && action[G.UI]
  const canUse = ui && _canUse(ui)

  appState[G.ROUTE] = routeComposition(
    sessionState[G.MODULE][G.CONFIGURATION].module,
    sessionState[G.ACTION],
    _hasRef(app) && _getRef(app),
    sessionState[G.CONTEXT],
  )

  canUse && app[G.EVENTS].dispatchEvent(new CustomEvent('gaia:hashchange', {
    bubbles: true,
    cancelable: true,
    detail: {},
  }))

  return args
}

export default setAppRoute
