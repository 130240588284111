import { curry, pipe } from 'lib/util'
import withObjectFreeze from 'lib/trait/with/ObjectFreeze'

/**
 * Component factory.
 *
 * loads and initializes artifacts from lib/module/[MODULE]
 *
 * @memberOf Gaia.Factory#
 * @typedef {function(*=)} Factory.Component
 * @param {Gaia.requireLoaders} loaders - {@link Gaia.requireLoaders}
 * @param { Gaia.Component.Spec } configuration
 * @returns { Gaia.Component } object composition
 */
export const componentFactoryFn = (loaders, configuration) => pipe(
  loaders.component(configuration.module),
  withObjectFreeze,
)(configuration)


export default curry(componentFactoryFn)
