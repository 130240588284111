/* eslint-disable no-unused-vars */
/* global G */

import { search } from 'platform/adapter/notification/helper/search'

/**
 * Fetches all notifications
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function({detail: *}): Promise<{data: (*&{key: *})[]}|null>)|*}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.STATE]: groupState } = group
  const { [G.API]: { api, types, version, limit } = {} } = groupState
  const { page } = detail
  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const url = `/api/v${version}/${api}/${types.list}`

  const params = {
    limit,
    page,
  }

  try {
    const result = await search(httpAdapter[G.API].post, url, params, [])

    return {
      ...result,
      data: result.data.map(item => ({ ...item, key: item.id })),
    }
  } catch (e) {
    console.error(e)
  }

  return null
}

export default fn
