const ns = {
  sub: 'subscribe',
  unsub: 'unsubscribe',
}
const sockets = {}
const emit = socket => (...args) => socket.emit(...args)
/**
 * Uses internal counter for listeners on the same key
 * emits subscribe to relevant key (room) with associated listener
 * @param socket
 * @return {function(*=, *=): void}
 */
const sub = socket => (key, cb) => {
  if (!key) { return }
  sockets[key] = sockets[key] ? sockets[key] + 1 : 1
  emit(socket)(ns.sub, key, cb)
}
/**
 * Unsubs completely from key (room), only if there are no existing listeners left
 * Otherwise executes callback, which usually just removes a single listener,
 * ie socket.off(key, listener)
 *
 * @param socket
 * @return {function(*=, *=): boolean|*}
 */
const unsub = socket => (key, cb) => {
  sockets[key] -= 1
  return sockets[key] === 0 ? emit(socket)(ns.unsub, key, cb) && delete sockets[key] : cb()
}
const error = key => throw ReferenceError(`callback for ${key} is required`)

const api = () => socket => Object.freeze(Object.create({}, {
  get: {
    value: socket,
    iterable: true,
    enumerable: true,
  },
  sub: {
    value: sub(socket),
    iterable: true,
    enumerable: true,
  },
  unsub: {
    value: unsub(socket),
    iterable: true,
    enumerable: true,
  },
  on: {
    value: (key, cb) => (!cb ? error(key) : socket.on(key, cb)),
    iterable: true,
    enumerable: true,
  },
  off: {
    value: (key, listener) => socket.off(key, listener),
    iterable: true,
    enumerable: true,
  },
  connect: {
    value: () => socket.connect(),
    iterable: true,
    enumerable: true,
  },
  disconnect: {
    value: () => socket.disconnect(),
    iterable: true,
    enumerable: true,
  },
}))

export default api
