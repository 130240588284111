
import login from '@gaia/sequence/app/session/cookie/login'
import logout from '@gaia/sequence/app/session/cookie/logout'
import validate from '@gaia/sequence/app/session/cookie/validate'

const sessionCookieSequences = {
  login,
  logout,
  validate,
}

export {
  sessionCookieSequences as default,
  login,
  logout,
  validate,
}
