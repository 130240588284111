/* eslint-disable no-unused-vars */
/* global G */
import routeComposition from '@gaia/trait/composition/route'

/**
 * Sets the selected notification as read and opens it depending on its contents.
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function({detail: *}): Promise<*|null>)|*}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.STATE]: groupState, [G.DATA]: groupData, [G.API]: { update } } = group
  const { [G.API]: { version } = {} } = groupState

  const eventBus = app[G.ADAPTER][G.EVENTS]
  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const routerAdapter = app[G.ADAPTER][G.ROUTER]

  // Setting the current notification as read
  update?.({ detail: { id: detail.key } })

  const [documentationId] = detail?.item?.refs?.documentation || []
  const [attachmentUrl] = detail?.item?.refs?.url || []

  const type = (documentationId && 'documentation') || 'generic'

  // Only do something if we have a specific type
  if (type !== 'generic') {
    const { module, action } = groupState[G.ROUTE][type]

    const route = routeComposition(module, action)
    const url = `/api/v${version}/search`
    const params = {
      limit: 1,
      filter: { parent: documentationId },
      query: { type: 'attachment' },
    }

    try {
      const { value: [attachment = null] = [] } = await httpAdapter[G.API].post({ url, params }) || {}

      const attachmentArgs = attachment || attachmentUrl
        ? { key: attachment?.key,
          value: {
            name: detail?.item?.value?.body, // may get overwritten by attachment.value
            ...attachment?.value,
            ...attachmentUrl && { url: attachmentUrl },
          } }
        : {}

      const documentationArgs = {
        value: {
          attachment: attachmentArgs,
          documentationId,
        },
      }

      const args = (type === 'documentation' && documentationArgs) || {}

      // closing the list
      groupData.anchor = null
      eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.DONE, groupName), { [G.DATA]: groupData })

      // redirect
      await routerAdapter[G.API].moduleAction(route, args)
    } catch (e) {
      console.error(e)
    }
  }
}

export default fn
