/* global G */
import { setKey } from '@gaia/util'

/**
 * Transaction Begin Flag Setter
 *
 * should be used in conjunction with transactionEnd hook
 *
 * @param obj
 * @return {function(...[*]): *[]}
 */
const transactionBegin = obj => (...args) => {
  setKey(true, G.UPDATE, obj[G.STATE])
  return args
}

export default transactionBegin
