/**
 * RegExp Matcher
 *
 * Matches {@param x} with an array of regular expressions and returns the first match or null.
 *
 * @param {String} x input to parse
 * @return {(function([*,...*]): (null|*))|*}
 */
export const bulkMatch = x => ([re, ...rest]) => {
  if (!re) return null
  const result = x.match(re)

  // The first element of the array is the full match, if it's an empty string, we have no match
  return (result?.[0]?.length && result) || bulkMatch(x)(rest)
}

/**
 * Regular expressions to match against.
 *
 * @type {RegExp[]}
 */
export const rules = [
  /^([0-9]+)h( ?)([0-9]+)?m?$/, // 1h, 1h30, 1h30m, 1h 30m
  /^([0-9]+)([:.,])([0-9]+)$/, // 1:30, 1.5, 1,5
  /^\d+$/, // int
]

/**
 *
 * Matches input against {@link rules}.
 *
 * @param value
 * @param options
 * @return {boolean}
 */
export default (value, options) => {
  if (!value) return true

  const { error } = options
  const [match] = bulkMatch(value)(rules) || []

  if (!match) throw TypeError(error)

  // otherwise, field is valid
  return true
}
