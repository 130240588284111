import { repeat } from 'lib/util'

/**
 * Triggers a 'storage' event locally.
 *
 * The native setItem and removeItem functions from localStorage won't trigger 'storage' events in
 * the current tab (only in the other ones), so we need to trigger them ourselves.
 *
 * @param {object} event  an object containing information about the triggered storage event
 */
const dispatchLocalStorageEvent = event => window.dispatchEvent(new StorageEvent('storage', event))

/**
 * Sets {@param newValue} as the value of the local storage entry identified by {@param key}
 * @param {string} key      the key of the entry to add or update
 * @param {string} newValue the value to set for the entry
 */
const set = (key, newValue) => {
  const oldValue = localStorage.getItem(key)
  localStorage.setItem(key, newValue)
  dispatchLocalStorageEvent({ key, oldValue, newValue })
}

/**
 * Removes the local storage entry identified by {@param key}.
 * @param {string} key  the key of the entry to remove
 * @return {string}     the value of the removed item
 */
const remove = (key) => {
  const newValue = null
  const oldValue = localStorage.getItem(key)
  localStorage.removeItem(key)
  // only dispatch event if there was a value to remove
  oldValue !== null && dispatchLocalStorageEvent({ key, oldValue, newValue })
  return oldValue
}

/**
 * Storage API
 *
 * @memberOf Gaia.Adapter.Storage#
 * @typedef {Object} API
 * @property {function} get
 * @property {function} set
 * @property {function} remove
 * @property {function} clear
 * @property {function} listen
 *
 * @return {Gaia.Adapter.Storage.API} api - storage api
 */
const api = () => Object.create({}, {
  get: {
    value: key => localStorage.getItem(key),
    iterable: true,
    enumerable: true,
  },
  set: {
    value: set,
    iterable: true,
    enumerable: true,
  },
  remove: {
    value: remove,
    iterable: true,
    enumerable: true,
  },
  clear: {
    value: () => repeat(localStorage.length, (index) => {
      remove(localStorage.key(index))
    }),
    iterable: true,
    enumerable: true,
  },
  listen: {
    value: handler => window.addEventListener('storage', handler),
    iterable: true,
    enumerable: true,
  },
})

export default api
