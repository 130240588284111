/* eslint-disable import/no-dynamic-require, global-require */
/**
 * Validator Require Loader.
 *
 * Loads library validator.
 *
 * Uses webpack v4 chunk mechanism
 *
 * @memberOf Gaia.requireLoaders
 * @typedef {Function} requireValidatorLoader
 * @param {string} type - validator type
 * @return {Function} validator composition
 */
export default type => require(`lib/validator/${type}`).default
