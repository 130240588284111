/* global G */
import { curry, pipe } from 'lib/util'
import withDependencyCheck from 'lib/trait/with/DependencyCheck'

const trait = 'withGetterSetter'
const dependencies = [G.CONFIGURATION]


/**
 * Library Trait With Getter Setter.
 *
 * Adds object property via provided symbol identifier.
 * The object property is set with getter and setter attributes.
 *
 * @example
 *  import { pipe } from 'lib/util'
 *  import moduleComposition from 'lib/trait/composition/module'
 *  import withGetterSetter from 'lib/trait/with/GetterSetter'
 *  ...
 *  const key = Symbol('optional description') // key can also be an alphanumeric string
 *  ...
 *  return pipe(
 *    moduleComposition,
 *    withGetterSetter(key)
 *  )
 *
 *
 * @param {symbol | string | number} symbol - property identifier
 * @param {Module.Spec | AppModule.Spec } obj - object composition
 * @return {Module.Spec | AppModule.Spec } obj - mutated object composition
 */
const withGetterSetter = (symbol, obj) => {
  let getSet
  Object.defineProperty(obj, symbol, {
    get() {
      // todo: refactor config provider to use symbols, then remove .description
      //  ... config[key] -> config[G[key.toUpperCase()]] if config stays the same
      //  ... config[KEY] -> config[G[KEY]] if we refactor config namespaces to capital letters
      return (getSet && getSet)
        || (obj[G.CONFIGURATION] && obj[G.CONFIGURATION][symbol.description])
        || null
    },
    set(value) {
      getSet = value
    },
    iterable: true,
  })
  return obj
}

export const withGetterSetterFn = curry(withGetterSetter)

export default symbol => pipe(
  withDependencyCheck(trait, dependencies),
  withGetterSetterFn(symbol),
)
