import sequenceComponentState from 'lib/sequence/component/state/index'

const {
  get: hidden,
  set: hide,
  unset: show,
  toggle,
} = sequenceComponentState('hidden')

const sequenceComponentStateHidden = {
  hidden,
  hide,
  show,
  toggle,
}

export {
  sequenceComponentStateHidden as default,
  hidden,
  hide,
  show,
  toggle,
}
