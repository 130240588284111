/* global G */
import { setKey } from '@gaia/util'

/**
 * Sets remote assets to adapter's data.
 * It will also sort all the messages
 * by date (oldest message first).
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => ({ detail }) => {
  const { [G.DATA]: items } = detail
  const { [G.EVENTS]: eventBus, [G.MESSAGE]: adapter } = app[G.ADAPTER]
  setKey(items.reverse(), G.DATA, adapter)
  eventBus.dispatch(
    eventBus.type(G.MESSAGE, G.DONE),
    { [G.DATA]: adapter[G.DATA] },
  )
}

export default fn
