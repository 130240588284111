/* global G */
import { Manager } from 'socket.io-client'
import { API_URL } from 'lib/util/environment'
import api from './api'

// adds /:type/:parentId/count/:relation route and aggregate function

const getNamespace = ns => `/${ns}`
const ns = {
  ref: 'reference', // id_type
  doc: 'document', // id
}

/**
 * Global types the client should derive
 * and subscribe a room from.
 */
export const globalRoomTypes = [
  'message',
]

const transports = [
  'websocket',
]

let _doc
let _ref

/**
 * PubSub Manager
 *
 * Uses Steam.IO Client instance.
 *
 * TODO: Refactor PubSub Manager so that room's are tied to
 *       specific user roles / permissions (SP-957)
 *
 * @param obj
 * @returns {{ref: api, doc: api}}
 */
const pubSubManager = (obj) => {
  const manager = new Manager(API_URL, { transports, autoConnect: false })

  _doc = manager.socket(getNamespace(ns.doc))
  _ref = manager.socket(getNamespace(ns.ref))

  const channels = {
    /**
     * @type {api} socket.io API
     */
    doc: api(obj)(_doc),

    /**
     * @type {api} socket.io API
     */
    ref: api(obj)(_ref),
  }

  const eventBus = obj[G.EVENTS]

  const roomEventListener = type => (event) => {
    eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.CREATE, type), { [G.DATA]: event })
  }

  eventBus.add(eventBus.type(G.DATA, G.INIT), (event) => {
    channels.doc.connect()
    channels.ref.connect()

    globalRoomTypes.forEach((type) => {
      const room = `${event.detail[G.DATA].key}_${type}`
      channels.ref.sub(room, () => {
        channels.ref.on(room, roomEventListener(type))
      })
    })
  })

  eventBus.add(eventBus.type(G.DATA, G.DESTROY), (event) => {
    channels.doc.disconnect()
    channels.ref.disconnect()

    globalRoomTypes.forEach((type) => {
      const room = `${event.detail[G.DATA].key}_${type}`
      channels.ref.unsub(room, () => {
        channels.ref.off(room, roomEventListener(type))
      })
    })
  })

  return channels
}

export default pubSubManager
