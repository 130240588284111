/* global G */
import settings from '@tenant/settings'
import { pipe } from '@gaia/util'
import { hasNamespace } from '@gaia/trait/has'
import { withGetterSetterFn } from '@gaia/trait/with/GetterSetter'
import { usesGetterSetter, usesNamespace } from '@gaia/trait/uses'
import { withObjectFreeze, withDescriptor } from '@gaia/trait/with'
import listenConnectivity from './api/ping'
import api from './api'

const descriptor = 'adapterHttp'

/**
 * Initializes the (ping) connectivity listener.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @return {function(Object): Object}
 */
const withInitialization = obj => (args) => {
  !settings.suppressPing && listenConnectivity(obj)
  return args
}

/**
 * Http Adapter.
 *
 * Adapter used to call different API end points.
 *
 * It utilizes FETCH API, polyfill required in some scenarios.
 *
 * It will utilize STREAM API for progress bars
 * It might utilize FORMDATA API for input submissions
 *
 * @memberOf Gaia.Adapter#
 * @typedef Adapter.Http
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {object} [middleware]
 * @return {function(*=): *}
 */
const adapterFn = (obj, middleware) => pipe(
  withDescriptor(descriptor),
  withInitialization(obj),
  hasNamespace(G.API),
  usesNamespace(G.API, api(obj, middleware)),
  withGetterSetterFn(G.APP),
  usesGetterSetter(G.APP, () => obj),
  withObjectFreeze,
)({})

export default adapterFn
