/* eslint-disable implicit-arrow-linebreak */
/* global G */

const staticItem = {
  route: {
    module: 'static',
    action: 'text',
  },
}

/**
 * Reset App History Effect
 *
 * Empties the navigation stack if the current module and action are defined as a navigation or menu
 * routes.
 *
 * @param {Gaia.Web.Application} app
 * @return {function(...[*]): *[]}
 */
const resetAppHistory = app => (...args) => {
  const { navigation, menu } = app[G.CONFIGURATION].defaults
  const sessionState = app[G.SESSION][G.STATE]
  // obtaining currently set module and action
  const currentModule = sessionState[G.MODULE]?.[G.CONFIGURATION]?.module
  const currentAction = sessionState[G.ACTION]
  // checking whether current module and action are defined in navigation/menu elements
  const isNavigation = [...navigation, ...menu, staticItem].some(({ route }) =>
    route.module === currentModule && route.action === currentAction)
  // isNavigation && console.log('History reset')
  // clearing history stack if current module and action are defined in navigation/menu
  sessionState[G.PREV] = isNavigation ? [] : sessionState[G.PREV]

  return args
}

export default resetAppHistory
