/* eslint-disable object-curly-newline */
import PostalAddress from 'i18n-postal-address'

const format = {
  setAddress1: obj => (obj?.street ? `${obj.street}${obj?.number ? ` ${obj.number}` : ''}` : null),
  setAddress2: obj => obj?.additionalLine || null,
  setCountry: (obj, i18n) => (obj?.country
    ? i18n.t(
      `country.${obj.country}`,
      { ns: 'generic', _key: `country.${obj.country}`, fallbackValue: obj.country, defaultValue: obj.country },
    )
    : null),
  setCity: obj => obj?.city || null,
  setPostalCode: obj => obj?.zip || null,
  setState: obj => obj?.state || null,
}

const fn = i18n => app => (address, options = {}) => {
  const { inline = false } = options
  const formatter = new PostalAddress()

  formatter.setFormat({
    // capitalized ISO 3166-1 alpha-2 code, fallbacks to US
    country: address?.country || null,
    // english and french are for canadian provinces
    // business and personal would be relevant if company and
    // person names were part of the address output
    // type: '', // 'default' | 'business' | 'personal' | 'english' | 'french'
  })

  Object.keys(format).reduce((acc, key) => {
    formatter[key](format[key](address, i18n))
    return acc
  }, null)

  const formattedAddress = !inline
    ? formatter.toString()
    : formatter.toString().replaceAll('\n', ',')

  return formattedAddress
    .split(',')
    .filter(x => x.length)
    .reduce((acc, key) => (acc ? `${acc}, ${key}` : key), '')
}

export default fn
