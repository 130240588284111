/* global G */
import seqRead from '@gaia/sequence/model/api/read'
import { setKey } from 'lib/util'

const descriptor = Symbol('hook::model::readAttribute').toString()

/**
 * Model Read Attribute Hook.
 *
 * Executes {@link seqRead} for the given sub-model. If the sub-model has no {@code G.REF}
 * set, it will use the one from the parent model.
 *
 * @param {string} attribute  attribute to read
 * @return {function(...[*]): *[]}
 */
const modelReadAttribute = attribute => obj => async (...args) => {
  try {
    const parentModel = obj[G.MODEL]
    const targetModel = obj[G.MODEL][G.CHILDREN][attribute]

    if (parentModel[G.STATE][G.REF] && !targetModel[G.STATE][G.REF]) {
      setKey(parentModel[G.STATE][G.REF], G.REF, targetModel[G.STATE])
    }

    await seqRead(obj[G.MODEL][G.CHILDREN][attribute])(obj[G.STATE][G.ACTION][G.COMPONENT])
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }
  return args
}

export default modelReadAttribute
