import { curry } from 'lib/util'
import showInvalidTokenErrorDialog from './invite/invalid'
import showNotFoundTokenErrorDialog from './invite/notFound'

const dialogs = {
  401: showInvalidTokenErrorDialog,
  404: showNotFoundTokenErrorDialog,

}

const showDialog = (obj, code) => dialogs[code]?.(obj)

/**
 * Invite middleware handler.
 *
 * Is responsible for handling special error codes related to invitation
 *
 * @type {Gaia.Adapter.Http.Middleware}
 * @param {Gaia.Web.Application} obj      the Web platform Application
 * @param {function} next                 the next middleware function
 * @param {object} args                   the request's arguments
 * @return {object}                       the response object
 */
export default curry(async (obj, next, args) => {
  let result
  try {
    result = await next(args)
  } catch (e) {
    showDialog(obj, e.code)
    throw e
  }
  return result
})
