/* global G */
import { pipe } from '@gaia/util'
import { hasNamespace } from '@gaia/trait/has'
import { usesNamespace } from '@gaia/trait/uses'
import { withDescriptor } from '@gaia/trait/with'

import api from './api'

const descriptor = 'SessionManager'

const _requiredStatePlaceHolders = {
  [G.ONLINE]: true,
  [G.LANGUAGE]: null,
  [G.CONTEXT]: null,
  [G.USER]: null,
  [G.NOTIFICATION]: {},
  [G.PREV]: [], // todo: probably need to introduce different NS, refactor accordingly
}

/**
 * Session Manager.
 *
 * Manages current application state
 *
 * @param {Object} [obj] - optional object reference
 * @return {Object} composition - object composition
 */
const sessionManagerFn = (obj = {}) => pipe(
  withDescriptor(descriptor),
  hasNamespace(G.API),
  usesNamespace(G.API, api),
  hasNamespace(G.STATE),
  usesNamespace(G.STATE, _requiredStatePlaceHolders),
)(obj)

export default sessionManagerFn
