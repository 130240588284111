/* eslint-disable import/no-dynamic-require, global-require */

/**
 * Configuration Require Loader.
 *
 * Loads configuration objects provided by default
 * or flavor implementation
 *
 * Dynamic (inline) Require Implementation.
 *
 * Uses webpack v4 chunk mechanism
 *
 * @memberOf Gaia.requireLoaders
 * @typedef {Function} requireLoaders.requireConfigLoader
 * @param {function} resolver - configuration resolver
 * @param {string} path - configuration location
 * @returns {Gaia.AppModule | Gaia.Module | Gaia.Settings} configuration
 */
const oldLoader = resolver => (path) => {
  let configModule
  try {
    configModule = require(`configPlatform/${path}.json5`)
  } catch (platformPathError) {
    try {
      configModule = require(`configPlatform/${path}/index.json5`)
    } catch (platformIndexPathError) {
      try {
        configModule = require(`config/${path}.json5`)
      } catch (pathError) {
        try {
          configModule = require(`config/${path}/index.json5`)
        } catch (indexPathError) {
          throw ReferenceError(`${path} configuration was not found`)
        }
      }
    }
  }
  return resolver(configModule)
}

export default oldLoader(
  process.env.NODE_ENV === 'test'
    ? config => config
    : config => config.default,
)
