/* global G */
import ACLError from 'lib/manager/acl/error'

const descriptor = 'hook::acl'

const acl = obj => (...args) => {
  try {
    obj[G.ADAPTER][G.ACL].action(obj[G.STATE][G.ACTION][G.COMPONENT])
  } catch (e) {
    throw new ACLError(`${obj._name} ${descriptor} - ${e.message}`)
  }
  return args
}

export default acl
