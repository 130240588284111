/* global G */
import { curry } from 'lib/util'

const sequenceInitAclFn = (obj, args) => {
  obj[G.ADAPTER][G.EVENTS].dispatch(obj[G.ADAPTER][G.EVENTS].type(G.ACL, G.CACHE))
  return args
}

const sequenceInitAcl = curry(sequenceInitAclFn)

export default sequenceInitAcl
