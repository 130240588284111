/* global React */

/**
 * Hook Use Handle Change
 *
 * designed to be used in controlled components
 *
 * inspiration: https://dev.to/stanleyjovel/simplify-controlled-components-with-react-hooks-23nn
 *
 * @param initialValue
 * @return {[string, function(...args: any[]) => any]}
 */
const useHandleChange = (initialValue = '') => {
  const [value, setValue] = React.useState(initialValue)
  // Event handler utilizing useCallback, so that reference never changes
  const handleChange = React.useCallback(
    (event) => {
      setValue(event.target.value)
    },
    [setValue],
  )

  return [value, handleChange]
}

export default useHandleChange
