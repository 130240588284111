/* eslint-disable implicit-arrow-linebreak */
/* global G */
import { curry, pipe } from 'lib/util'
import cancelOnError from 'lib/sequence/model/cancelOnError'
import { withDependencyCheck } from 'lib/trait/with'
// import { sequenceModelCreateFn } from 'lib/sequence/model/api/create'
import sequenceModelCreate from 'lib/sequence/model/api/create'

const descriptor = 'sequence::model::update'

/**
 * Preliminary Check for sequence required attributes.
 *
 * @param obj - model object composition
 * @param component - component object composition
 * @return {*}
 */
const checkDeps = (obj) => {
  withDependencyCheck(descriptor, [G.CACHE, G.STATE], obj)
  withDependencyCheck(descriptor, [G.REF], obj[G.STATE])
  return obj
}

/**
 * Returns a new object formed by putting together id and _rev from {@param cache} and the
 * content from {@param state} data.
 * @param cache
 * @param state
 * @return {*&{_rev, _id}}
 */
export const getUpdatedPayload = (cache, state) => {
  const { _rev, key } = cache || {}
  return {
    key,
    _rev,
    ...state[G.DATA],
  }
}

/**
 * Sync Model Update Function
 *
 * we expect G.CACHE, G.DATA, G.STATE to exist in model composition
 *
 * @param {Gaia.Model.Spec} obj - data model
 * @return {Gaia.Model.Spec}
 * @deprecated {@link sequenceModelCreate} already does that.
 */
const sequenceModelUpdateFn = (obj) => {
  const objCache = obj[G.CACHE]
  const objState = obj[G.STATE]
  // const { key: _id, _rev } = objCache
  objState[G.DATA] = getUpdatedPayload(objCache, objState)
  // console.log('objState[G.DATA]', objState[G.DATA])
  return obj
}

export default pipe(
  cancelOnError(descriptor),
  checkDeps,
  sequenceModelCreate,
  sequenceModelUpdateFn,
)
