
const canRestore = async (obj) => {
  console.log('sm:api:canRestore():tbi', obj)
  return false
}

const createFn = async (obj) => {
  console.log('sm:api:create():tbi', obj)
  return false
}

const readFn = async (obj) => {
  console.log('sm:api:read():tbi', obj)
  return false
}

const updateFn = async (obj) => {
  console.log('sm:api:update():tbi', obj)
  console.log('todo: setCurrentModule, setCurrentAction, setCurrentParameters')
  return true
}

const deleteFn = async (obj) => {
  console.log('sm:api:delete():tbi', obj)
  return false
}


export default {
  create: createFn,
  read: readFn,
  update: updateFn,
  delete: deleteFn,
}
