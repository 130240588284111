import render from './render'
import update from './update'
import create from './create'
import provide from './provide'
import portal from './portal'

/**
 * Native Adaptation of Library UI API.
 *
 * A Collection of API Methods.
 *
 * render
 * update
 * [error]
 * create
 * title
 *
 * _cloneElement
 * _createElement
 * _map/_reduce
 *
 * We need to reference our current module, so we can get the view tree structure.
 *
 */
const api = rootRender => app => Object.create({}, {
  portal: {
    value: portal(app),
    iterable: true,
    enumerable: true,
  },
  provide: {
    value: provide(rootRender, app),
    iterable: true,
    enumerable: true,
  },
  render: {
    value: render(rootRender, app),
    iterable: true,
    enumerable: true,
  },
  update: {
    value: update(rootRender, app),
    iterable: true,
    enumerable: true,
  },
  create: {
    value: create(rootRender, app),
    iterable: true,
    enumerable: true,
  },
  title: {
    value: (title = '') => {
      document.title = title
    },
    iterable: true,
    enumerable: true,
  },
  hasFocus: {
    value: () => document.hasFocus(),
    iterable: true,
    enumerable: true,
  },
})

export default api
