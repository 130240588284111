/* eslint-disable no-param-reassign */
/* global G */
import sequenceModelBulk from 'lib/sequence/model/api/bulk'

const descriptor = 'hook::model::bulk'

/**
 * Model Submit Hook.
 *
 * Executes model data remote submission, based on model cache and reference.
 *
 * @param {Gaia.Component.Spec} obj - app module composition
 * @return {function(...[*]): Promise<*[]>}
 */
const modelBulk = obj => async (options = {}, ...args) => {
  const model = obj[G.MODEL]

  try {
    await sequenceModelBulk(model, options)
  } catch (e) {
    throw Error(`${model._name} ${descriptor} - ${e.message}`)
  }

  return args
}

export default modelBulk
