/* eslint-disable arrow-body-style,no-unused-expressions */
const DATABASE_VERSION = 1
const READ_MODE = true
const WRITE_MODE = false

/**
 * Describes a store by specifying the names of attributes (i.e. keys) that form its key, and the
 * names and attributes used to create its indexes.
 *
 * @typedef {Readonly<Object>} StoreDescriptor
 * @property {string[]} keyPath   the names of the attributes to be mapped to SQL
 * @property {string[]} [indexes] the query to perform to add indexes to the store
 */

const stores = Object.freeze({
  requests: {
    keyPath: ['url', 'method', 'hash'],
    indexes: [['date', 'date']],
  },
  files: {
    keyPath: 'url',
    indexes: [['date', 'date']],
  },
})

let currentDatabase

/**
 * Converts a {@param method} of {@link IDBObjectStore} by wrapping its resulting {@link IDBRequest}
 * with a promise.
 *
 * @param {function(any)} method
 * @return {function(...[*]): Promise<unknown>}
 */
const promisify = method => (...params) => new Promise((resolve, reject) => {
  const request = method(...params)
  request.onsuccess = () => resolve(request.result)
  request.oncomplete = () => resolve(request.result)
  request.onerror = reject
})

/**
 * Represents a specific kind of object that can be persisted in a {@link Store}.
 *
 * @typedef {Object} Entity
 * @typedef {number|string|any[]} EntityKey
 */

/**
 * Offers methods to interact with a specific store (table), used to persist a specific kind of
 * item. Intended to abstract an underlying persistence system.
 *
 * @interface
 * @typedef {Object<Entity>} Store
 * @property {function(EntityKey): Promise<Entity>} get
 * @property {function(Entity): Promise<any>} put
 * @property {function(EntityKey): Promise<any>} remove
 */

/**
 * Wraps {@param objectStore} with an object that complies with the {@link Store} interface.
 *
 * @param {IDBObjectStore} objectStore
 * @returns {Store}
 */
const adapt = async objectStore => ({
  get: promisify(objectStore.get.bind(objectStore)),
  put: promisify(objectStore.put.bind(objectStore)),
  remove: promisify(objectStore.delete.bind(objectStore)),
})

/**
 * Checks whether {@param storeName} is defined in {@link stores}, otherwise it throws an error.
 *
 * @param {string} storeName  the name of the store to check
 */
const checkStoreName = (storeName) => {
  !stores[storeName] && throw new Error(`No configuration defined for stores '${storeName}'`)
}

/**
 * Checks whether there is a {@link currentDatabase} set, otherwise it throws an error.
 */
const checkCurrentDatabase = () => {
  !currentDatabase && throw new Error('No database set to be used.')
}

/**
 * Sets the database with name {@param databaseName} as the one to be used for the following store
 * management calls.
 *
 * @param {string} databaseName the name of the database to use
 */
const use = (databaseName) => {
  currentDatabase = databaseName
}

/**
 * Connects to the database with name {@link currentDatabase} and calls {@param transactionCallback}
 * with an {@link IDBObjectStore} of the objectStore with name {@param storeName}.
 *
 * @param {string} storeName        target object store
 * @param {boolean} [readMode]      whether to perform a readonly transaction (to better handle
 *                                  concurrent accesses)
 * @returns {Promise<Store>} the manager object
 */
const connect = (storeName, readMode) => new Promise((resolve, reject) => {
  checkCurrentDatabase()
  checkStoreName(storeName)
  const mode = readMode ? 'readonly' : 'readwrite'
  const openRequest = indexedDB.open(currentDatabase, DATABASE_VERSION)
  openRequest.onerror = reject
  openRequest.onsuccess = () => {
    const database = openRequest.result
    const transaction = database.transaction([storeName], mode)
    const objectStore = transaction.objectStore(storeName)
    resolve(adapt(objectStore))
    database.close()
  }
})

/**
 * Initializes an {@link indexedDB} database with name {@param databaseName} and objectStores with
 * names {@param storeNames}.
 *
 * @param {string} databaseName
 * @param {string[]} storeNames
 * @returns {Promise<void>}
 */
const init = async (databaseName, storeNames) => new Promise((resolve, reject) => {
  use(databaseName)
  checkCurrentDatabase()
  storeNames.forEach(checkStoreName)
  const openRequest = indexedDB.open(currentDatabase, DATABASE_VERSION)
  openRequest.onerror = reject
  openRequest.onupgradeneeded = (event) => {
    const database = event.target.result

    storeNames.forEach((storeName) => {
      const { keyPath, indexes } = stores[storeName]

      const createRequest = database.createObjectStore(storeName, { keyPath })
      createRequest.transaction.onerror = reject

      indexes?.forEach(([name, key]) => {
        const indexRequest = createRequest.createIndex(name, key)
        indexRequest.onerror = reject
      })
    })
    database.close()
  }
})

/**
 * Attempts to obtain a persisted {@link RequestRecord} from the current database.
 *
 * @param {number|string|array} key the identifier of the {@link RequestRecord} to obtain
 * @param {string} storeName        name of the store where the item is to be obtained from
 * @returns {Promise<any>}           the data contents of the obtained {@link RequestRecord}
 */
const get = async (key, storeName) => {
  const store = await connect(storeName, READ_MODE)
  return await store.get(key)
}

/**
 * Attempts to persist {@param item} in the current database, overwriting any records identified by
 * the same key in the process.
 *
 * @param {Object} item       the item to persist
 * @param {string} storeName  name of the store where the object is to be persisted
 * @returns {Promise<any>}     the identifier of the new persisted item
 */
const put = async (item, storeName) => {
  const store = await connect(storeName, WRITE_MODE)
  return await store.put(item)
}

/**
 * Attempts to delete a persisted {@link RequestRecord} by its key.
 *
 * @param {number|string|array} key the identifier of the {@link RequestRecord} to delete
 * @param {string} storeName        name of the store where the object is to be deleted from
 * @returns {Promise<any>}
 */
const remove = async (key, storeName) => {
  const store = await connect(storeName, WRITE_MODE)
  return await store.remove(key)
}

/**
 * @type {DataService}
 */
const service = {
  init,
  use,
  get,
  put,
  remove,
}

export default service
