/* eslint-disable max-len */
/* global G */
import { curry, isArr, pipe } from 'lib/util'
import { usesNamespace, usesGetterSetter } from 'trait/uses'

const {
  HTTP, API, ADAPTER,
} = G

/**
 * Model Namespace
 *
 * Descriptor for Gaia's Model Composition
 *
 * The composition is used to provide consistent api for various data models
 *
 * @memberOf Gaia
 * @namespace Model
 */

/**
 * @typedef NativeAdapter
 *
 * Native platform adapter collection, see app[G.ADAPTER] {@link Gaia.Application.G.ADAPTER}
 */

/**
 * Gaia Model Specification
 *
 * @memberOf Gaia.Model
 * @typedef {Object} Gaia.Model.Spec
 * @property {String} _name - model descriptor, if sub-model, options.key is used instead. see {@link withDescriptor}
 * @property {object} [G.CONFIGURATION] - model configuration {@link Gaia.Model.Configuration}
 * @property {Object[]} [G.CHILDREN] - model data structure
 * @property {Object} [G.PROPS] - model metadata, provide meta information
 * @property {Object} [G.STATE] - model state, contains runtime data used in sequences
 * @property {Object} [G.CACHE] - model cache, used for persisting incoming data payload from remote api, or filled by sub models
 * @property {Object} [G.DATA] - model data, contains valid model data, based on ui components, associated with model attributes
 * @property {Object} [G.ADAPTER] - model adapter collection, propagated via factory.model()
 * @property {Object} [G.VALIDATOR] - data validation methods, provisioned via model composition
 * @property {Object} [G.DECORATOR] - data decoration methods, used when data is being passed to related ui component (by options.key)
 * @property {Object} [G.TRANSFORMER] - data transformation methods, used for providing a valid payload
 */

/**
 * Gaia Model Configuration
 *
 * @memberOf Gaia.Model
 * @typedef {Object} Gaia.Model.Configuration
 * @property {Object} [validator] - data validation methods, not used at the moment, due to provider implementation restrictions
 * @property {String} model - model path, used for providing configuration and compositions
 * @property {Object} children - model data structure, mirrors end point payload
 * @property {Object} options - model metadata, provide meta information
 */

/**
 * Library Sync Factory Model.
 *
 * Model consists of proxying api,
 * which in turn, uses the db provider,
 * in order to achieve results
 * with the provided data set
 *
 * @param {NativeAdapter} NativeAdapter
 * @param {Gaia.requireLoaders} loaders - {@link Gaia.requireLoaders}
 // * @param {string} path - model path
 * @param {Gaia.Model.Spec} configuration - parsed model configuration
 * @return {Model.Spec} model - object with bound dbProvider
 */
// export const modelFactoryFn = (NativeAdapter, loaders, path) => {
//   const Model = loaders.model(path)
export const modelFactoryFn = (NativeAdapter, loaders, configuration) => {
  const Model = loaders.model(configuration.model)
  // configuration.children precedes pshData, as it is newer implementation
  const children = configuration.children || configuration.pshData

  if (!isArr(children)) {
    console.error('REFACTOR PSHDATA, MAN')
  }
  return pipe(
    Model,
    usesNamespace(ADAPTER, {
      [HTTP]: NativeAdapter[HTTP][API],
      [G.ACL]: NativeAdapter[G.ACL][G.API],
      [G.INTL]: NativeAdapter[G.INTL],
    }),
    (obj) => {
      try {
        return usesGetterSetter(G.ACL, obj[G.ADAPTER][G.ACL].get(obj[G.PROPS].api), obj)
      } catch (e) {
        // console.error(`model factory ${e.name} api ${obj[G.PROPS].api}`)
      }
      return obj
    },
  )(configuration)
}

/**
 * Library Sync Factory Model (curried).
 *
 * Model consists of proxying api,
 * which in turn, uses the db provider,
 * in order to achieve results
 * with the provided data set
 *
 * @param {PouchDB | NativeAdapter} NativeAdapter
 * @param {Gaia.requireLoaders} loaders - {@link Gaia.requireLoaders}
 * @param {string} path - model path
 * @return {Model.Spec} model - object with bound dbProvider
 */
export default curry(modelFactoryFn)
