/* global G */
import ui from './ui'
import missing from './missing'
import validator from './validator'
import address from './address'

/**
 *
 * @param {i18n} i18n
 * @returns {function(*): any}
 */
const api = i18n => app => Object.create({}, {
  get: {
    /**
     *
     * @returns {i18n}
     */
    value: () => i18n,
    iterable: true,
    enumerable: true,
  },
  set: {
    value: i18n.changeLanguage.bind(i18n),
    iterable: true,
    enumerable: true,
  },
  ui: {
    value: ui(i18n)(app),
    iterable: true,
    enumerable: true,
  },
  missing: {
    value: missing(i18n)(app),
    iterable: true,
    enumerable: true,
  },
  validator: {
    value: validator(i18n)(app),
    iterable: true,
    enumerable: true,
  },
  _t: {
    value: i18n.t.bind(i18n),
    iterable: true,
    enumerable: true,
  },
  translate: {
    value: (...args) => app[G.ADAPTER][G.INTL].markdown?.(...args) || i18n.t.bind(i18n)(...args),
    iterable: true,
    enumerable: true,
  },
  add: {
    value: i18n.loadNamespaces.bind(i18n),
    iterable: true,
    enumerable: true,
  },
  addTranslations: {
    value: i18n.addResourceBundle.bind(i18n),
    iterable: true,
    enumerable: true,
  },
  ns: {
    value: i18n.setDefaultNamespace.bind(i18n),
    iterable: true,
    enumerable: true,
  },
  reset: {
    // still not sure if required
  },
  address: {
    value: address(i18n)(app),
    iterable: true,
    enumerable: true,
  },
})

export default api
