/* global G */
import { asyncPipeSpread } from 'lib/util'

/**
 * Execute Module ACTION Hooks BEFORE
 *
 * The hooks should be located inside your module folder, as they are specific for module's action
 *
 * @param obj - action object
 * @return {function(...[*]): Promise<*|*[]>}
 */
const actionHooksBefore = obj => async (...args) => (
  obj[G.HOOKS] && obj[G.HOOKS].before
    ? await asyncPipeSpread(...obj[G.HOOKS].before)(...args)
    : args
)


export default actionHooksBefore
