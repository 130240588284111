/* eslint-disable quote-props */
/* global G */
import { pipe, curry } from 'lib/util'
import { withDependencyCheck } from 'lib/trait/with'

const descriptor = 'sequence::model::map'

const checkDeps = (obj, component) => {
  withDependencyCheck(descriptor, [G.CHILDREN], obj)
  withDependencyCheck(descriptor, [G.CHILDREN], component)
  return component
}

const seqDecorateValue = (obj, component) => {
  const { key } = component[G.PROPS]
  // const keys = Object.keys(obj[G.DECORATOR])
  // console.log('seqDecorateValue',
  //   // obj[G.DECORATOR],
  //   key,
  //   Object.keys(obj[G.CHILDREN]),
  //   Object.keys(obj[G.DECORATOR]),
  //   obj._name,
  // )
  const decorator = obj[G.DECORATOR][key]
  decorator && decorator(obj, component, component[G.STATE].value)

  // console.log(`Decorated: ${key}`)
  // let result

  return component
}


/**
 * Maps Data to UI Component
 *
 * populates component state with data from model cache
 *
 * todo: cache is set in read sequence, if the children cache is empty, nothing is mapped
 *
 * @param obj
 * @param component
 * @return {*}
 */
const dataToComponent = (obj, component) => {
  const debug = 'country'
  const attributeKeys = Object.keys(obj[G.CHILDREN])

  // console.groupCollapsed('Model mapping', obj._name)

  component[G.CHILDREN] && component[G.CHILDREN].reduce(
    (acc, ui) => {
      const { key } = ui[G.PROPS]
      // console.info(attributeKeys, key, obj[G.CHILDREN][key] && obj[G.CHILDREN][key][G.CHILDREN])
      if (attributeKeys.includes(key)) {
        // key === debug && console.clear()
        key === debug && console.log('-.......... found', key)

        key === debug && console.log('got decorator??', obj._name)

        obj[G.DECORATOR]
        && obj[G.DECORATOR][key]
        && seqDecorateValue(obj, ui) // || item[G.DATA]

        const item = obj[G.CHILDREN][key]
        const state = ui[G.STATE]
        state.value = item[G.CACHE] // || item[G.DATA]
        // state.value = item[G.CACHE] || item[G.DATA].value
        // todo i am not sure here, why sometimes data, why sometimes cache?
        // cache - filled via read, data filled via validate() | decorator()
        // console.log(`Mapped attribute: ${key}`, state.value)
        // !!obj[G.DECORATOR] && console.log(key, obj[G.DECORATOR][key])
        key === debug && console.log('value?', state.value)
        // recursion for sub models
        // eslint-disable-next-line no-use-before-define
        item[G.CHILDREN] && dataToComponent(item, ui)

        // console.log(`Mapped attribute: ${key}`, state.value)
      }
      acc[key] = ui
      return acc
    },
    {},
  )
  // console.groupEnd()

  return obj
}

// const sequenceModelMapToComponent = obj => pipe(
//   curry(checkDeps)(obj),
//   curry(dataToComponent)(obj),
//   // curry(seqDecorateValue)(obj),
// )

// export default sequenceModelMapToComponent
export default obj => pipe(
  curry(checkDeps)(obj),
  curry(dataToComponent)(obj),
)
