import helper from '@platform/adapter/http/helper'

/**
 * http GET
 *
 * executes requests by using GET method
 *
 * @param {string} url          partial request's url
 * @param {Object|array} params request body's content
 * @param {Object} headers      request headers
 * @param {AbortSignal} signal  request's abort signal
 * @return {Promise<{}>}
 */
const get = async ({ url, params, headers, signal }) => {
  const _url = helper._url(url)
  _url.search = new URLSearchParams(params).toString()

  const result = await fetch(_url, {
    method: 'GET',
    headers: helper._headers(headers),
    signal,
  })
  return await helper._data(result)
}

/**
 * http POST
 *
 * executes requests by using POST method
 *
 * @param {string} url          partial request's url
 * @param {Object|array} params request body's content
 * @param {Object} headers      request headers
 * @param {AbortSignal} signal  request's abort signal
 * @return {Promise<{}>}
 */
const post = async ({ url, params, headers, signal }) => {
  const _url = helper._url(url)
  const result = await fetch(_url, {
    method: 'POST',
    headers: helper._headers(headers),
    body: JSON.stringify(params),
    // body: new FormData(params), // switch to this, if using html form reference
    signal,
  })
  return await helper._data(result)
}

/**
 * http PUT
 *
 * executes requests by using PUT method
 *
 * Used for updating purposes.
 *
 * @param {string} url          partial request's url
 * @param {Object|array} params request body's content
 * @param {Object} headers      request headers
 * @param {AbortSignal} signal  request's abort signal
 * @return {Promise<{}>}
 */
const put = async ({ url, params, headers, signal }) => {
  const result = await fetch(helper._url(url), {
    method: 'PUT',
    headers: helper._headers(headers),
    body: JSON.stringify(params), // check if stringify is required
    // body: new FormData(params), // switch to this, if using html form reference
    signal,
  })
  return await helper._data(result)
}

/**
 * http PATCH
 *
 * executes requests by using PATCH method
 *
 * Used for updating a small set of attributes.
 *
 * @param {string} url          partial request's url
 * @param {Object|array} params request body's content
 * @param {Object} headers      request headers
 * @param {AbortSignal} signal  request's abort signal
 * @return {Promise<{}>}
 */
const patch = async ({ url, params, headers, signal }) => {
  const result = await fetch(helper._url(url), {
    method: 'PATCH',
    headers: helper._headers(headers),
    body: JSON.stringify(params), // check if stringify is required
    // body: new FormData(params), // switch to this, if using html form reference
    signal,
  })
  return await helper._data(result)
}

/**
 * http DELETE
 *
 * executes DELETE document request
 *
 * Used for deleting purposes.
 * todo: Current implementation accepts an array of doc IDs?
 *
 * we need to use an abbreviation of the word "delete", as it is a protected JS word
 *
 * @param {string} url          partial request's url
 * @param {Object|array} params request body's content
 * @param {Object} headers      request headers
 * @param {AbortSignal} signal  request's abort signal
 * @return {Promise<{}>}
 */
const del = async ({ url, params, headers, signal }) => {
  const result = await fetch(helper._url(url), {
    method: 'DELETE',
    headers: helper._headers(headers),
    body: JSON.stringify(params), // check if stringify is required
    // body: new FormData(params), // switch to this, if using html form reference
    signal,
  })
  return await helper._data(result)
}

export default {
  get,
  post,
  put,
  del,
  patch,
}
