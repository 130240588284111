/* global G */
// see GAIA-514, GAIA-407
import { curry } from '@gaia/util'

const sequenceSessionCookieLoginFn = async (obj, args) => {
  console.log('sequenceSessionCookieLoginFn', obj, args)
  const result = await obj[G.ADAPTER][G.HTTP][G.API].post({
    url: '/api/v1/public/login',
    params: {
      username: 'demo',
      password: 'demo',
    },
  })
  console.log('sequenceSessionCookieLoginFn', result)
  // todo, move seqSessionSetValue to here
  return result
}

const sequenceSessionCookieLogin = curry(sequenceSessionCookieLoginFn)

export default sequenceSessionCookieLogin
