/* global G */
import { setKey } from 'lib/util'
// import reset from './reset'

// FIXME: reset -> init -> reset circular dependency. Avoided by putting the two modules together.

const init = obj => () => {
  const { acl } = obj[G.SESSION][G.STATE][G.META] || {}
  acl && setKey(acl, G.CACHE, obj[G.ADAPTER][G.ACL])
  // eslint-disable-next-line no-use-before-define
  const eventHandler = acl ? reset : init
  const eventType = acl ? G.DELETE : G.CACHE
  const { add, type } = obj[G.EVENTS]
  add(type(G.ACL, eventType), eventHandler(obj), { once: true })
}

const reset = obj => () => {
  setKey(null, G.CACHE, obj[G.ADAPTER][G.ACL])
  const { add, type } = obj[G.EVENTS]
  add(type(G.ACL, G.CACHE), init(obj), { once: true })
}

export {
  init as default,
  reset,
}
