/* global G */

import { curry, getFirstItem } from 'lib/util'

/**
 * Initializes the settings adapter by dispatching {@code G.SETTINGS, G.INIT} if the user's
 * role has permissions to have user settings.
 *
 * @param {Gaia.Web.Application} obj the platform Application
 * @returns {function(*): *}
 */
const init = obj => async (args) => {
  const meta = obj?.[G.SESSION]?.[G.STATE]?.[G.META]
      || obj[G.ADAPTER]?.[G.SESSION]?.[G.STATE]?.[G.META]
  const eventBus = obj[G.ADAPTER][G.EVENTS]

  if (meta?.refs && meta?.acl?.userSettings?.includes(10)) { // user is logged in
    const userSettings = getFirstItem(meta?.refs?.settings)

    // Cart should be initialized if user has create permission on cart document
    const data = await new Promise((resolve) => {
      eventBus.add(eventBus.type(G.SETTINGS, G.DONE), () => {
        resolve(true)
      })

      eventBus.dispatch(
        eventBus.type(G.SETTINGS, G.INIT),
        { [G.DATA]: { [G.API]: { type: 'userSettings', version: 1 }, settings: userSettings } },
      )
    })

    if (!data) {
      console.warn('Could not initialize user settings')
    }
  }

  return args
}

export default curry(init)
