/* global React */
import { isFn } from 'lib/util'

const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

const useEventListener = (target, type, listener, ...options) => {
  useEnhancedEffect(
    () => {
      const targetIsRef = !!target.current
      const currentTarget = targetIsRef ? target.current : (isFn(target) && target()) || target
      currentTarget?.addEventListener?.(type, listener, ...options)
      return () => currentTarget?.removeEventListener?.(type, listener, ...options)
    },
    [target, type, listener, options],
  )
}

export default useEventListener
