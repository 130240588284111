/**
 * HttpError constructor.
 *
 * Extends the base Error class by adding a code property to it, allowing us to pass the actual HTTP
 * status codes through throw statements.
 *
 * @param {string} message  a short text informing about the error
 * @param {number} code     an identifier for the error
 * @param {any} [response]  response data
 */
function HttpError(message, code, response) {
  this.message = message
  this.code = code
  this.response = response
}

HttpError.prototype = Object.create(Error.prototype)
HttpError.prototype.constructor = HttpError
HttpError.prototype.name = 'HttpError'

export default HttpError
