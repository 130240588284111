/* global G */

/**
 * Opens a route or an url in a new browser tab.
 *
 * @param {Gaia.Web.Application} app - web application reference
 * @return {function(...[*])}
 */
const newTab = app => (routeOrUrl) => {
  if (routeOrUrl[G.MODULE]) {
    const route = routeOrUrl
    const context = app[G.SESSION][G.STATE][G.CONTEXT]
    const moduleName = route[G.MODULE]
    const actionName = route[G.ACTION]
    const ref = route[G.REF]
    window.open(`#${context}/${moduleName}/${actionName}/${ref}`, '_blank')
  } else {
    window.open(routeOrUrl, '_blank')
  }
}

export default newTab
