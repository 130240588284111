/* eslint-disable no-unused-vars */
/* global React */
import { useState } from 'react'
import { Grid, Link } from '@mui/material'

/**
 * Link HoC
 *
 * The {@code onOpen} event handler should return {@code null} in case
 * the link could not be resolved (e.g. there is no device to link to).
 *
 * Can also be used with {@code prefix} (like {@code mailto} or
 * {@code tel}) to use the browser API. In cas it is non-nil,
 * the {@code value} of the child component will be used.
 *
 * @example
 * {
 *  module: 'Card/Form',
 *  view: 'Component/Grid/SectionAsLink',
 *  options: {
 *    md: 12,
 *    xl: 12,
 *    events: {
 *      onOpen: 'linkTo',
 *    },
 *    target: '_blank',
 *  },
 *  route: {
 *    action: 'detail',
 *  },
 *  ...
 *  }
 * @param Component
 * @return {function(*, *)}
 * @constructor
 */
const LinkHOC = Component => ({ target, prefix, ...props }, ref) => {
  const [href] = useState(() => props.events?.onOpen(null))
  const { decorators = {} } = props

  !href && delete decorators?.link

  return <Grid
    item
    xs={12}
    target={target}
    /**
     * We use {@link DecoratedLink} as {@link Component}, if we want underline, just apply link
     * decorator. If we don't disable it here, {@link Link} will default to having an underline.
     */
    sx={{ textDecoration: 'none' }}
    component={href || prefix ? Link : 'div'}
    href={href || (prefix && `${prefix}:${props.value}`)}
    color={'text.primary'}
  >
    <Component
      {...props}
      ref={ref}
      /**
       * TODO: explicitly passing decorators makes this HOC only useful
       *       to wrap components that have a decorators prop. Passing
       *       {...props} should be enough.
       */
      decorators={decorators}
    />
  </Grid>
}

export default LinkHOC
