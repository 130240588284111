/* eslint-disable no-unused-vars */
import { Box, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'

/**
 * Default styles to apply to all cell component.
 *
 * Needs {@param theme}, even though it isn't used.
 *
 * @param theme
 * @returns Object
 */
export const cellStyles = theme => ({
  root: {
    overflow: 'hidden',
  },
  row: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
})

/**
 * Default styling used for list cells.
 *
 * @param {Function} additionalStyles additional styles for the template
 * @param {Object} additionalProps    additional props for the styling
 * @param {Function} defaultStyles      default styles to pass to the template
 * @return Object
 */
export const useTemplateStyles = (additionalStyles, additionalProps, defaultStyles = cellStyles) => {
  const theme = useTheme()
  const styles = { ...defaultStyles(theme), ...additionalStyles(theme, additionalProps) }
  return makeStyles(styles)()
}

/**
 * Cell Higher Order Component
 *
 * HOC that will facilitate the common structure and styling of a cell in a list view.
 * {@param renderFn} is supposed to be a function responsible for rendering the actual
 * content (formerly called {@code propsToContent}).
 *
 * @param {Function} renderFn function to render the content
 * @param {Function} additionalStyles additional styles for the cell component
 *
 * @return {*}
 * @constructor
 */
const CellHOC = (renderFn, additionalStyles = () => ({})) => (props) => {
  const classes = useTemplateStyles(additionalStyles, props)
  const content = renderFn(classes, props)

  return <Box className={classes.root}>{content}</Box>
}

export default CellHOC
