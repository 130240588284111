import preload from '@platform/react/hook/preload'
import mapClasses from '@platform/react/hook/mapClasses'
import useAcl from '@platform/react/hook/useAcl'
import useAclIterator from '@platform/react/hook/useAclIterator'
import useAttachmentAPI from '@platform/react/hook/useAttachmentAPI'
import useDebounce from '@platform/react/hook/useDebounce'
import useEventCallback from '@platform/react/hook/useEventCallback'
import useEventListener from '@platform/react/hook/useEventListener'
import useEventHandler from '@platform/react/hook/useEventHandler'
import useHandleChange from '@platform/react/hook/useHandleChange'
import useMemoRef from '@platform/react/hook/useMemoRef'
import useMessageAPI from '@platform/react/hook/useMessageAPI'
import useNetInfo from '@platform/react/hook/useNetInfo'
import useNoteAPI from '@platform/react/hook/useNoteAPI'
import usePagination from '@platform/react/hook/usePagination'
import usePromise from '@platform/react/hook/usePromise'
import useSockets from '@platform/react/hook/useSockets'
import useStyles from '@platform/react/hook/useStyles'

const hooksCollection = {
  mapClasses,
  preload,
  useAcl,
  useAclIterator,
  useAttachmentAPI,
  useDebounce,
  useEventCallback,
  useEventListener,
  useEventHandler,
  useHandleChange,
  useMemoRef,
  useMessageAPI,
  useNetInfo,
  useNoteAPI,
  usePagination,
  usePromise,
  useSockets,
  useStyles,
}

export {
  mapClasses,
  preload,
  useAcl,
  useAclIterator,
  useAttachmentAPI,
  useDebounce,
  useEventCallback,
  useEventListener,
  useEventHandler,
  useHandleChange,
  useMemoRef,
  useMessageAPI,
  useNetInfo,
  useNoteAPI,
  usePagination,
  usePromise,
  useSockets,
  useStyles,
  hooksCollection as default,
}
