/* global G */
import { curry } from 'lib/util'

const sequenceDisconnectPubSubFn = (obj, args) => {
  obj[G.ADAPTER][G.EVENTS].dispatch(obj[G.ADAPTER][G.EVENTS].type(G.DATA, G.DESTROY),
    {
      [G.DATA]: obj[G.ADAPTER]?.[G.SESSION]
        ? obj[G.ADAPTER][G.SESSION][G.STATE][G.META]?.refs?.user?.[0]
        : obj[G.SESSION][G.STATE][G.META]?.refs?.user?.[0],
    })
  return args
}

const sequenceDisconnectPubSub = curry(sequenceDisconnectPubSubFn)

export default sequenceDisconnectPubSub
