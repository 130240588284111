/* eslint-disable no-unused-vars */
/* global G */
import { confirmUpdateDialog } from 'platform/adapter/notification/helper/dialog'

/**
 * Sets all notifications as read.
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function({detail: *}): Promise<*|null>)|*}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.STATE]: groupState, [G.DATA]: groupData } = group
  const { [G.API]: { api, types, version } = {} } = groupState

  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const confirm = await confirmUpdateDialog(app)

  try {
    if (confirm) {
      const url = `/api/v${version}/${api}/${types.setReadAll}`
      const result = await httpAdapter[G.API].put({ url })

      result.forEach((item) => {
        if (item.ok) {
          const notification = groupData.data.find(x => x.id === item.id)
          notification && (notification.value.read = item.read)
        }

        groupData.update = {}
      })

      eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.CREATE, groupName), { [G.DATA]: { count: groupData.length } })
    }
  } catch (e) {
    console.error(e)
  }
}

export default fn
