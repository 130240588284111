/* eslint-disable import/no-dynamic-require, global-require */
/**
 * Model Require Loader.
 *
 * Loads library model composition.
 *
 * Uses webpack v4 chunk mechanism
 *
 * @memberOf Gaia.requireLoaders
 * @typedef {Function} requireModelLoader
 * @param {string} path - model path
 * @return {Function} model composition
 */
export default (path) => {
  let obj
  try {
    obj = require(`@model/${path}`)
  } catch (appError) {
    throw ReferenceError(`${path} model was not found`)
  }
  return obj.default
}
