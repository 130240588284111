/* global G */

/**
 * Initializes the user cart by dispatching {@code G.CART, G.CREATE} event.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => ({ detail = {} }) => {
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapter = app[G.ADAPTER][G.CART]

  const { [G.DATA]: { [G.API]: api } = {} } = detail

  adapter[G.STATE][G.API] = api

  // Dispatching G.CREATE to fetch the cart
  eventBus.dispatch(eventBus.type(G.CART, G.CREATE))
}

export default fn
