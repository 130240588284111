import { asyncPipeSpread } from 'lib/util'
import hooksBefore from './HooksBefore'
import hooksAfter from './HooksAfter'
import actionFn from './Fn'

/**
 * Execute Module ACTION full stack
 *
 * Hooks BEFORE
 * ACTION FN
 * Hooks AFTER
 *
 * @param obj - action object
 * @return {function(...[*])}
 */
const actionWithHooks = obj => async (...args) => await asyncPipeSpread(
  hooksBefore(obj),
  actionFn(obj),
  hooksAfter(obj),
)(...args)

export default actionWithHooks
