/* eslint-disable object-curly-newline */
/* global G */
import { active, get, strategy, resolve } from 'lib/manager/acl/helper'

/**
 * data acl resolver
 *
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(Gaia.Component, array): void}
 */
const fn = obj => (item, acls) => {
  // is acl active?
  if (!active(obj)) {
    return {}
  }
  const acl = !item[G.ACL] ? null : resolve(item)
  try {
    acl && get(obj)[G.API].check(
      get(obj)[G.API].get(acl[G.META]),
      Array.from(new Set([...acl[G.ACL], ...acls])),
    )
  } catch (e) {
    // console.warn(`${e.name} - ${item[G.PROPS].key} - ${e.message}`)
    return acl[G.PROPS]
  }
  return {}
}

export default fn
