/* global G */

/**
 * Initializes the cart by dispatching {@code G.CART, G.INIT} if the user's role has permissions
 * to manage carts
 *
 * @param {Gaia.Web.Application} obj the platform Application
 * @returns {function(*): *}
 */
const init = obj => async (args) => {
  const meta = obj?.[G.SESSION]?.[G.STATE]?.[G.META]
      || obj[G.ADAPTER]?.[G.SESSION]?.[G.STATE]?.[G.META]
  const eventBus = obj[G.ADAPTER][G.EVENTS]

  const cartAcl = meta?.acl?.cart || null

  // Cart should be initialized if user has create permission on cart document
  if (cartAcl && cartAcl.includes(10)) {
    const data = await new Promise((resolve) => {
      eventBus.add(eventBus.type(G.CART, G.DONE), ({ detail }) => {
        resolve(detail)
      })

      eventBus.dispatch(
        eventBus.type(G.CART, G.INIT),
        { [G.DATA]: { [G.API]: { type: 'cart', version: 1 } } },
      )
    })

    if (data) {
      return args
    }

    console.error(`Could not receive shopping cart. Got ${data}`)
  }

  return args
}

export default init
