import { useState, useRef, useLayoutEffect, useContext, Suspense, useEffect } from 'react'
import ApplicationContext from '@platform/react/context/application'
import { debounce } from 'lib/util'
import PlatformEvent from 'lib/util/event'
import { useMemoRef, preload } from '@platform/react/hook'

/**
 * High Order Component for displaying count or single entry data
 *
 * @param {JSX.Element} props.template  the child element to return
 * @param {Number} [props.debounced]    optional debounce duration in ms
 * @param {Object} props.cell           subscription information
 * @param {Object} props.events         event handlers
 * @param {Object} props                props to pass to the child
 * @param {React.Ref} ref               ref to the element
 * @returns {JSX.Element}
 * @constructor
 */
const CellHOC = (props, ref) => {
  const { template, debounced, cell, events } = props

  const { socket: { ref: socket } } = useContext(ApplicationContext)
  const { options, ref: key, handler } = cell
  const refType = Object.keys(options).pop()
  const event = new PlatformEvent(new CustomEvent('cell', { detail: { key, type: refType } }))
  const socketKey = useRef(`${key}_${refType}`).current

  /**
   * Use the {@param props} passed from the parent as the
   * initial value. E.g.:
   *
   * @example
   * {
   *     ...
   *     options: {
   *         ...
   *         keys: {
   *             ...
   *             foo: true, // this
   *             bar: true  // stuff
   *         }
   *     }
   * }
   */
  const [cellData, setCellData] = useState(props)
  const [handlerState, setHandlerState] = useState({})
  const [ChildComponent, setChildComponent] = useState(preload(template))

  useLayoutEffect(() => {
    // In case we have a {@code data} property inside {@code handlerState}, it means
    // we execute the {@code cell} event handler once before, in this case execute
    // the callback again with the new props, but with the already existing data
    // to not lose the transient state
    handlerState.data
      ? setCellData(() => handlerState.addData(props, handlerState.data))
      : setCellData(props)
  }, [props, handlerState.data])

  useEffect(() => {
    const { data = null, addData = null } = handlerState || {}

    // Every time the {@link handlerState.data} changes, we need to execute the callback
    // the handler defined and update our state
    data !== null
      && addData
      && setCellData(prevState => addData(prevState, data))
  }, [handlerState.data])

  useLayoutEffect(() => {
    setChildComponent(preload(template))
  }, [template])

  useLayoutEffect(() => {
    const listener = async () => {
      /**
       * The listener is expected to return an object
       * containing the data to be updated/added as well as a
       * callback instructing us how to do so!
       *
       * CAUTION: The HOC passes the state directly to its child
       * component, so if the child expects {@code { foo, bar }}
       * as props, make sure {@code addData} sets the state appropriately.
       *
       * If the returned {@code data} is null, the state is untouched.
       */
      const { data, addData } = await handler(event)

      // Persisting the data that came from the {@code cell} event handler so that
      // we can reuse it in case we rerender this component from the outside.
      setHandlerState(prevHandlerState => (prevHandlerState?.addData
        ? { ...prevHandlerState, data }
        : { data, addData }))
    }

    /**
     * There is {@link useDebounce}, but we can't use
     * it here, unfortunately.
     */
    const debouncedListener = debounced
      ? debounce(listener, debounced)
      : null

    socket.sub(socketKey, () => {
      socket.on(socketKey, debouncedListener ?? listener)
    })
    return () => {
      socket.unsub(socketKey, () => {
        socket.off(socketKey, debouncedListener ?? listener)
      })
    }
  }, [socketKey])

  return (
    <Suspense ref={ref} fallback={<></>}>
      <ChildComponent
        {...{ events, ...cellData }}
      />
    </Suspense>
  )
}

export default useMemoRef(CellHOC, props => [props])
