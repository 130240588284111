/* eslint-disable no-unused-vars */
/* global G */

/**
 * Returns the users current active cart. If the user doesn't have a cart, it dispatches
 * {@code G.CART, G.CREATE} event to create one. Dispatches {@code G.CART, G.DONE} event
 * afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail = {} }) => {
  const adapter = app[G.ADAPTER][G.CART]
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const activeCart = adapter[G.DATA]

  if (activeCart) {
    eventBus.dispatch(eventBus.type(G.CART, G.DONE), { [G.DATA]: activeCart })
  } else {
    eventBus.dispatch(eventBus.type(G.CART, G.CREATE))
  }
}

export default fn
