/* eslint-disable camelcase */
const noop = () => {}

/**
 * disabled api, if configuration is set to false
 *
 * @return {Readonly<{}>}
 */
const disabledApi = () => Object.freeze(Object.create({}, {
  set: {
    value: noop,
    iterable: true,
    enumerable: true,
  },
  add: {
    value: noop,
    iterable: true,
    enumerable: true,
  },
  ns: {
    value: noop,
    iterable: true,
    enumerable: true,
  },
  ui: {
    value: noop,
    iterable: true,
    enumerable: true,
  },
  address: {
    value: ({
      address_1 = '',
      // address_2,
      number = '',
      zip = '',
      city = '',
      // state,
      country = '',
      street = '',
    }) => `${address_1 || street} ${number}, ${zip} ${city}, ${country}`,
    iterable: true,
    enumerable: true,
  },
  _t: {
    value: (_, { defaultValue }) => defaultValue,
    iterable: true,
    enumerable: true,
  },
  validator: {
    value: (_, __, v) => v,
    iterable: true,
    enumerable: true,
  },
}))

export default disabledApi
