/**
 * Model Async Loader.
 *
 * Async Await Implementation of a dynamic import functionality.
 *
 * Uses webpack v4 chunk mechanism
 * Requires babel dynamic import plugin
 *
 * @memberOf Gaia.asyncLoaders
 * @typedef {Function} asyncModelLoader
 * @param {string} path - module path
 * @return {Promise<*>} lib module
 */
export default async path => await import(/* webpackChunkName: "model." */`@model/${path}`)
  .then(module => module.default);
