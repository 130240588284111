/* global G */
import {
  curry,
} from 'lib/util'

/**
 * Sequence Error Thrower
 *
 * throws sequence error in predefined format
 *
 * @param {string} descriptor - sequence descriptor
 * @param {Gaia.Model.Spec} obj - data model
 * @throws {Error} error
 */
const throwSeqError = (descriptor, obj) => throw Error(
  `${descriptor} - ${obj._name} contains an error: ${JSON.stringify(obj[G.STATE][G.ERROR])}`,
)

/**
 * Cancel Sequence on Model Error
 *
 * @param {string} descriptor - sequence descriptor
 * @param {Gaia.Model.Spec} obj
 * @return {*}
 */
const cancelOnError = (descriptor, obj) => (!obj[G.STATE][G.ERROR]
  ? obj
  : throwSeqError(descriptor, obj))

export default curry(cancelOnError)
