/* eslint-disable max-len */
import { strategy } from 'lib/manager/acl/helper'

/**
 * checks list against existing acls executes
 * strategy on access results
 *
 * @return {function(*=, *=): boolean}
 */
const check = () => (control = [], list = []) => {
  /**
   * Checks whether the first item (and therefore the whole)
   * in {@param list} is an inverse item.
   *
   * @type {boolean}
   */
  const isInversed = list?.length && typeof list[0] === 'string' && list[0].charAt(0) === '!'

  const access = (control && list) && isInversed
    ? list.every(item => control.indexOf(item.slice(1)) === -1)
    : control.filter(item => list.indexOf(item) !== -1)

  /**
   * Caution: Do NOT mix inverse and normal items, like
   * {@code aclContextRole: ["!Requester", "CSBase"]}.
   * This doesn't make sense.
   */
  return strategy(access && (isInversed || access.length > 0))
}

export default check
