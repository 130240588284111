/* global G */
import { get, resolve } from 'lib/manager/acl/helper'
import { _defaultAclProps } from 'lib/manager/acl/api/component'

/**
 * hide by role
 * @example see
 * - lib/manager/acl/api/component.js
 * - lib/manager/acl/api/link.js
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(*=): (*)}
 */
const fn = obj => (item) => {
  if (!item.aclContextRole) return {}

  const aclContextRole = resolve({
    [G.ACL]: { role: item.aclContextRole },
    [G.CONFIGURATION]: item.aclProps ? { aclProps: item.aclProps } : _defaultAclProps,
  })

  try {
    const { context } = obj[G.SESSION][G.STATE][G.META]
    const { roles } = context[obj[G.SESSION][G.STATE][G.CONTEXT]]
    get(obj)[G.API].check(
      roles,
      aclContextRole[G.ACL],
    )
  } catch (e) {
    return aclContextRole[G.PROPS]
  }
  return {}
}

export default fn
