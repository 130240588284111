/* global G */
import { pipe } from '@gaia/util'
import moduleComposition from '@gaia/trait/composition/module'
import withGetterSetter from '@gaia/trait/with/GetterSetter'
import hasNamespace from '@gaia/trait/has/Namespace'

const {
  CHILDREN, DATA, ADAPTER,
} = G


/**
 * Account Model Composition
 *
 * @param {string} descriptor - model descriptor
 * @return {function(*=): Gaia.Model} model - composed object
 */
export default descriptor => pipe(
  moduleComposition(descriptor),
  withGetterSetter(CHILDREN),
  hasNamespace(DATA),
  hasNamespace(ADAPTER),
  withGetterSetter(G.CACHE), // used for persisting end api call results
  withGetterSetter(G.TRANSFORMER), // used for transforming attribute values via model
)
