/* eslint-disable object-curly-newline */
/* global G */

/**
 * Removes all event handlers assigned to the {@param groupName}'s group and deletes its reference
 * from the adapter.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => () => {
  const adapter = app[G.ADAPTER][G.ATTACHMENT]
  const group = adapter[G.GROUP][groupName]
  group.destroy()
}

export default fn
