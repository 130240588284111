/* eslint-disable import/no-dynamic-require, global-require */
/**
 * Component Require Loader.
 *
 * Loads library component module composition.
 *
 * Uses webpack v4 chunk mechanism
 *
 * @memberOf Gaia.requireLoaders
 * @typedef {Function} requireModuleLoader
 * @param {string} path - component path
 * @return {Function} component module composition
 */
export default path => require(`lib/component/${path}`).default
