/* eslint-disable no-param-reassign */
/* global G */
import { _update } from '@platform/adapter/settings/helper/settings'

/**
 * Updates the remote document with the adapters local {@G.STATE} and {G.DATA}.
 *
 * Dispatches {@code G.SETTINGS, G.DONE} event afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {(function(): Promise<boolean>)|*}
 */
const fn = app => async () => {
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapter = app[G.ADAPTER][G.SETTINGS]
  const { status, ...data } = adapter[G.DATA]
  const { refs } = adapter[G.STATE]
  const state = adapter[G.STATE]

  const { [G.API]: api, _rev, key } = state

  try {
    // Updating the userSettings document
    const response = await _update(app, { api, _rev, key, data: { value: data, refs } })

    if (response.ok) {
      // Saving the new rev
      adapter[G.API].change({ detail: { [G.DATA]: { _rev: response.rev } } })

      eventBus.dispatch(eventBus.type(G.SETTINGS, G.DONE))

      return true
    }
  } catch (e) {
    console.error(e)
  }

  return false
}

export default fn
