/* eslint-disable import/no-dynamic-require, global-require */
/**
 * App Module (Controller) Require Loader.
 *
 * Loads business logic app module controller.
 *
 * Uses webpack v4 chunk mechanism
 *
 * @note using the word controller out of convention, even though those are function compositions
 *
 * @memberOf Gaia.requireLoaders
 * @typedef {Function} requireControllerLoader
 * @param {string} path - controller path
 * @returns {Function} controller module
 */
export default (path) => {
  let obj
  try {
    obj = require(`@app/${path}`)
  } catch (appError) {
    throw ReferenceError(`${path} module was not found`)
  }
  return obj.default
}
