/* global G */
import create from '@platform/adapter/attachment/api/group/create'

/**
 * Executes {@link create} for every file among the {@param groupName}'s group's (G.DATA) list of
 * local files.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => ({ detail }) => {
  const adapter = app[G.ADAPTER][G.ATTACHMENT]
  const group = adapter[G.GROUP][groupName]
  const files = group[G.DATA]
  files.forEach((file) => {
    create(app, groupName)({ detail: { [G.DATA]: file, [G.API]: detail[G.DATA][G.API] } })
  })
}

export default fn
