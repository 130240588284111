/* global G */
import { setKey } from 'lib/util'

const fn = i18n => app => (path, options, tokens, props) => {
  Object.keys(options).reduce((acc, key) => {
    // module.action.component.attribute
    let _key = !!acc[key] && (options[key].options?._key || `${path.join('.')}.${key}`)
    // with ns:1 -> NS:someKey
    _key = options[key].ns && !!acc[key] ? acc[key].split(':')[1] : _key

    const tOptions = {
      defaultValue: acc[key],
      _key,
      ...options[key].options,
      ...tokens,
      model: app[G.SESSION][G.STATE][G.MODULE][G.MODEL]
        && app[G.SESSION][G.STATE][G.MODULE][G.MODEL][G.CACHE],
      user: {}, // todo: this should be replaced with initial payload eventually
      context: app[G.SESSION][G.STATE][G.CONTEXT],
    }

    options[key].count && setKey(props.count, 'count', tOptions)
    options[key].count && setKey(true, '_count', tOptions)

    // revert to ns:1, set if ns:1 common ns
    options[key].ns && !!acc[key] && setKey(acc[key].split(':')[0], 'ns', tOptions)

    // Maybe the platform has a markdown implementation. If not, use the generic translator.
    const translator = app[G.ADAPTER][G.INTL].markdown || i18n.t
    !!acc[key] && setKey(translator(_key, tOptions), key, acc)

    return acc
  }, props || {})

  return props
}

export default fn
