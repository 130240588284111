/* global G */
import { pipe, curry } from 'lib/util'
import { hasNamespace } from 'trait/has'
import { withDescriptor, withGetterSetter, withObjectFreeze } from 'trait/with'
import { usesNamespace } from 'trait/uses'

const {
  DATA, STATE, VALIDATOR, DECORATOR, CONFIGURATION, TRANSFORMER,
} = G

/**
 * @memberOf Gaia
 * @typedef {Object} Gaia.ModelAttribute
 * @property {{key: String, [type]: String}} options
 * @property {[Object]} [children]
 * @property {String} [model] - BEWARE circular references!!!!
 */

/**
 * Model Attribute Object base structure.
 * Sets configuration symbol getter in an object,
 * which will be used as prototype in module composition
 *
 * @param {Gaia.ModelAttribute | Gaia.ModelAttribute} configuration - {@link Gaia.ModelAttribute}
 * @return {object} module object
 */
const moduleObject = configuration => ({
  get [CONFIGURATION]() {
    return { ...configuration }
  },
})

/**
 * Model Attribute Composition.
 *
 * Creates a new object to be used as root of composition.
 *
 * @param {string} descriptor
 * @param {Gaia.ModelAttribute || Gaia.ModelAttribute} configuration - {@link Gaia.ModelAttribute}
 * @return {module} module composition
 */
const moduleComposition = (descriptor, configuration) => pipe(
  withDescriptor(descriptor),
  hasNamespace(DATA),
  hasNamespace(G.PROPS),
  usesNamespace(G.PROPS, withObjectFreeze(configuration.options)),
  withGetterSetter(STATE),
  withGetterSetter(VALIDATOR),
  withGetterSetter(DECORATOR),
  withGetterSetter(TRANSFORMER),
)(moduleObject(configuration))


/**
 * ModelAttribute Composition
 *
 *
 * @param {string} descriptor
 * @return {function(*=): *} composition
 */
const composition = descriptor => pipe(
  curry(moduleComposition)(descriptor),
  withGetterSetter(G.CHILDREN),
)

export default composition
