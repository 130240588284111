/* global G */

import logoutHandler from '@platform/adapter/http/middleware/handler/logout'

/**
 * Unauthorized error dialog
 *
 * Displays an error dialog prompting the user to login again.
 * After clicking 'OK', the user is redirected to the guest module.
 *
 * To be displayed when a response with code 401 is received from the server (except on the login
 * screen).
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 */
export default (obj) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]
  const okHandler = logoutHandler(obj)

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.validation.invalidToken.title',
      {
        ns: 'common',
        _key: 'dialog.error.validation.invalidToken.title',
        defaultValue: 'Error',
      },
    ),
    text: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.validation.invalidToken.text',
      {
        ns: 'common',
        _key: 'dialog.error.validation.invalidToken.text',
        defaultValue: 'The invitation token provided is no longer valid. This could be the case if the invitation has already been completed, it expired or got revoked.',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: obj[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
    okHandler,
  })
}
