/* eslint-disable object-curly-newline */
/* global G */
import { isArr, isStr, isObj, def, isNum, isBool } from 'lib/util'

/**
 * Attempts to obtain the value of an attribute named {@param key} of {@param obj} by first
 * looking at its G.DATA values and then at its G.CACHE values, so that the values set through
 * UI forms (and stored in G.DATA) replace the ones obtained from the server (and stored in
 * G.CACHE)
 *
 * So that we allow fields to be cleared, We have to consider the following:
 * Empty TextFields "contain" an empty string
 * Empty Autocomplete fields "contain" an empty array
 * We should only look at the cache values if there was no field, which we can know by checking for
 * the existence of the corresponding property inside G.DATA, accepting also the two possibilities
 * above as valid.
 *
 * Whenever a matching property is found, it stops looking at other values also if the found one
 * is falsy, so that they can be set to empty.
 *
 * @param {Gaia.Model.Spec} obj - data model
 * @param {string} type - attribute type
 * @param {string} key - attribute key
 * @return {null|*}
 * @private
 */
const _findModelValue = (obj, type, key) => {
  const data = obj[G.DATA]
  const cache = obj[G.CACHE]

  if (def(data[key])) {
    return data[key]
  }

  if (def(data.value)) {
    if (def(data.value[key])) {
      return data.value[key]
    }

    const dataValue = data.value[0] || data.value

    if (dataValue && dataValue[type] && def(dataValue[type][key])) {
      return dataValue[type][key]
    }
  } else if (cache) { // we only check cache if there is no value inside G.DATA
    if (cache[type] && def(cache[type][key])) {
      return cache[type][key]
    }

    if (cache[0] && cache[0][type] && def(cache[0][type][key])) {
      return cache[0][type][key]
    }

    if (def(cache[key])) {
      return cache[key]
    }
  }
  return null
}

/**
 * Simple Transform
 *
 * if value is an object, strip everything but value
 *
 * @param {*} value - value to transform
 * @return {*} value - transformed value
 * @private
 */
const _transformSimple = value => (isStr(value) ? value : { value: value.value })

/**
 * Get Value from Model
 *
 * It extracts value from G.DATA or G.CACHE
 * Based on value type, it does simple transformation
 *
 * @param {Gaia.Model.Spec} obj - data model
 * @param {string} key - value key
 * @return {null} [value] - transformed value
 */
const sequenceGetValueFromModel = (obj, key) => {
  let value = null
  const child = obj[G.CHILDREN][key]

  try {
    const { type } = child[G.PROPS]
    // todo: this works great NOW, tyvm @skl!!!
    //   eventually a mergeDeep could be useful,
    //   especially if G.CACHE & G.DATA exist!
    // this looks a bit nicer but does not consider empty values:
    // const keyValue = obj[G.DATA][key]
    //   || (obj[G.DATA].value && obj[G.DATA].value[0][type][key]) // doesnt respect child config
    //   || (obj[G.CACHE] && obj[G.CACHE][type] && obj[G.CACHE][type][key])
    //   || (obj[G.CACHE] && obj[G.CACHE][0] && obj[G.CACHE][0][type] && obj[G.CACHE][0][type][key])
    //   || (obj[G.CACHE] && obj[G.CACHE][key])
    const keyValue = _findModelValue(obj, type, key)
    // todo: an issue arises by removing cached value via user input.
    //  make sure we respect empty values!!!
    // We allow empty strings
    if (keyValue !== null && isStr(keyValue)) {
      value = _transformSimple(keyValue)
    } else if (keyValue && (isObj(keyValue) || isArr(keyValue))) {
      // todo: prolly should move transform sequence into here
      value = keyValue
    } else if (keyValue && isNum(keyValue)) {
      value = keyValue
    } else if (isBool(keyValue)) {
      value = keyValue
    }
    // 1: no model, select - ok! ["selection"]
    // 3: no model, new - no refs, no key [{value, !key, !refs}]
    // 2: model, select - data model, no array
    // 3: model, new - data model, no array
    // 3: model, select, transform -
    // missing values, sub model, new key [{key, value, refs:{sub model}}]
    // 3: model, new, transform -
    // missing values, sub model, new key [{key, value, refs:{sub model}}]
  } catch (e) {
    console.warn(e.message)
  }

  return value
}

export default sequenceGetValueFromModel
