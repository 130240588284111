/* global G */
const okHandler = async () => {
  window.location.reload()
}

/**
 * Conflict error dialog
 *
 * Displays an error informing the user about a data conflict.
 *
 * To be displayed when a response with code 409 is received from the server.
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 */
export default (obj) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.conflict.title',
      {
        ns: 'common',
        _key: 'dialog.error.conflict.title',
        defaultValue: 'Error',
      },
    ),
    text: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.conflict.text',
      {
        ns: 'common',
        _key: 'dialog.error.conflict.text',
        defaultValue: 'Sorry, your data has been updated by another user. Please reopen the'
                      + ' object and repeat your input.',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: obj[G.ADAPTER][G.INTL]._t(
          'button.reloadData',
          {
            ns: 'common',
            _key: 'button.reloadData',
            defaultValue: 'Reload data',
          },
        ),
      },
      cancel: {
        key: 'cancel',
        value: obj[G.ADAPTER][G.INTL]._t(
          'button.cancel',
          {
            ns: 'common',
            _key: 'button.cancel',
            defaultValue: 'Cancel',
          },
        ),
      },
    },
    okHandler,
  })
}
