/* eslint-disable no-unused-vars */
/* global React */
import { Grid, Typography } from '@mui/material'

/**
 * Fallback NoData component
 *
 * @param {Object} props
 * @returns {JSX.Element[]}
 * @constructor
 */
const NoData = props => [
  <Grid
    item
    container
    component={'li'}
    key={'noData'}
    justifyContent={'center'}
    xs={props.xs}
    md={props.md}
  >
    <Typography
      variant={'subtitle1'}
      color={'textPrimary'}
      align={'center'}
    >
      {props.noDataLabel || 'No data found.'}
    </Typography>
  </Grid>,
]

/**
 * NoData HOC component.
 *
 * Will return {@code ui/Element/Placeholder/NoData} if defined in config,
 * otherwise it will return {@link NoData}.
 *
 * @param {React.ReactNode} Component child component
 * @returns {function(*, *): *}
 * @constructor
 */
const NoDataHOC = Component => (props, ref) => {
  const { fromSearch = false, unsegmented = false } = props

  const children = [...props.children || []]
  const index = children.findIndex(child => child.key === 'nodata')
  const [emptyPlaceholder = <NoData {...props} />] = index > -1 ? children.splice(index, 1) : []
  const childProps = { ...props, forwardedRef: ref, children }

  const emptyPlaceholderComponent = fromSearch
    ? React.cloneElement(emptyPlaceholder, {
      label: emptyPlaceholder.props.searchLabel,
      description: emptyPlaceholder.props.searchDescription,
      icon: emptyPlaceholder.props.searchIcon,
      action: false,
    })
    : emptyPlaceholder

  return (
    <Component
      {...childProps}
      unsegmented={fromSearch || unsegmented}
      noData={emptyPlaceholderComponent}
    />
  )
}

export default Component => React.forwardRef(NoDataHOC(Component))
