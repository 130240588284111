/* global G */
import { curry } from 'lib/util'

/**
 * Retrieves the current users' context and sets it application wide.
 *
 * @param {Gaia.AppModule.Spec} obj - module composition
 * @return {function(*=): *}
 */
const sequenceModuleAdapterSessionStateContextSetFn = obj => async (...args) => {
  const meta = obj[G.ADAPTER][G.SESSION][G.STATE][G.META]
  const { context } = meta
  const [userContext] = Object.keys(context)

  /**
   * Setting the context application wide
   */
  obj[G.ADAPTER][G.ROUTER].context(userContext)

  /**
   * Setting acl and roles according to current context
   */
  meta.acl = context[userContext].acl
  meta.roles = context[userContext].roles

  return args
}

const sequenceModuleAdapterSessionStateContextSet = curry(sequenceModuleAdapterSessionStateContextSetFn)

export default sequenceModuleAdapterSessionStateContextSet
