/* global G */
import seqReset from '@gaia/sequence/model/api/reset'

const descriptor = Symbol('hook::model::reset').toString()

/**
 * Model Reset Hook.
 *
 * Executes sequence reset, which in turn resets model instance.
 *
 * @example
 * ``` module/[module]/hooks.js
 * const hooks = {
 *  [G.ACTIONS]: {
 *    submit: {
 *      before: [
 *        validate,
 *      ],
 *    after: [
 *      ui, // display any validation errors
 *      submit,
 *      reset,
 *      redirect,
 *    ],
 *  }
 *  ...
 *  ```
 *
 * @param {Gaia.AppModule.Spec} obj - app module composition
 * @return {function(...[*]): *[]}
 */
const modelReset = obj => (...args) => {
  try {
    seqReset(obj[G.MODEL])
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }
  return args
}

export default modelReset
