/* eslint-disable object-curly-newline */
/* global G */
import { active, get, resolve } from 'lib/manager/acl/helper'

/**
 * it expects navigation structure
 * @example nav structure
 *  {
 *    options: { key: 'organisation', label: 'Customers', icon: 'group' },
 *    route: { module: 'organisation', action: 'index' },
 *    acl: { organisation: [20]}, // optional
 *    aclProps: { hidden: true }, // optional
 *    aclContextRole: ['CustomerServiceAgent', 'CSBase'], // optional
 *  },
 * @param {Gaia.Web.Application} obj - navigation structure
 * @return {function(*=): (*)}
 */
const fn = obj => (item) => {
  // is acl active?
  if (!active(obj)) {
    return {}
  }
  const acl = !item.acl ? null : resolve({
    [G.ACL]: item.acl,
    [G.CONFIGURATION]: {
      aclProps: item.aclProps || { disabled: true, onClick: null },
    },
  })
  try {
    acl && get(obj)[G.API].check(
      get(obj)[G.API].get(acl[G.META]),
      acl[G.ACL],
    )
  } catch (e) {
    // console.warn(`${e.name} - ${item.key} - ${e.message}`)
    return acl[G.PROPS]
  }
  return get(obj)[G.API].contextRole(item)
}

export default fn
