import { dataService, fileService } from '@platform/adapter/persistence/service'
import { hash, lowercase, now } from 'platform/adapter/persistence/helper'

const DATABASE_NAME = 'gaia'
const REQUESTS_STORE_NAME = 'requests'
const FILES_STORE_NAME = 'files'

/**
 * A key that uniquely identifies a {@link RequestRecord}.
 *
 * @memberOf Gaia.Adapter.Persistence
 * @typedef {EntityKey} RequestRecordKey
 * @property {string} url       the request's URL
 * @property {string} method    the request's method
 * @property {Object} [payload] the request's payload, if applicable
 */

/**
 * An entity intended to store/cache network requests together with their responses.
 *
 * A RequestRecord entity is identified by the fields in its {@link RequestRecordKey}
 *
 * @memberOf Gaia.Adapter.Persistence
 * @typedef {Entity} RequestRecord
 * @property {string} url       the request's URL
 * @property {string} method    the request's method
 * @property {Object} [payload] the request's payload, if applicable
 * @property {Object} data      the response data
 * @property {string} date      the time at which the entity was created
 */

/**
 * @typedef {Object} FileService
 * @property {function(Object, string): Promise<Blob>} get
 * @property {function(Object, string): Promise<void|boolean>} put
 * @property {function(Object, string): Promise<void|boolean>} remove
 */

/**
 * @typedef {Object} DataService
 * @property {function(string, string[]): Promise<void>} init
 * @property {function(string): void} use
 * @property {function(number|string|array, string): Promise<any>} get
 * @property {function(Object, string): Promise<Object>} put
 * @property {function(number|string|array, string): Promise<any>} remove
 */

/**
 * Persistence API
 *
 * Defines the methods to be exposed by the API object of the Persistence Adapter.
 *
 * @memberOf Gaia.Adapter.Persistence
 * @typedef {Object} PersistenceAPI
 * @property {function(RequestRecordKey, string): Promise<Object>} getRequest
 *    attempts to obtain and return single entry key from a specific store
 * @property {(RequestRecord) => Promise} putRequest
 *    attempts to insert or replace an entry in a concrete store
 * @property {(RequestRecordKey) => Promise} deleteRequest
 *    attempts to delete an entry from a concrete store
 * @property {function(string): Promise<Blob>} getFile
 *    attempts to obtain and return single file key from a specific store
 * @property {function({ url: string, name: string, contents: Blob }): Promise<void>} putFile
 *    attempts to insert or replace a file in a concrete store
 * @property {function(string): Promise<void>} deleteFile
 *    attempts to delete an entry from a concrete store
 */

/**
 * Initializes the persistence stores.
 *
 * @return {Promise<void>}
 */
const initialize = async () => {
  await dataService.init(DATABASE_NAME, [REQUESTS_STORE_NAME, FILES_STORE_NAME])
}

/**
 * Returns an implementation of the {@link PersistenceAPI}. Intended to abstract the use of the
 * underlying platform-dependent persistence mechanisms.
 *
 * {@see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API}
 * @returns {PersistenceAPI} api - storage api
 */
const api = () => Object.create({}, {
  getRequest: {
    /**
     * Attempts to obtain a persisted {@link RequestRecord} from the database.
     *
     * @param {RequestRecordKey} key the identifier of the {@link RequestRecord} to obtain
     * @return {Promise<any>}        the data contents of the obtained {@link RequestRecord}
     */
    value: async (key) => {
      const { url, method, payload } = key
      const params = [url, lowercase(method), hash(payload)]
      const item = await dataService.get(params, REQUESTS_STORE_NAME)
      return item.data
    },
    iterable: true,
    enumerable: true,
  },
  putRequest: {
    /**
     * Attempts to persist {@param item} in the database, overwriting any records identified by
     * the same key in the process.
     *
     * @param {RequestRecord} item  the item to persist
     * @return {Promise<any>}       the identifier of the new persisted item
     */
    value: async (item) => {
      const { url, payload, data } = item
      const method = lowercase(item.method)
      const params = { url, method, hash: hash(payload), payload, data, date: now() }
      return await dataService.put(params, REQUESTS_STORE_NAME)
    },
    iterable: true,
    enumerable: true,
  },
  deleteRequest: {
    /**
     * Attempts to delete a persisted {@link RequestRecord} by its key.
     *
     * @param {RequestRecordKey} key  the identifier of the {@link RequestRecord} to delete
     * @return {Promise<any>}
     */
    value: async (key) => {
      const { url, method, payload } = key
      const params = [url, lowercase(method), hash(payload)]
      return await dataService.remove(params, REQUESTS_STORE_NAME)
    },
    iterable: true,
    enumerable: true,
  },
  getFile: {
    /**
     * Attempts to obtain a file from {@link FILES_STORE_NAME} which was previously stored with
     * {@link putFile}.
     *
     * @param {string} url        the URL the file was obtained from, which is also its identifier
     * @return {Promise<Blob>}    the contents of the file
     */
    value: async (url) => {
      const item = await dataService.get(url, FILES_STORE_NAME)
      return await fileService.get(item, FILES_STORE_NAME)
    },
    iterable: true,
    enumerable: true,
  },
  putFile: {
    /**
     * Attempts to store a new file at {@link FILES_STORE_NAME}.
     *
     * @param {string} url        the URL the file was obtained from, which is also its identifier
     * @param {string} name       the name of the file
     * @param {Blob} blob         the contents of the file
     * @return {Promise<void>}    the identifier of the new persisted item
     */
    value: async ({ url, name, contents }) => {
      const item = { url, name, contents, date: now() }
      await dataService.put(item, FILES_STORE_NAME)
      try {
        await fileService.put(item, FILES_STORE_NAME)
      } catch (error) {
        await dataService.remove(url, FILES_STORE_NAME)
        throw error
      }
    },
    iterable: true,
    enumerable: true,
  },
  deleteFile: {
    /**
     * Attempts to delete an existing file from {@link FILES_STORE_NAME}.
     *
     * @param {string} url        the URL the file was obtained from, which is also its identifier
     * @return {Promise<void>}       the identifier of the new persisted item
     */
    value: async (url) => {
      const item = await dataService.get(url, FILES_STORE_NAME)
      await dataService.remove(url, FILES_STORE_NAME)
      return await fileService.remove(item, FILES_STORE_NAME)
    },
    iterable: true,
    enumerable: true,
  },
})

export {
  initialize,
  api as default,
}
