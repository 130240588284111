import seqModuleAction from '@gaia/sequence/app/module/action'

/**
 * Route to Action inside Module.
 *
 * Executes module action sequence {@link seqModuleAction}
 *
 *
 * @param {Gaia.Web.Application} obj - web application reference
 * @param {Gaia.AppModule.Action} action - module action
 * @param {*} args - arguments
 * @return {function(*, ...[*]): Promise<*>}
 */
const toAction = obj => async (action, ...args) => await seqModuleAction(obj)(action, ...args)

export default toAction
