/* global G */
import restoreCachedApp from 'platform/adapter/router/effect/restoreCachedApp'

/**
 * Navigate to the previous route taking the current action's dirty state into account.
 *
 * If action is dirty, it waits for a 'back' event in window and resolves with the confirm dialog's
 * confirmed value. Otherwise, it just resolves with true.
 *
 * @param {Gaia.Web.Application} app - web application reference
 * @param {*} args - arguments
 * @return {function(...[*])}
 */
const back = app => async () => await new Promise((resolve) => {
  // if the current action is dirty (has the G.UNDO flag set), we listen for the back event to
  // know whether the user confirms or cancels the confirmation dialog
  if (app[G.SESSION][G.STATE][G.MODULE][G.STATE][G.ACTION][G.STATE][G.UNDO]) {
    window.addEventListener('back', (event) => {
      resolve(event.detail.confirmed)
    }, { once: true })
  } else {
    resolve(true)
  }
  // check if _setAppCache() has been used earlier
  app[G.SESSION][G.STATE][G.CACHE]
    ? restoreCachedApp(app)()
    : window.history.back()
})

export default back
