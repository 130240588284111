/* global G */
/* eslint-disable no-use-before-define,no-param-reassign */
import { pipe, curry } from 'lib/util'
import provideEvents from '@gaia/provider/content/events'
import providePath from 'lib/provider/content/path'
import validatorComposition from 'trait/composition/validator'

const {
  CONFIGURATION,
  MODEL,
  PARENT,
  VIEW,
  CHILDREN,
  TEMPLATE,
  ACTIONS,
  COMPONENT,
  FN,
  UI,
  HOOKS,
  VALIDATOR,
} = G

let provideComposition

const _prop = symbol => symbol.description

const provideModelComposition = factory => obj => pipe(
  // executes one of the factories and returns obj
  obj.config ? factory.model : factory.modelAttribute,
  provideModelAttributes(factory),
  obj.config ? provideModelValidators : provideAttributeValidators(factory),
  // _provideChildrenValidators(factory),
  // provideDecorator(factory),
  // provideTransformer,
)(obj)

// const provideDecorator = factory => obj => {
//   console.log('deco', obj[G.DECORATOR])
//   return obj
// }

/**
 * Transformer Provider
 *
 * used on data model's children ONLY (todo: rethink transformer provisioning on model level)
 * atm the transformer is being provisioned on the model level,
 * since the model is object, and attribute's is array.. hmmm
 * @param obj
 * @return {*}
 */
// const provideTransformer = (obj) => {
//   // console.log('provideTransformer', obj)
//   // console.log('provideTransformer', obj[G.TRANSFORMER])
//   // console.log('provideTransformer', obj[G.CONFIGURATION])
//   return obj
// }
// (
//   obj[G.TRANSFORMER]
//   ? (obj[G.TRANSFORMER] = factory.transformer(obj[G.TRANSFORMER])) && obj
//   : obj
// ))

const provideModelAttributes = factory => (obj) => {
  // we convert the children array to map,
  // so we can adhere to existing structure model.children.childName
  obj[CHILDREN] && Object.assign(obj, {
    [CHILDREN]: obj[CHILDREN].reduce((acc, child) => {
      // BEWARE: having model as model attribute can lead to circular dependency
      acc[child.options.key] = provider.provideModelComposition(factory)({ ...child })
      return acc
    }, {}),
  })
  return obj
}

const provideModel = factory => (obj) => {
  if (!obj[MODEL]) {
    return obj
  }
  const Model = provider.provideModelComposition(factory)(obj[MODEL])
  Object.assign(obj, { [MODEL]: Model })
  return obj
}

const provideParentModel = factory => (obj) => {
  if (obj[PARENT] === undefined) {
    return obj
  }

  Object.assign(obj, { [PARENT]: obj[MODEL] })
  return obj
}

/**
 * Loads model validators as compositions inside model's VALIDATOR namespace.
 * @param {Gaia.Model} obj
 * @returns {*}
 */
// eslint-disable-next-line no-return-assign
const provideModelValidators = obj => (
  obj[VALIDATOR] ? (obj[VALIDATOR] = Object.keys(obj[VALIDATOR]).reduce((acc, key) => {
    acc[key] = validatorComposition({
      ...obj[CONFIGURATION].validator[key],
      fn: obj[VALIDATOR][key],
    })
    return acc
  }, {})) && obj : obj
)

/**
 * Loads attribute validators as composition inside model's VALIDATOR namespace.
 *
 * @type {function(...[*]=)}
 */
// eslint-disable-next-line no-return-assign
const provideAttributeValidators = curry((factory, obj) => (
  obj[VALIDATOR] ? (obj[VALIDATOR] = factory.validator(obj[VALIDATOR])) && obj : obj
))

// const provideValidator = curry((factory, obj) => {
//   console.log('obj validator?', obj[VALIDATOR], obj, obj[G.CONFIGURATION])
//   if (obj[VALIDATOR]) {
//     // console.log('obj validator?', obj, obj[VALIDATOR], obj[G.CONFIGURATION])
//     obj[VALIDATOR] = factory.validator(obj[VALIDATOR])
//   }
//   return obj
// })

// const provideChildrenValidators = factory => symbol => (obj) => {
//   if (obj[symbol] && obj[symbol][CHILDREN]) {
//     Object.keys(obj[symbol][CHILDREN]).forEach(
//       child => provider.provideAttributeValidators(factory)(obj[symbol][CHILDREN][child]),
//     )
//   }
//   return obj
// }

// const _provideChildrenValidators = factory => (obj) => {
//   // console.log('children validators...')
//   obj[CHILDREN] && Object.keys(obj[CHILDREN]).forEach(
//     child => provider.provideAttributeValidators(factory)(obj[CHILDREN][child]),
//   )
//   return obj
// }

const provideView = factory => obj => (
  obj[VIEW] ? Object.assign(obj, { [VIEW]: factory.ui(obj[VIEW]) }) : obj
)

/**
 * If {@param obj} has a G.ACTIONS namespace and its configuration an actions property in its root,
 * provides a component composition for each one of its items inside {@param obj}'s G.ACTIONS
 * namespace.
 *
 * @param {Gaia.Component} obj
 * @returns {*}
 */
const provideActions = (obj) => {
  const actions = obj[ACTIONS]
  return !actions || actions.length === 0 ? obj
    : Object.assign(obj, { [ACTIONS]: actions.map(item => provideComposition(item)) })
}

const provideChildren = (obj) => {
  const children = obj[CHILDREN]
  return !children || children.length === 0 ? obj
    : Object.assign(obj, { [CHILDREN]: children.map(item => provideComposition(item)) })
}

// eslint-disable-next-line no-unused-vars
// const provideListTemplates = (obj) => {
//   const collection = obj[CONFIGURATION][_prop(TEMPLATE)]
//   // console.log('composition?', obj)
//   // todo: test: object assign might lead to errors during reloading module(s)
//   return !collection || collection.length === 0 ? obj
//     : Object.assign(obj, {
//       [TEMPLATE]: Object.keys(collection).map(key => provideComposition(collection[key])),
//     })
// }

const provideListTemplatesViaFactory = factory => (obj) => {
  const collection = obj[TEMPLATE] || obj[G.CONFIGURATION][_prop(TEMPLATE)]

  return !collection || collection.length === 0 ? obj
    : Object.assign(obj, {
      [TEMPLATE]: Object
        .keys(collection)
        .map(key => factory.lazy(provideComposition, collection[key])),
    })
}

const provideComponentComposition = factory => context => (configuration) => {
  const moduleComposition = factory.component(configuration)
  const provide = pipe(
    provider.provideChildren,
    provider.provideActions,
    // provider.provideListTemplates,
    provider.provideListTemplatesViaFactory(factory),
    provider.provideView(factory),
    provider.provideEvents(context),
    provider.provideAttributeValidators(factory),
  )
  return provide(moduleComposition)
}

const provideControllerComposition = factory => (configuration) => {
  const composition = factory.module(configuration)

  provideComposition = provideComposition(composition)

  const moduleActions = {}
  const actions = composition[ACTIONS]
    ? composition[ACTIONS]
    : throw ReferenceError(`App Module ${composition._name} requires action(s) configuration`)

  const actionsConfiguration = configuration[_prop(ACTIONS)]

  Object.keys(actions).forEach((key) => {
    const actionModule = actionsConfiguration[key] && provideComposition(actionsConfiguration[key])
    const actionModel = actionsConfiguration?.[key]?.options?.model || null

    // TODO: turn into actionComposition
    moduleActions[key] = actionModule ? {
      _name: key,
      [COMPONENT]: actionModule,
      ...actionModel && { [MODEL]: actionModel },
      // shallow copy, to avoid mutation on reload from cache
      [UI]: { ...actionsConfiguration[key].ui },
      [FN]: composition[ACTIONS][key](composition)(actionModule),
      [HOOKS]: composition[HOOKS][ACTIONS][key],
      [G.STATE]: {},
      [G.CACHE]: {},
      [G.DATA]: {},
    } : {
      _name: key,
      [FN]: actions[key](composition)(),
      [HOOKS]: composition[HOOKS][ACTIONS][key],
      [G.STATE]: {},
      [G.CACHE]: {},
      [G.DATA]: {},
    }
  })

  composition[ACTIONS] = moduleActions

  providePath(composition)

  return composition
}

/**
 * Library Sync Content Provider.
 *
 * Content provider is consumed by platform application, and should not be used otherwise.
 *
 * Provides module object with loaded component, model, ui view artifacts.
 *
 * @example
 * import contentProvider from 'lib/provider/content'
 * ...
 * //given configuration has been provided and defined as config constant
 * //sync factories have been initialised and defined as factories constant
 *
 * const content = contentProvider(factories)(config)
 * ...
 * @typedef {Function} contentProviderFn
 * @param {Gaia.SyncFactories} factory
 * @param {Gaia.AppModule.Spec} configuration
 * @return {Gaia.AppModule} initialised module composition
 */
const contentProviderFn = (factory, configuration) => {
  provideComposition = provider.provideComponentComposition(factory)
  const provide = pipe(
    provider.provideControllerComposition(factory),
    provider.provideModel(factory),
    provider.provideParentModel(factory),
    // provider.provideModelAttributes(factory),
    // provider.provideChildrenValidators(factory)(MODEL),
    provider.provideView(factory),
  )
  return provide(configuration)
}

/**
 *
 * @type {contentProviderFn} contentProvider
 */
export const contentProvider = curry(contentProviderFn)

const provider = {
  contentProvider,
  provideControllerComposition,
  provideComponentComposition,
  provideModel,
  provideParentModel,
  provideView,
  provideActions,
  provideChildren,
  // provideListTemplates,
  provideListTemplatesViaFactory,
  provideEvents,
  // provideChildrenValidators,
  provideModelValidators,
  provideAttributeValidators,
  provideModelAttributes,
  provideModelComposition,
}

export {
  provider,
  contentProvider as default,
}
