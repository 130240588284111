/* eslint-disable no-param-reassign */
/* global G */
import sequenceModelSubmit from 'lib/sequence/model/api/submit'

const descriptor = Symbol('hook::model::submitAttribute').toString()

/**
 * Model Submit Attribute Hook
 *
 * Submits a specific sub-model using {@link sequenceModelSubmit}.
 *
 * @param  {string} attribute the attribute to submit
 * @return {function(...[*]): Promise<*[]>}
 */
const modelSubmitAttribute = attribute => obj => async (...args) => {
  const model = obj[G.MODEL][G.CHILDREN][attribute]
  try {
    await sequenceModelSubmit(model)

    const _ = (...x) => JSON.stringify(...x, null, 2)
    console.log('state data', descriptor, _(model[G.STATE][G.DATA]))
  } catch (e) {
    console.log(descriptor, model[G.DATA])
    console.log(descriptor, model[G.STATE])
    throw Error(`${model._name} ${descriptor} - ${e.message}`)
  }
  return args
}

export default modelSubmitAttribute
