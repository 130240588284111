/* global G */

import { debounce } from 'lib/util'

/**
 * Adds an article to the users cart, or, if the item is already present, increases its amount.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail = {} }) => {
  const { key, name, data } = detail[G.DATA]

  const adapter = app[G.ADAPTER][G.CART]
  const activeCart = adapter[G.DATA]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  // User has no active cart, let's create one
  if (!activeCart) {
    eventBus.dispatch(eventBus.type(G.CART, G.CREATE), { [G.DATA]: { data } })
    return
  }

  const isAlreadyAdded = activeCart.value.positions.find(
    position => (position?.key && position.key === data?.key)
          || position?.name === data?.name,
  )

  activeCart.value.positions = !isAlreadyAdded
    ? activeCart.value.positions.concat(data)
    : activeCart.value.positions.map(position => (
      (key && key === position?.key) || name === position?.name
        ? { ...position, amount: position.amount + 1 }
        : position))

  eventBus.dispatch(eventBus.type(G.CART, G.SET), { [G.DATA]: activeCart })
}

export default app => debounce(fn(app), 300, false)
