/**
 * useACL hook
 *
 * to use only in ACL ns components
 *
 * handler (fn) is a bound acl event()
 * which accepts an additional parameter
 *
 * @param {function} fn - acl event handler
 * @param {*} arg - parameter to pass to fn
 * @return {[{}]}
 */
const useAcl = (fn, arg) => [fn(arg)]

export default useAcl
