/* global G */
import { pipe } from 'lib/util'
import withGetterSetter from 'lib/trait/with/GetterSetter'
// import withObjectFreeze from 'lib/trait/with/ObjectFreeze'
import moduleComposition from 'lib/trait/composition/module'


const composition = descriptor => pipe(
  moduleComposition(descriptor),
  withGetterSetter(G.VIEW),
  withGetterSetter(G.REF),
  withGetterSetter(G.CHILDREN),
  withGetterSetter(G.EVENTS),
  withGetterSetter(G.VALIDATOR),
  // withObjectFreeze,
)

export default composition
