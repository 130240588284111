/* global G */

import { getFirstItem } from 'lib/util'

/**
 * Fetches the count of articles in the current cart.
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {function({detail: *}): Promise<null>}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.STATE]: groupState, [G.DATA]: groupData } = group
  const { [G.API]: { api: type, version } = {} } = groupState
  const { key: userKey } = getFirstItem(app[G.SESSION][G.STATE][G.META].refs.user)

  const eventBus = app[G.ADAPTER][G.EVENTS]
  const httpAdapter = app[G.ADAPTER][G.HTTP]

  const params = {
    filter: {
      status: 50, // only active ones
      submitter: userKey, // only mine
    },
    query: { type },
  }

  try {
    const result = await httpAdapter[G.API].post({ url: `/api/v${version}/search`, params })
    const activeCart = getFirstItem(result?.value)

    groupData.count = activeCart?.value?.positions?.reduce((acc, key) => acc + key.amount, 0) || 0

    eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.DONE, groupName), { [G.DATA]: groupData })
  } catch (e) {
    console.error(e)
  }

  return null
}

export default fn
