/* global G */
import { setKey } from '@gaia/util'

/**
 * Sets remote assets to adapter's data.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => ({ detail }) => {
  const { [G.DATA]: items } = detail
  const { [G.EVENTS]: eventBus, [G.NOTE]: adapter } = app[G.ADAPTER]
  setKey(items, G.DATA, adapter)
  eventBus.dispatch(
    eventBus.type(G.NOTE, G.DONE),
    { [G.DATA]: adapter[G.DATA] },
  )
}

export default fn
