/* global G */
import { pipe } from '@gaia/util'
import { hasNamespace } from '@gaia/trait/has'
import { withGetterSetterFn } from '@gaia/trait/with/GetterSetter'
import { usesNamespace, usesGetterSetter } from '@gaia/trait/uses'
import { withObjectFreeze, withDescriptor } from '@gaia/trait/with'
import api from './api'

const descriptor = 'adapterRouter'

/**
 * Router Adapter.
 *
 * Adapter used to route application modules and their actions, and who knows what else.
 *
 * @memberOf Gaia.Adapter#
 * @typedef Adapter.Router
 *
 * @param { Gaia.Web.Application } obj - Web Application
 * @return {function(*=): *}
 */
const adapter = obj => pipe(
  withDescriptor(descriptor),
  hasNamespace(G.API),
  usesNamespace(G.API, api(obj)),
  withGetterSetterFn(G.APP),
  usesGetterSetter(G.APP, () => obj),
  withObjectFreeze,
)({})

export default adapter
