/* global G */
import { pipe, def } from 'lib/util'
// import { routeToHash } from '@platform/adapter/router/effect/setAppHistory'

/**
 * gaia:hashchange Event Dispatcher
 *
 * @param {Gaia.Web.Application} obj - web application
 * @return {boolean | void}
 * @private
 */
// export const _gaiaHashChange = obj => obj[G.EVENTS].dispatchEvent(new CustomEvent('gaia:hashchange', {
//   bubbles: true,
//   cancelable: true,
//   detail: {},
// }))

/**
 *
 * null is default of useGetterSetter() trait
 *
 * @param {Gaia.Route} route
 * @return {boolean}
 * @private
 */
const _hasRef = route => def(route[G.REF]) && route[G.REF] !== null

/**
 * Pad History State
 *
 * pads history stack with default entry when necessary
 *
 * @param {Gaia.Web.Application} obj - application composition {@link Gaia.Web.Application}
 * @return {Gaia.Web.Application} obj - application composition {@link Gaia.Web.Application}
 * @private
 */
const _padHistoryState = obj => (
  (!window.history.state || !window.history.state.route)
  && (`${obj[G.SESSION][G.STATE][G.HTTP]}/` !== window.location.href)
  && window.history.replaceState(
    null,
    '',
    obj[G.SESSION][G.STATE][G.HTTP],
  )
) || obj

/**
 * History State Structure Setter
 *
 * @private
 * @param {Gaia.Web.Application} obj - application composition {@link Gaia.Web.Application}
 * @return {[{[symbol]: string, route: Gaia.Route}, string, string]} historyState
 */
export const historyStateFromRoute = (obj) => {
  const route = obj[G.STATE][G.ROUTE]

  const parts = [route[G.CONTEXT], route[G.MODULE], route[G.ACTION]]
  _hasRef(route) && parts.push(route[G.REF])

  const title = parts.join(' - ')
  const url = `#/${parts.join('/')}`

  // console.log('historyStateFromRoute - route', routeToHash(route))
  // console.log('Stack length: ', obj[G.SESSION][G.STATE][G.PREV].length)

  return [
    { [G.REF]: _hasRef(route) && route[G.REF], route },
    title,
    url,
  ]
}

/**
 * History State Method Selector
 *
 * on initial load and foreign state - pushes state
 * on refresh - replaces state
 *
 * @param {[{[symbol]: string, route: Gaia.Route}, string, string]} historyState
 * @private
 */
const _setHistoryState = historyState => (
  !window.history.state || !window.history.state.route
    ? window.history.pushState(...historyState)
    : window.history.replaceState(...historyState)
)

/**
 * BROWSER HISTORY MANIPULATION ONLY
 *
 * depends on on APP[G.STATE][G.MODULE] settings
 * executed via event bus dispatchEvent(new CustomEvent('gaia:init'))
 * the module and the action have been already rendered,
 * route has been set via sequence app::init
 * so we are only manipulating browser history,
 * either by pushing or replacing state
 * @param {Gaia.Web.Application} obj - application composition {@link Gaia.Web.Application}
 * @return {function(e:Event): Event}
 */
const initHandler = obj => event => pipe(
  _padHistoryState,
  historyStateFromRoute,
  _setHistoryState,
)(obj) || event

export default initHandler
