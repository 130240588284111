/* eslint-disable object-curly-newline */
/**
 * Local Attachment Create Worker Body
 *
 * is a closure with context of DedicatedWorkerGlobalScope
 * babel transformation error when inline
 * excluded via webpack config
 * current usage of arrow functions needs to be evaluated on multiple OS and browsers,
 * as ES6 native support might still be janky on some constellations
 * alternatives would be to evaluate webpack 5, otherwise use worker-loader
 */
export default () => {
  // eslint-disable-next-line no-restricted-globals
  const _worker = self
  _worker.addEventListener('message', (evt) => {
    const { url, key, uuid } = evt.data
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.open('get', url, true)
    // xhr.setRequestHeader('Cache-Control', 'max-age=3600, max-stale') // no effect, it seems
    xhr.onprogress = (event) => {
      const done = event.position || event.loaded
      const total = event.totalSize || event.total
      // eslint-disable-next-line no-mixed-operators
      const progress = Math.floor(done / total * 1000) / 10
      _worker.postMessage({ key, uuid, done, total, progress, status: xhr.status })
    }

    xhr.onload = () => {
      _worker.postMessage(xhr.status !== 200
        ? { key, url, uuid, done: 0, total: 100, progress: 0, status: xhr.status }
        : { key, url, uuid, blob: xhr.response })
    }
    xhr.send()
  })
}
