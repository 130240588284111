/* global G */
import { curry, isArr } from 'lib/util'

/**
 * Initializes all notification groups that have {@code notification.hidden} set, if the user
 * is logged in by dispatching {@code G.NOTIFICATION, G.INIT} event. It will also dynamically
 * load all event handlers configured in {@code notification.listEvents} and pass it on to
 * the init event.
 *
 * Will then pass the instantiate event handlers to the group so that they can be used
 * from within it.
 *
 * @param {Gaia.Web.Application} obj the platform Application
 * @returns {function(*): *}
 */
const init = obj => async (args) => {
  const session = obj?.[G.SESSION] || obj?.[G.ADAPTER]?.[G.SESSION]
  const meta = obj?.[G.SESSION]?.[G.STATE]?.[G.META]
      || obj[G.ADAPTER]?.[G.SESSION]?.[G.STATE]?.[G.META]

  const eventBus = obj[G.ADAPTER][G.EVENTS]

  const module = session[G.STATE][G.MODULE]
  const component = module[G.STATE][G.ACTION][G.COMPONENT]

  if (meta?.refs && meta?.acl?.userSettings?.includes(10)) { // user is logged in
    const groupConfig = session?.[G.STATE]?.[G.NOTIFICATION]?.[G.GROUP] || []
    // eslint-disable-next-line no-restricted-syntax
    for (const group of groupConfig) {
      const notificationSettings = group?.options?.notification
      const listEvents = notificationSettings?.listEvents || null

      // noinspection ES6RedundantAwait
      const notificationListEvents = listEvents
        ? await Object.keys(listEvents).reduce(async (acc, key) => {
          const [path, fn] = isArr(listEvents[key])
            ? listEvents[key]
            : [listEvents[key], 'default']

          const handlers = await acc
          const { [fn]: handler } = await import(`@app/_shared/events/${path}`)
          handlers[key] = await handler(module, component)

          return handlers
        }, Promise.resolve({}))
        : {}

      notificationSettings?.hidden && eventBus.dispatch(
        eventBus.type(G.NOTIFICATION, G.INIT), { [G.DATA]: {
          [G.GROUP]: notificationSettings?.type,
          [G.API]: notificationSettings?.api,
          [G.ROUTE]: group?.route,
          [G.MENU]: notificationSettings?.menu,
          [G.LIST]: {
            [G.EVENTS]: notificationListEvents,
          },
        } },
      )
    }
  }

  return args
}

export default curry(init)
