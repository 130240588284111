/* global G */

import { def, isArr, isObj } from 'lib/util'
import { empty } from 'lib/util/object'

/**
 * Returns the correct item with {@param key} from {@param resource} regardless of whether
 * it's an array or an object. If not found, it returns {@code null}
 * @param {array|Object} resource the resource to look at
 * @param {string} key            the key to find
 * @returns {*|null}
 * @private
 */
const _get = (resource, key) => {
  if (def(resource) && isArr(resource)) {
    return resource.find(x => x.key === key)
  }

  if (def(resource) && isObj(resource) && !empty(resource)) {
    return resource[key]
  }

  return null
}

/**
 * Retrieves an item from the adapters {@code G.DATA}.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => ({ key, ns = null }) => {
  const settings = app[G.ADAPTER][G.SETTINGS][G.DATA]

  return ns
    ? _get(settings[ns], key) || null
    : settings[key] || null
}

export default fn
