/* eslint-disable no-plusplus,no-await-in-loop */

/**
 * Attempts to obtain the translations of all token options from the t configuration variable.
 *
 * @param {object} i18n
 * @param {object} tokens the tokens to be translated before the interpolation
 * @returns {function(*): Promise<{}>}
 */
const translateTokens = async (i18n, tokens) => {
  const tTokens = {}
  const keys = Object.keys(tokens)

  for (let x = 0; x < keys.length; x++) {
    const tokenKey = keys[x]
    const { _key, ns, defaultValue } = tokens[tokenKey]
    tTokens[tokenKey] = await i18n.t(_key, { _key, ns, defaultValue })
  }

  return tTokens
}

/**
 * Translate a validator string.
 *
 * @example
 * Config:
 * '404': {
 *   options: {
 *     ns: 'common',
 *     _key: 'validator.generic.notRegistered',
 *     foo: {
 *       ns: 'common',
 *       _key: 'label.email'
 *     }
 *     bar: {
 *       ns: 'ticket',
 *       _key: 'label.whatever'
 *     }
 *   }
 * }
 *
 * will send the following payload to i18n:
 *
 * {
 *     _key: 'validator.generic.notRegistered',
 *     ns: 'common',
 *     {
 *         foo: '$(common:label.email)',
 *         bar: '$(ticket:label.whatever)'
 *     }
 * }
 * @param i18n
 * @returns {function(*): function(*, *, *, *, ...[*]): *}
 */
const fn = i18n => app => async (path, key, defaultValue, t, ...args) => {
  const { _key = `${path.join('.')}.validator.${key}`, ns = 'common', ...tokens } = t?.options || {}

  const tTokens = await translateTokens(i18n, tokens)

  return await i18n.t(_key, {
    _key, ns, defaultValue, ...tTokens,
  })
}

export default fn
