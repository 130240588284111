
import actionFn from './Fn'
import actionWithHooks from './WithHooks'
import actionHooksBefore from './HooksBefore'
import actionHooksAfter from './HooksAfter'

const moduleActionSequences = {
  actionHooksBefore,
  actionFn,
  actionHooksAfter,
  actionWithHooks,
}

export {
  actionHooksBefore,
  actionFn,
  actionHooksAfter,
  actionWithHooks,
  moduleActionSequences as default,
}
