/* global G */
import logoutHandler from '@platform/adapter/http/middleware/handler/logout'

/**
 * Unauthorized error dialog
 *
 * Displays an error dialog prompting the user to login again.
 * After clicking 'OK', the user is redirected to the guest module.
 *
 * To be displayed when a response with code 401 is received from the server (except on the login
 * screen).
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 */
export default (obj) => {
  const currentRoute = obj[G.STATE][G.ROUTE]?.[G.CONFIGURATION]
  const { module, action } = currentRoute || {}
  const isLogin = !module || (action === 'index' && module === 'guest')
  const eventBus = obj[G.ADAPTER][G.EVENTS]
  const okHandler = logoutHandler(obj)
  const path = obj[G.ADAPTER][G.ROUTER][G.API].path()

  !isLogin && obj[G.ADAPTER][G.ROUTER][G.API].setRestorePoint(path)

  !isLogin && eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.unauthorized.title',
      {
        ns: 'common',
        _key: 'dialog.error.unauthorized.title',
        defaultValue: 'Authentication error',
      },
    ),
    text: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.unauthorized.text',
      {
        ns: 'common',
        _key: 'dialog.error.unauthorized.text',
        defaultValue: 'Your session has expired. Please hit \'OK\' to renew.',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: obj[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
    okHandler,
  })
}
