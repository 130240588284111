/* eslint-disable no-unused-vars */
/* global G */
import routeComposition from '@gaia/trait/composition/route'
import { getFirstItem } from 'lib/util'

/**
 * Sets the selected notification as read and opens it depending on its contents.
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function({detail: *}): Promise<*|null>)|*}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.STATE]: groupState, [G.DATA]: groupData, [G.API]: { update } } = group
  const { [G.API]: { version } = {} } = groupState

  const { item = {}, openInNewTab = false } = detail || {}

  const eventBus = app[G.ADAPTER][G.EVENTS]
  const routerAdapter = app[G.ADAPTER][G.ROUTER]

  const { [G.STATE]: { [G.META]: { refs: { user = [] } = {} } = {} } = {} } = app[G.ADAPTER][G.SESSION] || {}
  const currentUser = getFirstItem(user)
  const submitter = getFirstItem(item.refs.submitter)

  // The logic is: if I am submitter, only source is relevant, if I am receiver only target.
  const relevantObject = currentUser?.key === submitter ? 'source' : 'target'

  // item.value.sourceObject or item.value.targetObject
  const ressource = item.value[`${relevantObject}Object`]
  const ressourceId = item.refs[relevantObject][0]

  const { module, action } = groupState[G.ROUTE][ressource]
  const route = routeComposition(module, action)

  if (openInNewTab) {
    const currentContext = app[G.SESSION][G.STATE][G.CONTEXT]

    return `#/${currentContext}/${module}/${action}/${ressourceId}`
  }

  // Closing the list
  groupData.anchor = null
  eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.DONE, groupName), { [G.DATA]: groupData })

  // redirect
  await routerAdapter[G.API].moduleAction(route, { key: ressourceId })
}

export default fn
