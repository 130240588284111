/* eslint-disable max-len */
import init from '@platform/adapter/notification/api/init'

/**
 * @memberOf Adapter.Notification
 * @typedef Adapter.Notification.API
 *
 * Gathers methods used to manage notifications.
 *
 * @property {function} init  inits notification groups
 *
 * @param {Gaia.Web.Application} app  Platform Web Application
 * @return {{}}
 */
const api = app => Object.create({}, {
  init: {
    value: init(app),
    iterable: true,
    enumerable: true,
  },
})

export default api
