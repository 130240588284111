/* global G */
import { pipe } from 'lib/util'
import composition from 'trait/composition/module'
import hasNamespace from 'trait/has/Namespace'
import withGetterSetter from 'trait/with/GetterSetter'

/**
 * Application Module Composition.
 *
 * Used as base composition for application modules
 * It requires a descriptor string, and a configuration object
 * It uses module composition
 * It provides protected namespaces MODEL, VIEW, ACTIONS, EVENTS, HOOKS, ADAPTER
 *
 * @information
 * Application Module is being initialized via content provider's factory.module instance,
 * not manually.
 *
 * @example
 *  import { appModuleComposition } from 'lib/trait/composition'
 *  import configuration from 'somewhere'
 *  ...
 *  const appModule = appModuleComposition('some module name')(configuration)
 *  ...
 *
 * @param {string} descriptor
 * @return {Gaia.AppModule.Spec} app module composition
 */
export default descriptor => pipe(
  composition(descriptor),
  withGetterSetter(G.VIEW),
  withGetterSetter(G.MODEL),
  withGetterSetter(G.ACTIONS),
  withGetterSetter(G.EVENTS),
  hasNamespace(G.HOOKS),
  hasNamespace(G.ADAPTER),
)
