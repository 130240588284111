import redirectSequence from '@gaia/sequence/module/adapter/router/redirect'

/**
 * Router Adapter Redirect Hook.
 *
 * Executes routing sequence, imported directly, based on current module state
 *
 * @param {Gaia.AppModule.Spec} obj - app module composition
 * @return {function(...[*]): *[]}
 */
export default redirectSequence
