import { Link } from '@mui/material'
import { pipe } from 'lib/util'

/**
 * @example <caption>Matching a URL</caption>
 * - https://www.ab.com
 * - http://www.ab.com
 * - https://ab.com
 * - http://ab.com
 * - www.ab.com
 *
 * @type {RegExp}
 */
const urlRegex = /((?:https?:\/\/|https?:\/\/www\.?|www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_+.~#?&/=]*)/g

/**
 * RFC2822 compliant
 * https://regexr.com/2rhq7
 *
 * @type {RegExp}
 */
const emailRegex = /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/g

/**
 * Replaces {@param text} with a link component
 * if it matches {@code urlRegex}
 *
 * @param {string} text
 * @returns {JSX.Element|string}
 */
const urlDecoratorFn = (text) => {
  const withHttps = url => (/^www\..+$/i.test(url) ? `https://${url}` : url)
  const match = typeof text === 'string' && text.match(urlRegex)

  return match
    ? <Link sx={{ color: 'text.link', textDecoration: 'none' }} href={withHttps(match[0])} key={match[0]} target={'_blank'}>{match[0]}</Link>
    : text
}

/**
 * Replaces {@param text} with a link component
 * if it matches {@code emailRegex}
 *
 * @param {string} text
 * @returns {JSX.Element|string}
 */
const emailDecoratorFn = (text) => {
  const match = typeof text === 'string' && text.match(emailRegex)

  return match
    ? <Link href={`mailto:${match[0]}`} key={match[0]}>{match[0]}</Link>
    : text
}

/**
 * Pipe the various decorator functions together.
 * Most generic decorator should be last.
 *
 * @type {function(*=): *}
 */
const decoratorsFn = pipe(
  emailDecoratorFn,
  urlDecoratorFn,
)

/**
 * Decorates certain parts of a given text with links.
 * Can currently convert
 * - URLs
 * - emails
 * to links. But could be extended to also decorate
 * tweets, users and what not.
 *
 * @param Component
 * @returns {function({value: *})}
 * @constructor
 */
const HyperHOC = Component => ({ value }) => {
  const parsedText = value
    .split(/(\s)/g)
    .map(text => decoratorsFn(text))

  return (
    <Component
      children={parsedText}
    />
  )
}

export default HyperHOC
