/**
 * Async Configuration Loader.
 *
 * Async Await Implementation of a dynamic import functionality.
 *
 * Uses webpack v4 chunk mechanism
 * Requires babel dynamic import plugin
 *
 * @memberOf Gaia.asyncLoaders
 * @typedef {Function} asyncLoaderConfig
 * @param {string} path - configuration location
 * @returns {Gaia.AppModule | Gaia.Module | Gaia.Settings} configuration
 */
export default async path => await import(/* webpackChunkName: "config." */`config/${path}`)
  .then(module => module.default);
