/* global G */
// import deepFreeze from 'lib/util/deepFreeze'
import { pipe, curry } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
import { withConfiguration, withGetterSetter } from 'trait/with'


/**
 * @memberOf Gaia#
 * @typedef Gaia.Composition.Lazy
 * @property {Gaia.Component.Spec} CONFIGURATION - configuration to use during lazy initialisation
 * @property {function} FN - function to use to initialize lazy component
 *
 */

/**
 * Lazy Composition
 *
 * used for allowing later initialisation of a component,
 * based on it's initial configuration.
 *
 * The composition can be used for list templates, dynamic children, optional elements.
 *
 *
 * @param {Gaia.Component.Spec} configuration
 * @param {function} fn - lazy initialisation fn
 * @return {Gaia.Composition.Lazy} lazy composition
 */
const composition = (configuration, fn) => pipe(
  withConfiguration,
  withGetterSetter(G.FN),
  usesGetterSetter(G.FN, fn),
)(configuration)
// )(deepFreeze(configuration))

export default curry(composition)
