/* eslint-disable object-curly-newline */
/* global G */
import { getReadUrl } from '@platform/adapter/attachment/helper'

/**
 * Gets the current attachment's remote URL and dispatches
 * a  {@code G.ATTACHMENT, G.HTTP, key} event
 * containing it.
 *
 * @param {Gaia.Web.Application} app  the Web platform Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => ({ detail }) => {
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const { key, name, api } = detail[G.DATA]

  eventBus.dispatch(
    eventBus.type(G.ATTACHMENT, G.HTTP, key),
    { key, url: `${getReadUrl(app, api, key, name)}` },
  )
}

export default fn
