/* global G */
import { pipe, curry } from 'lib/util'
import { withDependencyCheck } from 'trait/with'
import { usesNamespace } from 'trait/uses'
import set from 'app/_shared/hook/model/set'

const def = x => typeof x !== 'undefined'
const isFn = x => typeof x === 'function'
const isArr = x => def(x) && Array.isArray(x)
const map = ([x, ...xs], fn, opts) => (def(x) ? [fn(x, opts), ...map(xs, fn, opts)] : [])
const fn = scope => (item, iterable) => {
  if (isFn(item)) {
    // this is the end point of recursion, no result needs to be set
    return item(scope)
  }

  const result = {}
  const iterableItem = iterable[item]

  if (isFn(iterableItem)) {
    result[item] = iterableItem(scope)
  } else if (isArr(iterableItem)) {
    result[item] = map(iterableItem, fn(scope), iterableItem)
  } else {
    result[item] = map(Object.keys(iterableItem), fn(scope), iterableItem)
      .reduce((accumulator, entry) => ({ ...accumulator, ...entry }), {})
  }
  return result
}

const symbol = G.HOOKS
const descriptor = 'canHookInto'

/**
 * Can Hook Into
 *
 * Adds optional hooks to specific namespace, provided by Symbol.
 *
 * It allows deviations in application flow, derived of provided
 * hooks functionality.
 *
 * ie. a hook can test for user rights, before executing a module action, or an api call.
 * if user has insufficient rights, a redirect sequence would be called.
 *
 * @example
 * /* global G *\/
 * import { canHookInto } from 'trait/can'
 * import hooks from './hooks'
 *
 * const composition = pipe(
 *    hasNamespace(G.API),
 *    hasNamespace(G.HOOKS),
 *    canHookInto(G.API, hooks)
 * )
 *
 * const objComposition =  composition({}) // { [G.API]: *, [G.HOOKS]: { [G.API]: {Function} } }
 *
 * @param {Symbol} identifier
 * @param {Object} unboundHooks - collection of hook functions, based on namespace
 * @param {Gaia.Component.Spec | Gaia.AppModule.Spec | Gaia.Model.Spec} obj - object composition
 * @return {Gaia.Component.Spec | Gaia.AppModule.Spec | Gaia.Model.Spec} obj - object composition
 */
const canHookInto = (identifier, unboundHooks, obj) => {
  withDependencyCheck(descriptor, [symbol, identifier], obj)

  /**
   * Before binding the hooks, we'll look into the configuration for each hook and if a specific
   * model is specific, we add the {@link set} hook as the first hook to the {@code before} hooks
   * for that action.
   */
  const preboundActionHooks = Object.keys(unboundHooks[identifier]).reduce((acc, key) => (
    { ...acc, [key]: { ...acc[key], ...acc[key]?.before && { before: [set, ...acc[key].before] } } }
  ), unboundHooks[identifier])

  const preboundHooks = { [identifier]: preboundActionHooks }

  const boundHooks = map(Object.keys(preboundHooks[identifier]), fn(obj), preboundHooks[identifier])
    .reduce((accumulator, entry) => ({ ...accumulator, ...entry }), {})

  const hooksComposition = pipe(
    usesNamespace(symbol, { [identifier]: boundHooks }),
  )

  return hooksComposition(obj)
}

export default curry(canHookInto)
