/**
 * noop Action
 *
 * is a noop(), as it doesn't execute any BL
 * It respects current action propagation inside application module(s)
 * It respects spread arguments, for use with asyncPipeSpread() see: @lib/util for more information.
 *
 * @example
 * // given there is a noop action in module composition
 * // these would be exemplary contents of [module]/actions.js
 *
 * import noop from '@gaia/util/noop/action'
 *
 * export default {
 *  overview: noop,
 * }
 *
 * @return {function(*): function(...[*]): *[]}
 */
const actionNoopFn = () => () => async (...args) => args

export default actionNoopFn
