/* global G */
import { getUrl } from '@platform/adapter/note/helper'

/**
 * Adds a note to the adapter's G.DATA list of messages, dispatches a G.DONE event and then
 * attempts to send it to the server. On successful send, it sets the new note's id and rev and
 * submitTimestamp, adds it to the adapter's data and dispatches a G.DONE event again.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => async ({ detail }) => {
  const adapter = app[G.ADAPTER][G.NOTE]
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapterData = adapter[G.DATA]
  const item = detail[G.DATA]
  const callback = detail[G.FN]
  adapterData.unshift(item)

  eventBus.dispatch(eventBus.type(G.NOTE, G.DONE), { [G.DATA]: adapterData })

  const url = `${getUrl(app)}/default`
  const result = await app[G.ADAPTER][G.HTTP][G.API].post({ url, params: item })

  if (result) {
    item.key = result.key
    item._rev = result._rev
    item.value = result.value
    item.refs = result.refs
    item.acl = result.acl

    await callback?.(item)

    eventBus.dispatch(eventBus.type(G.NOTE, G.DONE), { [G.DATA]: adapterData })
  }
}

export default fn
