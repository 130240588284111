/* global G */
import { getUrl } from '@platform/adapter/note/helper'

/**
 * Attempts to update a note from the server. On success, it also updates it from the adapter's
 * data and then dispatches a G.DONE event.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {Gaia.OnAttachmentEvent}  an AttachmentEventListener
 */
const fn = app => async ({ detail }) => {
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const item = detail[G.DATA]
  const adapter = app[G.ADAPTER][G.NOTE]
  const adapterData = adapter[G.DATA]

  const url = getUrl(app, { key: item.key })
  const result = await app[G.ADAPTER][G.HTTP][G.API].update({ url, params: item })

  if (result.ok) {
    const adapterItem = adapterData.find(dataItem => dataItem.key === item.key)
    adapterItem._rev = result.rev
    adapterItem.value = item.value
    adapterItem.refs = item.refs

    eventBus.dispatch(eventBus.type(G.NOTE, G.DONE), { [G.DATA]: adapterData })
  }
}

export default fn
