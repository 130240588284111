import { curry } from 'lib/util'
import { modelAttributeComposition } from '@gaia/trait/composition'

/**
 *
 * @param {array} obj - model children, mapped pshData structure
 * @return {array} attribute compositions
 */
// const provideAttributes = obj => Object.keys(obj).reduce((acc, attr) => {
//   const attribute = obj[attr]
//   acc[attr] = modelAttributeComposition(attr)(attribute)
//   // configuration.children precedes pshData, as it is newer implementation
//   let children = attribute.children || attribute.pshData
//
//   if (isArr(children)) {
//     children = children.reduce((a, cur) => {
//       console.log(' ----------- child??', cur.options.key, cur)
//       Object.assign(a, { [cur.options.key]: cur })
//       return a
//     }, {})
//     // console.log('ATTRIBUTES', children)
//     // console.log('children obj?', children)
//   }
//
//   acc[attr][G.CHILDREN] = children
//     ? provideAttributes({ ...children })
//   // acc[attr][G.CHILDREN] = attribute.pshData
//   //   ? provideAttributes({ ...attribute.pshData })
//     : acc[attr][G.CHILDREN]
//   return acc
// }, obj)

// export const modelAttributeFactoryFn = (loader, obj) => provideAttributes(obj[G.CHILDREN])

/**
 *
 * @param {Gaia.requireLoaders} loader - resource loaders
 * @param {Gaia.ModelAttribute} obj - model attribute configuration
 * @return {*}
 */
const modelAttributeFactoryFn = (loader, obj) => modelAttributeComposition(obj.options.key)(obj)

export default curry(modelAttributeFactoryFn)
