/* global G */
import { pipe } from 'lib/util'
import { withDescriptor } from 'trait/with'
import { hasNamespace } from 'trait/has'
import { usesNamespace } from 'trait/uses'
import { withGetterSetterFn } from 'lib/trait/with/GetterSetter'
import api from './api'

const descriptor = 'gaia:manager:acl'

/**
 * @memberOf Gaia#
 * @namespace Manager
 *
 * managers responsible for different aspects of application
 */

/**
 * @memberOf Gaia.Manager#
 * @namespace ACL
 *
 * @param { Gaia.Web.Application } obj - application composition
 * @return {Gaia.Manager.ACL} manager - acl manager
 */
const aclManager = (obj) => {
  const Api = api(obj)
  const manager = pipe(
    withDescriptor(descriptor),
    hasNamespace(G.PROPS),
    usesNamespace(G.PROPS, { active: !!obj[G.PROPS].acl }),
    withGetterSetterFn(G.CACHE), // login acl payload
    hasNamespace(G.API),
    usesNamespace(G.API, Api),
  )({})

  /**
   * @type {Gaia.Adapter.EventBus}
   */
  const { add, type } = obj[G.EVENTS]

  add(type(G.ACL, G.CACHE), Api.init, { once: true })

  return manager
}

export default aclManager
