/* global G */
import { pipe } from 'lib/util'
import { withGetterSetter } from 'trait/with'
import componentComposition from 'lib/trait/composition/component'

import hasNamespaceKeysGetter from 'trait/has/NamespaceWithKeysGetter'
import { hasNamespace } from 'trait/has'

const descriptor = 'G.List'

export default pipe(
  componentComposition(descriptor),
  withGetterSetter(G.TEMPLATE),
  hasNamespace(G.KEYS),
  hasNamespaceKeysGetter(G.CHILDREN),
  hasNamespaceKeysGetter(G.PROPS),
)
