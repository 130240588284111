/* eslint-disable object-curly-newline */
/* global G */
import _route from 'trait/composition/route'
import { isObj } from 'lib/util'
import { active, get, strategy, valid } from 'lib/manager/acl/helper'
import ACLError from 'lib/manager/acl/error'

/**
 * checks access for a module action
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(Gaia.AppModule.Action): void}
 */
const action = obj => (component) => {
  try {
    // is acl active?
    if (!active(obj)) {
      return component
    }
    // component has ACL?
    strategy(!!component[G.ACL])
    // ACL is implicit
    valid(component[G.ACL])
    // ACL is explicit
    isObj(component[G.ACL]) && (() => {
      // console.log('got an explicit acl', component[G.ACL], Object.values(component[G.ACL]).pop())
      // compare with adapter cache
      get(obj)[G.API].check(
        get(obj)[G.API].get(Object.keys(component[G.ACL]).pop()),
        Object.values(component[G.ACL]).pop(),
      )
    })()
  } catch (e) {
    obj[G.ADAPTER][G.ROUTER][G.API].moduleAction(_route('error', 'acl'))
    throw new ACLError('action insufficient rights')
  }
  return component
}

export default action
