/* global G */
import { setKey } from '@gaia/util'
import Group from '@platform/adapter/attachment/group'

/**
 * Initializes a new attachment group.
 *
 * {@code G.GROUP}: Name of the attachment group
 *
 * {@code G.CACHE}: Whether remote attachments should be added to the G.CACHE namespace.
 * If it's {@code false}, G.CACHE should never be populated. This is useful if the group
 * is meant to only manage local attachments, possibly upload and then forget about them.
 *
 * {@code G.UNDO}: Whether the G.UNDO flag should be set if an attachment is added.
 *
 *  {@code G.FILTER}: Various filter options to check when an attachment is added. Currently:
 * - {@type {Number|false} size}: Number in bytes declaring maximum size for a single attachment
 * - {@type {Number|false} groupSize}: Number in bytes declaring maximum size for all attachments
 * - {@type {Number|false} count}: Maximum number of attachments
 * - {@type {String[]|false} types}: Array of allowed file extensions
 * - {@type {Boolean} warned}: Whether the user has been informed about big attachments
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => ({ detail }) => {
  const {
    [G.GROUP]: groupName,
    [G.CACHE]: cache,
    [G.UNDO]: undo,
    [G.FILTER]: filter,
  } = detail[G.DATA]

  const adapter = app[G.ADAPTER][G.ATTACHMENT]
  const group = adapter[G.GROUP][groupName] || Group(app, groupName, {
    [G.CACHE]: cache,
    [G.UNDO]: undo,
    [G.FILTER]: filter,
    warned: false,
  })

  setKey(group, groupName, adapter[G.GROUP])
}

export default fn
