/**
 * Async UI Loader
 *
 * Async Await Implementation of a dynamic import functionality.
 *
 * The loader respects process env parameter PLATFORM.
 *
 * @memberOf Gaia.asyncLoaders
 * @typedef {Function} asyncUiLoader
 * @param {string} path - location of ui module
 * @returns {Promise<*>} ui module
 */
export default async path => await import(/* webpackChunkName: "ui." */ `ui/${path}`)
  .then(module => module.default);
