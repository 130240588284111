/* eslint-disable object-curly-newline */
/* global G */
import { getKey } from 'lib/util'
import asObject from 'lib/sequence/component/children/asObject'
import { active, get, resolve } from 'lib/manager/acl/helper'

export const _defaultAclProps = {
  aclProps: { hidden: true },
}
/**
 * component acl resolver
 *
 * do not forget to use ACL parent, otherwise configuration has no impact
 * @example action configuration
 * {
 * module: 'Card/Form',
 * view: 'Component/Acl/Grid/Section',
 * options: {
 *   ...,
 *   events: {
 *     acl: 'component',
 *   },
 * },
 * children: [
 *   {
 *     config: 'ui/image/avatar/2',
 *     options: {
 *       ...,
 *     },
 *     role: ['CustomerServiceAgent', 'CSBase'],
 *     acl: {
 *       person: [20],
 *     }
 *   },
 *   {
 *     config: 'ui/grid/section/10',
 *     view: 'Component/Acl/Grid/Section', // override
 *     options: {
 *       ...
 *       events: {
 *         acl: 'component',
 *       }
 *     },
 *     children: [
 *       ...,
 *       {
 *         config: 'ui/button/fab/2/right',
 *         route: {
 *           ...,
 *         },
 *         options: {
 *           ...,
 *         },
 *         role: ['CustomerServiceAgent', 'CSBase'],
 *         acl: {
 *           organisation: [20],
 *         }
 *       },
 *       ...,
 *     ],
 *   },
 *   ...,
 * ],
 * ...,
 * }
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(Gaia.Component): void}
 */
const fn = obj => (component, key) => {
  // is acl active?
  if (!active(obj)) {
    return {}
  }

  const item = getKey(key, asObject(component[G.CHILDREN]))
  const acl = !item[G.ACL] ? null : resolve(item)

  // permission check
  try {
    acl && get(obj)[G.API].check(get(obj)[G.API].get(acl[G.META]), acl[G.ACL])
  } catch (e) {
    return acl[G.PROPS]
  }
  // context role check
  if (item[G.CONFIGURATION].aclContextRole) return get(obj)[G.API].contextRole(item[G.CONFIGURATION])

  // context role check
  if (item[G.CONFIGURATION].aclUserRole) return get(obj)[G.API].userRole(item[G.CONFIGURATION])

  // context check
  if (item[G.CONFIGURATION].aclContext) return get(obj)[G.API].context(item[G.CONFIGURATION])

  return {}
}

export default fn
