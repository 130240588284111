/* eslint-disable no-param-reassign */
/* global G */
import sequenceModelSubmit from 'lib/sequence/model/api/submit'

const descriptor = Symbol('hook::model::submit').toString()

/**
 * Model Submit Hook.
 *
 * Executes model data remote submission, based on model cache and reference.
 *
 * @param {Gaia.Component.Spec} obj - app module composition
 * @return {function(...[*]): Promise<*[]>}
 */
const modelSubmit = obj => async (...args) => {
  const model = obj[G.MODEL]
  try {
    await sequenceModelSubmit(model)

    const _ = (...x) => JSON.stringify(...x, null, 2)
    console.log('state data', descriptor, _(model[G.STATE][G.DATA]))
  } catch (e) {
    console.log(descriptor, model[G.DATA])
    console.log(descriptor, model[G.STATE])
    throw Error(`${model._name} ${descriptor} - ${e.message}`)
  }
  return args
}

export default modelSubmit
