/**
 * Attempts to obtain a persisted file with name {@param item.name} from the cache with name
 * {@param containerName}.
 *
 * @param {Object} item           information about the file to obtain
 * @param {string} item.url       the url of the file to obtain
 * @param {string} containerName  the name of the cache where the file is to be found
 * @return {Promise<Blob>}
 */
const get = async ({ url }, containerName) => {
  const cache = await caches.open(containerName)
  const response = await cache.match(url)
  return await response.blob()
}

/**
 * Attempts to persist a new file with name {@param item.name} and contents {@param item.contents}
 * in the cache with name {@param containerName}.
 *
 * @param {Object} item           information about the file to save
 * @param {string} item.url       the url of the file to obtain
 * @param {Blob} item.contents    the contents of the file to save
 * @param {string} containerName  the name of the cache where the file is to be found
 * @return {Promise<void>}
 */
const put = async ({ url, contents }, containerName) => {
  const cache = await caches.open(containerName)
  const response = new Response(contents)
  await cache.put(url, response)
}

/**
 * Attempts to remove a file with name {@param item.name} from the cache with name
 * {@param containerName}.
 *
 * @param {Object} item           information about the file to delete
 * @param {string} item.url       the url of the file to obtain
 * @param {string} containerName  the name of the cache where the file is to be found
 * @return {Promise<boolean>}
 */
const remove = async ({ url }, containerName) => {
  const cache = await caches.open(containerName)
  return await cache.delete(url)
}

/**
 * @type {FileService}
 */
const service = {
  get,
  put,
  remove,
}

export default service
