/* global G */
import sequenceModuleLogin from 'lib/sequence/module/login'
import routeComposition from 'trait/composition/route'
import contextInit from 'lib/sequence/app/init/context'

const IMP_KEY = 'imp'

/**
 * Redirects to the current user's default route.
 *
 * @param {Gaia.Web.Application} obj the platform Application
 * @returns {Promise<void>}
 */
const redirectToDefaultRoute = async (obj) => {
  const router = obj[G.ADAPTER][G.ROUTER][G.API]
  const defaultRoute = router.defaultRoute()
  const route = routeComposition(defaultRoute.module, defaultRoute.action)
  await contextInit(obj, [route])
  await router.moduleAction(route)
}

/**
 * Performs a login call with the current module data and updates the ui accordingly.
 *
 * @param {Gaia.Web.Application} obj the platform Application
 * @returns {Promise<void>}
 */
const login = async (obj) => {
  const module = obj[G.SESSION][G.STATE][G.MODULE]
  await sequenceModuleLogin(module)()
  await module[G.ADAPTER][G.UI].provide(module)
}

/**
 * Calls {@link login} and hides the application's frame.
 *
 * @param {Gaia.Web.Application} obj the platform Application
 * @returns {Promise<void>}
 */
const finish = async (obj) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]
  eventBus.dispatch(eventBus.type(G.FRAME, G.DONE))
  await login(obj)
}

/**
 * Calls {@link login} and initializes the application's frame
 *
 * @param {Gaia.Web.Application} obj the platform Application
 * @param {string} username         the name of the user being impersonated
 * @returns {Promise<void>}
 */
const start = async (obj, username) => {
  const eventBus = obj[G.ADAPTER][G.EVENTS]
  const storage = obj[G.ADAPTER][G.STORAGE][G.API]
  await login(obj)

  eventBus.dispatch(eventBus.type(G.FRAME, G.INIT), {
    title: obj[G.ADAPTER][G.INTL]._t(
      'frame.impersonation.title',
      {
        ns: 'admin',
        _key: 'frame.impersonation.title',
        defaultValue: 'Impersonating: {{username}}',
        username,
      },
    ),
    actions: [
      {
        text: obj[G.ADAPTER][G.INTL]._t(
          'button.end',
          {
            ns: 'common',
            _key: 'button.end',
            defaultValue: 'End',
          },
        ),
        onClick: () => storage.remove(IMP_KEY),
      },
    ],
  })
}

/**
 * Handles changes on the storage's entry with 'imp' key by toggling the impersonation state.
 *
 * @param {Gaia.Web.Application} obj the platform Application
 * @returns {(function({key: *, newValue: *}): Promise<void>)|*}
 */
const handle = obj => async (event) => {
  const router = obj[G.ADAPTER][G.ROUTER][G.API]
  const { key, newValue: username } = event
  if (key === IMP_KEY) {
    try {
      username
        ? await start(obj, username)
        : await finish(obj)
      await redirectToDefaultRoute(obj)
    } catch (e) {
      // if login fails, it means we lost our session, but in this case we don't want to keep a
      // restore point, because we may be in a route not accessible by the original user
      router.restorePoint() // throwing restore point away
    }
  }
}

/**
 * Start impersonation:
 *  - after auto-logging in
 *  - and redirect to user's default route
 *    - if the user clicks on the "Impersonate" button
 *    - if other tab starts impersonation
 *
 * Finish impersonation:
 *  - before logging out
 *  - and redirect to user's default route
 *    - if the user clicks on the "End impersonation" button
 *    - if other tab finishes impersonation
 */

/**
 * Initializes the impersonation functionality.
 *
 * @param {Gaia.Web.Application} obj the platform Application
 * @returns {function(*): Promise<*>}
 */
const init = obj => async (args) => {
  const storage = obj[G.ADAPTER][G.STORAGE][G.API]
  const username = storage.get(IMP_KEY)
  // if the storage has an 'imp' key, start impersonation
  username && await start(obj, username)
  // start listening for changes in the storage
  storage.listen(handle(obj))

  return args
}

export default init
