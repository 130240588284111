/* eslint-disable no-unused-vars */
/* global G */
import { search } from 'platform/adapter/notification/helper/search'
import { getFirstItem } from 'lib/util'

/**
 * Fetches all notifications
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function({detail: *}): Promise<{data: (*&{key: *})[]}|null>)|*}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.STATE]: groupState } = group
  const { [G.API]: { api, transform, version, limit } = {} } = groupState
  const httpAdapter = app[G.ADAPTER][G.HTTP]

  const { page } = detail

  const { [G.STATE]: { [G.META]: { refs: { user = [] } = {} } = {} } = {} } = app[G.ADAPTER][G.SESSION] || {}
  const currentUser = getFirstItem(user)

  const params = {
    limit,
    page: page || undefined,
    lucene: `submitter:("${currentUser?.key}") OR recipient:("${currentUser?.key}")`,
    query: { type: api },
    transform: { key: transform },
    sort: [['submitTimestamp', 'desc']],
  }

  const url = `/api/v${version}/search`

  try {
    const result = await search(httpAdapter[G.API].post, url, params, [])

    return {
      ...result,
      data: result.data
      // filtering out duplicate threads
        .filter((message, i) => i === result.data.findIndex(
          x => getFirstItem(x.refs.thread) === getFirstItem(message.refs.thread),
        ))
        .map((message) => {
          const submitter = getFirstItem(message.refs.submitter)

          return {
            ...message,
            value: {
              ...message.value,
              partner: message.value[`${currentUser !== submitter ? 'source' : 'target'}Partner`],
            },
          }
        }),
    }
  } catch (e) {
    console.error(e)
  }

  return null
}

export default fn
