/* global G */

import { getFirstItem } from 'lib/util'

/**
 * Returns the default route for the given user role.
 *
 * @param {Gaia.Web.Application} obj the Web Platform Application
 * @returns {Object}
 * @private
 */
const _getDefaultRoute = (obj) => {
  const meta = obj[G.SESSION][G.STATE][G.META]
  const { refs: { user = [] } = {} } = meta || {}
  const currentUser = getFirstItem(user)
  const { value: { roles = [] } = {} } = currentUser || {}
  const userRoles = roles.map(role => role.split('@')?.[0] || '')

  const { defaults } = obj[G.CONFIGURATION]
  const { route: defaultRoute, routes } = defaults
  let entry

  // for each one of the current context's roles...
  userRoles?.some((role) => {
    // ...look for a default route that contains it in its role array
    entry = routes.find(item => item.role.includes(role))
    // if a route is found, break the loop
    return entry
  })

  return entry?.route || defaultRoute
}

export default _getDefaultRoute
