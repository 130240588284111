/* global G */

/**
 * Toggles the state of the anchor element for the current group. Depending on what event handler
 * (onClick, onClose) called this method, the menu will appear/disappear.
 *
 * This function is handled by the adapter rather than the React component itself because we need
 * to trigger it from the adapter as well, see {@code show} api method.
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function(*): Promise<void>)|*}
 */
const fn = (app, groupName = '', group = {}) => async (anchor) => {
  const { [G.DATA]: groupData } = group

  const eventBus = app[G.ADAPTER][G.EVENTS]
  groupData.anchor = anchor || null

  eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.DONE, groupName), { [G.DATA]: groupData })
}

export default fn
