/* global G */
import { asyncPipeSpread, translationsMap } from 'lib/util'

/**
 * Set Session State MODULE parameter
 *
 * Uses session manager
 *
 * The method uses explicit deconstructing of arguments,
 * and has to rebuild the args array-like content as return value
 *
 *
 * @param {string} module - module identifier to use in the sequence
 * @param {*} args - optional arguments
 * @return {function(*, ...[*]): *[]}
 */
// eslint-disable-next-line no-unused-vars
const setModule = () => async (module, ...args) => [`module/${module}`, ...args]

/**
 * Provides Module Configuration
 *
 * Uses session manager
 * Uses config provider
 *
 * @param {Gaia.Web.webApplication} obj - native application
 * @param {*} args - optional arguments
 * @return {function(...[*]): *[]}
 */
const provideModuleConfiguration = obj => async (module, ...args) => [
  obj[G.PROVIDER].config(`${module}`), ...args,
]

/**
 * loads ns translation /:lng/:ns
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(*=, ...[*]): *[]}
 */
const provideModuleTranslationNamespaces = obj => async (configuration, ...args) => {
  const { module, ns = [] } = configuration
  const namespace = translationsMap[module] || module
  await obj[G.ADAPTER][G.INTL].add([namespace, ...ns])
  return [configuration, ...args]
}

/**
 * Provides App Module Instance
 *
 * Uses platform content provider
 *
 * @param {Gaia.Web.webApplication} obj - native application
 * @param {*} args - optional arguments
 * @return {function(...[*]): *[]}
 */
const provideModuleInstance = obj => async (configuration, ...args) => {
  const state = obj[G.SESSION][G.STATE]
  state[G.MODULE] = obj[G.PROVIDER].content(configuration)
  return args
}

export default obj => asyncPipeSpread(
  setModule(obj),
  provideModuleConfiguration(obj),
  provideModuleTranslationNamespaces(obj),
  provideModuleInstance(obj),
)
