/* eslint-disable arrow-body-style,no-param-reassign */
/* global G */
import { pipe } from 'lib/util'
import cancelOnError from 'lib/sequence/model/cancelOnError'

const descriptor = 'sequence::model::flatten'

/**
 * Sets the model's id stored in its state as the value of a new _id property inside its data, if
 * the base model also has the bulk flag set. We need to do this if we are also modifying the base
 * model.
 *
 * @param obj
 * @returns {*[]}
 */
const setModelId = (obj) => {
  const ids = []
  const id = obj[G.STATE][G.REF]
  const bulk = obj[G.STATE][G.BULK]
  if (id && bulk) {
    obj[G.DATA]._id = id
    ids.push(id)
  }
  return ids
}

/**
 * Callback of sequenceModelFlattenFn
 *
 * Returns an array containing {@param obj} and all children of type ref that have children and bulk
 * state set to true, recursively. It also sets those children's keys as their _id in their data to
 * comply with the server requirements on the bulk call.
 *
 * @callback sequenceModelFlattenFn.Callback
 * @param {string[]} [ids]      an array with the already found _ids
 * @return {Gaia.Model.Spec[]}  an array containing {@param obj} and all children, recursively
 */

/**
 * Sync Model Flatten Sequence
 *
 * Returns a {@link sequenceModelFlattenFn.Callback} for the passed {@param obj}.
 *
 * @param {Gaia.Model.Spec} obj the model object composition to flatten
 * @return {sequenceModelFlattenFn.Callback}
 */
const sequenceModelFlattenFn = obj => (ids = []) => {
  return Object.keys(obj[G.CHILDREN]).reduce((acc, key) => {
    const item = obj[G.CHILDREN][key]
    const id = item[G.STATE]?.[G.REF]
    id && (item[G.DATA]._id = id)
    const { type } = item[G.PROPS]
    return type === 'refs'
           && item[G.CHILDREN]
           && item[G.STATE][G.BULK]
           && (!id || !ids.includes(id))
      ? ids.push(id) && acc.concat(sequenceModelFlattenFn(item)(ids))
      : acc
  }, [obj])
}

/**
 * Returns a new array containing {@param obj} and all ref children with save state set to true,
 * recursively and reversed.
 *
 * @param {Gaia.Model.Spec} obj the model object composition to flatten
 * @return {Gaia.Model.Spec[]}  an array containing {@param obj} and all children, recursively
 */
const flatten = obj => pipe(
  cancelOnError(descriptor),
  setModelId,
  sequenceModelFlattenFn(obj),
  items => items.reverse(),
)(obj)

export default flatten
