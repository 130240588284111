/* global G */

/**
 * Update UI Hook.
 *
 * Uses native UI adapter to execute update (module action) sequence.
 *
 * @param {Gaia.AppModule.Spec} obj - app module
 * @return {function(...[*]): *[]}
 */
const updateUI = obj => async (...args) => {
  await obj[G.ADAPTER][G.UI].update(obj)
  return args
}


export default updateUI
