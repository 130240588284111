/* global G */
import { sequenceModalModeOn } from '@gaia/sequence/app/session/state/modal'

/**
 * Effect Force App Modal
 *
 * sets upcoming action's ui flag, modal, to true
 * this would be used in the G.UI adapter, in order to delegate the action ui imperatively to modal
 *
 *
 * @param {Gaia.Web.Application} obj - native application composition
 * @return {function(...[*]): *[]}
 */
const forceAppModal = obj => (route, ...args) => {
  try {
    sequenceModalModeOn(obj[G.SESSION][G.STATE][G.MODULE])()
  } catch (e) {
    console.warn('ok, no route', obj[G.SESSION][G.STATE][G.MODULE]._name, e.message)
  }
  console.groupEnd()

  return [route, ...args]
}

export default forceAppModal
