/* global G, React */
let handler

const _events = ['contextmenu', 'popstate', 'click']

const closePortal = component => () => {
  component.setState(
    { ...component.state, children: [], open: false },
    () => _events.map(
      event => window.removeEventListener(event, handler),
    ),
  )
}

const fn = () => _events.map(
  event => window.addEventListener(event, handler, { once: true }),
)

const portal = app => (content) => {
  const children = content.map(({ item, props }) => React
    .cloneElement(
      item[G.REF],
      props,
    ))

  const component = app[G.REF].getPortal()

  handler = closePortal(component)

  component.setState({
    ...component.state,
    children,
    open: true,
  }, fn)
}

export default portal
