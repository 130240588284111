/* global React */
import {
  useRef,
  useContext, // useCallback, useEffect, useRef, useState,
} from 'react'
import ApplicationContext from '@platform/react/context/application'
import PlatformEvent from 'lib/util/event'

/**
 * HoC for reference based subscriptions
 *
 * @param Component
 * @return {function(*, *)}
 * @constructor
 */
const RefHOC = Component => (props, ref) => {
  const { socket: { ref: socket } } = useContext(ApplicationContext)
  const { documentType } = props
  const socketKey = useRef()
  React.useLayoutEffect(() => {
    const customEvent = new CustomEvent('load', {})
    const event = new PlatformEvent(customEvent)
    props.events.onOpen(event)
    const parentRef = props.events.onSubscription()
    socketKey.current = documentType ? `${parentRef}_${documentType}` : parentRef
    const listener = props.events.onPublication
    socket.sub(socketKey.current, () => {
      socket.on(socketKey.current, listener)
    })
    return () => socket.unsub(socketKey.current, () => {
      socket.off(socketKey.current, listener)
    })
  }, [])

  return (
    <Component
      {...props}
      ref={ref}
    />
  )
}

export default RefHOC
