/* global G */
import { getFirstItem } from 'lib/util'

/**
 * Creates the userSettings document
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {Object} options            options for the post
 * @param {Object} options.user       the user document
 * @param {Object} options.api        api options
 * @returns {Promise<*>}
 * @private
 */
const _create = async (app, options) => {
  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const { user, api: { version, type } } = options

  const url = `/api/v${version}/${type}`

  const params = {
    value: {
      status: 50,
      favorites: [],
    },
    refs: {
      submitter: [user.key],
    },
  }

  return getFirstItem(await httpAdapter[G.API].post({ url, params }))
}

/**
 * Updates the userSettings document
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {Object} options            options for the post
 * @param {Object} options._rev       _rev of the user
 * @param {Object} options.key        key of the user
 * @param {Object} options.data       data to submit
 * @param {Object} options.api        api options
 * @returns {Promise<*>}
 * @private
 */
const _update = async (app, options) => {
  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const { api: { version, type }, _rev, key, data } = options

  const params = {
    _rev,
    value: data.value,
    refs: data.refs,
  }

  const url = `/api/v${version}/${type}/${key}`

  return getFirstItem(await httpAdapter[G.API].put({ url, params }))
}

export {
  _create as default,
  _update,
}
