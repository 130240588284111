/* eslint-disable no-param-reassign */
/* global G */
import { asyncpipe, curry, deleteKey, setKey } from 'lib/util'
import { seqModuleInit, seqModuleAction } from 'lib/sequence/app/module'
import seqModuleModel from 'lib/sequence/app/module/model'
import { validate } from 'lib/sequence/app/session/cookie'
import seqSetInitialRoute from 'lib/sequence/app/init/route'
import cartInit from 'lib/sequence/app/init/cart'
import settingsInit from 'lib/sequence/app/init/settings'
import pluginsInit from 'lib/sequence/app/init/plugin'
import notificationsInit from 'lib/sequence/app/init/notifications'
import contextInit from 'lib/sequence/app/init/context'
import seqInitAcl from 'lib/sequence/app/acl/init'
import seqConnectPubSub from 'lib/sequence/app/pubsub/connect'
import impersonationInit from 'lib/sequence/app/init/impersonation'
import initSyncFactoriesFn from 'lib/factory/sync'
import syncLoadersObject from 'lib/loader'
import configProviderFn from 'lib/provider/config'
import contentProviderFn from 'lib/provider/content'

/**
 * If the current session's state contains the limit flag, displays a notification informing the
 * user that they should log out before attempting to go to a pre-login route with an active
 * session. It removes the limit flag afterward.
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(*): *}
 */
const showInitializationAlert = obj => (args) => {
  const sessionState = obj[G.SESSION][G.STATE]
  const eventBus = obj[G.ADAPTER][G.EVENTS]

  sessionState[G.LIMIT] && eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.context.title',
      {
        ns: 'common',
        _key: 'dialog.error.context.title',
        defaultValue: 'Invalid context',
      },
    ),
    text: obj[G.ADAPTER][G.INTL]._t(
      'dialog.error.context.text',
      {
        ns: 'common',
        _key: 'dialog.error.context.text',
        defaultValue: 'Please, log out first.',
      },
    ),
    children: {
      ok: {
        key: 'ok',
        value: obj[G.ADAPTER][G.INTL]._t(
          'button.ok',
          {
            ns: 'common',
            _key: 'button.ok',
            defaultValue: 'Ok',
          },
        ),
      },
    },
  })

  deleteKey(G.LIMIT, sessionState)

  return args
}

/**
 * Executes Module Init Sequence
 *
 * @param {Gaia.Web.Application} obj - native application
 * @param {*} args - optional arguments
 * @return {function([*]): *}
 */
const moduleInit = curry(async (obj, route, args) => await seqModuleInit(obj)(route, args))

/**
 * Renders Current Module's View
 *
 * Uses session manager to get current session state
 * Uses platform ui adapter to do initial content render into browser
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(*=): *}
 */
const renderModuleInstance = obj => async (args) => {
  const { [G.MODULE]: moduleInstance } = obj[G.SESSION][G.STATE]
  await obj[G.ADAPTER][G.UI][G.API].render(moduleInstance)
  return args
}

/**
 * Sets the application language according to the user's session data.
 *
 * @param {Gaia.Web.Application} obj - native application
 * @returns {function(*): Promise<*>}
 */
const setSessionLanguage = obj => async (args) => {
  const userSession = obj[G.SESSION][G.STATE][G.META]
  const { lang } = userSession?.refs?.person?.[0]?.value || {}
  lang && await obj[G.ADAPTER][G.INTL].set(lang)
  return args
}

/**
 * Sets the application's title by obtaining the provider and name app translations.
 *
 * @param {Gaia.Web.Application} obj - native application
 * @returns {(function(*): Promise<*>)|*}
 */
const setApplicationTitle = obj => async (args) => {
  const providerKey = 'app.provider'
  const nameKey = 'app.name'
  const ns = 'custom'

  const providerOptions = { _key: providerKey, ns, defaultValue: 'ApproLogic' }
  const nameOptions = { _key: nameKey, ns, defaultValue: 'Service Pacemaker' }

  const provider = await obj[G.ADAPTER][G.INTL]._t(providerKey, providerOptions)
  const name = await obj[G.ADAPTER][G.INTL]._t(nameKey, nameOptions)

  obj[G.ADAPTER][G.UI][G.API].title(`${provider} | ${name}`)
  return args
}

/**
 * Executes Module ACTION Init Sequence
 *
 * Uses session manager to get current session state
 *
 * @param {Gaia.Web.Application} obj - native application
 * @param {*} args - optional arguments
 * @return {function([*]): *}
 */
const actionInit = curry(async (obj, route, args) => await seqModuleAction(obj)(route, args))

/**
 * Initializes the application's config and content providers with the platform-dependent adapters
 * set during the bootstrapping phase.
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(*): *}
 */
const providersInit = obj => (args) => {
  const factoryProviders = {
    model: obj[G.ADAPTER],
    module: obj[G.ADAPTER],
  }

  /** @type {Gaia.SyncFactories} */
  const factory = initSyncFactoriesFn(factoryProviders)(syncLoadersObject)

  /**
   * @typedef {Object} Gaia.Web.Providers
   * @property {(function(string): (Gaia.AppModule|Gaia.Module))} config - configuration provider
   * @property {Gaia.AppModule} content - content provider
   */
  obj[G.PROVIDER] = {
    config: configProviderFn(factory),
    content: contentProviderFn(factory),
  }

  return args
}

// todo: asyncPipeSpread() WONT WORK WITH methods wrapped in curry() | asyncCurry(),
//  as it cannot differentiate between curried parameters, and rest spread parameters(...args)
/**
 * Sequence Native Application Initialisation
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(*=): *}
 */
const sequenceAppInit = obj => asyncpipe(
  providersInit(obj),
  async (args) => {
    setKey(await obj[G.ADAPTER][G.INTL](), G.INTL, obj[G.ADAPTER])
    return args
  },
  setApplicationTitle(obj),
  validate(obj), // todo: 401 redirect to login page
  setSessionLanguage(obj),
  contextInit(obj),
  seqSetInitialRoute(obj),
  pluginsInit(obj),
  cartInit(obj),
  seqInitAcl(obj),
  settingsInit(obj),
  // conditionally initializing pub-sub only if there is session data
  (args) => {
    const meta = obj[G.SESSION][G.STATE][G.META]
    // TODO: Refactor after SP-957
    return meta && meta.role.hasMessages
      ? seqConnectPubSub(obj)(args)
      : args
  },
  async (args) => {
    const [route, rest] = args
    await asyncpipe(
      moduleInit(obj, route[G.MODULE]),
      seqModuleModel(obj, route[G.REF]),
      renderModuleInstance(obj),
      impersonationInit(obj),
      actionInit(obj, route[G.ACTION]),
    )(args)
    return rest
  },
  // Needs to come after module initialization because we need the {@link NotificationMenu}
  // component to have rendered already, so that emitting the G.INIT (and thus G.DONE afterward)
  // can be listened to by the component
  notificationsInit(obj),
  showInitializationAlert(obj),
)

export default sequenceAppInit
