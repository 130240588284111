/* global G */
import { curry, setKey } from 'lib/util'

/**
 * Resets PREV flag in adapter session state
 *
 * PREV, NEXT, CURRENT namespaces are used for navigation stack
 *
 * @param {Gaia.AppModule.Spec} obj - module composition
 * @param {*} args - arguments
 * @return {function(*=): *}
 */
const sequenceAppSessionStateNavigationStackFn = (obj, args) => setKey(
  [],
  G.PREV,
  obj[G.ADAPTER][G.SESSION][G.STATE],
) && args

const sequenceAppSessionStateNavigationStack = curry(sequenceAppSessionStateNavigationStackFn)

export default sequenceAppSessionStateNavigationStack
