/* global React */

const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 *
 * source: https://bit.dev/mui-org/material-ui/internal/use-event-callback/~code
 *
 * @param {function} fn
 */
export default function useEventCallback(fn) {
  const ref = React.useRef(fn)
  useEnhancedEffect(() => {
    ref.current = fn
  });
  return React.useCallback((...args) => (0, ref.current)(...args), [])
}
