/* global G */

/**
 * Sets the adapters local {@code G.DATA} and {G.STATE}
 * to the incoming {@code settings}.
 *
 * Dispatches {@code G.SETTINGS, G.DONE} event afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {(function({detail?: {}}): Promise<void>)|*}
 */
const fn = app => async ({ detail = {} }) => {
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapter = app[G.ADAPTER][G.SETTINGS]
  const sessionAdapter = app[G.ADAPTER][G.SESSION]

  const { [G.DATA]: data = {} } = detail

  const {
    key = null,
    _rev = null,
    value = null,
    refs = null,
  } = data

  key && (adapter[G.STATE].key = key)
  _rev && (adapter[G.STATE]._rev = _rev)
  refs && (adapter[G.STATE].refs = refs)

  if (value) {
    adapter[G.DATA] = value
    sessionAdapter[G.STATE][G.SETTINGS] = value
  }

  eventBus.dispatch(eventBus.type(G.SETTINGS, G.DONE))
}

export default fn
