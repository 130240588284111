import hasNamespace from './Namespace'

const traitCollection = {
  hasNamespace,
}

export {
  hasNamespace,
  traitCollection as default,
}
