/* eslint-disable no-param-reassign */
/* global G */
import { createRef, createElement, forwardRef } from 'react'
import { curry, translationsMap } from '@gaia/util'

/**
 * List of props that should not be wrapped by ReactMarkdown
 *
 * @type {string[]}
 * @private
 */
const _pureProps = [
  'placeholder',
]

/**
 * @memberOf Gaia.Component#
 * @namespace DefaultProps
 * @type {Object}
 * @property {React.Ref} ref - default React implementation
 */
/**
 * @memberOf Gaia.Component#
 * @namespace OptionsProps
 * @type {Object}
 * @property {string|number} [key] - key as per requirement of React Children definition
 */

/**
 * React Component Default Props
 *
 * @return {Gaia.Component.DefaultProps} default props
 * @private
 */
const _defaultProps = () => {
  const viewRef = createRef()
  return {
    ref: viewRef,
  }
}

/**
 * Random Key Generator
 *
 * Uses native JS Math in order to compute random integer values.
 *
 * @return {number}
 * @private
 */
const _randomKey = () => Math.abs(Math.round(Math.random() * 1000))

/**
 *  Options to Props Converter.
 *
 * It returns Component configuration options, extended by default properties such as ref,
 * as React component properties object.
 *
 * @param {Gaia.Web.Application} app - native application
 * @return {function(*): {DefaultProps|OptionsProps}} props
 * @private
 */
const _optionsToProps = app => (item) => {
  // console.log('what is my intl path',
  //   app[G.ADAPTER][G.INTL],
  //   item[G.PROPS].key,
  //   item[G.CONFIGURATION].t,
  //   item[G.PROPS][G.ROUTE],
  // )
  const { subscriptions } = item[G.CONFIGURATION]
  const subscription = subscriptions && {
    [G.PROPS]: [...subscriptions],
    [G.EVENTS]: app[G.ADAPTER][G.EVENTS],
  }

  const options = item[G.PROPS]

  /**
   * Checking if we have a pure prop ({@link _pureProps}). If so, set a flag so that the intlAdapter
   * doesn't wrap it in a markdown component.
   */
  Object.keys(options).forEach((option) => {
    _pureProps.includes(option)
    && item[G.CONFIGURATION].t[option]
    && (item[G.CONFIGURATION].t[option].options.pure = true)
  })

  const key = (options && options.key) || _randomKey()
  const props = {
    ...options,
    ...item[G.STATE],
    events: item[G.EVENTS],
    key,
    ..._defaultProps(),
    // 'data-test': `${item._name}_${key}`,
    subscription,
  }
  // todo: device better identification method for bdd e2e suite
  props['data-test'] = `_${key}`

  item[G.CONFIGURATION].t && app[G.ADAPTER][G.INTL].ui(
    item[G.PROPS][G.ROUTE],
    item[G.CONFIGURATION].t,
    item[G.STATE].token || {},
    props,
  )

  // translating itemProps cells. Not sure if that belongs here
  options.structure && options.structure.filter(cell => cell.t).reduce((views, cell) => {
    const viewParts = cell.view.split('/')
    const viewName = viewParts[viewParts.length - 1]
    const viewKey = viewName && (viewName.charAt(0).toLowerCase() + viewName.substring(1))
    // keeping counters of found keys in case they repeat
    views[viewKey] = views[viewKey] >= 0 ? views[viewKey] + 1 : 0
    // if a key is repeated, we append its counter
    const routeKey = views[viewKey] ? viewKey + views[viewKey] : viewKey
    const route = viewName && [...item[G.PROPS][G.ROUTE], 'table', routeKey]
    route && app[G.ADAPTER][G.INTL].ui(route, cell.t, item[G.STATE].token || {}, cell.options)
    return views
  }, {})

  return props
}

/**
 * React Element Creator
 *
 * @param {Gaia.Web.Providers.EventBus} app
 * @return {function(Gaia.Component): React.ReactNode}
 * @private
 */
export const _create = app => (item) => {
  const mutation = item
  const referencedUI = forwardRef(item[G.VIEW])
  const props = _optionsToProps(app)(item)
  referencedUI.displayName = mutation._name

  if (item[G.ACTIONS]) {
    props.actions = item[G.ACTIONS].map(_create(app))
  }

  mutation[G.REF] = createElement(
    referencedUI,
    props,
    item[G.CHILDREN] && item[G.CHILDREN].map(_create(app)),
  )
  return mutation[G.REF]
}

/**
 * Checks if content target is modal
 *
 * @param {boolean} initialValue
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @return {*}
 * @private
 */
export const _isModal = (initialValue, app) => app[G.SESSION][G.STATE][G.MODAL] || initialValue

/**
 * Checks if content target is drawer
 *
 * @param {boolean} initialValue
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @return {*}
 * @private
 */
export const _isDrawer = (initialValue, app) => app[G.SESSION][G.STATE][G.DRAWER] || initialValue

/**
 * Checks if translations should be context specific.
 * Uses i18next's context feature:
 * https://www.i18next.com/translation-function/context
 *
 * @param action
 * @returns {boolean}
 * @private
 */
export const _isContextSpecific = action => action[G.UI].context || false

/**
 * Returns the context of the current user
 *
 * @param app
 * @returns {*}
 * @private
 */
export const _context = app => app[G.SESSION][G.STATE][G.CONTEXT]

/**
 * Translates the topbar's page title.
 *
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @param {Gaia.AppModule.Action} action
 * @private
 */
const _translateUITitle = (app, obj, action) => {
  const { module } = obj[G.CONFIGURATION]
  const ns = translationsMap[module] || module
  const _key = `title.${module}.${action._name}`
  const defaultValue = action[G.UI].title
  const context = _context(app)

  defaultValue
    && (action[G.UI].title = defaultValue
      ? app[G.ADAPTER][G.INTL]._t(_key, { _key, ns, context, defaultValue, ...obj[G.STATE].token })
      : defaultValue)

  action[G.DATA].title = action[G.DATA].title || { _cache: action[G.UI].title }
}

/**
 * Translates the topbar's search field placeholder text.
 *
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @param {Gaia.AppModule.Action} action
 * @private
 */
const _translateUISearch = (app, obj, action) => {
  const { module } = obj[G.CONFIGURATION]
  const ns = translationsMap[module] || module
  const actionKey = action[G.UI]?.search?.key || null
  const _key = actionKey ? `search.${actionKey}` : `search.${module}`
  const defaultValue = action[G.UI].search?.placeholder
  const context = _context(app)

  defaultValue
    && (action[G.UI].search.placeholder = defaultValue
      ? app[G.ADAPTER][G.INTL]._t(_key, { _key, ns, context, defaultValue })
      : defaultValue)
}

/**
 * Uses the intl manager to translate the action title and the search placeholder.
 *
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @param {Gaia.AppModule.Action} action
 * @private
 */
const _translateUI = (app, obj, action) => {
  _translateUITitle(app, obj, action)
  _translateUISearch(app, obj, action)
}

/**
 * Create Content for rendering inside App REF.
 *
 * @param rootGetter
 * @param {Gaia.Web} app - {@link Gaia.Web}
 * @param {Gaia.AppModule.Spec} obj - {@link Gaia.AppModule.Spec}
 * @return {Gaia.AppModule.Spec} fire and forget
 */
const createFn = (rootGetter, app, obj) => {
  const { [G.ACTION]: action } = obj[G.STATE]
  const actionToRender = _create(app)(action[G.COMPONENT])
  const modal = _isModal(action[G.UI].modal, app)
  const drawer = _isDrawer(action[G.UI].drawer, app)

  _translateUI(app, obj, action)

  // see layout[name].jsx for more info in regard to get[Name]() methods
  const parentComponent = (modal && app[G.REF].getDialog())
                          || (drawer && app[G.REF].getDrawer())
                          || app[G.REF].getContent()
  const state = modal || drawer ? {
    open: true,
    title: action[G.UI].title || '',
    children: [actionToRender],
  } : {
    scroll: true,
    ...action[G.UI].content || {},
    fullScreen: action[G.UI].fullScreen,
    appBar: action[G.UI].appBar,
    children: [actionToRender],
  }
  parentComponent.setState(state)

  return obj
}

export default curry(createFn)
