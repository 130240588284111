/* global G */
import { curry } from 'lib/util'

const sequenceResetAclFn = async (obj, args) => {
  obj[G.ADAPTER][G.EVENTS].dispatch(obj[G.ADAPTER][G.EVENTS].type(G.ACL, G.DELETE))
  return args
}

const sequenceResetAcl = curry(sequenceResetAclFn)

export default sequenceResetAcl
