/* global G */
import validateSequence from 'lib/sequence/model/validate'

const descriptor = Symbol('hook::modelValidate').toString()

/**
 * Model Validate Hook.
 *
 * Executes attribute validators, based on visibility, provided by the passed component.
 *
 *
 * @param {Gaia.AppModule.Spec} obj - app module composition
 * @return {function(...[*]): *[]}
 */
const modelValidate = obj => async (...args) => {
  try {
    await validateSequence(obj[G.MODEL])(obj[G.STATE][G.ACTION][G.COMPONENT])
  } catch (e) {
    throw Error(`${obj._name} ${descriptor} - ${e.message}`)
  }
  return args
}

export default modelValidate
