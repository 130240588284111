/* eslint-disable no-unused-vars */
/* global G */

import { getFirstItem } from 'lib/util'

/**
 * Deletes a single notification and updates the list.
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function({detail: *}): Promise<*|null>)|*}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.STATE]: groupState, [G.DATA]: groupData } = group
  const { [G.API]: { api, types, version } = {} } = groupState
  const { id } = detail

  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const url = `/api/v${version}/${api}/${types.setDeleted}`
  const params = { id }

  try {
    const result = await httpAdapter[G.API].put({ url, params })
    const data = getFirstItem(result)

    if (data.ok) {
      const updatedItemIndex = groupData.data.findIndex(notification => notification.id === data.id)

      const updatedItem = groupData.data.at(updatedItemIndex)
      if (!updatedItem?.value?.read?.length) {
        groupData?.count > 1 && (groupData.count -= 1)
      }

      groupData.data.splice(updatedItemIndex, 1)
      groupData.update = {}
    }

    eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.CREATE, groupName), { [G.DATA]: { count: groupData.length } })
  } catch (e) {
    console.error(e)
  }

  return null
}

export default fn
