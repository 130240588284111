/* global G */
import { pipe } from 'lib/util'
import { withDescriptor, withObjectFreeze } from 'trait/with'
import { withGetterSetterFn } from '@gaia/trait/with/GetterSetter'
import { hasNamespace } from 'trait/has'
import { usesNamespace, usesGetterSetter } from 'trait/uses'
import api from './api'

const descriptor = 'adapter:Attachment'

/**
 * Native Web Platform Note Adapter
 *
 * @memberOf Gaia.Adapter#
 * @typedef Adapter.Note
 *
 * @property {String} _name - module descriptor. see {@link withDescriptor}
 * @property {object[]} G.DATA - accumulator of notes
 * @property {object} G.API - adapter api
 * @property {Adapter.Note.API} API - adapter api revised
 *
 * @param {Gaia.Web.Application} obj - web application
 * @return {Gaia.Adapter.Note} composition - Native Platform Web Adapter
 */
const adapterFn = (obj) => {
  const adapter = pipe(
    withDescriptor(descriptor),
    withGetterSetterFn(G.DATA),
    usesGetterSetter(G.DATA, []),
    hasNamespace(G.API),
    usesNamespace(G.API, api(obj)),
    withObjectFreeze,
  )({})

  /**
   * @type {Gaia.Adapter.EventBus}
   */
  const eventBus = obj[G.EVENTS]

  const defaults = {
    set: G.DATA,
    create: G.CREATE,
    update: G.UPDATE,
    delete: G.DELETE,
  }

  Object.keys(defaults).reduce(
    (acc, key) => eventBus.add(eventBus.type(G.NOTE, acc[key]), adapter[G.API][key]) || acc,
    defaults,
  )

  return adapter
}

export default adapterFn
