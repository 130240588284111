/* global G */
import { curry } from 'lib/util'

const sequenceConnectPubSubFn = (obj, args) => {
  obj[G.ADAPTER][G.EVENTS].dispatch(
    obj[G.ADAPTER][G.EVENTS].type(G.DATA, G.INIT),
    {
      [G.DATA]: obj[G.ADAPTER]?.[G.SESSION]
        ? obj[G.ADAPTER][G.SESSION][G.STATE][G.META].refs?.user?.[0]
        : obj[G.SESSION][G.STATE][G.META].refs?.user?.[0],
    },
  )
  return args
}

const sequenceConnectPubSub = curry(sequenceConnectPubSubFn)

export default sequenceConnectPubSub
