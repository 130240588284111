/* global G */

/**
 * Displays a confirm dialog
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {Promise<unknown>}
 * @private
 */
const _confirmDialog = async app => await new Promise((resolve) => {
  const { [G.ADAPTER]: { [G.EVENTS]: eventBus, [G.INTL]: intl } } = app

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: intl.translate(
      'dialog.edit.title',
      {
        ns: 'cart',
        _key: 'dialog.edit.title',
        defaultValue: 'Edit order',
      },
    ),
    text: intl.translate(
      'dialog.edit.text',
      {
        ns: 'cart',
        _key: 'dialog.edit.text',
        defaultValue: 'Are you sure you want to edit selected Purchase Request?\nYour current active cart will be transferred to draft mode, and a new one will be created from the selected order. You can always access any cart, your data will not be lost.',
      },
    ),
    children: {
      cancel: {
        key: 'cancel',
        value: intl.translate(
          'button.cancel',
          {
            ns: 'common',
            _key: 'button.cancel',
            defaultValue: 'Cancel',
          },
        ),
      },
      ok: {
        key: 'ok',
        variant: 'contained',
        value: intl.translate(
          'button.editPurchaseRequest',
          {
            ns: 'cart',
            _key: 'button.editPurchaseRequest',
            defaultValue: 'Edit purchase request',
          },
        ),
      },
    },
    okHandler: () => { resolve(true) },
    cancelHandler: () => { resolve(false) },
  })
})

/**
 * Sets the users active cart (if present) to draft status and sets the incoming cart to
 * active status.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail = {} }) => {
  const adapter = app[G.ADAPTER][G.CART]
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const activeCart = adapter[G.DATA]

  const { [G.API]: { type, version } = {} } = adapter[G.STATE] || {}

  const oldKey = activeCart?.key

  const confirmed = !oldKey ? true : await _confirmDialog(app)

  const { newCart } = detail[G.DATA]

  try {
    if (confirmed) {
      const url = `/api/v${version}/${type}/${oldKey}`

      oldKey && await httpAdapter[G.API].put({ url,
        params: {
          _rev: activeCart._rev,
          refs: activeCart.refs,
          value: {
            ...activeCart.value,
            status: 0,
          },
        } })

      eventBus.dispatch(eventBus.type(G.CART, G.SET), { [G.DATA]: {
        key: newCart.key,
        _rev: newCart._rev,
        refs: newCart.refs,
        value: {
          ...newCart.value,
          status: 50,
        },
      } })
    }
  } catch (e) {
    console.error(e)
  }
}

export default fn
