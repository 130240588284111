/* global G */
import { get, active } from 'lib/manager/acl/helper'
import ACLError from 'lib/manager/acl/error'

/**
 * used in acl hooks
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(Gaia.Model.Spec): *}
 */
const fn = obj => (model, acls) => {
  if (!active(obj)) {
    return true
  }
  try {
    get(obj)[G.API].check(
      model,
      acls,
    )
  } catch (e) {
    throw new ACLError('action insufficient rights')
  }
  return true
}

export default fn
