/* global G */
import { asyncPipeSpread, isObj, setKey } from 'lib/util'
import { actionWithHooks } from 'lib/sequence/module/action'
import { withDependencyCheck } from 'lib/trait/with'

const descriptor = 'sequence::app::module::action'

/**
 * Clones {@param cache} and its properties so that their references are broken, consequently
 * allowing properties of {@param cache} to be modified without modifying the returned object.
 *
 * @param {object} cache  a cache object to clone so that it can be used as the action's data
 * @returns {object}
 */
const cloneCache = cache => Object.keys(cache).reduce((acc, key) => {
  const { _cache: itemCache, ...item } = cache[key]
  const _cache = itemCache && (isObj(itemCache)
    ? { ...itemCache, props: { ...itemCache.props } }
    : itemCache)
  acc[key] = { ...cloneCache(item), ..._cache && { _cache } }
  return acc
}, {})

/**
 * Reset Application Session State Action Parameter
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(...[*]): *}
 */
const resetSessionStateAction = obj => (...args) => {
  const state = obj[G.SESSION][G.STATE]
  delete state[G.ACTION]
  return args
}

/**
 * Preliminary Check for sequence required attributes.
 *
 * @param obj - model object composition
 * @return {*}
 */
const checkDeps = obj => (...args) => {
  withDependencyCheck(`${descriptor} module`, [G.MODULE], obj[G.SESSION][G.STATE])
  return args
}

/**
 * User ACL check.
 *
 * Example of how we can chain different sequences.
 *
 * todo: does nothing atm, replace with seqAclCheck()
 *
 * @return {function(...[*]): *[]}
 */
const userAccess = () => (...args) => args

/**
 * Set Session State Module Action parameter.
 *
 * @param {Gaia.Web.Application} obj - native application
 * @param {*} args - optional arguments
 * @return {function(...[*]): *}
 */
const setSessionStateModuleAction = obj => async (action, ...args) => {
  const state = obj[G.SESSION][G.STATE]
  state[G.ACTION] = state[G.MODULE][G.ACTIONS][action] && action
  return args
}

/**
 * Set Module State Action parameter.
 *
 * @param {Gaia.Web.Application} obj - native application
 * @param {*} args - optional arguments
 * @return {function(...[*]): *}
 */
const setModuleStateAction = obj => async (...args) => {
  const state = obj[G.SESSION][G.STATE]
  const { [G.MODULE]: moduleInstance, [G.ACTION]: moduleAction } = state
  // moduleInstance[G.STATE].moduleAction = moduleAction
  // moduleInstance[G.STATE].action = moduleInstance[G.ACTIONS][moduleAction]
  moduleInstance[G.STATE][G.ACTION] = moduleInstance[G.ACTIONS][moduleAction]
  // console.log('app module action:: setModuleStateAction', moduleInstance[G.STATE][G.ACTION])
  // console.log('app module action:: setModuleStateAction', state)
  return args
}

/**
 * Assigns the key parameter of the first args object as the current model ref.
 *
 * @param {Gaia.Web.Application} obj - native application
 * @param {*} args - optional arguments
 * @returns {function(...[*]): Promise<*[]>}
 */
const setModuleModelRef = obj => async (...args) => {
  const model = obj[G.SESSION][G.STATE][G.MODULE][G.MODEL]
  const state = model && model[G.STATE]
  const { key } = args[0] || {}
  key && state && setKey(key, G.REF, state)
  return args
}

/**
 * Sets next route cache as the current action's cache.
 *
 * It also shallow-copies action's cache to action's data so that the previously cached values are
 * cached again the next time the route changes. The user can, however, change these values in the
 * same way as they were first added, so they act only as the default for when the user navigates
 * back.
 *
 * @param {Gaia.Web.Application} obj - native application
 * @returns {function({}=, ...[*]): Promise<*[]>}
 */
const loadActionCache = obj => async (...args) => {
  const action = obj[G.SESSION][G.STATE][G.MODULE][G.STATE][G.ACTION]
  const entry = obj[G.STATE][G.NEXT]
  const cache = entry?.[G.CACHE] || {}
  const data = cloneCache(cache)
  setKey(cache, G.CACHE, action)
  setKey(data, G.DATA, action)
  return args
}

/**
 * Executes Module Sequence actionWithHooks()
 *
 * @param {Gaia.Web.Application} obj - native application
 * @return {function(...[*]): Promise<*>}
 */
const executeActionWithHooks = obj => async (...args) => await actionWithHooks(
  obj[G.SESSION][G.STATE][G.MODULE][G.STATE][G.ACTION],
)(...args)

export default obj => asyncPipeSpread(
  resetSessionStateAction(obj),
  checkDeps(obj),
  userAccess(),
  setSessionStateModuleAction(obj),
  setModuleStateAction(obj),
  setModuleModelRef(obj),
  loadActionCache(obj),
  executeActionWithHooks(obj),
)
