/* global G */
import { curry } from 'lib/util'

const symbol = '_name'
/**
 * Set Descriptor
 *
 * adds descriptor to the object composition
 *
 * can be replaced with a noop during production
 *
 * @example
 * import { withDescriptor } from 'trait/with'
 *
 * const descriptor = Symbol('someDescriptor')
 *
 * const composition = pipe(
 *  withDescriptor(descriptor),
 *  ...
*  )
 *
 *
 * @param {Symbol|String} descriptor - object property descriptor
 * @param {Object} obj - composition
 * @return {Object} obj - composition
 */
const setDescriptor = (descriptor, obj) => {
  Object.defineProperty(obj, symbol, {
    // value: descriptor,
    // writable: false, // todo: GAIA-415 (accessors issue)
    get() { // todo: GAIA-415
      // console.log(descriptor, obj, obj[G.CONFIGURATION])
      const value = obj[G.PROPS] && obj[G.PROPS].key
      // requirement for model children' submodel naming convention, use key instead of model descriptor
      // const value = obj[G.CONFIGURATION].model && obj[G.PROPS] && obj[G.PROPS].key
      return value || descriptor
    },
    enumerable: true,
  })
  return obj
}

const withDescriptor = (descriptor, obj) => setDescriptor(descriptor, obj)

export default curry(withDescriptor)
