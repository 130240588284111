import { curry } from 'lib/util'

/**
 * @memberOf Factory.List
 * @function syncListFactoryCallback
 * @param {Module.Spec} configuration - module configuration
 * @return {Object} object containing lazy view and module instances
 */

/**
 * List Factory
 *
 * Factory that is used for list items template initialisation.
 * It returns a lazy module instance and a lazy view component instance
 *
 * @memberOf Gaia.Factory#
 * @typedef {function(*=)} Factory.List
 * @argument {Gaia.requireLoaders} loaders - {@link Gaia.requireLoaders}
 * @return {syncListFactoryCallback} function - {@link syncListFactoryCallback}
 */
export const listFactoryFn = (loaders, configuration) => {
  try {
    const module = loaders.module(configuration.module)
    const view = loaders.ui(configuration.view)
    return {
      module,
      view,
    }
  } catch (error) {
    throw ReferenceError(`listFactory: ${error.message}`)
  }
}

export default curry(listFactoryFn)
