/* eslint-disable no-nested-ternary, no-undefined, arrow-body-style */
import { useMemo, createRef } from 'react'
import { isFn, isArr } from '@gaia/util'

/**
 * Hook useMemoRef
 *
 * It memoizes the passed component instance based on deps' parameter.
 * It provides ref to nested components
 *
 * deps can be:
 * - undefined, will rerender every time
 * - [], will never rerender, complete memoization
 * - false, as above, but will be cast as UNDEFINED, if passed as parameter.
 * - [attr1, [attr2, attr3]], will rerender, if provided attributes will change their values
 *
 * @example
 * // will ALWAYS rerender
 * const x = useMemoRef(SomeComponent)
 * // will ALWAYS rerender, due to GAIA specific handling
 * const x = useMemoRef(SomeComponent, false)
 * // will rerender when props are changed
 * const x = useMemoRef(SomeComponent, props => [props.prop1, props.propN])
 * // will never rerender, as long as page is not refreshed
 * const x = useMemoRef(SomeComponent, [])
 *
 * @param Component - Component to useMemo() hook
 * @param [depsFn] - dependencies function, to use for memoizing
 * @return {function(*, *): *} Component - memoized Component
 */
const useMemoRef = (Component, depsFn) => (props, ref) => {
  return useMemo(
    () => (<Component {...props} forwardedRef={ref?.current ? ref : createRef()} />),
    isFn(depsFn)
      ? depsFn(props)
      : isArr(depsFn) && depsFn
        ? depsFn
        : undefined,
  )
}

export default useMemoRef
