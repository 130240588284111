/* eslint-disable max-len */
import create from './create'
import update from './update'
import set from './set'
import remove from './delete'

/**
 * @memberOf Adapter.Note#
 * @typedef Adapter.Note.API
 *
 * Gathers methods used to manage notes.
 *
 * @property {function} set - persist remote payload in G.DATA
 * @property {function} create - remote creation
 * @property {function} update - update remote and local item
 * @property {function} delete - delete remote and local item
 *
 * @param {Gaia.Web.Application} app  Platform Web Application
 * @return {{}}
 */
const api = app => Object.create({}, {
  set: {
    value: set(app),
    iterable: true,
    enumerable: true,
  },
  create: {
    value: create(app),
    iterable: true,
    enumerable: true,
  },
  update: {
    value: update(app),
    iterable: true,
    enumerable: true,
  },
  delete: {
    value: remove(app),
    iterable: true,
    enumerable: true,
  },
})

export default api
