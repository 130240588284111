/* eslint-disable import/no-dynamic-require, global-require */
/**
 * Ui Require Loader.
 *
 * Loads native platform ui module.
 *
 * The loader respects process env parameter PLATFORM.
 *
 * Uses webpack v4 chunk mechanism
 *
 * @memberOf Gaia.requireLoaders
 * @typedef {Function} requireUiLoader
 * @param {string} path - module path
 * @return {Function} ui module
 */
export default path => require(`ui/${path}`).default
