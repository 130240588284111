/* global G */
import { pipe } from 'lib/util'
import { usesGetterSetter } from 'trait/uses'
// import { hasNamespace } from 'trait/has'
import { withGetterSetterFn } from 'trait/with/GetterSetter'
import { withDescriptor } from 'trait/with'

const descriptor = 'composition::list'

/**
 * @memberOf Gaia#
 * @typedef Gaia.Composition.List
 * @property {Array} DATA - collective data entries aggregator
 * @property {Array} NEXT - incoming data entries aggregator
 * @property {Array} PREV - previous data entries aggregator
 * @property {Array} CURRENT - current data entries aggregator
 * @property {Object} META - meta collection, stores parameters for querying, pagination, etc
 *
 */

/**
 * List Composition
 *
 * Composition consisting of attributes, containing
 * necessary information to allow iteration over multiple
 * data entries, associated with the same model.
 *
 * List should be used mainly in Component Composition
 *
 *
 * @return {Gaia.Composition.List} list composition
 */
const composition = () => pipe(
  withDescriptor(descriptor),
  withGetterSetterFn(G.DATA),
  withGetterSetterFn(G.PREV),
  withGetterSetterFn(G.NEXT),
  withGetterSetterFn(G.CURRENT),
  withGetterSetterFn(G.META),
  usesGetterSetter(G.META, {}),
  usesGetterSetter(G.DATA, []),
  usesGetterSetter(G.NEXT, []),
  usesGetterSetter(G.PREV, []),
  usesGetterSetter(G.CURRENT, []),
)({})

export default composition
