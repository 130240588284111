/* global G */

/**
 * Component Children as Object sequence
 *
 * It returns an object, consisting of children.
 *
 * As the child key should be unique on given level, no overwriting should occur
 *
 * @param obj
 * @return {Object}
 */
const sequenceComponentChildrenAsObject = obj => Object.keys(obj)
  .reduce(
    (acc, i) => ({ ...acc, [obj[i][G.PROPS].key]: obj[i] }),
    {},
  )

export default sequenceComponentChildrenAsObject
