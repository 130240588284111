/* global G */
import { curry } from 'lib/util'

/**
 * Has Namespace Keys Getter Trait.
 *
 * Expected NS context to be an array.
 *
 * Adds a protected namespace to passed object during composition.
 *
 * Adds symbol G.KEYS fn, for getting mutated ns context
 *
 * @example
 * import { pipe } from 'lib/util'
 * import hasNamespace from 'lib/trait/has/Namespace'
 * ...
 * const someComposable = obj => obj
 * const protectedNamespace = Symbol('optional description')
 *
 * return pipe(
 *    someComposable,
 *    hasNamespace(protectedNamespace),
 * )
 *
 * @param {PropertyKey} namespace - assigned namespace symbol or string
 * @param {Gaia.AppModule.Spec | Gaia.Component.Spec} obj - object to mutate
 * @return {Object} obj - mutated object
 */
const hasNamespaceWithKeysGetter = (namespace, obj) => {
  const keys = nsObj => Object
    .keys(nsObj)
    .reduce((acc, i) => ({ ...acc, [nsObj[i][G.PROPS].key]: nsObj[i] }), {})
  // const keys = objKeys => objKeys.reduce((acc, i) => ({ ...acc, [i]: i }), {})
  const ns = {
    get [G.KEYS]() {
      return keys(Object.keys(ns))
    },
  }

  Object.defineProperty(ns, namespace, {
    get() {
      return ns[namespace]
    },
  })

  Object.defineProperty(obj[G.KEYS], namespace, {
    get() {
      return ns
    },
    iterable: true,
  })

  return obj
}

export default curry(hasNamespaceWithKeysGetter)
