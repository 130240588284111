import create from '@platform/adapter/notification/api/group/alert/create'
import remove from '@platform/adapter/notification/api/group/alert/delete'
import removeAll from '@platform/adapter/notification/api/group/alert/deleteAll'
import read from '@platform/adapter/notification/api/group/alert/read'
import update from '@platform/adapter/notification/api/group/alert/update'
import updateAll from '@platform/adapter/notification/api/group/alert/updateAll'
import set from '@platform/adapter/notification/api/group/alert/set'
import show from '@platform/adapter/notification/api/group/alert/show'

const api = Object.create({}, {
  create: {
    value: create,
    iterable: true,
    enumerable: true,
  },
  read: {
    value: read,
    iterable: true,
    enumerable: true,
  },
  update: {
    value: update,
    iterable: true,
    enumerable: true,
  },
  updateAll: {
    value: updateAll,
    iterable: true,
    enumerable: true,
  },
  delete: {
    value: remove,
    iterable: true,
    enumerable: true,
  },
  deleteAll: {
    value: removeAll,
    iterable: true,
    enumerable: true,
  },
  set: {
    value: set,
    iterable: true,
    enumerable: true,
  },
  show: {
    value: show,
    iterable: true,
    enumerable: true,
  },
})

export {
  api as default,
}
