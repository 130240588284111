/* global G */

/**
 * Initializes the user settings by either setting the local adapter to the
 * incoming {@code settings} in case the user already has a userSettings
 * document or by creating a new document with calling the {@code create}
 * method.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {(function({detail?: {}}): Promise<void>)|*}
 */
const fn = app => async ({ detail = {} }) => {
  const adapter = app[G.ADAPTER][G.SETTINGS]
  const { [G.DATA]: { [G.API]: api = {}, settings = {} } = {} } = detail

  adapter[G.STATE][G.API] = api

  if (settings) {
    // User already has userSettings, lets use them
    adapter[G.API].change({ detail: { [G.DATA]: settings } })
  } else {
    // User doesn't have userSettings, lets create them
    adapter[G.API].create()
  }
}

export default fn
