/* global G */
import { setKey } from 'lib/util'

/**
 * Empties the adapter's (G.DATA) local files list and moves all files from the (G.DELETE) list of
 * remote files to be deleted to the (G.CACHE) list of remote files. Dispatches a DONE event with
 * the current local and remote ones afterwards.
 *
 * @param {Gaia.Web.Application} app  the Web platform Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => () => {
  const { [G.EVENTS]: eventBus, [G.ATTACHMENT]: adapter } = app[G.ADAPTER]
  const group = adapter[G.GROUP][groupName]
  const markedFiles = group[G.DELETE]
  const cachedFiles = group[G.CACHE]
  setKey(cachedFiles.concat(markedFiles), G.CACHE, group)
  setKey([], G.DATA, group)
  setKey([], G.DELETE, group)
  eventBus.dispatch(
    eventBus.type(G.ATTACHMENT, G.DONE, groupName),
    { [G.DATA]: group[G.DATA].concat(group[G.CACHE]), [G.STATE]: group[G.STATE] },
  )
}

export default fn
