/* eslint-disable no-unused-vars */
/* global G */

/**
 * Removes the users local cart and dispatched {@code G.CART, G.DONE} event.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail = {} }) => {
  const adapter = app[G.ADAPTER][G.CART]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  adapter[G.DATA] = null

  eventBus.dispatch(eventBus.type(G.CART, G.DONE), { [G.DATA]: null })
}

export default fn
