import React, { useEffect, useRef, useState } from 'react'
import { useStyles } from 'platform/react/hook'
import { Box, ButtonBase, Grid, useTheme } from '@mui/material'
import { PlatformEvent } from 'lib/util'
import SvgIcon from 'ui/Element/Icon/Svg'
import {
  Chat as ChatIcon,
  Download as DownloadIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from '@mui/icons-material'
import AttachmentListTemplateButton from 'ui/Component/Attachment/List/Template/Button'
import { formatFilesize } from 'lib/util/attachment'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

const styles = theme => ({
  root: {
    height: '100%',
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
  toolbar: {
    flexBasis: 'auto',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    borderRadius: '0.5rem',
    padding: '0.5rem',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  button: {
    minWidth: '2rem',
    width: '2rem',
    height: '2rem',
    backgroundColor: theme.palette.background.content,
    borderRadius: '0.25rem',
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      '& > *': {
        backgroundColor: theme.palette.border.main,
      },
    },
    '& > *': {
      margin: 0,
    },
    padding: 0,
    minHeight: 0,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  column: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
    height: '100%',
    display: 'flex',
    gap: '0.5rem',
  },
})

/**
 * Helper function to create an icon
 * @param {Object} icon       the icon
 * @param {string|null} color the color for the icon
 * @returns {Element}
 * @private
 */
const _createIcon = ({ icon, color }) => {
  const theme = useTheme()
  return (
    <SvgIcon
      width={'1.5rem'}
      height={'1.5rem'}
      icon={icon.name}
      variant={icon.variant}
      color={color || theme.palette.black.main}
    />
  )
}

/**
 * Toolbar HOC
 *
 * Wraps {@param Component} with a toolbar if {@code showToolbar} is truthy. If not, simply returns
 * {@param Component} without doing anything. The toolbar will display a download button by default
 * if the attachment is a document and not a mere url.
 *
 * Will optionally display a feedback button if {@code showFeedback} is truthy. Requires
 * {@code onFeedbackOpen} event handler to be present in {@code events}.
 *
 * @param {React.ReactNode} Component the attachment component to render
 * @returns {function({showToolbar: *, events: *, [p: string]: *}): React.JSX.Element}
 * @constructor
 */
const Toolbar = Component => ({ showToolbar, events, ...props }) => {
  const theme = useTheme()
  const titleRef = useRef()

  const [titleWidth, setTitleWidth] = useState(null)

  const { attachment, showFeedback, showFavorite } = props
  const {
    onFeedbackOpen = null,
    onFavoriteOpen = null,
    onFavoriteClick = null,
    ...restEvents
  } = events || {}

  const classes = useStyles(styles)()

  const currentAttachment = attachment?.value?.name
    ? attachment
    : { value: { name: '' } }
  const { value: item } = currentAttachment

  const handleFeedbackOpen = (e) => {
    onFeedbackOpen?.(new PlatformEvent(e, { payload: item?.documentationId || item?.key }))
  }

  const favoriteId = item?.documentationId || item?.key
  const isFavorite = onFavoriteOpen?.(new PlatformEvent('open', { value: favoriteId }))
  const favoriteEvent = e => new PlatformEvent(e, { payload: favoriteId, options: { delete: !!isFavorite, type: 'documentation' } })

  // Dynamically calculating the width based on the parents height
  const resizeHandler = () => { setTitleWidth(titleRef?.current?.clientWidth) }

  // Recalculating height if we resize the window
  useEffect(() => {
    window.addEventListener('resize', resizeHandler)
    return () => { window.removeEventListener('resize', resizeHandler) }
  }, [])

  useEffect(() => { titleRef?.current && setTitleWidth(titleRef?.current.clientWidth) }, [titleRef])

  const feedbackIcon = props?.feedbackIcon
    ? _createIcon({ icon: props.feedbackIcon })
    : <ChatIcon/>

  const favoriteIcon = props?.favoriteIcon
    ? _createIcon({ icon: props.favoriteIcon, color: 'signal.main' })
    : <FavoriteIcon/>

  const noFavoriteIcon = props?.noFavoriteIcon
    ? _createIcon({ icon: props.noFavoriteIcon })
    : <FavoriteBorderIcon/>

  const downloadIcon = props?.downloadIcon
    ? _createIcon({ icon: props.downloadIcon })
    : <DownloadIcon/>

  return !showToolbar
    ? <Component
      events={events}
      {...props}
    />
    : (
      <Grid
        item
        xs={12}
        container
        className={classes.root}
      >
        <Grid
          item
          container
          xs={12}
          className={classes.toolbar}
        >
          <Grid
            item
            sm={2}
            xs={12}
            className={classes.column}
            sx={{ justifyContent: 'flex-start' }}
          >
            {/* Optional left column options */}
          </Grid>
          <Grid
            item
            sm={8}
            xs={12}
            ref={titleRef}
            className={classes.column}
            sx={{ justifyContent: 'space-around' }}
          >
            <Box
              className={classes.title}
              sx={{ ...titleWidth && { maxWidth: titleWidth } }}
            >
              <OverflowTooltip
                variant={'14/bold'}
                sx={{ color: 'common.black' }}
              >
                {attachment?.value?.name || ''}
              </OverflowTooltip>
              {attachment.value?.size && (
                <OverflowTooltip
                  variant={'14/medium'}
                  sx={{ color: theme.palette.grey['400'] }}
                >
                  {formatFilesize(attachment.value.size)}
                </OverflowTooltip>
              )}
            </Box>
          </Grid>
          <Grid
            item
            sm={2}
            xs={12}
            className={classes.column}
            sx={{ justifyContent: 'flex-end' }}
          >
            {!item.url && (
              <AttachmentListTemplateButton
                value={null}
                attachment={attachment}
                startIcon={downloadIcon}
                className={classes.button}
              />
            )}
            {showFeedback && (
              <ButtonBase
                onClick={handleFeedbackOpen}
                className={classes.button}
              >
                {feedbackIcon}
              </ButtonBase>
            )}
            {showFavorite && (
              <ButtonBase
                onClick={e => onFavoriteClick?.(favoriteEvent(e))}
                className={classes.button}
              >
                {isFavorite ? favoriteIcon : noFavoriteIcon}
              </ButtonBase>
            )}
          </Grid>
        </Grid>
        <Component
          {...props}
          events={restEvents}
        />
      </Grid>
    )
}

export default Component => Toolbar(Component)
