import { curry } from 'lib/util'
/**
 * Error String Helper.
 *
 * @param {string} trait - descriptor
 * @param {string} information - of property
 * @return {string} error message
 */
const error = (trait, information) => `trait ${trait} requires ${information}`

/**
 * Trait Dependencies Checker.
 *
 * Checks if scoped object contains trait dependencies.
 * It throws an error if provided obj parameter is not an object or on missing dependency.
 *
 * @example
 *  import withDependencyCheck from 'lib/trait/with/DependencyCheck'
 *
 *  const trait = 'nTrait'
 *  const deps = [someSymbol, anotherSymbol]
 *
 *  const nTrait = (obj) => {
 *    ...
 *  }
 *  ...
 *  export default pipe(
 *    // we want to check for dependencies BEFORE the trait logic is executed
 *    withDependencyCheck(trait, deps),
 *    nTrait,
 *  )
 *
 * @param {string} trait - descriptor
 * @param {Array} deps - array consisting of required property symbols
 * @param {Object} obj - object to test dependencies for
 * @throws {TypeError} error
 * @returns {Object} obj - non-mutated tested object
 */
const withDependencyCheck = (trait, deps, obj) => (
  obj !== Object(obj) || obj.constructor !== Object
    ? throw TypeError(error(trait, 'an object'))
    // eslint-disable-next-line no-prototype-builtins
    : deps.forEach(dep => (obj[dep] !== Object(obj[dep]) && !obj.hasOwnProperty(dep))
      && throw TypeError(error(trait, `object property ${dep.toString()}`))) || obj
)

export default curry(withDependencyCheck)
