/**
 * Maps the name of each CSS class passed inside the classes string array with the classes declared
 * in {@param styleFn}.
 *
 * @param {string[]} decorators an array containing the names of the classes to use
 * @param {Function} styleFn    a function returning concatenated MUI style classes
 * @returns {string}            a string containing the mapped classes separated by a whitespace
 */
export default (decorators, styleFn = () => ({})) => {
  const classNames = []
  const compiledStyles = styleFn()
  decorators && decorators.reduce((acc, item) => acc.push(compiledStyles[item]) && acc, classNames)
  return classNames ? classNames.join(' ') : ''
}
