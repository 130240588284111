import seqModuleAction from './action'
import seqModuleInit from './init'

const appModuleSequences = {
  seqModuleInit,
  seqModuleAction,
}

export {
  seqModuleInit,
  seqModuleAction,
  appModuleSequences as default,
}
