/* global PUBLIC_API_ENDPOINT, PUBLIC_PING_ENDPOINT, ENV, TENANT, PLATFORM */

const API_URL = PUBLIC_API_ENDPOINT || window.location.origin
const PING_URL = `${PUBLIC_PING_ENDPOINT}?${new URLSearchParams({
  mode: ENV,
  tenant: TENANT,
  platform: PLATFORM,
})}`

export default API_URL

export {
  API_URL,
  PING_URL,
}
