/* eslint-disable object-curly-newline */
/* global G */
import settings from '@tenant/settings'
import readWorkerBody from '@platform/worker/attachment/read'
import previewWorkerBody from '@platform/worker/attachment/preview'
import { createWorker, terminate, isRemote, getReadUrl } from '@platform/adapter/attachment/helper'

/**
 * MessageEvent Listener function executed on #postMessage from inside the {@link Worker}.
 *
 * @param {Object} worker                       worker instance
 * @param {Gaia.Web.Application} app            the Web platform Application
 * @param {string} group                        the attachment group identifier
 * @param {Object} item
 * @param {string} item.name
 * @returns {((MessageEvent) => Promise<void>)} the MessageEvent listener
 */
export const onMessage = (worker, source, app, group, { name }, persist) => async (evt) => {
  const { blob, key, url, ...rest } = evt.data
  const storage = app[G.ADAPTER][G.PERSISTENCE][G.API]
  const bus = app[G.ADAPTER][G.EVENTS]

  const type = blob ? G.DONE : G.READ
  const objectUrl = blob && URL.createObjectURL(blob)
  const attachmentGroup = app[G.ADAPTER][G.ATTACHMENT][G.GROUP][group]?.[G.DATA]

  // After we uploaded something (without refreshing the page) the attachment group will be an empty
  // array, clicking on items therefore won't work. So let's check for that.
  const attachments = attachmentGroup?.length ? attachmentGroup : [{ key }]
  const attachment = attachments.find(item => item.key === key)

  attachment.url = objectUrl // adding blob url to the attachment
  bus.dispatch(bus.type(G.ATTACHMENT, type, key), { ...rest, key, url: objectUrl })

  try {
    url && blob && persist && worker && await storage.putFile({ url, name, contents: blob })
  } catch (error) {
    console.warn(`Unable to persist file ${name} with url: ${url}`)
  }

  // Terminate the worker if the attachment couldn't be found or the request is completed
  ((rest?.status && rest?.status !== 200) || blob) && worker && terminate(worker, source)()
}

/**
 * Initializes a {@link Worker} in order to obtain a remote or local attachment.
 *
 * @param {Gaia.Web.Application} app  the Web platform Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail }) => {
  const storage = app[G.ADAPTER][G.PERSISTENCE][G.API]
  const online = app[G.ADAPTER][G.SESSION][G.STATE][G.ONLINE]
  const persistenceEnabled = !settings.suppressPersistence

  const { key, uuid = key, name, api, group, value: item } = detail[G.DATA]
  const _isRemote = isRemote(detail[G.DATA])
  const url = getReadUrl(app, api, key, name)
  const data = _isRemote ? { url, key, uuid } : { item, key, uuid }

  if (online) {
    const { source, worker } = createWorker(_isRemote ? readWorkerBody : previewWorkerBody)
    worker.onmessage = onMessage(worker, source, app, group, item, persistenceEnabled)
    worker.onerror = terminate(worker, source)
    worker.postMessage(data)
  } else if (persistenceEnabled) {
    try {
      const blob = await storage.getFile(url)
      const event = new MessageEvent('message', { data: { ...data, blob } })
      await onMessage(null, null, app, group, item, false)(event)
    } catch (error) {
      console.warn(`Unable to obtain persisted file by url: ${url}`, error)
    }
  }
}

export default fn
