/* global G */
import set from '@platform/adapter/attachment/api/group/set'
import init from '@platform/adapter/attachment/api/init'

/**
 * Initializes a new attachment group, sets remote assets to its cache and empties its local and
 * delete data.
 *
 * @param {Gaia.Web.Application} app  the Web platform Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => ({ detail }) => {
  const { [G.GROUP]: groupName } = detail[G.DATA]
  init(app)({ detail })
  set(app, groupName)({ detail })
}

export default fn
