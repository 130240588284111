/* global G */
import { asyncPipeSpread } from 'lib/util'


/**
 * Execute Module ACTION Hooks AFTER
 *
 * The hooks should be located inside your module folder, as they are specific for module's action
 *
 * @param obj - action object
 * @return {function(...[*]): Promise<*|*[]>}
 */
const actionHooksAfter = obj => async (...args) => (
  obj[G.HOOKS] && obj[G.HOOKS].after
    ? await asyncPipeSpread(...obj[G.HOOKS].after)(...args)
    : args
)

export default actionHooksAfter
