/* eslint-disable object-curly-newline */
/* global G */
import ReactMarkdown from 'react-markdown'
import { setKey } from 'lib/util'
import { addToFilename } from 'lib/util/string'
import { formatFilesize } from 'lib/util/attachment'
import { empty } from 'lib/util/object'
import listAttachmentFilters from 'app/_shared/events/collection/listAttachmentFilters'

/**
 * Compare {@param newAttachment} against {@param attachments}' name and size.
 *
 * @param {String} newAttachment the new file to be added
 * @param {Object[]} attachments existing attachments
 * @return {{size: boolean, name: boolean, index}|{size: boolean, name: boolean, index: boolean}}
 * @private
 */
const _comparison = (newAttachment, attachments) => {
  // Get the file with the same initialName and highest index
  const similarFile = attachments.reduce((acc, key) => (
    key.initialName === newAttachment.name && key.index > acc?.index ? key : acc
  ), attachments.find(f => f.name === newAttachment.name) || {})

  return !empty(similarFile)
    ? {
      name: true,
      size: similarFile.size === newAttachment.size,
      index: similarFile.index,
    }
    : { name: false, size: false, index: false }
}

/**
 * Get the dialog translation {@param key}.
 *
 * @param {Object} intl         intl adapter
 * @param {String} key          translation key
 * @param {String} defaultValue default translation
 * @return {{transform: string, value: *, key}}
 * @private
 */
const _getTranslation = (intl, key, defaultValue) => ({
  key,
  value: intl.translate(
    `button.${key}`,
    {
      ns: 'common',
      _key: `button.${key}`,
      defaultValue,
    },
  ),
})

/**
 * Shows a warning dialog about a large attachment size.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {Number} warningSize        threshold of attachment size that warrants a warning.
 * @param {Boolean} hint              whether to show a messages telling the user that they
 *                                    will be able to upload larger attachments after registration
 * @private
 */
const _warningDialog = (app, warningSize, hint = false) => {
  const { [G.ADAPTER]: { [G.EVENTS]: eventBus, [G.INTL]: intl } } = app

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: _getTranslation(intl, 'title', 'Warning')?.value,
    text: intl.translate(
      `dialog.warning.attachment.${hint ? 'hint' : 'text'}`,
      {
        warningSize: formatFilesize(warningSize),
        ns: 'common',
        _key: `dialog.warning.attachment.${hint ? 'hint' : 'text'}`,
        defaultValue: 'The attachments you\'ve added are larger than {{warningSize}}, please don\'t close the window after confirming the form.',
      },
    ),
    children: { ok: _getTranslation(intl, 'ok', 'Ok') },
  })
}

/**
 * Shows an error dialog about attachments not meeting the requirements.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {Object} filters            attachment filters to show the user.
 * @private
 */
const _errorDialog = (app, filters) => {
  const { [G.ADAPTER]: { [G.EVENTS]: eventBus, [G.INTL]: intl } } = app
  const { [G.MODULE]: module } = app[G.SESSION][G.STATE]

  const violatedFilters = listAttachmentFilters(module, null, {
    ...filters.size && { size: formatFilesize(filters.size) },
    ...filters.groupSize && { groupSize: formatFilesize(filters.groupSize) },
    ...filters.types && { types: filters.types.join(', ') },
    ...filters.count && { count: filters.count },
  })

  eventBus.dispatch(eventBus.type(G.DATA, G.UNDO), {
    title: _getTranslation(intl, 'title', 'Error')?.value,
    text: [
      intl.translate(
        'dialog.error.attachment.text',
        {
          ns: 'common',
          _key: 'dialog.error.attachment.text',
          defaultValue: 'Coming soon',
        },
      ),
      <ReactMarkdown>
        {violatedFilters.map(filter => `- ${filter.value}`).join('\n')}
      </ReactMarkdown>,
    ],
    children: { ok: _getTranslation(intl, 'ok', 'Ok') },
  })
}

/**
 * Validate the current {@param attachment} against the predefined {@param filters}.
 *
 * @param {File} attachment                   the current attachment
 * @param {Number} filesize                   current combined size of all group attachments
 * @param {Number} count                      current number of all group attachments
 * @param {File[]} attachments                list of already added attachments
 * @param {*[]} filters                       list of defined filters to check against
 * @param {Boolean|Number} filters.size       maximum size for a single attachment
 * @param {Boolean|Number} filters.groupSize  maximum size for all attachment
 * @param {Boolean|Number} filters.count      maximum number of all attachment
 * @param {Boolean|*[]} filters.types         list of allowed file types
 *
 * @return {{types?: boolean, size?: boolean, count?: boolean, groupSize?: boolean}}
 * @private
 */
const _validateAttachment = (attachment, {
  groupFilesize: filesize,
  groupFileCount: count,
  newAttachments: attachments,
}, filters) => (
  {
    /**
     * Validating the size of the attachment against the maximum single attachment size defined
     * in the group.
     */
    ...filters.size && filters.size < attachment.size ? { size: true } : { },

    /**
     * Validating the size of all attachments already added + the size of the current attachment
     * against the maximum group size defined in the group.
     */
    ...filters.groupSize && filters.groupSize < filesize + attachment.size
      ? { groupSize: true }
      : { },

    /**
     * Validating the number of all attachments already added + the current attachment against
     * the maximum number of attachments defined in the group.
     */
    ...filters.count && (filters.count < count + attachments.length)
      ? { count: true }
      : { },

    /**
     * Validating the type of the current attachment against the array of allowed types defined
     * in the group. Works for MIME types and groups, such as "image", "jpg", and "image/jpg".
     */
    ...filters.types && filters.types.every(t => !attachment.type.includes(t))
      ? { types: true }
      : { },
  }
)

/**
 * Adds new (non-existing) files that comply with the maximum size and types allowed to the
 * {@param groupName}'s group (G.DATA) list of local files, then dispatches a DONE event with the
 * current local and remote ones.
 *
 * An event can send an {@code [G.API]} property to override the default upload path for the
 * uploaded files.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => ({ detail }) => {
  const {
    [G.DATA]: newAttachments,
    [G.PROPS]: componentFilter,
    [G.REF]: name,
    [G.API]: api,
  } = detail[G.DATA]

  const adapter = app[G.ADAPTER][G.ATTACHMENT]
  const group = adapter[G.GROUP][groupName]

  // Getting filter options defined in the group
  const { [G.STATE]: { [G.FILTER]: groupFilter } } = group

  // Use options passed by event if possible, if not use group options
  const allFilters = componentFilter && !empty(componentFilter)
    ? componentFilter
    : groupFilter
  const { size, groupSize, types, count, warningSize } = allFilters || {}
  const filters = allFilters ? { size, groupSize, types, count } : {}

  const groupAttachments = group[G.DATA].map(f => ({
    name: f.key,
    size: f.value.size,
    index: f.value?.index || 0,
    initialName: f.value?.initialName || null,
  }))

  const groupFilesize = groupAttachments.reduce((acc, key) => acc + key.size, 0)
  const groupFileCount = groupAttachments.length

  /**
   * Set validation errors to an empty object. Validation should be checked against all
   * attachments that are about to be added. If the object has properties after we looped
   * through all attachments, we know some of them failed, so we'll show an error dialog.
   */
  let validationErrors = {}

  /**
   * If the user selects multiple attachments at once we need to check their size right away
   * in order to know if they exceed the limit.
   */
  const combinedNewSize = newAttachments.reduce((acc, att) => acc + att.size, 0)
  combinedNewSize > groupSize && (validationErrors = { ...validationErrors, groupSize: true })

  // Looping through the newly added attachments
  let validatedAttachments = newAttachments.reduce((acc, newAttachment) => {
    const { name: sameName, size: sameSize, index } = _comparison(newAttachment, groupAttachments)

    // Same name, same size -> same file, don't add
    if (sameName && sameSize) return acc

    // Validating the attachment
    validationErrors = {
      ...validationErrors,
      ..._validateAttachment(newAttachment, {
        groupFilesize,
        groupFileCount,
        newAttachments,
      }, filters),
    }

    // Failed validation, return early
    if (!empty(validationErrors)) return acc

    // Checking if we should warn the user about big attachments
    if (warningSize
        && !group?.[G.STATE]?.warned
        && (groupFilesize + newAttachment.size > warningSize)
    ) {
      _warningDialog(app, warningSize, app[G.SESSION][G.STATE][G.CONTEXT] === 'pre')
      setKey(true, 'warned', group[G.STATE])
    }

    // Same name, different size -> different file, add with new name
    if (sameName && !sameSize) {
      const addedFile = new File(
        [newAttachment.slice(0, newAttachment.size, newAttachment.type)],
        addToFilename(`${index + 1}`, newAttachment.name),
        { type: newAttachment.type },
      )
      addedFile.index = index + 1
      addedFile.initialName = newAttachment.name

      return [...acc, addedFile]
    }

    // new file, add
    return [...acc, newAttachment]
  }, [])

  /**
   * Checking if we have validations errors. If so, show them in an error dialog. We don't need
   * to abort early here, because {@code validatedAttachments} will be empty if there were any
   * errors.
   */
  const violatedFilters = Object.keys(validationErrors).reduce((acc, key) => (
    validationErrors[key] ? { ...acc, [key]: filters[key] } : acc
  ), {})
  !empty(violatedFilters) && _errorDialog(app, violatedFilters)

  /**
   * At this point we either have valid attachments in {@code validatedAttachments}, or not
   * if validation failed. Let's map over them and build up the structure we need for saving
   * them.
   */
  validatedAttachments = validatedAttachments.map(value => ({ // mimic remote doc structure
    key: value.name,
    _rev: null, // update on creation, leave key as is, so we won't rerender
    name, // in case it is a named attachment
    api, // target api values
    group: groupName, // name of the group the attachment belongs to
    value,
  }))

  /**
   * If we have any validated attachments, let's now add the to the group by dispatching the
   * respective event.
   */
  if (validatedAttachments.length > 0) {
    const eventBus = app[G.ADAPTER][G.EVENTS]
    // keep newly added attachments in the beginning of the group
    setKey(validatedAttachments.concat(group[G.DATA]), G.DATA, group)
    eventBus.dispatch(
      eventBus.type(G.ATTACHMENT, G.DONE, groupName),
      // keep newly added attachments in the beginning of the group
      { [G.DATA]: group[G.DATA].concat(group[G.CACHE]), [G.STATE]: group[G.STATE] },
    )
  }
}

export default fn
