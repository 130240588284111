/* eslint-disable no-param-reassign */
/* global G */
import { curry } from 'lib/util'

/**
 * Impersonation middleware handler.
 *
 * Adds the imp parameter to the request arguments if the corresponding record can be found in the
 * local storage.
 *
 * @type {Gaia.Adapter.Http.Middleware}
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @param {function} next             the next middleware function
 * @param {object} args               the request's arguments
 * @return {object}                   the response object
 */
export default curry(async (obj, next, args) => {
  const username = obj[G.ADAPTER][G.STORAGE][G.API].get('imp')
  if (username) {
    const { method } = args
    if (method === 'GET') {
      args.params = { ...args.params, imp: username }
    } else {
      args.url += `${args.url.match(/[?]/g) ? '&' : '?'}imp=${username}`
    }
  }
  return await next(args)
})
