import usesGetterSetter from './GetterSetter'
import usesNamespace from './Namespace'
import usesScopedNamespace from './ScopedNamespace'

const traitCollection = {
  usesGetterSetter,
  usesNamespace,
  usesScopedNamespace,
}

export {
  usesGetterSetter,
  usesNamespace,
  usesScopedNamespace,
  traitCollection as default,
}
