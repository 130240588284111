/* global G */
import toModule from '@platform/adapter/router/api/toModule'
import toAction from '@platform/adapter/router/api/toAction'
import _forceAppModal from '@platform/adapter/router/effect/forceAppModal'

/**
 * Route to a different Module and Action.
 *
 * Pipes toModule(), toAction() api methods.
 *
 * Forces rendering into modal, ie dialog
 *
 *
 * @param {Gaia.Web.Application} app - web application reference
 * @param {*} args - arguments
 * @return {function(...[*])}
 */
const toModalModuleAction = app => async (route, ...args) => {
  await toModule(app)(route, ...args)
  _forceAppModal(app)(route)
  await toAction(app)(route[G.ACTION], ...args)
  return args
}

export default toModalModuleAction
