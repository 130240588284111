// GAIA-14, EYT-13, GAIA-514, GAIA-407
/* global G */
import { curry, asyncpipe } from '@gaia/util'
import setMeta, { sequenceAppSessionStateRoleSet as setRole } from '@gaia/sequence/app/session/state/meta'

/**
 * Cookie Validation Sequence
 *
 * It tests for (existing) cookie validity
 * It sets session meta, if cookie is valid
 * It sets session error, if cookie is invalid
 *
 * @param {Gaia.Web.Application } obj - application composition
 * @param {[*]} args - arguments
 * @return {Promise<*>} args - arguments
 */
const sequenceSessionCookieValidateFn = async (obj, args) => {
  try {
    await asyncpipe(
      () => obj[G.ADAPTER][G.HTTP][G.API].get({
        url: '/api/v1/public/login',
      }),
      setRole(obj),
      setMeta(obj),
    )(args)
  } catch (e) {
    console.warn(e)
    // If the user was impersonating another user and ended their cookie-based session without
    // explicitly ending the impersonation (e.g. by closing their browser), the application has not
    // had a chance to delete the impersonation entry saved in the browser's local storage. The
    // login call may have failed, then, because we sent the imp parameter to the server, so we try
    // to solve this here by removing the impersonation entry and retrying the login call (but only
    // if there was any entry to be removed in the first place).
    if (obj[G.ADAPTER][G.STORAGE][G.API].remove('imp')) { // if removed, returns the value
      await sequenceSessionCookieValidateFn(obj, args) // retry without imp key
    } else {
      const sessionState = obj[G.SESSION][G.STATE]
      sessionState[G.ERROR] = true
    }
  }
  return args
}

const sequenceSessionCookieValidate = curry(sequenceSessionCookieValidateFn)

export default sequenceSessionCookieValidate
