/* eslint-disable max-len */
import create from './create'
import update from './update'
import set from './set'
import remove from './delete'
import add from './add'
import activate from './activate'

/**
 * @memberOf Adapter.Message#
 * @typedef Adapter.Message.API
 *
 * Gathers methods used to manage messages.
 *
 * @property {function} set - persist remote payload in G.DATA
 * @property {function} create - remote creation
 * @property {function} update - update remote and local item
 * @property {function} delete - delete remote and local item
 * @property {function} activate - activate a draft message
 *
 * @param {Gaia.Web.Application} app  Platform Web Application
 * @return {{}}
 */
const api = app => Object.create({}, {
  set: {
    value: set(app),
    iterable: true,
    enumerable: true,
  },
  create: {
    value: create(app),
    iterable: true,
    enumerable: true,
  },
  update: {
    value: update(app),
    iterable: true,
    enumerable: true,
  },
  delete: {
    value: remove(app),
    iterable: true,
    enumerable: true,
  },
  add: {
    value: add(app),
    iterable: true,
    enumerable: true,
  },
  activate: {
    value: activate(app),
    iterable: true,
    enumerable: true,
  },
})

export default api
