/* global G */
import { pipe, curry } from '@gaia/util'
import withGetterSetter from '@gaia/trait/with/GetterSetter'
import withConfiguration from '@gaia/trait/with/Configuration'
import usesGetterSetter from '@gaia/trait/uses/GetterSetter'

/**
 *
 * @memberOf Gaia#
 * @namespace Route
 * @type {Object}
 *
 * @property {object} [G.CONFIGURATION] - module configuration {@link Gaia.AppModule.Configuration}
 * @property {String} [G.CONTEXT] - context name
 * @property {String} [G.MODULE] - module name
 * @property {String} [G.ACTION] - action name
 * @property {String} [G.REF] - events collection, required for action ui components
 */

/**
 * Route Composition
 *
 * Used in native adapter router, to delegate information required for business logic execution.
 *
 * @param {string} module - module name
 * @param {string} action - action
 * @param {string|null} [context] - context name
 * @param {string|null} [ref] - referenced uuid, ie :module/:action/:ref
 * @param {object|null} [cache] - cached parameters
 * @return {Gaia.Route} - object composition
 */
const routeCompositionFn = (module, action, ref = null, context = null, cache = null) => pipe(
  withConfiguration, // requirement of withGetterSetter
  withGetterSetter(G.CONTEXT),
  withGetterSetter(G.MODULE),
  withGetterSetter(G.ACTION),
  withGetterSetter(G.REF),
  withGetterSetter(G.CACHE),
  usesGetterSetter(G.CONTEXT, context),
  usesGetterSetter(G.MODULE, module),
  usesGetterSetter(G.ACTION, action),
  usesGetterSetter(G.REF, ref), // either model ID or password reset ID
  usesGetterSetter(G.CACHE, cache),
)({ module, action })

const routeComposition = curry(routeCompositionFn)

export default routeComposition
