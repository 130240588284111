import { curry } from 'lib/util'
import lazyComposition from 'trait/composition/lazy'

/**
 * Lazy Component Factory
 *
 * The factory propagates initialisation fn and configuration to
 * lazyComposition, for later use.
 *
 * @memberOf Gaia.Factory#
 * @typedef {function(*=)} Factory.Lazy
 * @param {Gaia.requireLoaders} loaders
 * @param {function} lazyFn - lazy provider fn
 * @param {Gaia.Component.Spec} obj - lazy component configuration
 * @return {Function}
 */
const lazyFactoryFn = (loaders, lazyFn, obj) => lazyComposition(obj, lazyFn)

export default curry(lazyFactoryFn)
