import { historyStateFromRoute } from '@platform/trait/uses/WindowHash/init'

/**
 * BROWSER HISTORY MANIPULATION ONLY
 * depends on on APP[G.STATE][G.MODULE] settings
 * executed via event bus dispatchEvent(new CustomEvent('gaia:hashchange'))
 *
 * @param {Gaia.Web.Application} obj - application composition {@link Gaia.Web.Application}
 * @return {function(e:Event): Event}
 */
const hashHandler = obj => (event) => {
  window.history.replaceState(...historyStateFromRoute(obj))
  return event
}

export default hashHandler
