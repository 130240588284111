/**
 * Event object adapter.
 *
 * Adapter object used to abstract events fired from platform. It implements the {@link Event}
 * interface and adapts {@param event} to it. It also assigns {@param detail}'s value to its detail
 * property.
 *
 * {@see https://www.w3.org/TR/uievents/#event-interfaces}
 *
 * @memberOf Gaia#
 * @typedef {Event} Gaia.PlatformEvent
 * @property {Object} detail                  {@param detail}'s contents
 * @property {Object} dataTransfer            holds the data being dragged during drag and drop
 * @property {Object} nativeEvent             the event triggered from the native platform
 * @property {function} isPropagationStopped  whether {@link stopImmediatePropagation} was executed
 * @property {function} isDefaultPrevented    whether {@link preventDefault} was executed
 * @property {function} persist               holds the event onto a reference, preventing it from
 *                                            being added back to the React's SyntheticEvent pool
 *
 * @type {Gaia.PlatformEvent}
 * @param {Object|string} event               the event that originated this event or the type of
 *                                            new event
 * @param {Object} detail                     additional information to be passed
 * @constructor
 */
export default function PlatformEvent(event, detail) {
  const rootEvent = typeof event === 'object' && event ? event : { type: event || '' }
  const eventDetail = detail || rootEvent.detail || {}

  Object.defineProperties(this, {
    // Event interface attributes
    type: {
      get: () => rootEvent.type,
      enumerable: true,
    },
    target: {
      get: () => rootEvent.target,
      enumerable: true,
    },
    currentTarget: {
      get: () => rootEvent.currentTarget,
      enumerable: true,
    },
    eventPhase: {
      get: () => rootEvent.eventPhase,
      enumerable: true,
    },
    bubbles: {
      get: () => rootEvent.bubbles,
      enumerable: true,
    },
    cancelable: {
      get: () => rootEvent.cancelable,
      enumerable: true,
    },
    composed: {
      get: () => rootEvent.composed,
      enumerable: true,
    },
    timeStamp: {
      get: () => rootEvent.timeStamp,
      enumerable: true,
    },
    defaultPrevented: {
      get: () => rootEvent.defaultPrevented,
      enumerable: true,
    },
    isTrusted: {
      get: () => rootEvent.isTrusted,
      enumerable: true,
    },
    // Additional attributes
    dataTransfer: {
      get: () => rootEvent.dataTransfer,
      enumerable: true,
    },
    detail: {
      get: () => eventDetail,
      enumerable: true,
    },
    nativeEvent: {
      get: () => rootEvent.nativeEvent,
      enumerable: true,
    },
    // Event interface methods
    stopPropagation: {
      value: () => rootEvent.stopPropagation?.(),
      enumerable: true,
    },
    stopImmediatePropagation: {
      value: () => rootEvent.stopImmediatePropagation?.(),
      enumerable: true,
    },
    preventDefault: {
      value: () => rootEvent.preventDefault?.(),
      enumerable: true,
    },
    initEvent: {
      value: () => rootEvent.initEvent?.(),
      enumerable: true,
    },
    // Additional methods
    isPropagationStopped: {
      value: () => rootEvent.isPropagationStopped?.(),
      enumerable: true,
    },
    isDefaultPrevented: {
      value: () => rootEvent.isDefaultPrevented?.(),
      enumerable: true,
    },
    persist: {
      value: () => rootEvent.persist?.(),
      enumerable: true,
    },
    key: {
      get: () => rootEvent?.key,
      enumerable: true,
    },
  })
}
