/* eslint-disable no-mixed-operators */
/* global G */
import { getDraftUrl, getUrl } from '@platform/adapter/message/helper'

/**
 * Adds a message to the adapter's G.DATA list of messages, dispatches a G.DONE event and then
 * attempts to send it to the server. On successful send, it sets the new message's id and rev and
 * submitTimestamp, adds it to the adapter's data and dispatches a G.DONE event again.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => async ({ detail }) => {
  const adapter = app[G.ADAPTER][G.MESSAGE]
  const eventBus = app[G.ADAPTER][G.EVENTS]
  const adapterData = adapter[G.DATA]
  const { draft, ...item } = detail[G.DATA]
  const callback = detail[G.FN]
  adapterData.push(item)

  /**
   * Why do we dispatch the same event here and then later below?
   * Because we want to show the message in the chat window already while
   * it's sending to the server with the 'Sending...' footer.
   */
  eventBus.dispatch(eventBus.type(G.MESSAGE, G.DONE), { [G.DATA]: adapterData })

  const url = draft
    ? getDraftUrl(app)
    : `${getUrl(app)}/default`

  const options = { middleware: ({ persistence, error }) => [persistence, error] }
  const result = await app[G.ADAPTER][G.HTTP][G.API].post({ url, params: item }, options)

  if (result) {
    const { data = result } = result

    draft
      && (item.key = data[0].id)
      && (item._rev = data[0].rev)
      && (item.draft = true)

    !draft
      && (item.key = data.key)
      && (item._rev = data._rev)
      && (item.value = data.value)
      && (item.refs = data.refs)
      && (item.acl = data.acl)

    callback?.(item)

    eventBus.dispatch(eventBus.type(G.MESSAGE, G.DONE), { [G.DATA]: adapterData })
  }
}

export default fn
