/**
 * Get the mime type of {@param item}
 *
 * @param {Object} item   the attachment
 * @param {Boolean} type  return either the type ({@code 'application/zip'} <-- zip)
 *                        or the group ({@code 'application/zip'} <-- application)
 * @returns {String|null}
 */
export const getMimeType = (item, type = true) => (item?.type?.split('/') || item?.mimetype?.split('/') || '')[type ? 1 : 0]
    || (item?.url && item.url.split('.').pop().trim())
    || 'other'

/**
 * Get the mime group of {@param item}.
 *
 * @param {Object} item   the attachment
 * @returns {String|null}
 */
export const getMimeGroup = item => getMimeType(item, false)

/**
 * Get file type of external attachments.
 *
 * @param {Object} item    the attachment
 * @param {Object} types  object of known file types
 * @private
 */
export const getFileType = (item, types) => {
  let foundType
  Object.keys(types).forEach((type) => {
    if (types[type].includes(item.url.split('.').at(-1))) foundType = type
  })

  return foundType
}

/**
 * Format the given {@param bytes} into human-readable format.
 *
 * @param {Number} bytes        the bytes to format
 * @param {Number} [decimals=2] number of decimals
 * @return {string}
 */
export const formatFilesize = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}
