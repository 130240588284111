import canHookInto from './HookInto'
import canList from './List'

const traitCollection = {
  canHookInto,
  canList,
}

export {
  canHookInto,
  canList,
  traitCollection as default,
}
