/* global G */
import { curry, pipe } from 'lib/util'
import { usesNamespace } from 'trait/uses'

/**
 * Controller Factory
 *
 * Provides app module controller based on provided configuration
 *
 * @memberOf Gaia.Factory#
 * @typedef {function(*=)} Factory.Controller
 * @argument {Gaia.requireLoaders} loaders - {@link Gaia.requireLoaders}
 * @return {function(configuration: Gaia.AppModule): function}
 */
export const moduleFactoryFn = (platform, loaders, configuration) => {
  const { module } = loaders
  const moduleComposition = module(configuration.module)

  const extendedComposition = pipe(
    moduleComposition,
    usesNamespace(G.ADAPTER, {
      // G.SESSION is used for fetching ref ids from logged account
      [G.SESSION]: platform[G.SESSION],
      [G.SETTINGS]: platform[G.SETTINGS],
      [G.NOTIFICATION]: platform[G.NOTIFICATION],
      [G.EVENTS]: platform[G.EVENTS],
      [G.ROUTER]: platform[G.ROUTER][G.API],
      [G.UI]: platform[G.UI][G.API],
      [G.ACL]: platform[G.ACL][G.API],
      [G.INTL]: platform[G.INTL],
      [G.PERSISTENCE]: platform[G.PERSISTENCE]?.[G.API],
      [G.STORAGE]: platform[G.STORAGE]?.[G.API],
      [G.PLUGIN]: platform[G.PLUGIN]?.[G.API],
    }),
  )

  return extendedComposition(configuration)
}

export default curry(moduleFactoryFn)
