/* global G */
import Group from '@platform/adapter/notification/group'
import { setKey } from 'lib/util'

/**
 * Inits the given notification group identified by {@code detail[G.DATA][G.group]} and dispatches
 * {@code G.NOTIFICATION, G.CREATE, groupName} event, executing the groups {@code create} event
 * handler.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 */
const fn = app => async ({ detail = {} }) => {
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const {
    [G.GROUP]: groupName,
    [G.API]: api,
    [G.ROUTE]: route,
    [G.MENU]: menu,
    [G.LIST]: list,
  } = detail[G.DATA] || {}

  const adapter = app[G.ADAPTER][G.NOTIFICATION]
  const group = adapter[G.GROUP][groupName] || await Group(app, groupName, {
    [G.API]: {
      api: groupName,
      ...api,
    },
    [G.ROUTE]: route,
    [G.MENU]: menu,
    [G.LIST]: list,
  })

  setKey(group, groupName, adapter[G.GROUP])

  eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.CREATE, groupName), { id: null })
}

export default fn
