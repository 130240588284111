/* eslint-disable no-unused-vars */
/* global React */
import { Grid, Button } from '@mui/material'
import { useEventCallback, useStyles } from '@platform/react/hook'
import PlatformEvent from 'lib/util/event'

const styles = () => ({
  root: {
    textAlign: 'left',
    '& *': {
      cursor: 'pointer!important',
    },
  },
})

/**
 * Button HoC
 *
 * Wraps {@param Component} with a button.
 *
 * @example
 * {
 *  module: 'Card/Form',
 *  view: 'Component/Grid/SectionAsButton',
 *  route: {
 *    action: 'detail',
 *  },
 *  options: {
 *    md: 12,
 *    xl: 12,
 *    events: {
 *      onClick: 'redirect',
 *    },
 *  },
 *  children: [
 *    ...
 *  ]
 *  ...
 *  }
 * @param Component
 * @return {function(*, *)}
 * @constructor
 */
const ButtonHOC = Component => ({ target, prefix, buttonProps, ...props }, ref) => {
  const classes = useStyles(styles)()

  const handleClick = useEventCallback((event) => {
    props.events?.onClick(new PlatformEvent(event))
  })

  return (
    <Grid
      item
      component={Button}
      className={classes.root}
      onClick={handleClick}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
      xs={12}
      {...buttonProps}
    >
      <Component
        {...props}
        ref={ref}
      />
    </Grid>
  )
}

export default ButtonHOC
