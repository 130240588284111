/* global G */
import componentComposition from 'trait/composition/component'
import { pipe } from 'lib/util'
import { withGetterSetter } from 'trait/with'

const descriptor = 'Gaia.Pane'

/**
 * Pane component module.
 */
export default pipe(
  componentComposition(descriptor),
  withGetterSetter(G.ACTIONS),
)
