import { Children, cloneElement } from 'react'

/**
 * Capitalize First Letter of Word
 *
 * @param first - first letter
 * @param rest - rest of the word
 * @param locale - currently used locale, for correct uppercase unicode mapping
 * @return {string} word - capitalized word
 */
export const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) => first
  .toLocaleUpperCase(locale) + rest.join('')

/**
 * Clone Component Helper
 *
 * @param {React.Component | any} Component
 * @return {function(*=): React.DetailedReactHTMLElement<*, HTMLElement>}
 * @private
 */
const _clone = Component => props => cloneElement(Component, props)

/**
 * Children HoC
 *
 * provides children as an object, where the child key is used as object property
 * allowing us to position children wherever required inside the parent ui element or component
 * thus, breaking the natural array order, in which React children are provided per default
 *
 * ATTENTION: abusing cloneElement might slow down the overall performance
 *
 * @example configuration stub usage with ui element, wrapped by ChildrenHOC
 * ...
 * {
 *   ...
 *   children: [
 *     {
 *       config: 'ui/some/component',
 *       options: {
 *         key: 'someComponent',
 *       },
 *     },
 *     {
 *       config: 'ui/some/element',
 *       options: {
 *         key: 'someElement',
 *       },
 *     },
 *   ,
 *   ...
 * }
 * ...
 *
 * const WrappedInChildrenHOCElement = ChildrenHOC(({ childrenObject, ...props}) => {
 *   const { SomeComponent, SomeElement } = childrenObject
 *   return (
 *     <>
 *       <SomeElement />
 *       ...
 *       <SomeComponent />
 *     <>
 *   )
 * })
 *
 * @param Component
 * @return {function(*)}
 * @constructor
 */
const ChildrenHOC = Component => (props) => {
  const children = Children.toArray(props.children)
  const childrenObject = Object.keys(props.children)
    .reduce(
      (acc, i) => ({
        ...acc,
        [capitalizeFirstLetter(props.children[i].key)]: _clone(children[i]),
      }),
      {},
    )

  return (
    <Component
      childrenObject={childrenObject}
      {...props}
    />
  )
}

export default ChildrenHOC
