/* eslint-disable max-len,no-nested-ternary */
import useAcl from '@platform/react/hook/useAcl'
import NotificationHOC from '@platform/react/hoc/notification'
import { Divider } from '@mui/material'

/**
 * Iterate through provided children, check ACLs and return a component for each
 * child.
 *
 * @example
 * If the child's {@code options.notification} property is non-nil, it returns
 * a {@link NotificationHOC} component wrapping the actual {@type Template}
 * component.
 *
 * @param {Object[]} children   react children, or data, based on whatever event handler can handle
 * @param {Function} acl        event handler fn
 * @param {ReactNode} Template  the react component to render for all children
 * @param {Object[]} options    additional options
 *
 * @returns {*}
 * @private
 */
const _iterate = (children, { acl, Template: ParentTemplate, ...options }) => children.map((child) => {
  const [aclProps] = useAcl(acl, child)

  // Give each child the option to specify its own Template.
  const Template = child.Template || ParentTemplate
  // shallow copying child, so that we can rewrite its props
  const { ...item } = child

  /**
   * passing aclProps to child. In the config this would be:
   *   {
   *     config: '...',
   *     acl: { ... },
   *     aclProps: {
   *       hidden: false,   // <--
   *       disabled: true,  // <--
   *     },
   *     options: {
   *        ...
   *     },
   *   },
   */
  item.props = { ...item.props, ...aclProps }

  const templateOptions = {
    key: item.key,
    child: item,
    options: {
      ...options,
      ...item?.options?.notification && {
        notification: item?.options?.notification,
      },
    },
    hidden: item.props?.hidden || aclProps.hidden,
  }

  // We have to declare a const for this hoc, otherwise it won't get rendered (and its hooks not
  // executed) if it should be hidden, then we'll run into react order of hook errors.
  // for some reason, props.hidden will be true on first render and only false on subsequent ones.
  // TODO: Investigate this
  const notificationHOC = NotificationHOC(Template)(templateOptions)

  // No need to reserve grid space for hidden children
  return item.props?.hidden || aclProps.hidden ? null
    : item?.options?.notification
      ? notificationHOC
      : item.options?.divider
        ? <Divider key={item.key} />
        : <Template {...templateOptions} />
})

/**
 * Iterates over children, uses provided acl event handlers on each entry
 *
 * @param {Object[]} children react children, or data, based on whatever event handler can handle
 * @param {Function} acl      event handler fn
 * @param {Object[]} options  additional options
 */
const useACLIterator = (children, { acl, ...options }) => _iterate(children, { acl, ...options })

export default useACLIterator
