import { curry } from 'lib/util'
/**
 * Configuration Factory
 *
 * Provides parsed json5 configuration files
 *
 * @memberOf Gaia.Factory#
 * @typedef {function(*=)} Factory.Config
 * @argument {Gaia.requireLoaders} loaders - {@link Gaia.requireLoaders}
 * @return {function(path: string): Object}
 */
export const configFactoryFn = (loaders, path) => {
  const { config } = loaders
  return config(path)
}

export default curry(configFactoryFn)
