/* global G */

/**
 * Call Module ACTION function.
 *
 * @param obj - action object
 * @return {function(...[*]): *}
 */
const actionFn = obj => async (...args) => await obj[G.FN](...args)


export default actionFn
