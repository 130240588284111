/* global G, React */
import AppContext from 'platform/react/context/application'
import { createContext, useContext, useState, useEffect } from 'react'

const GuestContext = createContext(null)
GuestContext.displayName = 'guestContext'

const { Consumer, Provider } = GuestContext

const GuestProvider = ({ children }) => {
  const { session, router, isLogged } = useContext(AppContext)
  const [logged, setLogged] = useState(isLogged)
  const isGuestContext = session[G.CONTEXT] === router.defaultContextName()

  useEffect(() => {
    setLogged(isLogged && !isGuestContext)
  }, [isLogged])

  return (
    <Provider value={logged}>
      <Consumer>
        {children}
      </Consumer>
    </Provider>
  )
}

// GuestContext.Provider = GuestProvider

export {
  GuestContext as default,
  // instead of importing consumer, use hook useContext(SubscriptionContext) wherever possible
  Consumer,
  GuestProvider,
}
