import withDependencyCheck from './DependencyCheck'
import withGetterSetter from './GetterSetter'
import withObjectFreeze from './ObjectFreeze'
import withDescriptor from './Descriptor'
import withConfiguration from './Configuration'

const traitCollection = {
  withDependencyCheck,
  withGetterSetter,
  withObjectFreeze,
  withDescriptor,
  withConfiguration,
}

export {
  withDependencyCheck,
  withGetterSetter,
  withObjectFreeze,
  withDescriptor,
  withConfiguration,
  traitCollection as default,
}
