/**
 * Recursive data fetcher
 *
 * executes remote api call with given parameters via POST
 *
 * @param {Function} fn     handler for the request
 * @param {string} url      url for the request
 * @param {Object} params   payload for the request
 * @param {Object[]} values previously fetched values
 * @return {Promise<Promise<*|{data: *[], page: *}>|{data: *[], page}>}
 */
export const search = async (fn, url, params, values = []) => {
  const { value, next: page } = await fn({ url, params })
  const data = [...values, ...value]
  return page && data.length < params.limit
    ? await search(fn, url, { ...params, page }, data)
    : { data, page }
}
