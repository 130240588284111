import create from '@platform/adapter/notification/api/group/cart/create'

const api = Object.create({}, {
  create: {
    value: create,
    iterable: true,
    enumerable: true,
  },
})

export {
  api as default,
}
