/* global G */
import { asyncpipe, setKey } from 'lib/util'
import setContext from 'lib/sequence/module/adapter/session/context'
import seqInitAcl from 'lib/sequence/app/acl/init'
import seqConnectPubSub from 'lib/sequence/app/pubsub/connect'
import setMeta from '@gaia/sequence/module/adapter/session/meta'
import setLanguage from '@gaia/sequence/module/adapter/intl/lang'
import routeComposition from 'trait/composition/route'
import resetNavigationStack from 'lib/sequence/module/adapter/session/navigation'
import { sequenceAppSessionStateRoleSet as setRole } from '@gaia/sequence/app/session/state/meta'
import cartInit from 'lib/sequence/app/init/cart'
import settingsInit from 'lib/sequence/app/init/settings'

/**
 * If there is a logged-in user, calls the login lifecycle method of the plugins-adapter.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @return {function(*): Promise<*>}
 */
const pluginsLogin = module => async (args) => {
  const session = module[G.ADAPTER][G.SESSION][G.STATE][G.META]
  const pluginsAdapter = module[G.ADAPTER][G.PLUGIN]
  session && await pluginsAdapter.login()
  return args
}

/**
 * Sequence Native Application Login
 *
 * Allows the application to authenticate with the server and
 * log in a user after it's been initialised.
 *
 * One can declare any route to be used in the next redirect. The sequence looks for
 * {@code module[G.STATE][G.ROUTE]} containing a route object like
 * {@code { module: 'person', action: 'index'}}. If it's not given
 * it will use the {@code defaultRoute} defined in application
 * config.
 *
 * @param {Gaia.AppModule.Spec} module the current module composition object
 * @return {function(*=): *}
 */
const sequenceModuleLogin = module => asyncpipe(
  () => module[G.MODEL][G.ADAPTER][G.HTTP].get({
    url: '/api/v1/public/login',
  }),
  setRole(module),
  setMeta(module),
  cartInit(module),
  setLanguage(module),
  resetNavigationStack(module),
  () => {
    const defaultRoute = module[G.ADAPTER][G.ROUTER].defaultRoute()
    return [
      routeComposition(
        module[G.STATE][G.ROUTE]?.module || defaultRoute.module,
        module[G.STATE][G.ROUTE]?.action || defaultRoute.action,
      ),
    ]
  },
  args => setKey(args[0], G.ROUTE, module[G.STATE]) && args,
  setContext(module),
  settingsInit(module),
  pluginsLogin(module),
  seqInitAcl(module),
  // TODO: Refactor after SP-957
  (args) => {
    const meta = module[G.ADAPTER][G.SESSION][G.STATE][G.META]
    return meta && meta?.role?.hasMessages
      ? seqConnectPubSub(module)(args)
      : args
  },
)

export default sequenceModuleLogin
