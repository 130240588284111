/* eslint-disable no-unused-expressions */
/* global G */
import { isRemote } from '@platform/adapter/attachment/helper'
import removeLocal from '@platform/adapter/attachment/api/group/remove'
import deleteRemote from '@platform/adapter/attachment/api/group/delete'

/**
 * Checks whether the file passed inside event {@code detail}'s data is remote or local and passes
 * it to {@link deleteRemote} or {@link removeLocal} accordingly.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => ({ detail }) => {
  isRemote(detail[G.DATA])
    ? deleteRemote(app, groupName)({ detail })
    : removeLocal(app, groupName)({ detail })
}

export default fn
