import { createContext } from 'react'

const ApplicationContext = createContext(null)
ApplicationContext.displayName = 'applicationContext'

const { Consumer, Provider } = ApplicationContext

export {
  ApplicationContext as default,
  // instead of importing consumer, use hook useContext(SubscriptionContext) wherever possible
  Consumer,
  Provider,
}
