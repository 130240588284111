/* global G */
import seqModuleInit from '@gaia/sequence/app/module/init'


/**
 * Route to a different Application Module.
 *
 * Executes module initialisation sequence {@link seqModuleInit}
 *
 *
 * @param {Gaia.Web.Application} app - web application reference
 * @param {*} args - arguments
 * @return {function(*, ...[*]): Promise<*>}
 */
const toModule = app => async (route, ...args) => await seqModuleInit(app)(route[G.MODULE], ...args)

export default toModule
