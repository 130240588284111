/* global G */
// see GAIA-514, GAIA-407
import { curry } from '@gaia/util'

const sequenceSessionCookieLogout = async (obj, args) => {
  await obj[G.MODEL][G.ADAPTER][G.HTTP].post({ url: '/api/v1/logout' })
  const sessionState = obj[G.ADAPTER][G.SESSION][G.STATE]
  sessionState[G.META] = null
  return args
}

export default curry(sequenceSessionCookieLogout)
