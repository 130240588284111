/* global G */
import { pipe } from 'lib/util'
import withDependencyCheck from 'trait/with/DependencyCheck'

const trait = 'canStep'
const dependencies = [G.CONFIGURATION, G.ACTIONS]

const canStepFn = (obj) => {
  // we grab the configuration definition, avoiding potential mutation of G.PROPS
  const { actions } = obj[G.CONFIGURATION]
  const actionKeys = Object.keys(actions)
  const steps = actionKeys.filter(a => actions[a].options && actions[a].options.step)

  Object.defineProperty(obj, G.DATA, {
    get() {
      return steps
    },
  })

  // we define each step as non-iterable property on G.DATA
  Object.defineProperties(obj[G.DATA], steps.reduce((acc, i) => {
    acc[i] = {
      get() {
        return obj[G.ACTIONS][i][G.COMPONENT]
      },
    }
    return acc
  }, {}))

  // we add namespaces, not as symbols this time around.
  Object.defineProperties(obj[G.DATA], {
    steps: {
      get() {
        // console.info('gettin steps')
        return steps.map((i) => {
          // console.log(obj[G.ACTIONS][i][G.COMPONENT][G.CONFIGURATION])
          return obj[G.ACTIONS][i][G.COMPONENT][G.CONFIGURATION].ui.title
        })
      },
    },
    refs: {
      get() {
        return steps.map((i) => {
          // console.log(obj[G.ACTIONS][i][G.COMPONENT][G.CONFIGURATION])
          return obj[G.ACTIONS][i][G.COMPONENT][G.PROPS].ref
        })
      },
    },
    completed: {
      get() {
        console.log(obj[G.MODEL])
        // if step content was validated without any errors, then it can be set to completed.
        return 'completed'
      },
    },
    skippable: {
      // array of skippable steps, which would lead to displaying of skip button. ()
      // if step content has validators, which might lead to errors,
      // the step still can be skipped, but the complete stepper cannot be submitted
      // but can be persisted ONLY with status "draft" (t.b.p.)
      get() {
        return 'skippable'
      },
    },
  })

  return obj
}

const canStep = pipe(
  withDependencyCheck(trait, dependencies),
  canStepFn,
)

export default canStep
