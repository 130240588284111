/* global React */
import { createContext, useState } from 'react'

const BooleanContext = createContext(null)
BooleanContext.displayName = 'booleanContext'

const { Consumer, Provider } = BooleanContext

/**
 * Boolean Provider
 *
 * exposes initial boolean value and value setter
 * since we are passing useState() callback setter, we might run into memory leaks >:):):)
 *
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const BooleanProvider = ({ children }) => {
  const [boolean, setBoolean] = useState(false)

  return (
    <Provider value={{ boolean, setBoolean }}>
      <Consumer>
        {children}
      </Consumer>
    </Provider>
  )
}

export {
  BooleanContext as default,
  // instead of importing consumer, use hook useContext(SomeContext) wherever possible
  Consumer,
  BooleanProvider,
}
