/* global G */
import { setKey } from 'lib/util'

/**
 * @param {boolean} value - value to set
 * @return {function(obj:Gaia.AppModule.Spec): function(...[*]): *[]}
 */
const setUiModalState = value => obj => (...args) => {
  try {
    setKey(
      value,
      G.MODAL,
      obj[G.ADAPTER][G.SESSION][G.STATE],
    )
  } catch (e) {
    throw Error(`${obj._name} model hook - ${e.message}`)
  }
  return args
}

/**
 * Application UI Modal Mode Setter
 *
 * it sets a flag in G.SESSION
 * which is being evaluated in native adapter G.UI
 * and compared initial action configuration
 * in order to keep potential follow up action in
 * modal
 *
 * @example will render in modal
 * ``` module configuration
 * {
 *  ...
 *  actions: {
 *    someAction: {
 *      ui: {
 *        dialog: false,
 *      }
 *    }
 *    ...
 *  }
 * }
 * ```module hooks
 * const hooks = {
 *   someAction: {
 *     before: [...],
 *     after: [...,modalOn,...],
 *   },
 *   ...
 * }
 *
 *
 * @type {function(Gaia.AppModule.Spec): function(...[*]): *[]}
 */
const sequenceModalModeOn = setUiModalState(true)

/**
 * @type {function(Gaia.AppModule.Spec): function(...[*]): *[]}
 */
const sequenceModalModeOff = setUiModalState(false)

export {
  sequenceModalModeOn as default,
  sequenceModalModeOff,
  sequenceModalModeOn,
}
