/**
 * With Object Freeze.
 *
 * Naive Immutable implementation, disables mutations on 1st level.
 *
 * Referenced properties and their children are still mutable!!!
 *
 * @param {Object} obj - object to freeze
 * @return {ReadonlyArray<unknown>}
 */
export default obj => Object.freeze(obj)
