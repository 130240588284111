import { curry } from 'lib/util'

/**
 * Has Namespace Trait.
 *
 * Adds a protected namespace to passed object during composition.
 *
 * @example
 * import { pipe } from 'lib/util'
 * import hasNamespace from 'lib/trait/has/Namespace'
 * ...
 * const someComposable = obj => obj
 * const protectedNamespace = Symbol('optional description')
 *
 * return pipe(
 *    someComposable,
 *    hasNamespace(protectedNamespace),
 * )
 *
 * @param {PropertyKey} namespace - assigned namespace symbol or string
 * @param {Gaia.AppModule.Spec | Gaia.Component.Spec} obj - object to mutate
 * @return {Object} obj - mutated object
 */
const hasNamespace = (namespace, obj) => {
  const ns = {}

  Object.defineProperty(obj, namespace, {
    get() {
      return ns
    },
    iterable: true,
  })

  return obj
}

export default curry(hasNamespace)
