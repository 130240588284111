import { Typography } from '@mui/material'
import { useStyles } from '@platform/react/hook'

const styles = () => ({
  root: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textTransform: 'uppercase',
  },
})

/**
 * Labeled Higher Order Component
 *
 * HOC that will wrap {@param Component} with a label and return it.
 *
 * @param {Function} labelFn  function to return the translated label
 * @param {Function} Component the component to be wrapped
 *
 * @return {function(...[*]): *}
 * @constructor
 */
const LabeledHOC = (labelFn, Component) => (...props) => {
  const [rootClasses, restProps] = props
  const classes = useStyles(styles)()
  const translatedLabel = labelFn(restProps)

  // Accounting for react-markdown component
  const label = translatedLabel?.props?.children || translatedLabel

  return <>
    {label && (
      <Typography
        component={'p'}
        className={classes.root}
        variant={'caption'}
        color={'textSecondary'}
        title={label}
      >
        {label}
      </Typography>
    )}
    {Component(rootClasses, restProps)}
  </>
}

export default LabeledHOC
