import createAll from '@platform/adapter/attachment/api/group/createAll'
import deleteAll from '@platform/adapter/attachment/api/group/deleteAll'

/**
 * Executes the {@link deleteAll} and the {@link createAll} methods for the group with name
 * {@param groupName}.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @param {string} groupName          the attachment group identifier
 * @returns {AttachmentEventListener} an AttachmentEventListener
 */
const fn = (app, groupName) => ({ detail }) => {
  deleteAll(app, groupName)()
  createAll(app, groupName)({ detail })
}

export default fn
