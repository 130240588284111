/* eslint-disable no-empty */
/* global G */
import settings from '@tenant/settings'

/**
 * Initializes the application plugins defined in tenant's settings. If there is a logged-in user,
 * it also calls the login lifecycle method of the plugins-adapter.
 *
 * @type {(function(...[*]): (*))|*}
 */
const init = obj => async (args) => {
  const pluginsAdapter = obj[G.ADAPTER][G.PLUGIN][G.API]
  await pluginsAdapter.init(settings.plugins || [])

  if (obj[G.SESSION][G.STATE][G.META]) {
    await pluginsAdapter.login()
  }

  return args
}

export default init
