/* global G */
import { pipe } from '@gaia/util'
import { hasNamespace } from '@gaia/trait/has'
import { withGetterSetterFn } from '@gaia/trait/with/GetterSetter'
import { usesGetterSetter, usesNamespace } from '@gaia/trait/uses'
import { withObjectFreeze, withDescriptor } from '@gaia/trait/with'
import api from './api'

const descriptor = 'adapterSettings'

/**
 * Settings Adapter.
 *
 * Adapter used to control user settings.
 *
 * @memberOf Gaia.Adapter#
 * @typedef Adapter.Settings
 *
 * @param {Gaia.Web.Application} obj  the Web platform Application
 * @return {function(*=): *}
 */
const adapterFn = (obj) => {
  const adapter = pipe(
    withDescriptor(descriptor),
    hasNamespace(G.API),
    usesNamespace(G.API, api(obj)),
    withGetterSetterFn(G.STATE),
    usesGetterSetter(G.STATE, {}),
    withGetterSetterFn(G.DATA),
    usesGetterSetter(G.DATA, null),
    withGetterSetterFn(G.APP),
    usesGetterSetter(G.APP, () => obj),
    withObjectFreeze,
  )({})

  const eventBus = obj[G.EVENTS]

  const defaults = {
    init: G.INIT,
  }

  Object.keys(defaults).reduce(
    (acc, key) => eventBus.add(eventBus.type(G.SETTINGS, acc[key]), adapter[G.API][key]) || acc,
    defaults,
  )

  return adapter
}

export default adapterFn
