/* eslint-disable no-unused-vars */
/* global G */

/**
 * Sets the locals adapter {@code G.DATA} to the incoming details.
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function({detail: *}): Promise<*|null>)|*}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.DATA]: groupData } = group
  const { page, data } = detail

  const eventBus = app[G.ADAPTER][G.EVENTS]

  const newData = { page, data }
  Object.keys(newData).forEach((property) => { groupData[property] = newData[property] })

  eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.DONE, groupName), { [G.DATA]: groupData })
}

export default fn
