/* global G */
import ACLError from 'lib/manager/acl/error'
import { isObj } from 'lib/util'

export const get = obj => obj[G.ADAPTER][G.ACL]
export const active = obj => get(obj)[G.PROPS].active // true : false
export const allow = () => true
export const deny = m => throw new ACLError(m || 'acl.check.deny')
export const strategy = enabled => (!enabled ? deny() : allow())
export const create = (acl, props = { hidden: true }) => ({
  [G.META]: Object.keys(acl).pop(), // domain
  [G.ACL]: Object.values(acl).pop(), // acls
  [G.PROPS]: { ...props }, // aclProps
})
export const valid = acl => !isObj(acl) && deny('structure should be { domain: [acl] }')
export const resolve = (obj) => {
  let acl = null
  try {
    valid(obj[G.ACL])
    acl = create(obj[G.ACL], obj[G.CONFIGURATION].aclProps)
  } catch (e) {
    console.warn(`${e.name} - ${obj[G.PROPS].key} - ${e.message}`)
  }
  return acl
}
