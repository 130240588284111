import HttpError from '@platform/adapter/http/error'
import { API_URL } from 'lib/util/environment'

const status = {
  400: 'Invalid data sent',
  401: 'Invalid credentials',
  403: 'You have insufficient permissions',
  404: 'The given URL does not exist, contact Support',
  409: 'Data state conflict',
  412: 'Confirmation error',
  428: 'Confirmation required',
  429: 'Too many requests. Please, try again later',
  500: 'Internal server error',
  503: 'Service unavailable',
  504: 'API Server is down',
}

const _headers = (headers = {}) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  ...headers,
})

/**
 * Url Build Helper
 *
 * uses runtime window.location.origin to build a url to use with REST API call
 *
 * @param {string} url - url, beginning with /, ie /api/:version/:endPoint
 * @return {URL || RequestInfo} url - URL
 * @private
 */
// const _url = url => new URL(`${DEV_SERVER_PROTOCOL}://${DEV_ENV_URL}:${DEV_SERVER_PORT}${url}`)
const _url = url => new URL(url, API_URL)
// const _url = url => new URL('http://www.example.com')

/**
 * Error Thrower
 *
 * Throws HttpError error with given message
 *
 * @param {*} message - a string, or an object, containing error information
 * @param {number} code - a numeric status identifier
 * @param {any} [response] - response data
 * @throws HttpError
 * @private
 */
const _error = (message, code, response) => throw new HttpError(message, code, response)

/**
 * Invalid Status Code Handler.
 *
 * global Symbol(ERROR) API -> throw, _getCode, _getMessage
 * environment dependent error messages map
 *
 * @param {number} code - response status code
 * @throws on invalid status code
 * @return {Promise<number>} status
 * @private
 */
const _status = async (result) => {
  const code = result.status
  const error = status[code]
  error && _error(error, code, await result.json())
  return code
}

/**
 * Result Data resolver
 *
 * converts incoming request result to json,
 * adds symbol STATE to payload, containing result.status code, ie 200, 404, 401, etc.
 *
 * @param result
 * @return {Promise<{}>}
 * @private
 */
const _data = async (result) => {
  await _status(result)
  return await result.json()
}

const _fetch = async (url, params) => {
  console.group('_FETCH')
  try {
    const result = await fetch(url, params)
    console.log('got result', result)
    return result
  } catch (e) {
    console.groupCollapsed('error in fetch')
    console.log(e)
    console.groupEnd()
  }
  console.groupEnd()
  return {}
}

export default {
  _headers,
  _url,
  _status,
  _data,
  _fetch,
}
