/* global React */
import {
  useRef,
  useContext, // useCallback, useEffect, useRef, useState,
} from 'react'
import ApplicationContext from '@platform/react/context/application'

/**
 * HoC for document based subscriptions
 *
 * @param Component
 * @return {function(*, *)}
 * @constructor
 */
const DocHOC = Component => (props, ref) => {
  const { socket: { doc: socket } } = useContext(ApplicationContext)
  const socketKey = useRef()
  React.useLayoutEffect(() => {
    socketKey.current = props.events.onOpen()
    const listener = props.events.onPublication
    socket.sub(socketKey.current, () => {
      socket.on(socketKey.current, listener)
    })
    return () => socket.unsub(socketKey.current, () => {
      socket.off(socketKey.current, listener)
    })
  }, [])

  return (
    <Component
      {...props}
      ref={ref}
    />
  )
}

export default DocHOC
