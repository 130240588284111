import config from 'lib/loader/require/config'
// import controller from 'lib/loader/require/controller'
import ui from 'lib/loader/require/ui'
import module from 'lib/loader/require/module'
import component from 'lib/loader/require/component'
import model from 'lib/loader/require/model'
import validator from 'lib/loader/require/validator'

/**
 * Library Require Loaders.
 *
 * Important:
 *  - to maximize efficiency, bundling process should be chunked
 *    based on paths provided in requireLoaders
 *
 * To use for bootstrapping either via
 * - direct imports,
 * - provided dependency,
 * - or exportable as a stand alone module
 *
 * Since those are bootstrappable,
 * a platform can and maybe should extend on the functionality, by...
 * a functional composition >:))
 *
 * ie, const someNativeAsyncLoader = pipe(
 *  importedLibRequireLoader,
 *  nativePlatformRequireLoader
 * )
 *
 * @memberOf Gaia#
 * @typedef requireLoaders
 // * @property {requireConfigLoader} config - {@link Gaia.requireLoaders.config}
 // * @property {requireControllerLoader} controller - {@link Gaia.requireLoaders.controller}
 // * @property {requireUiLoader} ui - {@link Gaia.requireLoaders.ui}
 // * @property {requireModuleLoader} module - {@link Gaia.requireLoaders.module}
 // * @property {requireModelLoader} model - {@link Gaia.requireLoaders.model}
 // * @property {requireValidatorLoader} validator - {@link Gaia.requireLoaders.validator}
 * @param {Gaia.requireLoaders.requireConfigLoader} config - {@link Gaia.requireLoaders.config}
 * @param {requireControllerLoader} module - {@link Gaia.requireLoaders.module}
 * @param {requireUiLoader} ui - {@link Gaia.requireLoaders.ui}
 * @param {requireModuleLoader} component - {@link Gaia.requireLoaders.component}
 * @param {requireModelLoader} model - {@link Gaia.requireLoaders.model}
 * @param {requireValidatorLoader} validator - {@link Gaia.requireLoaders.validator}
 */
const requireLoaders = {
  config,
  component,
  ui,
  module,
  model,
  validator,
}


export {
  config,
  component,
  ui,
  module,
  model,
  validator,
  requireLoaders as default,
}
