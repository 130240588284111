/* global G */

import { getFirstItem, setKey } from 'lib/util'

/**
 * Updates the users current cart on the server with the local one. If the cart has just been
 * finished, it will dispatch {@code G.CART, G.CACHE} event to remove it from the local adapter.
 *
 * Will dispatch {@code G.CART, G.DONE} and {@code G.NOTIFICATION, G.CREATE, 'cart'} events
 * afterward.
 *
 * @param {Gaia.Web.Application} app  the Platform Web Application
 * @returns {AttachmentEventListener}  an AttachmentEventListener
 */
const fn = app => async ({ detail = {} }) => {
  const data = detail[G.DATA]

  const adapter = app[G.ADAPTER][G.CART]
  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const { [G.API]: { type, version } = {} } = adapter[G.STATE] || {}

  try {
    const url = `/api/v${version}/${type}/${data.key}`
    const result = await httpAdapter[G.API].put({ url,
      params: {
        ...data,
        key: undefined,
      } })
    const response = getFirstItem(result)

    if (response.ok) {
      setKey({
        ...data,
        _rev: response.rev,
      }, G.DATA, adapter)

      const activeCart = adapter[G.DATA]

      // if we just finished a cart, we need to remove it from the local adapter
      if (activeCart.value.status === 80) {
        eventBus.dispatch(eventBus.type(G.CART, G.CACHE))
      } else {
        eventBus.dispatch(eventBus.type(G.CART, G.DONE), { [G.DATA]: activeCart })
      }

      eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.CREATE, 'cart'))
    }
  } catch (e) {
    console.error(e)
  }
}

export default fn
