/* eslint-disable no-unused-vars */
/* global G */

import { confirmDeleteDialog } from 'platform/adapter/notification/helper/dialog'

/**
 * Deletes all notifications and re-renders the list.
 *
 * @param {Gaia.Web.Application} app                the Platform Web Application
 * @param {string} groupName                        name of the group
 * @param {Gaia.Adapter.Notification.Group}  group  the group itself
 * @returns {(function({detail: *}): Promise<*|null>)|*}
 */
const fn = (app, groupName = '', group = {}) => async ({ detail }) => {
  const { [G.STATE]: groupState, [G.DATA]: groupData } = group
  const { [G.API]: { api, types, version } = {} } = groupState

  const httpAdapter = app[G.ADAPTER][G.HTTP]
  const eventBus = app[G.ADAPTER][G.EVENTS]

  const confirm = await confirmDeleteDialog(app)

  try {
    if (confirm) {
      const url = `/api/v${version}/${api}/${types.setDeletedAll}`
      const result = await httpAdapter[G.API].put({ url })

      if (result) {
        groupData.data = []
        groupData.reload = {}
      }

      eventBus.dispatch(eventBus.type(G.NOTIFICATION, G.CREATE, groupName), { [G.DATA]: { count: groupData.length } })
    }
  } catch (e) {
    console.error(e)
  }
}

export default fn
