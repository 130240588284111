/* global G */
import { asyncpipe, setKey } from 'lib/util'
import cancelOnError from 'lib/sequence/model/cancelOnError'
import create from 'lib/sequence/model/api/create'
import read from 'lib/sequence/model/api/read'
import reset from 'lib/sequence/model/api/reset'
import { empty } from 'lib/util/object'

const descriptor = 'sequence::model::refresh'

const component = {
  [G.CHILDREN]: [],
}

/**
 * Regenerates {@param obj}'s cache from its own state data.
 *
 * @param {Gaia.Model.Spec} obj the model object composition to regenerate the cache to
 * @returns {Promise<*>}
 */
const refreshFromStateData = async (obj) => {
  create(obj)
  // transform(obj)
  const data = obj[G.STATE][G.DATA]
  const cache = !empty(data) ? data : null
  setKey(cache, G.CACHE, obj)
  await read(obj)(component)
  return obj
}

/**
 * Regenerates {@param obj}'s cache from data.
 *
 * @param {Gaia.Model.Spec} obj the model object composition to regenerate the cache to
 * @returns {function({key: *, data?: *}): Promise<*>}
 */
const refreshFromData = obj => async ({ key, ...data }) => {
  reset(obj)
  const cache = !empty(data) ? { key, ...data } : null
  setKey(cache, G.CACHE, obj)
  setKey(key || null, G.REF, obj[G.STATE])
  await read(obj)(component)
  return obj
}

/**
 * Regenerates {@param obj}'s cache from {@param item} or from its own state data, if no
 * {@param item} is passed.
 *
 * @param {Gaia.Model.Spec} obj       the model object composition to regenerate the cache to
 * @param {object} [item]             data to fill the model's cache with, instead of its G.STATE
 *                                    G.DATA
 * @return {Promise<Gaia.Model.Spec>} {@param obj}
 */
const refresh = async (obj, item) => await asyncpipe(
  cancelOnError(descriptor),
  async model => (item
    ? await refreshFromData(model)(item)
    : await refreshFromStateData(model)),
)(obj)

export default refresh
