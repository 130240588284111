/* global G */
import { pipe } from 'lib/util'
import { hasNamespace } from 'trait/has'
import { usesNamespace, usesGetterSetter } from 'trait/uses'
import { withConfiguration, withGetterSetter, withDescriptor } from 'trait/with'

/**
 * quick and dirty validator trait composition
 * to be used in factory.validator
 * to be used in model validator asap!!! todo: @alv, dude, get a move on!
 * @type {*}
 */
const validatorComposition = obj => pipe(
  withConfiguration,
  withDescriptor(obj.type),
  withGetterSetter(G.FN),
  usesGetterSetter(G.FN, obj.fn),
  hasNamespace(G.PROPS),
  usesNamespace(G.PROPS, { ...obj.options }),
  hasNamespace(G.STATE),
)(obj)

export default validatorComposition
